import { globalSettingsViewById, updateFromList, deleteFromList, getAllGlobalSettings } from '../globalSettings';
import { toast } from "react-toastify";

const listApi = async () => {
  try {
    const response = await getAllGlobalSettings()
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
};
const viewByIdApi = async (id) => {
  try {
    const response = await globalSettingsViewById(id)
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
};
const editApi = async (values) => {
  try {
    const response = await updateFromList(values)
    if (response.status === 200) {
      toast.success(response.data.message);
      return response.data.data.token
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    return Promise.reject(error)
  }
};
const deleteApi = async (values) => {
  try {
    const response = await deleteFromList(values)
    if (response.status === 200) {
      toast.success(response.data.message);
      return response.data.data.token
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    return Promise.reject(error)
  }
};

export {
  listApi,
  editApi,
  deleteApi,
  viewByIdApi

}
