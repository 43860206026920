import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbItem } from 'reactstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Link } from 'react-router-dom';

function Breadcrumb(props) {
  const borderBottomStyle = props.breadcrumbItem === 'Stats' ? '1px solid #CAD2DF' : 'none';
  return (
    <div className="page-title-box d-flex align-items-center justify-content-between w-100" 
    style={{ borderBottom: borderBottomStyle }}
    >
      <h4 className="mb-0" style={{ color: '#565656' }}>{props.breadcrumbItem}</h4>
      <div className="page-title-right">
        <ol className="breadcrumb m-0">
          <BreadcrumbItem>
            <Link to="#">{props.title}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <Link to="#">{props.breadcrumbItem}</Link>
          </BreadcrumbItem>
        </ol>
      </div>
    </div>
  );
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
