import React, { useEffect, useState } from 'react';
import {
  Row, Col, Card, CardBody, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import {
  Table, Tbody, Tr, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PaginationComponent from '../../components/Common/PaginationComponent';
import { ACTIONS, ALL_MODULES, DEFAULT_PER_PAGE_VALUE, MODULE, OPERATION_TYPE, PROPERTY_REQUEST_STATUS } from '../../common/constant';
import CircularProgress from '@mui/material/CircularProgress';
import NodataFound from '../../components/Common/NoDataFound';
import { getAllPropertyRequests, updatePropertyRequest } from '../../api/property-request';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import AddOrEditOrViewNoteModal from '../../components/Common/AddOrEditOrViewNoteModal';
import Search from '../../components/Common/GlobalSearch';
import PropertyRequestFilter from '../../components/NewPropertyRequest/propertyRequestFilterDrawer';
import SwipeIcon from "../../assets/images/icons/Swipe.svg"
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify"
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ViewPropertyRequestDrawer } from '../../components/NewPropertyRequest/viewPropertyRequestDrawer';
import TableHeaders from '../../components/Common/TableHeaders';
import AddNewPropertyRequestDrawer from '../../components/NewPropertyRequest/AddNewPropertyRequestDrawer';
import { connect } from 'react-redux';
import { usePermissionGiven } from '../../components/Hooks/UserPermission';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import { deletePropertyRequestHandler } from '../../api/NewPropertyRequest/api';
import NoDataIcon from "../../assets/images/no-data.png";
import { createTheme } from '@mui/material';

function NewPropertyRequest() {
  const [paginationMeta, setPaginationMeta] = useState({})
  const [searchItem, setSearchItem] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE)
  const [page, setPage] = useState(1)
  const [update, setUpdate] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [filterInputs, setFilterInputs] = useState([])
  const [filterFields, setFilterFields] = useState([]);

  const [isAddPropertyRequest, setIsAddPropertyRequest] = useState(false);
  const [isPropertyRequestFilter, seIsPropertyRequestFilter] = useState(false);
  const [propertyRequestList, setPropertyRequestList] = useState([]);
  const [selectedRow, setSelectedRow] = useState();

  const [noteModalActionAndPayload, setNoteModalActionAndPayload] = useState({
    action: null,
    item: null
  })
  const [noteModal, setNoteModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [isOpenSweetAlert, setIsOpenSweetAlert] = useState(false);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [sorts, setSorts] = useState({});
  const [menu, setMenu] = useState([false, false]);
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });
  const hasCreatePermission = usePermissionGiven(ALL_MODULES.ADD_PROPERTY_REQUESTS, OPERATION_TYPE.CREATE);
  const hasEditPermission = usePermissionGiven(ALL_MODULES.ADD_PROPERTY_REQUESTS, OPERATION_TYPE.UPDATE);
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.ADD_PROPERTY_REQUESTS, OPERATION_TYPE.DELETE);
  const colors = createTheme()?.palette;

  useEffect(() => {
    fetchAllPropertyRequestList()
  }, [sorts])

  const handleNoteModalToggle = (action, item, modalState) => {
    setNoteModalActionAndPayload({
      action,
      item,
      module: MODULE.PROPERTY_REQUEST
    })
    setNoteModal(prev => {
      if (modalState) {
        return modalState
      }
      return !prev
    })
  }

  const propertyRequestListApi = async (params) => {
    try {
      setIsLoading(true)
      const response = (await getAllPropertyRequests(params)).data.data
      setPaginationMeta(response.meta)
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response?.data?.map((res) => {
        return {
          indexNo: ++level,
          id: res?.id,
          name: res?.name,
          near_to: res?.near_to,
          landmark: res?.landmark,
          room_count: res?.room_count,
          status: res?.status,
          type: res?.type,
          amenities: res?.amenities,

          address_line_1: res?.address?.address_line_1,
          address_line_2: res?.address?.address_line_2,
          city: res?.address?.city,
          district: res?.address?.district,
          state: res?.address?.state,
          country: res?.address?.country,
          zip_code: res?.address?.zip_code,

          owner_id: res?.owner?.id,
          owner_user_id: res?.owner_user_id,
          owner_name: res?.owner?.name,
          owner_contact_number: res?.owner?.contact_number,
          owner_profile_photo: res?.owner?.profile_photo,
          code: res?.owner?.code,

          creator_id: res?.creator?.id,
          creator_name: res?.creator?.name,
          creator_contact_number: res?.creator?.contact_number,
          creator_profile_photo: res?.creator?.profile_photo,

          documents: res?.documents,
          note: res?.note
        };
      });
      return resultData
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAllPropertyRequestList = async (paramsData = '', paginatedData = { page, limit: rowsPerPage }) => {
    const params = {
      ...paginatedData,
      ...(paramsData && {
        ...paramsData
      }),
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields && filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs
      }),
      ...(sorts?.order && sorts?.direction && {
        ...sorts
      })
    }
    const resultData = await propertyRequestListApi(params)
    if (resultData) setPropertyRequestList(resultData);
  }

  const globalSearch = async (params) => {
    setPropertyRequestList([]);
    setSearchItem(params)
    setPage(1)
    setPaginationMeta()
    setUpdate((pre) => pre + 1)
    const resultData = await propertyRequestListApi({
      ...params,
      limit: params.limit,
      page: params.page,
      ...(filterFields && filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs
      }),
      ...(params.search && {
        search: params.search,
      }),
      ...(sorts?.order && { order: sorts.order }),
      ...(sorts?.direction && { direction: sorts.direction }),
    })
    setPropertyRequestList(resultData)
  };

  const paginationModelMethod = async (data) => {
    let params = {
      ...(searchItem?.search && {
        ...searchItem
      }),
      ...(filterFields && filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs
      }),
      ...(sorts?.order && { order: sorts.order }),
      ...(sorts?.direction && { direction: sorts.direction }),
    }
    if (data?.page) {
      setPage(data.page)
      params.page = data.page
      fetchAllPropertyRequestList(params, { page: data.page, limit: rowsPerPage })
    } else {
      setUpdate(prev => prev + 1)
      setPage(1)
      setRowsPerPage(data?.limit)
      params.limit = data.limit
      fetchAllPropertyRequestList(params, { page: data.page, limit: data?.limit })
    }
  };

  const toggleDrawer = () => {
    seIsPropertyRequestFilter(!isPropertyRequestFilter)
  }

  const toggleViewDrawer = () => {
    setIsViewDetails(!isViewDetails)
    setSelectedRow();
  }

  const filter = async (filterFields, filterInputs) => {
    setPropertyRequestList([]);
    setPage(1)
    setPaginationMeta()
    setUpdate((pre) => pre + 1)
    setFilterInputs(filterInputs)
    setFilterFields(filterFields)
    const resultData = await propertyRequestListApi({
      limit: rowsPerPage,
      ...(searchItem && {
        ...searchItem,
      }),
      filter_fields: filterFields,
      filter_inputs: filterInputs,
      ...(sorts?.order && { order: sorts.order }),
      ...(sorts?.direction && { direction: sorts.direction }),
    });
    setPropertyRequestList(resultData)
  }

  const toggleMenu = (index) => {
    const newMenuStates = [...menu];
    newMenuStates[index] = !newMenuStates[index];
    setMenu(newMenuStates);
  };

  const handleSubmit = async ({ note }) => {
    try {
      const payload = {
        note: note,
        ...(selectedStatus && {
          status: selectedStatus
        })
      }
      const response = await updatePropertyRequest(selectedRow.id, payload)
      if (response.status === 200) {
        toast.success(response.data.message);
        refresh();
      } else {
        toast.error(response.data.message);
      }

      setNoteModal(false)
    } catch (error) {
      toast.error(error.response.data.message);
      setNoteModal(false)
    } finally {
      setIsLoading(false);
    }
  }

  const refresh = async () => {
    setPropertyRequestList([]);
    setSelectedRow();
    setPage(1);
    const resultData = await propertyRequestListApi({
      limit: rowsPerPage,
      page: page,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields && filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sorts?.order && sorts?.direction && {
        ...sorts
      })
    })
    setPropertyRequestList(resultData)
  };

  const handleSort = async (sort) => {
    setPropertyRequestList([]);
    setSorts(sort);
  };

  const statusColor = (status) => {
    switch (status) {
      case PROPERTY_REQUEST_STATUS.APPROVED:
        return <Chip label={status} sx={{ bgcolor: colors.success.main, color: colors.success.contrastText, fontSize: '10px' }} />;
      case PROPERTY_REQUEST_STATUS.REJECTED:
        return <Chip label={status} sx={{ bgcolor: colors.error.main, color: colors.error.contrastText, fontSize: '10px' }} />;
      default:
        return <Chip label={status} sx={{ bgcolor: colors.warning.main, color: colors.warning.contrastText, fontSize: '10px' }} />;
    }
  };

  // Add new property request drawer
  const toggleAddPropertyRequest = () => {
    if (isAddPropertyRequest) {
      setSelectedRow();
    }
    setIsAddPropertyRequest(!isAddPropertyRequest);
  };

  const columns = [
    { column_name: `Property's Name`, key_name: 'name', sort_enabled: true, style: { minWidth: '150px' } },
    { column_name: `Property's Type`, key_name: 'type', sort_enabled: true, style: { minWidth: '145px' } },
    { column_name: `Owner's Name`, key_name: 'owner_name', sort_enabled: true, style: { minWidth: '170px' } },
    { column_name: `Owner's Contact No`, key_name: 'owner_phone_number', sort_enabled: true, style: { minWidth: '150px' } },
    { column_name: `Property's Status`, key_name: 'status', sort_enabled: true, style: { minWidth: '150px' } },
    { column_name: `Room's Count`, key_name: 'room_count', sort_enabled: true, style: { minWidth: '125px' } },
    { column_name: 'Note', key_name: 'note', sort_enabled: false, style: { minWidth: '125px' } },
  ]

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="flexbox_holder">
                <div className='app-search'>
                  <Search
                    listCall={globalSearch}
                    params={{
                      limit: rowsPerPage,
                      page: 1,
                      order: sorts?.order,
                      direction: sorts?.direction,
                      filter_fields: filterFields,
                      filter_inputs: filterInputs,
                    }}
                  />
                </div>

                <div>
                  <Button className="yellow_gradient_btn me-2" onClick={toggleDrawer}>
                    <i className="mdi mdi-filter-variant" />
                    Filter
                  </Button>
                  {hasCreatePermission &&
                    (<Button color='primary' onClick={toggleAddPropertyRequest}>
                      <i className='mdi mdi-plus' />
                      Property Request
                    </Button>)}
                </div>
              </div>
              <Card className="table_card_holder">
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >

                      {propertyRequestList.length === 0 && isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}
                      {propertyRequestList.length === 0 && !isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <NodataFound text="No new property request to show" icon={NoDataIcon} />
                        </div>
                      )}
                      {propertyRequestList?.length > 0 && (
                        <Table
                          id="tech-companies-1"
                          className="table table-bordered"
                        >
                          <TableHeaders
                            headers={columns}
                            sortBy={sorts ?? ''}
                            emitSortedColumn={handleSort}
                            module={ALL_MODULES.ADD_PROPERTY_REQUESTS}
                          />
                          <Tbody>
                            {propertyRequestList?.map((item, index) => (
                              <Tr key={index}>
                                <Td>{item.indexNo}</Td>
                                <Td className="cursor_pointer"
                                  onClick={() => {
                                    setSelectedRow(item)
                                    setIsViewDetails(true)
                                  }}
                                >{item.name}</Td>
                                <Td>{item.type}</Td>
                                <Td>{item?.owner_name}</Td>
                                <Td>{item?.owner_contact_number}</Td>
                                <Td >{statusColor(item.status)}</Td>
                                <Td>{item.room_count}</Td>
                                <Td className="d-flex justify-content-center">{item?.note ? (
                                  <DescriptionIcon titleAccess='Show Note'
                                    style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer' }}
                                    fontSize={'inherit'} color='info' onClick={() => {
                                      setSelectedStatus()
                                      setSelectedRow(item)
                                      handleNoteModalToggle(ACTIONS.VIEW, item)
                                    }} />
                                ) :
                                  hasEditPermission &&
                                  (<NoteAddIcon titleAccess='Add Note'
                                    style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer' }}
                                    fontSize={'inherit'} color='error'
                                    onClick={() => {
                                      setSelectedStatus()
                                      setSelectedRow(item)
                                      handleNoteModalToggle(ACTIONS.CREATE, item)
                                    }} />)
                                }</Td>

                                {(hasEditPermission || hasDeletePermission) ?
                                  (<Td>
                                    <Dropdown
                                      isOpen={menu[index]}
                                      toggle={() => toggleMenu(index)}
                                      className="d-inline-block"
                                    >
                                      <DropdownToggle
                                        className="btn waves-effect"
                                        id="page-header-user-dropdown"
                                        tag="button"
                                      >
                                        <i className="mdi mdi-dots-vertical font-size-15" />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                        {hasEditPermission &&
                                          (<DropdownItem tag="a" href="#" onClick={() => {
                                            setSelectedRow(item)
                                            setIsOpenSweetAlert(true)
                                          }}>
                                            <img src={SwipeIcon} alt={SwipeIcon} />
                                            <span>Change status </span>
                                          </DropdownItem>)}
                                        {hasEditPermission &&
                                          (<DropdownItem tag="a" href="#" onClick={(e) => {
                                            e.preventDefault()
                                            setSelectedRow(item);
                                            setIsAddPropertyRequest(true);
                                          }}>
                                            <i className='mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1' />
                                            <span> Edit </span>
                                          </DropdownItem>)}
                                        <DropdownItem tag="a" href="#" onClick={() => {
                                          setSelectedRow(item)
                                          setIsViewDetails(true)
                                        }}>
                                          <VisibilityIcon />
                                          <span> View </span>
                                        </DropdownItem>
                                        {/* <div className="dropdown-divider" /> */}

                                        {hasDeletePermission &&
                                          (<DropdownItem onClick={() => setDeleteConfirm({ status: true, id: item.id })}>
                                            <i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                                            <span> Delete </span>
                                          </DropdownItem>)
                                        }

                                      </DropdownMenu>
                                    </Dropdown>
                                  </Td>
                                  ) : null}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                    </div>
                  </div>

                  {
                    noteModal && <AddOrEditOrViewNoteModal handleNoteModalToggle={handleNoteModalToggle} handleSubmit={handleSubmit} noteModalActionAndPayload={noteModalActionAndPayload} />
                  }
                  {propertyRequestList.length > 0 && (<div className="numbers_pagination_holder">
                    <PaginationComponent
                      paginationMeta={paginationMeta}
                      handleChangePagination={paginationModelMethod}
                      update={update}
                    >
                    </PaginationComponent>
                  </div>)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <PropertyRequestFilter
        isOpen={isPropertyRequestFilter}
        toggleDrawer={toggleDrawer}
        emitFilterItem={filter}
      />

      {isViewDetails && <ViewPropertyRequestDrawer isOpen={isViewDetails} toggleDrawer={toggleViewDrawer} selectedRow={selectedRow} />}
      {/* Delete confirmation modal */}
      {isDeleteConfirm.status &&
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await deletePropertyRequestHandler(
              isDeleteConfirm.id,
            );
            if (isDeleted) {
              refresh();
            }
            setDeleteConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}
      {
        isAddPropertyRequest &&
        (
          <AddNewPropertyRequestDrawer
            isOpen={isAddPropertyRequest}
            toggleDrawer={toggleAddPropertyRequest}
            refresh={refresh}
            selectedPropertyRequestData={selectedRow}
            action={selectedRow ? ACTIONS.EDIT : ACTIONS.CREATE}
          />
        )

      }

      {isOpenSweetAlert && (
        <SweetAlert
          title="Do you want to change this property status?"
          info
          customButtons={
            <React.Fragment>
              <Button onClick={() => setIsOpenSweetAlert(false)} style={{ backgroundColor: '#008CBA', marginRight: '4px', border: 'unset' }}>Cancel</Button>
              <Button onClick={() => {
                setSelectedStatus(PROPERTY_REQUEST_STATUS.APPROVED)
                setIsOpenSweetAlert(false)
                handleNoteModalToggle(selectedRow.note ? ACTIONS.EDIT : ACTIONS.CREATE, selectedRow)
              }} style={{ backgroundColor: '#04AA6D', marginRight: '4px', border: 'unset' }} >Approve</Button>
              <Button
                onClick={() => {
                  setSelectedStatus(PROPERTY_REQUEST_STATUS.REJECTED)
                  setIsOpenSweetAlert(false)
                  handleNoteModalToggle(selectedRow.note ? ACTIONS.EDIT : ACTIONS.CREATE, selectedRow)
                }}
                style={{ backgroundColor: '#f44336', marginRight: '4px', border: 'unset' }}
              >
                Reject</Button>
            </React.Fragment>
          }
        ></SweetAlert>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.AuthLogin.auth.user,
});

export default connect(mapStateToProps, null)(NewPropertyRequest);
