import product1 from '../../assets/images/product/img-1.png';
import product2 from '../../assets/images/product/img-2.png';
import product3 from '../../assets/images/product/img-3.png';
import product4 from '../../assets/images/product/img-4.png';
import product5 from '../../assets/images/product/img-5.png';
import product6 from '../../assets/images/product/img-6.png';

import avatar2 from '../../assets/images/users/avatar-2.jpg';
import avatar3 from '../../assets/images/users/avatar-3.jpg';
import avatar4 from '../../assets/images/users/avatar-4.jpg';
import avatar5 from '../../assets/images/users/avatar-5.jpg';
import avatar6 from '../../assets/images/users/avatar-6.jpg';
import avatar7 from '../../assets/images/users/avatar-7.jpg';
import avatar8 from '../../assets/images/users/avatar-8.jpg';

const productsData = [
  {
    id: 1,
    image: product1,
    name: 'Nike N012 Shoes',
    link: '#',
    rating: 4,
    category: 'Nike',
    currentcolor: 'Gray',
    islable: true,
    lable: 'Trending',
    oldPrice: 280,
    newPrice: 260,
    isOffer: false,
    offer: 0,
    reviews: 234,
    colors: ['dark', 'light', 'primary'],
    shortspecifications: [
      'High Quality',
      'Leather',
      'All Sizes available',
      '4 Different Color',
    ],
    decription:
      'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: 'exchange', value: '10 Days Replacement' },
      { icon: 'bill', value: 'Cash on Delivery available' },
    ],
    extraimgs: [product2, product3, product4],
    specification: [
      { type: 'Category', value: 'Shoes' },
      { type: 'Brand', value: 'Nike' },
      { type: 'Color', value: 'Gray' },
      { type: 'Quality', value: 'High' },
      { type: 'Material', value: 'Leather' },
    ],
    colorOptions: [
      { image: product1, color: 'Red' },
      { image: product2, color: 'Dark' },
      { image: product3, color: 'Purple' },
    ],
  },
  {
    id: 2,
    image: product2,
    name: 'Adidas Running Shoes',
    link: '#',
    rating: 3,
    category: 'Nike',
    currentcolor: 'Black',
    islable: false,
    lable: '',
    oldPrice: 250,
    newPrice: 240,
    isOffer: true,
    offer: 20,
    colors: ['danger', 'dark', 'light'],
    reviews: 100,
    shortspecifications: [
      'High Quality',
      'Leather',
      'All Sizes available',
      '4 Different Color',
    ],
    decription:
      'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: 'exchange', value: '10 Days Replacement' },
      { icon: 'bill', value: 'Cash on Delivery available' },
    ],
    extraimgs: [product1, product3, product4],
    specification: [
      { type: 'Category', value: 'Shoes' },
      { type: 'Brand', value: 'Nike' },
      { type: 'Color', value: 'Black' },
      { type: 'Quality', value: 'High' },
      { type: 'Material', value: 'Leather' },
    ],
    colorOptions: [
      { image: product2, color: 'Red' },
      { image: product4, color: 'Dark' },
      { image: product3, color: 'Purple' },
    ],
  },
  {
    id: 3,
    image: product3,
    rating: 5,
    name: 'Puma P103 Shoes',
    link: '#',
    category: 'Nike',
    currentcolor: 'Purple',
    islable: false,
    lable: '',
    oldPrice: 260,
    newPrice: 250,
    isOffer: false,
    offer: 0,
    colors: ['purple', 'light', 'dark'],
    reviews: 200,
    shortspecifications: [
      'High Quality',
      'Leather',
      'All Sizes available',
      '4 Different Color',
    ],
    decription:
      'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: 'exchange', value: '10 Days Replacement' },
      { icon: 'bill', value: 'Cash on Delivery available' },
    ],
    extraimgs: [product1, product2, product4],
    specification: [
      { type: 'Category', value: 'Shoes' },
      { type: 'Brand', value: 'Nike' },
      { type: 'Color', value: 'Black' },
      { type: 'Quality', value: 'High' },
      { type: 'Material', value: 'Leather' },
    ],
    colorOptions: [
      { image: product3, color: 'Red' },
      { image: product4, color: 'Dark' },
      { image: product1, color: 'Purple' },
    ],
  },
  {
    id: 4,
    image: product4,
    rating: 1,
    name: 'Sports S120 Shoes',
    link: '#',
    category: 'Nike',
    currentcolor: 'Cyan',
    islable: false,
    lable: '',
    oldPrice: 240,
    newPrice: 230,
    isOffer: false,
    offer: 0,
    colors: ['info', 'success'],
    reviews: 150,
    shortspecifications: [
      'High Quality',
      'Leather',
      'All Sizes available',
      '4 Different Color',
    ],
    decription:
      'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: 'exchange', value: '10 Days Replacement' },
      { icon: 'bill', value: 'Cash on Delivery available' },
    ],
    extraimgs: [product1, product2, product3],
    specification: [
      { type: 'Category', value: 'Shoes' },
      { type: 'Brand', value: 'Nike' },
      { type: 'Color', value: 'Cyan' },
      { type: 'Quality', value: 'High' },
      { type: 'Material', value: 'Leather' },
    ],
    colorOptions: [
      { image: product4, color: 'Red' },
      { image: product1, color: 'Dark' },
      { image: product2, color: 'Purple' },
    ],
  },
  {
    id: 5,
    image: product5,
    rating: 2,
    name: 'Adidas AB23 Shoes',
    link: '#',
    category: 'Nike',
    currentcolor: 'Blue',
    islable: false,
    lable: '',
    oldPrice: 240,
    newPrice: 250,
    isOffer: false,
    offer: 0,
    colors: ['dark', 'light', 'primary'],
    reviews: 170,
    shortspecifications: [
      'High Quality',
      'Leather',
      'All Sizes available',
      '4 Different Color',
    ],
    decription:
      'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: 'exchange', value: '10 Days Replacement' },
      { icon: 'bill', value: 'Cash on Delivery available' },
    ],
    extraimgs: [product5, product2, product3],
    specification: [
      { type: 'Category', value: 'Shoes' },
      { type: 'Brand', value: 'Nike' },
      { type: 'Color', value: 'Blue' },
      { type: 'Quality', value: 'High' },
      { type: 'Material', value: 'Leather' },
    ],
    colorOptions: [
      { image: product5, color: 'Red' },
      { image: product2, color: 'Dark' },
      { image: product3, color: 'Purple' },
    ],
  },
  {
    id: 6,
    image: product6,
    rating: 5,
    name: 'Nike N012 Shoes',
    link: '#',
    category: 'Nike',
    currentcolor: 'Gray',
    islable: false,
    lable: '',
    oldPrice: 270,
    newPrice: 260,
    isOffer: true,
    offer: 20,
    colors: ['dark', 'light'],
    reviews: 80,
    shortspecifications: [
      'High Quality',
      'Leather',
      'All Sizes available',
      '4 Different Color',
    ],
    decription:
      'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: 'exchange', value: '10 Days Replacement' },
      { icon: 'bill', value: 'Cash on Delivery available' },
    ],
    extraimgs: [product6, product4, product3],
    specification: [
      { type: 'Category', value: 'Shoes' },
      { type: 'Brand', value: 'Nike' },
      { type: 'Color', value: 'Gray' },
      { type: 'Quality', value: 'High' },
      { type: 'Material', value: 'Leather' },
    ],
    colorOptions: [
      { image: product6, color: 'Red' },
      { image: product4, color: 'Dark' },
      { image: product3, color: 'Purple' },
    ],
  },
];

const comments = [
  {
    id: 1,
    name: 'Samuel',
    description:
      'It will be as simple as in fact, it will be Occidental. It will seem like simplified',
    date: '12 July, 2020',
    review: '4.1',
  },
  {
    id: 2,
    name: 'Joseph',
    description: 'Sed ut perspiciatis unde omnis iste natus error sit',
    date: '06 July, 2020',
    review: '4.0',
  },
  {
    id: 3,
    name: 'Paul',
    description:
      'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet',
    date: '26 June, 2020',
    review: '4.2',
  },
];

const discountData = [
  { label: '50% or more', value: 0 },
  { label: '40% or more', value: 10 },
  { label: '30% or more', value: 20 },
  { label: '20% or more', value: 30 },
  { label: '10% or more', value: 40 },
  { label: 'Less than 10%', value: 50 },
];

const colorData = [
  { label: 'Black', value: 'dark', icon: 'mdi mdi-circle text-dark mx-1' },
  { label: 'White', value: 'light', icon: 'mdi mdi-circle text-light mx-1' },
  { label: 'Gray', value: 'secondary', icon: 'mdi mdi-circle text-secondary mx-1' },
  { label: 'Blue', value: 'primary', icon: 'mdi mdi-circle text-primary mx-1' },
  { label: 'Green', value: 'success', icon: 'mdi mdi-circle text-success mx-1' },
  { label: 'Red', value: 'danger', icon: 'mdi mdi-circle text-danger mx-1' },
  { label: 'Yellow', value: 'warning', icon: 'mdi mdi-circle text-warning mx-1' },
  { label: 'Purple', value: 'purple', icon: 'mdi mdi-circle text-purple mx-1' },
];

const orderData = [
  {
    id: 'ordercheck1',
    orderId: '#MN0132',
    billingName: 'Melvin Martin',
    orderdate: '10 Jul, 2020',
    total: '$142',
    badgeclass: 'success',
    paymentStatus: 'Paid',
  },
  {
    id: 'ordercheck2',
    orderId: '#MN0131',
    billingName: 'Roy Michael',
    orderdate: '09 Jul, 2020',
    total: '$130',
    badgeclass: 'danger',
    paymentStatus: 'Paid',
  },
  {
    id: 'ordercheck3',
    orderId: '#MN0130',
    billingName: 'Shelby Wolf',
    orderdate: '08 Jul, 2020',
    total: '$123',
    badgeclass: 'success',
    paymentStatus: 'unpaid',
  },
  {
    id: 'ordercheck4',
    orderId: '#MN0129',
    billingName: 'James Riddick',
    orderdate: '07 Jul, 2020',
    total: '$173',
    badgeclass: 'success',
    paymentStatus: 'Paid',
  },
  {
    id: 'ordercheck5',
    orderId: '#MN0128',
    billingName: 'George Kwan',
    orderdate: ' 07 Jul, 2020',
    total: '$160',
    badgeclass: 'warning',
    paymentStatus: 'Chargeback',
  },
  {
    id: 'ordercheck6',
    orderId: '#MN0127',
    billingName: 'Kevin Patterson',
    orderdate: '06 Jul, 2020',
    total: ' $165',
    badgeclass: 'success',
    paymentStatus: 'Paid',
  },
  {
    id: 'ordercheck7',
    orderId: '#MN0126',
    billingName: 'Danny Orr',
    orderdate: '05 Jul, 2020',
    total: '$161',
    badgeclass: 'success',
    paymentStatus: 'Paid',
  },
  {
    id: 'ordercheck8',
    orderId: '#MN0125',
    billingName: 'Sylvia Garcia',
    orderdate: ' 04 Jul, 2020',
    total: '$153',
    badgeclass: 'success',
    paymentStatus: 'unpaid',
  },
  {
    id: 'ordercheck9',
    orderId: '#MN0124',
    billingName: 'Charles Denney',
    orderdate: ' 04 Jul, 2020',
    total: '$152',
    badgeclass: 'warning',
    paymentStatus: 'Paid',
  },
  {
    id: 'ordercheck10',
    orderId: '#MN0123',
    billingName: 'Lisa Farrell',
    orderdate: '03 Jul, 2020',
    total: '$167',
    badgeclass: 'success',
    paymentStatus: 'Paid',
  },
  {
    id: 'ordercheck11',
    orderId: '#MN0122',
    billingName: 'Connie Franco',
    orderdate: '02 Jul, 2020',
    total: '$163',
    badgeclass: 'danger',
    paymentStatus: 'Paid',
  },
  {
    id: 'ordercheck12',
    orderId: '#MN0121',
    billingName: 'Lara Casillas',
    orderdate: ' 02 Jul, 2020',
    total: '$171',
    badgeclass: 'success',
    paymentStatus: 'Paid',
  },
];

const cartData = {
  products: [
    {
      id: 1,
      img: 'img1',
      name: 'Half sleeve T-shirt',
      color: 'Maroon',
      price: '450',
      data_attr: 2,
      total: 900,
    },
    {
      id: 2,
      img: 'img2',
      name: 'Light blue T-shirt',
      color: 'Light blue',
      price: '225',
      data_attr: 6,
      total: 225,
    },
    {
      id: 3,
      img: 'img3',
      name: 'Black Color T-shirt',
      color: 'Black',
      price: '152',
      data_attr: 2,
      total: 304,
    },
    {
      id: 4,
      img: 'img4',
      name: 'Hoodie (Blue)',
      color: 'Blue',
      price: '145',
      data_attr: 2,
      total: 290,
    },
    {
      id: 5,
      img: 'img5',
      name: 'Half sleeve T-Shirt',
      color: 'Light orange',
      price: '138',
      data_attr: 8,
      total: 138,
    },
    {
      id: 6,
      img: 'img6',
      name: 'Green color T-shirt',
      color: 'Green',
      price: '152',
      data_attr: 2,
      total: 304,
    },
  ],
  orderSummary: {
    grandTotal: '$ 1,857',
    discount: '$ 157',
    shippingCharge: '$ 25',
    estimatedTax: '$ 19.22',
    total: '$ 1744.22',
  },
};

const customerData = [
  {
    id: 1,
    customerid: '#MN0123',
    img: avatar2,
    customerName: 'William Shipp',
    email: 'WilliamShipp@jourrapide.com',
    joiningDate: '14 Apr, 2020',
    badgeclass: 'success',
    customerStatus: 'Active',
  },
  {
    id: 2,
    customerid: '#MN0122',
    isImg: true,
    customerName: 'Joe Hardy',
    email: 'JoeHardy@dayrep.com',
    joiningDate: '14 Apr, 2020',
    badgeclass: 'success',
    customerStatus: 'Active',
  },
  {
    id: 3,
    customerid: '#MN0121',
    img: avatar3,
    customerName: 'Thomas Hankins',
    email: 'ThomasHankins@dayrep.com',
    joiningDate: '13 Apr, 2020',
    badgeclass: 'success',
    customerStatus: 'Active',
  },
  {
    id: 4,
    customerid: '#MN0120',
    img: avatar4,
    customerName: 'Mary Frazier',
    email: 'MaryFrazier@armyspy.com',
    joiningDate: '12 Apr, 2020',
    badgeclass: 'danger',
    customerStatus: 'Deactive',
  },
  {
    id: 5,
    customerid: '#MN0119',
    isImg: true,
    customerName: 'Sam Perry',
    email: 'SamPerry@rhyta.com',
    joiningDate: '12 Apr, 2020',
    badgeclass: 'success',
    customerStatus: 'Active',
  },
  {
    id: 6,
    customerid: '#MN0118',
    img: avatar5,
    customerName: 'Muriel Myers',
    email: 'MurielMyers@rhyta.com',
    joiningDate: '09 Apr, 2020',
    badgeclass: 'danger',
    customerStatus: 'Deactive',
  },
  {
    id: 7,
    customerid: '#MN0117',
    isImg: true,
    customerName: 'Jessie Jacobs',
    email: 'JessieJacobs@teleworm.us',
    joiningDate: '09 Apr, 2020',
    badgeclass: 'success',
    customerStatus: 'Active',
  },
  {
    id: 8,
    customerid: '#MN0116',
    img: avatar6,
    customerName: 'Edward King',
    email: 'EdwardKing@teleworm.us',
    joiningDate: '08 Apr, 2020',
    badgeclass: 'success',
    customerStatus: 'Active',
  },
  {
    id: 9,
    customerid: '#MN0115',
    img: avatar7,
    customerName: 'Stacy Webster',
    email: 'StacyWebster@armyspy.com',
    joiningDate: '07 Apr, 2020',
    badgeclass: 'danger',
    customerStatus: 'Deactive',
  },
  {
    id: 10,
    customerid: '#MN0114',
    isImg: true,
    customerName: 'Amy McDonald',
    email: 'AmyMcDonald@jourrapide.com',
    joiningDate: '05 Apr, 2020',
    badgeclass: 'success',
    customerStatus: 'Active',
  },
  {
    id: 11,
    customerid: '#MN0113',
    isImg: true,
    customerName: 'Terry Brown',
    email: 'TerryBrown@dayrep.com',
    joiningDate: '02 Apr, 2020',
    badgeclass: 'success',
    customerStatus: 'Active',
  },
  {
    id: 12,
    customerid: '#MN0112',
    img: avatar8,
    customerName: 'Crissy Holland',
    email: 'CrissyHolland@armyspy.com',
    joiningDate: '23 Apr, 2020',
    badgeclass: 'danger',
    customerStatus: 'Deactive',
  },
];

const shops = [
  {
    id: 1,
    color: 'primary',
    name: 'Morrie Mages',
    subtitle: 'Clifton Taylor',
    product: 94,
    balance: '9,852',

  },
  {
    id: 2,
    color: 'warning',
    name: "Brendle's",
    subtitle: 'Karl Early',
    product: 62,
    balance: '7,952',
  },
  {
    id: 3,
    color: 'success',
    name: 'Tech Hifi',
    subtitle: 'Marion Glaze',
    product: 40,
    balance: '6,265',
  },
  {
    id: 4,
    color: 'danger',
    name: 'Lafayette',
    subtitle: 'Brent Johnson',
    product: 51,
    balance: '7,235',
  },
  {
    id: 5,
    color: 'info',
    name: 'Micro Design',
    subtitle: 'Kimberly Martinez',
    product: 34,
    balance: '4,223',

  },
  {
    id: 6,
    color: 'dark',
    name: 'Sportmart',
    subtitle: 'Sarah Stewart',
    product: 43,
    balance: '5,632',
  },
  {
    id: 7,
    color: 'success',
    name: 'Tech Hifi',
    subtitle: 'Lauren Doyle',
    product: 40,
    balance: '5,268',
  },
  {
    id: 8,
    color: 'primary',
    name: "Brendle's",
    subtitle: 'Elaina Torres',
    product: 31,
    balance: '3,965',
  },
  {
    id: 9,
    color: 'warning',
    name: 'Standard Sales',
    subtitle: 'Willie Farber',
    product: 60,
    balance: '7,425',
  },
];

const productColorData = [
  {
    id: 1,
    color: 'Gray',
    img: product1,
  },
  {
    id: 2,
    color: 'Dark',
    img: product2,
  },
  {
    id: 3,
    color: 'Purple',
    img: product3,
  },
];

const productListvar = [
  {
    id: 1,
    img: product1,
    name: 'Nike N012 Running Shoes',
    color: 'Gray',
    price: '260',
    size: '08',
    data_attr: 2,
    total: '520',
  },
  {
    id: 2,
    img: product2,
    name: 'Adidas Running Shoes',
    color: 'Black',
    price: '260',
    size: '09',
    data_attr: 1,
    total: '260',
  },
];

const orderSummary = [
  {
    id: 1,
    img: product1,
    productTitle: 'Nike N012 Running Shoes',
    price: 260,
    qty: 2,
  },
  {
    id: 2,
    img: product2,
    productTitle: 'Adidas Running Shoes',
    price: 260,
    qty: 1,
  },
];

export {
  productsData,
  comments,
  discountData,
  colorData,
  orderData,
  shops,
  customerData,
  cartData,
  productColorData,
  productListvar,
  orderSummary,
};
