import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import StatusSelectBox from '../../components/Tenants/StatusSelectBox';
import 'flatpickr/dist/themes/material_blue.css';
import TenantDetailDrawer from '../../components/Tenants/TenantDetailDrawer';
import {
  ACTIONS,
  ALL_MODULES,
  DEFAULT_PER_PAGE_VALUE,
  OPERATION_TYPE,
} from '../../common/constant';
import AddTenantDrawer from '../../components/Tenants/AddTenantDrawer';
import { getAllTenants } from '../../api/tenant';
import CircularProgress from '@mui/material/CircularProgress';
import NodataFound from '../../components/Common/NoDataFound';
import CountUp from 'react-countup';
import PaginationComponent from '../../components/Common/PaginationComponent';
import TenantFilter from '../../components/Tenants/TenantFilterDrawer';
import Search from '../../components/Common/GlobalSearch';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import { deleteTenantHandler, updateStats } from '../../api/Tenants/api';
import TableHeaders from '../../components/Common/TableHeaders';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { handlePasswordResetLink } from '../../api/User/reset';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { getAllGlobalSettings } from '../../api/globalSettings';
import { CustomizePayoutPercentage } from '../../components/Tenants/CustomizePayoutPercentage';
import { useLocation } from 'react-router-dom';
import { usePermissionGiven } from '../../components/Hooks/UserPermission';
import NoDataIcon from "../../assets/images/no-data.png";
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getLivingStatus } from '../../common/tenant';

function Tenant() {
  document.title = 'Tenants | Dstayz';

  const [paginationMeta, setPaginationMeta] = useState({});
  const [searchItem, setSearchItem] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterInputs, setFilterInputs] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  const [isAddTenant, setAddTenant] = useState(false);
  const [isTenantFilter, setTenantFilter] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [tenantId, setTenantId] = useState(null);
  const [isCustomizePayout, setIsCustomizePayout] = useState({
    id: null,
    status: null
  });
  const [payoutPercentage, setPayoutPercentage] = useState();
  const [stats, setStats] = useState({
    totalTenants: 0,
    totalIncompletePoliceTenants: 0,
    totalIncompleteAgreementTenants: 0,
    currentlyLivingTenant: 0,
  });
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });
  const [onMounted, setOnMounted] = useState(false);
  const [sort, setSort] = useState({});
  const [detailShowId, setDetailShowId] = useState(useLocation()?.state?.id);
  const [isSendConfirm, setIsSendConfirm] = useState({ status: false, id: null })
  const hasCreatePermission = usePermissionGiven(ALL_MODULES.TENANTS, OPERATION_TYPE.CREATE);
  const hasEditPermission = usePermissionGiven(ALL_MODULES.TENANTS, OPERATION_TYPE.UPDATE);
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.TENANTS, OPERATION_TYPE.DELETE);

  const Listbox = styled('ul')(
    ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 220px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
      };
      z-index: 99;
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `,
  );

  const ListboxItem = styled('li')(
    `padding: '10px'`
  )

  const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 10px;
    /* Add more styles as needed */
  }
`;

  useEffect(() => {
    fetchGlobalSettings()
  }, [])

  const fetchGlobalSettings = async () => {
    try {
      setIsLoading(true)
      const response = (await getAllGlobalSettings({ search: 'payout_percentage' })).data.data
      setPayoutPercentage(response.data[0].payload)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const tenantListApi = async (params) => {
    try {
      setIsLoading(true);
      const response = (await getAllTenants(params)).data.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          ...res,
          indexNo: ++level,
        };
      });
      return resultData;
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllTenantList = async (
    searchItem = '',
    paginatedData = { page, limit: rowsPerPage },
  ) => {
    const params = {
      ...paginatedData,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    };
    const resultData = await tenantListApi(params);
    setTenantList(resultData);
    if (!onMounted) {
      updateStats(setStats);
      setOnMounted(true);
    }
  };

  const globalSearch = async (params) => {
    setTenantList([]);
    setSearchItem(params);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    const resultData = await tenantListApi({
      ...params,
      ...(params.search && {
        search: params.search,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setTenantList(resultData);
  };

  const paginationModelMethod = async (data) => {
    let params = {
      ...(searchItem?.search && {
        ...searchItem,
      }),
    };
    if (data?.page) {
      setPage(data.page);
      params.page = data.page;
      fetchAllTenantList(params, { page: data.page, limit: rowsPerPage });
    } else {
      setUpdate((prev) => prev + 1);
      setPage(1);
      setRowsPerPage(data?.limit);
      params.limit = data.limit;
      fetchAllTenantList(params, { page: data.page, limit: data?.limit });
    }
  };

  const filter = async (filterFields, filterInputs) => {
    setTenantList([]);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    setFilterInputs(filterInputs);
    setFilterFields(filterFields);
    const resultData = await tenantListApi({
      limit: rowsPerPage,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setTenantList(resultData);
  };

  const refresh = async () => {
    setTenantList([]);
    setTenantId(null);
    setPage(1);
    const resultData = await tenantListApi({
      limit: rowsPerPage,
      page: 1,
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setTenantList(resultData);
    updateStats(setStats);
  };

  const handleSort = async (sort) => {
    setSort(sort);
    const resultData = await tenantListApi({
      ...(page && page),
      ...(rowsPerPage && { limit: rowsPerPage }),
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    })
    setTenantList(resultData);
  };

  // Add new tenant drawer
  const toggleAddTenant = () => {
    if (AddTenantDrawer) {
      setTenantId(null)
    }
    setAddTenant(!isAddTenant);
  };

  // Update tenant drawer handler
  const editTenantHandler = async (id) => {
    setTenantId(id);
    setAddTenant(true);
  };

  // Delete confirmation modal
  const deleteHandler = async (id) => {
    setDeleteConfirm({ status: true, id });
  };

  // tenant filter drawer
  const toggleTenantFilter = () => {
    setTenantFilter(!isTenantFilter);
  };

  // const toggleMenu = (index) => {
  //   const newMenuStates = [...menu];
  //   newMenuStates[index] = !newMenuStates[index];
  //   setMenu(newMenuStates);
  // };

  const columns = [
    // code
    {
      column_name: 'Tenant Id',
      key_name: 'code',
      sort_enabled: false,
    },
    // Name
    {
      column_name: 'Tenant\'s Name',
      key_name: 'name',
      sort_enabled: true,
    },
    // Contact Number
    {
      column_name: 'Contact Number',
      key_name: 'contact_number',
      sort_enabled: true,
    },
    // Living Status
    {
      column_name: 'Living Status',
      key_name: 'living_status',
      sort_enabled: false,
    },
    // Type
    {
      column_name: 'Type',
      key_name: 'type',
      sort_enabled: true,
    },
    // College/ Office Name
    // {
    //   column_name: 'College/ Office Name',
    //   key_name: 'organization_name',
    //   sort_enabled: true,
    // },
    // Emergency Contact Person
    // {
    //   column_name: "Emergency Contact Person",
    //   key_name: 'local_guardian_name',
    //   sort_enabled: true,
    // },
    // // Emergency Contact Number
    // {
    //   column_name: "Emergency Contact Number",
    //   key_name: 'local_guardian_contact_no',
    //   sort_enabled: true,
    // },
    // Agreement Status
    {
      column_name: 'Agreement Status',
      key_name: 'agreement_status',
      sort_enabled: false,
    },
    // Police Verification Status
    {
      column_name: 'Police Verification Status',
      key_name: 'police_verification_status',
      sort_enabled: false,
    },
  ];

  useEffect(() => {
    fetchAllTenantList();
  }, []);

  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          <Row className='d-grid' style={{ gridTemplateColumns: 'repeat(4, 1fr)' }}>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>Tenants</h4>
                </div>
                <h2 className='num_heading'>
                  <CountUp
                    end={stats.totalTenants}
                    separator=','
                    prefix=''
                    suffix=''
                  />
                </h2>
              </div>
            </div>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>
                    Police verification incomplete
                  </h4>
                </div>
                <h2 className='num_heading'>
                  <CountUp
                    end={stats.totalIncompletePoliceTenants}
                    separator=','
                    prefix=''
                    suffix=''
                  />
                </h2>
              </div>
            </div>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>
                    Agreement incomplete
                  </h4>
                </div>
                <h2 className='num_heading'>
                  <CountUp
                    end={stats.totalIncompleteAgreementTenants}
                    separator=','
                    prefix=''
                    suffix=''
                  />
                </h2>
              </div>
            </div>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>
                    Currently living
                  </h4>
                </div>
                <h2 className='num_heading'>
                  <CountUp
                    end={stats.currentlyLivingTenant}
                    separator=','
                    prefix=''
                    suffix=''
                  />
                </h2>
              </div>
            </div>
          </Row>
          <Row>
            <Col className='col-12'>
              <div className='flexbox_holder'>
                <div className='app-search' style={{ padding: '0 0 10px 0' }}>
                  <Search
                    listCall={globalSearch}
                    params={{
                      limit: rowsPerPage,
                      page: 1,
                      ...(sort?.order &&
                        sort?.direction &&
                      {
                        order: sort?.order,
                        direction: sort?.direction,
                      }),
                      ...(filterFields &&
                        filterInputs &&
                      {
                        filter_fields: filterFields,
                        filter_inputs: filterInputs,
                      })
                    }}
                  />
                </div>

                <div>
                  <Button
                    className='yellow_gradient_btn me-2'
                    onClick={toggleTenantFilter}>
                    <i className='mdi mdi-filter-variant' />
                    Filter
                  </Button>
                  {hasCreatePermission &&
                    (<Button color='primary' onClick={toggleAddTenant}>
                      <i className='mdi mdi-plus' />
                      Tenant
                    </Button>)}
                </div>
              </div>
              <Card className='table_card_holder'>
                <CardBody>
                  <div className='table-rep-plugin'>
                    <div
                      className='table-responsive mb-0'
                      data-pattern='priority-columns'>
                      {tenantList?.length === 0 && isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}

                      {tenantList?.length === 0 && !isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <NodataFound text='No tenant to show' icon={NoDataIcon} />
                        </div>
                      )}

                      {tenantList?.length > 0 && (
                        <Table
                          id='tech-companies-1'
                          className='table table-bordered'>
                          <TableHeaders
                            headers={columns}
                            sortBy={sort ?? ''}
                            emitSortedColumn={handleSort}
                            module={ALL_MODULES.TENANTS}
                          />
                          <Tbody>
                            {tenantList &&
                              tenantList.map((tenant) => {
                                const {livingInfo, livingStatus} = getLivingStatus(tenant);
                                return (<Tr key={tenant?.indexNo}>
                                  <Td>{tenant?.indexNo}</Td>
                                  <Td>
                                    {tenant?.user?.code
                                      ? tenant?.user?.code
                                      : 'N/A'}
                                  </Td>
                                  <Td className='cursor_pointer' onClick={() => setDetailShowId(tenant.id)}>
                                    {tenant?.user?.name ?? 'N/A'}
                                  </Td>
                                  <Td>
                                    {tenant?.user?.contact_number
                                      ? tenant?.user?.contact_number
                                      : 'N/A'}
                                  </Td>
                                  <Td>
                                    <span>
                                      {livingStatus ?? 'N/A'}
                                      &nbsp;
                                      {livingInfo &&
                                        (<Tooltip title={livingInfo} leaveDelay={500}>
                                          <InfoOutlinedIcon
                                            sx={{ fontSize: '1rem' }}
                                          />
                                        </Tooltip>)}
                                    </span>
                                  </Td>
                                  <Td>{tenant?.type ? tenant?.type : 'N/A'}</Td>
                                  {/* <Td>
                                    {tenant?.organization_name
                                      ? tenant?.organization_name
                                      : 'N/A'}
                                  </Td> */}
                                  {/* <Td>
                                    {tenant?.local_guardian_name
                                      ? tenant?.local_guardian_name
                                      : 'N/A'}
                                  </Td>
                                  <Td>
                                    {tenant?.local_guardian_contact_no
                                      ? tenant?.local_guardian_contact_no
                                      : 'N/A'}
                                  </Td> */}
                                  {/* Agreement Status */}
                                  <Td >
                                    <StatusSelectBox
                                      id={tenant?.id}
                                      name='agreement'
                                      currentStatus={tenant?.agreement?.status}
                                      refresh={refresh}
                                      disableUpdate={!hasEditPermission}
                                    />
                                  </Td>
                                  {/* Police verification Status */}
                                  <Td>
                                    <StatusSelectBox
                                      id={tenant?.id}
                                      name='police_verification'
                                      currentStatus={tenant?.police_verification?.status}
                                      refresh={refresh}
                                      disableUpdate={!hasEditPermission}
                                    />
                                  </Td>
                                  {(hasEditPermission || hasDeletePermission) ?
                                    (<Td className="threeDotsSpace">
                                      <Dropdown>
                                        <MenuButton><i className='mdi mdi-dots-vertical font-size-15'></i></MenuButton>
                                        <Menu slots={{ listbox: Listbox }}>
                                          {hasEditPermission && (
                                            <><StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }}
                                              onClick={() => setIsCustomizePayout({ tenant: tenant, status: true })}>
                                              <DashboardCustomizeIcon />
                                              Customize Payout%
                                            </StyledMenuItem><StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }}
                                              onClick={() => editTenantHandler(tenant.id)}>
                                                <i className='mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1' />
                                                Edit
                                              </StyledMenuItem></>
                                          )}
                                          {hasEditPermission &&
                                            !tenant?.user?.is_password_reset &&
                                            (<StyledMenuItem
                                              className="cursor-pointer"
                                              slots={{
                                                ListboxItem: ListboxItem,
                                              }}
                                              onClick={() => setIsSendConfirm({ status: true, id: tenant?.user?.id })}
                                            >
                                              <i className="mdi mdi-message-draw font-size-18 align-middle text-muted me-1" />
                                              Send Invite
                                            </StyledMenuItem>
                                            )}
                                          {hasDeletePermission &&
                                            (<StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }} onClick={() =>
                                              deleteHandler(tenant?.id)
                                            }><i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                                              Delete
                                            </StyledMenuItem>)}
                                        </Menu>
                                      </Dropdown>
                                    </Td>
                                    ) : null}
                                </Tr>)
                              })}
                          </Tbody>
                        </Table>
                      )}
                    </div>
                  </div>

                  {tenantList?.length > 0 && (
                    <div className='numbers_pagination_holder'>
                      <PaginationComponent
                        paginationMeta={paginationMeta}
                        handleChangePagination={paginationModelMethod}
                        update={update}></PaginationComponent>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {detailShowId &&
        <TenantDetailDrawer
          tenantId={detailShowId}
          refresh={refresh}
          isOpen={!!detailShowId}
          setIsOpen={setDetailShowId}
          payout_percentage={payoutPercentage?.value}
        >
        </TenantDetailDrawer>}
      {
        isAddTenant &&
        (
          <AddTenantDrawer
            isOpen={isAddTenant}
            toggleDrawer={toggleAddTenant}
            refresh={refresh}
            tenantId={tenantId}
            action={tenantId ? ACTIONS.EDIT : ACTIONS.CREATE}
          />
        )
      }
      {/* Delete confirmation modal */}
      {isDeleteConfirm.status &&
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await deleteTenantHandler(isDeleteConfirm.id);
            if (isDeleted) {
              refresh();
            }
            setDeleteConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}

      {isCustomizePayout?.status && (
        <CustomizePayoutPercentage
          isOpen={isCustomizePayout.status}
          handleClose={() => setIsCustomizePayout({ status: null, tenant: null })}
          tenant={isCustomizePayout.tenant}
          refresh={refresh}
        />
      )}

      {isTenantFilter &&
        <TenantFilter
          isOpen={isTenantFilter}
          toggleDrawer={toggleTenantFilter}
          emitFilterItem={filter}
          selectedFields={filterFields}
          selectedInputs={filterInputs}
        />}
      {isSendConfirm.status &&
        <ConfirmationModal
          text={'send'}
          show={isSendConfirm.status}
          onCloseClick={() => setIsSendConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isSend = await handlePasswordResetLink(isSendConfirm.id);
            if (isSend) {
              setIsLoading(false)
            }
            setIsSendConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}
    </>
  );
}


const mapStateToProps = (state) => ({
  user: state.AuthLogin.auth.user,
});

export default connect(mapStateToProps, null)(Tenant);
