import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ActivityTimeline from "../../components/ActiveProperties/ActivePropertiesDetail/activityTimeline";
import { ALL_MODULES } from '../../common/constant';
import { useParams } from 'react-router-dom';
import GeneratePayoutSlips from '../../components/Finance/generatePayoutSlip';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const { detailValue } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{React.cloneElement(children, { detailValue })}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export function PayoutIndex() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { id } = useParams();
  return (
    <div className="page-content">
      <div className="container-fluid activity_fluid_scroll">
        <div className='active-property-main-detail'>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='aPTabs'>
                <Tab label="Payout Slips" {...a11yProps(0)} />
                <Tab label="Activity Timeline" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <GeneratePayoutSlips />
            </CustomTabPanel>
            {<CustomTabPanel value={value} index={1}>
              <ActivityTimeline module={ALL_MODULES.PAYOUT_SLIP} module_id={id} />
            </CustomTabPanel>}
          </Box>
        </div>
      </div>
    </div>
  )
}