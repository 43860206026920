import React, { useEffect, useState } from "react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Modal, TextField, Box } from '@mui/material';
import { Button } from 'reactstrap';
import { ACTIONS } from "../../common/constant";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AsterikLabel } from "../Common/AsterikLabel";
import ConfirmationModal from "../Common/ConfirmationModal";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Card } from "reactstrap";
import { updateTenantHandler } from "../../api/Tenants/api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export function CustomizePayoutPercentage({ isOpen, handleClose, tenant, refresh }) {
    const [isConfirm, setIsConfirm] = useState(false);
    const [modal, setModal] = useState(true);
    const [selectedOption, setSelectedOption] = useState('add');

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            custom_payout_percentage: '',
            reason_payout_percentage: "",
            selectedOption: 'add'
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            custom_payout_percentage: Yup.number().when('selectedOption', {
                is: 'add',
                then: () => Yup.number().required('Please enter custom payout percentage'),
            }),
            reason_payout_percentage: Yup.string().required('Please enter reason for payout percentage'),
        }),
    });

    useEffect(() => {
        if (tenant?.custom_payout_percentage > 0) {
            formik.setValues({
                custom_payout_percentage: tenant?.custom_payout_percentage,
                reason_payout_percentage: tenant?.reason_payout_percentage,
                selectedOption: 'add'
            })
        }
    }, [tenant])

    const handleChange = (event) => {
        formik.setValues({
            custom_payout_percentage: tenant?.custom_payout_percentage,
            reason_payout_percentage: event.target.value === 'reset' ? '' : tenant?.reason_payout_percentage,
        })
        formik.setFieldValue('selectedOption', event.target.value)
        setSelectedOption(event.target.value);
    };

    const onClose = () => {
        setModal(false)
    }

    const handleSubmit = async () => {
        try {
            const payload = {
                ...(formik.values.selectedOption === 'add' ? {
                    custom_payout_percentage: formik.values.custom_payout_percentage,
                } : {
                    custom_payout_percentage: 0,
                }),
                reason_payout_percentage: formik.values.reason_payout_percentage,
            }
            await updateTenantHandler(tenant?.id, payload)
            refresh()
        } catch (err) {
            console.log(err)
        } finally {
            setSelectedOption()
            handleClose()
            setIsConfirm(false)
        }
    }

    return (
        <div>
            {modal && (
                <Modal
                    open={isOpen}
                    disableScrollLock={true}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={style} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>{tenant?.custom_payout_percentage > 0 ? (selectedOption === 'add' ? 'Edit Payout Percentage' : 'Reset Payout Percentage') : 'Set Payout Percentage'}</h5>
                            <CloseOutlinedIcon onClick={handleClose} className="cursor-pointer" />

                        </div>
                        {tenant?.custom_payout_percentage > 0 && (
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <Card className="bedMatrixOptions" style={{ boxShadow: 'unset', marginLeft: '4px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            value={selectedOption}
                                            onClick={handleChange}
                                        >
                                            <FormControlLabel value="add" control={<Radio color="default" />} label={`${tenant?.custom_payout_percentage > 0 ? 'Edit' : 'Add'} Customize Payout%`} style={{ color: 'black' }} />
                                            <FormControlLabel value="reset" control={<Radio color="default" />} label="Reset Back to Default payout%" style={{ color: 'black' }} />
                                        </RadioGroup>
                                    </FormControl>
                                </Card>
                            </div>
                        )}
                        {selectedOption === 'add' && (
                            <div className="tenantBody">
                                <div className="mb-4">
                                    <TextField
                                        fullWidth
                                        label={<div>Customize payout percentage <AsterikLabel /></div>}
                                        name="custom_payout_percentage"
                                        type="text"
                                        value={formik.values.custom_payout_percentage}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        size="small"
                                        className="width-100"
                                    />
                                    {formik.touched.custom_payout_percentage && formik.errors.custom_payout_percentage && (
                                        <span className="text-danger">{formik.errors.custom_payout_percentage}</span>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className={((formik.touched.reason_payout_percentage && formik.errors.reason_payout_percentage) || (formik.touched.reason_payout_percentage && formik.errors.reason_payout_percentage)) ? "mb-3" : "mb-4"}>
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label={<div>Reason for changing payout percentage <AsterikLabel /></div>}
                                name="reason_payout_percentage"
                                value={formik.values.reason_payout_percentage}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                multiline
                                rows={3}
                                className="tenantNoteTextarea mb-1"
                            />
                            {formik.touched.reason_payout_percentage && formik.errors.reason_payout_percentage && (
                                <span className="text-danger">{formik.errors.reason_payout_percentage}</span>
                            )}
                        </div>

                        <div className="modal_footer text-center">
                            <Button className="yellow_gradient_btn"
                                disabled={!(formik.isValid && formik.values?.reason_payout_percentage)}
                                onClick={() => {
                                    onClose()
                                    setIsConfirm(true)
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Box>
                </Modal>
            )}

            {isConfirm && (
                <ConfirmationModal
                    action={ACTIONS.CREATE}
                    show={isConfirm}
                    onCloseClick={() => {
                        setIsConfirm(false)
                        handleClose()
                    }}
                    onAcceptClick={handleSubmit}
                />
            )}
        </div>
    )
}