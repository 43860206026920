import React from 'react';
import { Navigate } from 'react-router-dom';

// // Pages Component
// import Chat from "../pages/Chat/Chat";

// Dashboard
//import Dashboard from '../pages/Dashboard/index_old';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login/index';

import Tenant from '../pages/Tenants/index';
// import NewTenant from '../pages/Tenants/New/index';
import PropertyOwner from '../pages/PropertyOwner/index';
import ProspectingOwner from '../pages/PropertyOwner/Prospecting/index';
// import ActiveProperties from '../pages/ActiveProperties/index';
import LiveActiveProperty from '../pages/ActiveProperties/index';

// import NonActiveProperties from '../pages/NonActiveProperties/index';
import ComplainTicket from '../pages/ComplainTicket';
import VisitRequests from '../pages/VisitRequests/index';
import CompanyEmployees from '../pages/CompanyEmployees/index';
import KanbanBoard from '../pages/Kanban/index';
import PGVacantRequest from '../pages/PGVacantRequest/index';
import CompanyAssociates from '../pages/CompanyAssociates/index';
import GlobalSettings from '../pages/GlobalSettings/index';
import Roles from '../pages/RolesPermissions/Roles/index';
import Notifications from '../pages/Notifications';
import Message from '../pages/Message';

// Pages Calendar

// Ecommerce Pages
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
// import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

// Email
// import EmailInbox from "../pages/Email/email-inbox";
// import EmailRead from "../pages/Email/email-read";
// import EmailBasicTemplte from "../pages/Email/email-basic-templte";
// import EmailAlertTemplte from "../pages/Email/email-template-alert";
// import EmailTemplateBilling from "../pages/Email/email-template-billing";

// Invoices
// import InvoicesList from '../pages/Invoices/invoices-list';
// import InvoiceDetail from '../pages/Invoices/invoices-detail';

// Contacts
// import ContactsGrid from "../pages/Contacts/contacts-grid";
// import ContactsList from "../pages/Contacts/ContactList/contacts-list";
// import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";

// Pages
// import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from '../pages/Utility/pages-maintenance';
import PagesComingsoon from '../pages/Utility/pages-comingsoon';
// import PagesTimeline from "../pages/Utility/pages-timeline";
// import PagesFaqs from "../pages/Utility/pages-faqs";
// import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500';

// Ui
// import UiAlert from "../pages/Ui/UiAlert";
// import UiButtons from "../pages/Ui/UiButtons";
// import UiCards from "../pages/Ui/UiCards";
// import UiCarousel from "../pages/Ui/UiCarousel";
// import UiColors from "../pages/Ui/UiColors";
// import UiDropdown from "../pages/Ui/UiDropdown";
// import UiGeneral from "../pages/Ui/UiGeneral";
// import UiGrid from "../pages/Ui/UiGrid";
// import UiImages from "../pages/Ui/UiImages";
// import UiLightbox from "../pages/Ui/UiLightbox";
// import UiModal from "../pages/Ui/UiModal";
// import UiOffcanvas from "../pages/Ui/UiOffcanvas";
// import UiProgressbar from "../pages/Ui/UiProgressbar";
// import UiPlaceholders from "../pages/Ui/UiPlaceholders";
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
// import UiTypography from "../pages/Ui/UiTypography";
// import UiToasts from "../pages/Ui/UiToasts";
// import UiVideo from "../pages/Ui/UiVideo";
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
// import UiRating from "../pages/Ui/UiRating";
// import UiRangeSlider from "../pages/Ui/UiRangeSlider";
// import UiNotifications from "../pages/Ui/ui-notifications";
// import UiImageCropper from "../pages/Ui/ui-image-cropper";
import RecoverPassword from '../pages/Reset/index';

// Forms
// import BasicElements from '../pages/Forms/BasicElements';
// import FormLayouts from '../pages/Forms/FormLayouts';
// import FormAdvanced from '../pages/Forms/FormAdvanced';
// import FormEditors from '../pages/Forms/FormEditors';
// import FormValidations from '../pages/Forms/FormValidations';
// import FormMask from '../pages/Forms/FormMask';
// import FormRepeater from '../pages/Forms/FormRepeater';
// import FormUpload from '../pages/Forms/FormUpload';
// import FormWizard from '../pages/Forms/FormWizard';
// import FormXeditable from '../pages/Forms/FormXeditable';

// Tables
// import BasicTables from "../pages/Tables/BasicTables";
// import DatatableTables from "../pages/Tables/DatatableTables";
// import ResponsiveTables from "../pages/Tables/ResponsiveTables";
// import EditableTables from "../pages/Tables/EditableTables";

// // Charts
// import ChartApex from '../pages/Charts/Apexcharts';
// import ChartjsChart from '../pages/Charts/ChartjsChart';
// import EChart from '../pages/Charts/EChart';
// import SparklineChart from '../pages/Charts/SparklineChart';
// import ChartsKnob from '../pages/Charts/charts-knob';

// Icons

// import IconUnicons from '../pages/Icons/IconUnicons';
// import IconBoxicons from '../pages/Icons/IconBoxicons';
// import IconDripicons from '../pages/Icons/IconDripicons';
// import IconMaterialdesign from '../pages/Icons/IconMaterialdesign';
// import IconFontawesome from '../pages/Icons/IconFontawesome';

// // Maps
// import MapsGoogle from '../pages/Maps/MapsGoogle';
// import MapsVector from '../pages/Maps/MapsVector';

// // File Manager
// import FileManager from '../pages/FileManager';

// Authentication related pages
// import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login";
// import Register1 from "../pages/AuthenticationInner/Register";
// import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
// import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile

import { ALL_MODULES, OPERATION_TYPE } from '../common/constant';

import UserProfile from '../pages/Authentication/user-profile';
import VerifyToken from '../components/Reset/verifyOtp';
import AddActiveProperty from '../pages/ActiveProperties/addActiveProperty';
import ActivePropertyDetail from '../pages/ActiveProperties/activePropertiesDetails';
import NewPropertyRequest from '../pages/NewPropertyRequest';
import RentSlips from '../pages/Finance/rentSlips';
import PaySlips from '../pages/Finance/paySlips';
import GenerateRentSlips from '../pages/Finance/generateRentSlip';
import { ACTIONS } from '../common/constant';
// import { components } from 'react-select';
import SuspendClosedProperty from '../pages/SuspendedProperties/index';
import VerifyPasswordReset from '../pages/Reset/verifyPasswordReset';
import { RefundDeposit } from '../pages/Finance/depositRefund';
import { PayoutIndex } from '../pages/Finance/payoutIndex';
import Activities from '../pages/Activities';
import { CancelledBooking } from '../pages/Finance/cancelledBooking';
// import UiUtilities from '../pages/Ui/ui-utilities';

// const userRoutes = [
//   { path: "/dashboard", component: <Dashboard /> },
//   { path: "/calendar", component: <Calendar/> },
//   //chat
//   { path: "/chat", component: <Chat/> },

//   //Ecommerce
//   { path: "/ecommerce-products", component: <EcommerceProducts/> },
//   { path: "/ecommerce-product-detail", component: <EcommerceProductDetail/> },
//   { path: "/ecommerce-products-detail/:id", component: <EcommerceProductDetail/> },
//   { path: "/ecommerce-orders", component: <EcommerceOrders/> },
//   { path: "/ecommerce-customers", component: <EcommerceCustomers/> },
//   { path: "/ecommerce-cart", component: <EcommerceCart/> },
//   { path: "/ecommerce-checkout", component: <EcommerceCheckout/> },
//   { path: "/ecommerce-shops", component: <EcommerceShops/> },
//   { path: "/ecommerce-add-product", component: <EcommerceAddProduct/> },

//   //Email
//   { path: "/email-inbox", component: <EmailInbox/> },
//   { path: "/email-read", component: <EmailRead/> },
//   { path: "/email-template-basic", component: <EmailBasicTemplte/> },
//   { path: "/email-template-alert", component: <EmailAlertTemplte/> },
//   { path: "/email-template-billing", component: <EmailTemplateBilling/> },

//   //Invoices
//   { path: "/invoices-list", component: <InvoicesList/> },
//   { path: "/invoices-detail", component: <InvoiceDetail/> },
//   { path: "/invoices-detail/:id", component: <InvoiceDetail/> },

//   // Contacts
//   { path: "/contacts-grid", component: <ContactsGrid/> },
//   { path: "/contacts-list", component: <ContactsList/> },
//   { path: "/contacts-profile", component: <ContactsProfile/> },

//   //Utility
//   { path: "/pages-starter", component: <PagesStarter/> },
//   { path: "/pages-timeline", component: <PagesTimeline/> },
//   { path: "/pages-faqs", component: <PagesFaqs/> },
//   { path: "/pages-pricing", component: <PagesPricing/> },

//   // Ui
//   { path: "/ui-alerts", component: <UiAlert/> },
//   { path: "/ui-buttons", component: <UiButtons/> },
//   { path: "/ui-cards", component: <UiCards/> },
//   { path: "/ui-carousel", component: <UiCarousel/> },
//   { path: "/ui-colors", component: <UiColors/> },
//   { path: "/ui-dropdowns", component: <UiDropdown/> },
//   { path: "/ui-general", component: <UiGeneral/> },
//   { path: "/ui-grid", component: <UiGrid/> },
//   { path: "/ui-images", component: <UiImages/> },
//   { path: "/ui-lightbox", component: <UiLightbox/> },
//   { path: "/ui-modals", component: <UiModal/> },
//   { path: "/ui-offcanvas", component: <UiOffcanvas/> },
//   { path: "/ui-progressbars", component: <UiProgressbar/> },
//   { path: "/ui-placeholders", component: <UiPlaceholders/> },
//   { path: "/ui-tabs-accordions", component: <UiTabsAccordions/> },
//   { path: "/ui-typography", component: <UiTypography/> },
//   { path: "/ui-toasts", component: <UiToasts/> },
//   { path: "/ui-video", component: <UiVideo/> },
//   { path: "/ui-session-timeout", component: <UiSessionTimeout/> },
//   { path: "/ui-rating", component: <UiRating/> },
//   { path: "/ui-rangeslider", component: <UiRangeSlider/> },
//   { path: "/ui-notifications", component: <UiNotifications/> },
//   { path: "/ui-image-cropper", component: <UiImageCropper/> },
//   { path: "/ui-utilities", component: <UiUtilities /> },

//   // Forms
//   { path: "/basic-elements", component:<BasicElements/> },
//   { path: "/form-layouts", component: <FormLayouts/> },
//   { path: "/form-advanced", component: <FormAdvanced/> },
//   { path: "/form-editors", component: <FormEditors/> },
//   { path: "/form-mask", component: <FormMask/> },
//   { path: "/form-repeater", component: <FormRepeater/> },
//   { path: "/form-uploads", component: <FormUpload/> },
//   { path: "/form-wizard", component: <FormWizard/> },
//   { path: "/form-validation", component: <FormValidations/> },
//   { path: "/form-xeditable", component: <FormXeditable/> },

//   // Tables
//   { path: "/tables-basic", component: <BasicTables/> },
//   { path: "/tables-datatable", component: <DatatableTables/> },
//   { path: "/tables-responsive", component: <ResponsiveTables/> },
//   { path: "/tables-editable", component: <EditableTables/> },

//   //Charts
//   { path: "/apex-charts", component: <ChartApex/> },
//   { path: "/chartjs-charts", component: <ChartjsChart/> },
//   { path: "/e-charts", component: <EChart/> },
//   { path: "/sparkline-charts", component: <SparklineChart/> },
//   { path: "/charts-knob", component: <ChartsKnob/> },

//   // Icons
//   { path: "/icons-unicons", component: <IconUnicons/> },
//   { path: "/icons-boxicons", component: <IconBoxicons/> },
//   { path: "/icons-dripicons", component: <IconDripicons/> },
//   { path: "/icons-materialdesign", component: <IconMaterialdesign/> },
//   { path: "/icons-fontawesome", component: <IconFontawesome/> },

//   // Maps
//   { path: "/maps-google", component:<MapsGoogle/> },
//   { path: "/maps-vector", component: <MapsVector/> },

//   // File Manager
//   { path: "/file-manager", component: <FileManager /> },

//   // //profile
//   { path: "/profile", component: <UserProfile/> },

//   // this route should be at the end of all other routes
//   // eslint-disable-next-line react/display-name
//   {
//     path: "/",
//     exact: true,
//     component: <Navigate to="/dashboard" />,
//   },
//   { path: "*", component: <Navigate to="/dashboard" /> },
// ];

// add auth required route here
const authRoutes = [
  { path: '/dashboard', component: <Dashboard /> },
  // { path: '/calendar', component: <Calendar /> },
  {
    path: '/tenants',
    component: <Tenant />,
    permissions: { name: ALL_MODULES.TENANTS, action: [OPERATION_TYPE.READ] },
  },
  // { path: '/tenant/new', component: <NewTenant /> },

  {
    path: '/property-owners',
    component: <PropertyOwner />,
    permissions: { name: ALL_MODULES.OWNERS, action: [OPERATION_TYPE.READ] },
  },
  {
    path: '/property-owners/prospecting',
    component: <ProspectingOwner />,
    permissions: { name: ALL_MODULES.OWNERS, action: [OPERATION_TYPE.READ] },
  },
  {
    path: '/active-properties/add-active-properties',
    component: <AddActiveProperty />,
    permissions: {
      name: ALL_MODULES.PROPERTIES,
      action: [OPERATION_TYPE.CREATE],
    },
  },
  {
    path: '/active-properties/edit/:propertyId',
    component: <AddActiveProperty action={ACTIONS.EDIT} />,
    permissions: {
      name: ALL_MODULES.PROPERTIES,
      action: [OPERATION_TYPE.EDIT, OPERATION_TYPE.READ],
    },
  },
  {
    path: `/active-properties/active-properties-detail/:propertyType`,
    component: <ActivePropertyDetail />,
    permissions: {
      name: ALL_MODULES.PROPERTIES,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: `/active-properties/:property/:propertyType`,
    component: <ActivePropertyDetail />,
    permissions: {
      name: ALL_MODULES.PROPERTIES,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: '/active-properties/active-properties-live',
    component: <LiveActiveProperty />,
    permissions: {
      name: ALL_MODULES.PROPERTIES,
      action: [OPERATION_TYPE.READ],
    },
  },
  // {
  //   path: '/non-active-properties',
  //   component: <NonActiveProperties />,
  //   permissions: {
  //     name: ALL_MODULES.PROPERTIES,
  //     action: [OPERATION_TYPE.READ],
  //   },
  // },
  {
    path: '/complain-tickets',
    component: <ComplainTicket />,
    permissions: {
      name: ALL_MODULES.COMPLAIN_TICKETS,
      action: [OPERATION_TYPE.READ],
    },
    // permissions: {
    //   name: ALL_MODULES.COMPLAIN_TICKETS,
    //   action: [OPERATION_TYPE.READ],
    // },
  },
  {
    path: '/visit-requests',
    component: <VisitRequests />,
    permissions: {
      name: ALL_MODULES.VISIT_REQUESTS,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: '/new-property-requests',
    component: <NewPropertyRequest />,
    permissions: {
      name: ALL_MODULES.ADD_PROPERTY_REQUESTS,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: '/company-employees',
    component: <CompanyEmployees />,
    permissions: {
      name: ALL_MODULES.COMPANY_EMPLOYEES,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: '/kanban-board',
    component: <KanbanBoard />,
    permissions: { name: ALL_MODULES.TASKS, action: [OPERATION_TYPE.READ] },
  },
  {
    path: '/pg-vacant-request',
    component: <PGVacantRequest />,
    permissions: {
      name: ALL_MODULES.PG_VACANT_REQUESTS,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: '/company-associates',
    component: <CompanyAssociates />,
    permissions: {
      name: ALL_MODULES.COMPANY_ASSOCIATES,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: '/roles',
    component: <Roles />,
    permissions: {
      name: ALL_MODULES.ROLE_PERMISSIONS,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: '/global-settings',
    component: <GlobalSettings />
  },
  {
    path: '/notifications',
    component: <Notifications />
  },
  {
    path: '/activities',
    component: <Activities />
  },
  {
    path: '/generated-payout-slip/view/:id',
    component: <PayoutIndex />,
    permissions: { name: ALL_MODULES.FINANCE, action: [OPERATION_TYPE.READ] },
  },
  {
    path: '/generate-rent-slip/:bill_id',
    component: <GenerateRentSlips />,
    permissions: {
      name: ALL_MODULES.FINANCE,
      action: [OPERATION_TYPE.READ, OPERATION_TYPE.CREATE, OPERATION_TYPE.UPDATE]
    },
  },
  {
    path: '/generate-rent-slip/view/:bill_id',
    component: <GenerateRentSlips />,
    permissions: { name: ALL_MODULES.FINANCE, action: [OPERATION_TYPE.READ] },
  },
   {
    path: '/deposit-refund',
    component: <RefundDeposit />,
    permissions: { name: ALL_MODULES.FINANCE, action: [OPERATION_TYPE.READ] },
  },
  {
    path: '/cancelled-booking',
    component: <CancelledBooking />,
    permissions: { name: ALL_MODULES.FINANCE, action: [OPERATION_TYPE.READ] },
  },

  {
    path: '/rent-slips',
    component: <RentSlips />,
    permissions: { name: ALL_MODULES.FINANCE, action: [OPERATION_TYPE.READ] },
  },
  {
    path: '/generate-rent-slip',
    component: <GenerateRentSlips />,
    permissions: {
      name: ALL_MODULES.FINANCE,
      action: [OPERATION_TYPE.READ, OPERATION_TYPE.CREATE, OPERATION_TYPE.UPDATE]
    },
  },

  {
    path: '/active-properties/closed-properties',
    component: <SuspendClosedProperty />,
    permissions: {
      name: ALL_MODULES.PROPERTIES,
      action: [OPERATION_TYPE.READ],
    },
  },
  {
    path: '/payout-slips',
    component: <PaySlips />,
    permissions: { name: ALL_MODULES.FINANCE, action: [OPERATION_TYPE.READ] },
  },
  {
    path: '/generate-payout-slip',
    component: <PayoutIndex />,
    permissions: { name: ALL_MODULES.FINANCE, action: [OPERATION_TYPE.READ] },
  },
  {
    path: '/message',
    component: <Message />,
    permissions: { name: ALL_MODULES.MESSAGE, action: [OPERATION_TYPE.READ] },
  },

  // // chat
  // { path: "/chat", component: <Chat /> },

  // // Ecommerce
  // { path: "/ecommerce-products", component: <EcommerceProducts /> },
  // { path: "/ecommerce-product-detail", component: <EcommerceProductDetail /> },
  // {
  //   path: "/ecommerce-products-detail/:id",
  //   component: <EcommerceProductDetail />,
  // },
  // { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  // { path: "/ecommerce-customers", component: <EcommerceCustomers /> },
  // { path: "/ecommerce-cart", component: <EcommerceCart /> },
  // { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  // { path: "/ecommerce-shops", component: <EcommerceShops /> },
  // { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  // // Email
  // { path: "/email-inbox", component: <EmailInbox /> },
  // { path: "/email-read", component: <EmailRead /> },
  // { path: "/email-template-basic", component: <EmailBasicTemplte /> },
  // { path: "/email-template-alert", component: <EmailAlertTemplte /> },
  // { path: "/email-template-billing", component: <EmailTemplateBilling /> },

  // // Invoices
  // // { path: '/invoices-list', component: <InvoicesList /> },
  // // { path: '/invoices-detail', component: <InvoiceDetail /> },
  // // { path: '/invoices-detail/:id', component: <InvoiceDetail /> },

  // // Contacts
  // { path: "/contacts-grid", component: <ContactsGrid /> },
  // { path: "/contacts-list", component: <ContactsList /> },
  // { path: "/contacts-profile", component: <ContactsProfile /> },

  // // Utility
  // { path: "/pages-starter", component: <PagesStarter /> },
  // { path: "/pages-timeline", component: <PagesTimeline /> },
  // { path: "/pages-faqs", component: <PagesFaqs /> },
  // { path: "/pages-pricing", component: <PagesPricing /> },

  // // Ui
  // { path: "/ui-alerts", component: <UiAlert /> },
  // { path: "/ui-buttons", component: <UiButtons /> },
  // { path: "/ui-cards", component: <UiCards /> },
  // { path: "/ui-carousel", component: <UiCarousel /> },
  // { path: "/ui-colors", component: <UiColors /> },
  // { path: "/ui-dropdowns", component: <UiDropdown /> },
  // { path: "/ui-general", component: <UiGeneral /> },
  // { path: "/ui-grid", component: <UiGrid /> },
  // { path: "/ui-images", component: <UiImages /> },
  // { path: "/ui-lightbox", component: <UiLightbox /> },
  // { path: "/ui-modals", component: <UiModal /> },
  // { path: "/ui-offcanvas", component: <UiOffcanvas /> },
  // { path: "/ui-progressbars", component: <UiProgressbar /> },
  // { path: "/ui-placeholders", component: <UiPlaceholders /> },
  // { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  // { path: "/ui-typography", component: <UiTypography /> },

  // { path: "/ui-toasts", component: <UiToasts /> },
  // { path: "/ui-video", component: <UiVideo /> },
  // { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  // { path: "/ui-rating", component: <UiRating /> },
  // { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  // { path: "/ui-notifications", component: <UiNotifications /> },
  // { path: "/ui-image-cropper", component: <UiImageCropper /> },
  // { path: '/ui-utilities', component: <UiUtilities /> },

  // Forms
  // { path: '/basic-elements', component: <BasicElements /> },
  // { path: '/form-layouts', component: <FormLayouts /> },
  // { path: '/form-advanced', component: <FormAdvanced /> },
  // { path: '/form-editors', component: <FormEditors /> },
  // { path: '/form-mask', component: <FormMask /> },
  // { path: '/form-repeater', component: <FormRepeater /> },
  // { path: '/form-uploads', component: <FormUpload /> },
  // { path: '/form-wizard', component: <FormWizard /> },
  // { path: '/form-validation', component: <FormValidations /> },
  // { path: '/form-xeditable', component: <FormXeditable /> },

  // Tables
  // { path: "/tables-basic", component: <BasicTables /> },
  // { path: "/tables-datatable", component: <DatatableTables /> },
  // { path: "/tables-responsive", component: <ResponsiveTables /> },
  // { path: "/tables-editable", component: <EditableTables /> },

  // Charts
  // { path: '/apex-charts', component: <ChartApex /> },
  // { path: '/chartjs-charts', component: <ChartjsChart /> },
  // { path: '/e-charts', component: <EChart /> },
  // { path: '/sparkline-charts', component: <SparklineChart /> },
  // { path: '/charts-knob', component: <ChartsKnob /> },

  // Icons
  // { path: '/icons-unicons', component: <IconUnicons /> },
  // { path: '/icons-boxicons', component: <IconBoxicons /> },
  // { path: '/icons-dripicons', component: <IconDripicons /> },
  // { path: '/icons-materialdesign', component: <IconMaterialdesign /> },
  // { path: '/icons-fontawesome', component: <IconFontawesome /> },

  // // Maps
  // { path: '/maps-google', component: <MapsGoogle /> },
  // { path: '/maps-vector', component: <MapsVector /> },

  // File Manager
  // { path: '/file-manager', component: <FileManager /> },

  // Profile
  { path: '/profile', component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: <Navigate to='/dashboard' />,
  },
  { path: '*', component: <Navigate to='/dashboard' /> },
  { path: '/' },
];

const publicRoutes = [
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
  { path: '/recover-password', component: <RecoverPassword /> },
  { path: '/password-reset/:token', component: <VerifyPasswordReset /> },
  { path: '/verify-token', component: <VerifyToken /> },
  // { path: '/forgot-password', component: <ForgetPwd /> },
  { path: '/register', component: <Register /> },

  { path: '/pages-maintenance', component: <PagesMaintenance /> },
  { path: '/pages-comingsoon', component: <PagesComingsoon /> },
  { path: '/pages-404', component: <Pages404 /> },
  { path: '/pages-500', component: <Pages500 /> },

  // Authentication Inner
  // { path: "/pages-login", component: <Login1 /> },
  // { path: "/pages-register", component: <Register1 /> },
  // { path: "/page-recoverpw", component: <Recoverpw /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
];

export { publicRoutes, authRoutes };
