import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import { Table, Tbody, Tr, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import "flatpickr/dist/themes/material_blue.css";
import CompanyEmployeesDetailDrawer from "../../components/CompanyEmployees/CompanyEmployeesDetailsDrawer";
import CompanyEmployeesFilterDrawer from "../../components/CompanyEmployees/CompanyEmployeesFilterDrawer";
import AddCompanyEmployeesDrawer from "../../components/CompanyEmployees/AddCompanyEmployeesDrawer";
import Search from "../../components/Common/GlobalSearch";

import { deleteUser, getAllUser, updateUser } from "../../api/user";
import PaginationComponent from "../../components/Common/PaginationComponent";
import { toast } from "react-toastify";
import { employeeUserCreate } from "../../api/compay-employee";
import DeleteModal from "../../components/Common/DeleteModal";
import NodataFound from "../../components/Common/NoDataFound";
import TableHeaders from "../../components/Common/TableHeaders";
import { Columns } from "../../components/CompanyEmployees/Coloumn";
import { MenuButton } from "@mui/base/MenuButton";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { grey } from "@mui/material/colors";
import { MenuItem } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import { usePermissionGiven } from "../../components/Hooks/UserPermission";
import { ALL_MODULES, OPERATION_TYPE } from "../../common/constant";
import { connect } from "react-redux";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { handlePasswordResetLink } from "../../api/User/reset";
import NoDataIcon from "../../assets/images/no-data.png";


function CompanyEmployees() {
  document.title = "Company Employees | Dstayz";
  const [filterObject, setFilterObject] = useState({});
  const [update, setUpdate] = useState(0);
  const [searchItem, setSearchItem] = useState("");
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [userListData, setUserListData] = useState([]);
  const filterInputs = ["0"];
  const [isDetail, setIsDetail] = useState(false);
  const [sort, setSort] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [meta, setMeta] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const filterFields = ["is_system"];
  const [filterInput, setFilterInputs] = useState(["0"]);
  const [filterField, setFilterFields] = useState(["is_system"]);
  const [isAddCompanyEmployees, setAddCompanyEmployees] = useState(false);
  const [isCompanyEmployeesFilter, setCompanyEmployeesFilter] = useState(false);
  const [isCompanyEmployeesDetails, setCompanyEmployeesDetails] =
    useState(false);
  const [isSendConfirm, setIsSendConfirm] = useState({ status: false, id: null })
  const hasEditPermission = usePermissionGiven(ALL_MODULES.COMPANY_EMPLOYEES, OPERATION_TYPE.UPDATE);
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.COMPANY_EMPLOYEES, OPERATION_TYPE.DELETE);

  // Add new employees drawer
  const toggleAddCompanyEmployees = () => {
    setAddCompanyEmployees(!isAddCompanyEmployees);
  };

  // employees filter drawer
  const toggleCompanyEmployeesFilter = () => {
    setCompanyEmployeesFilter(!isCompanyEmployeesFilter);
  };

  // employees details drawer
  const toggleCompanyEmployeesDetails = (data) => {
    setUserId(data?.id);
    setIsDetail(true);
    setCompanyEmployeesDetails(!isCompanyEmployeesDetails);
  };

  // const [menu, setMenu] = useState([false, false]);

  // -----------------------------------------------------------------
  const fetchCompanyEmployees = async (
    search = searchItem || "",
    paginatedData = {
      page,
      limit,
    }
  ) => {
    const params = {
      ...(sort?.order && sort?.direction &&  { order: sort.order, direction: sort.direction }),
      ...paginatedData,
      ...(search && {
        search: searchItem,
      }),
      ...(filterField &&
        filterInput && {
        filter_fields: filterField,
        filter_inputs: filterInput,
      }),
    };
    const resultData = await userListApi(params);
    setUserListData(resultData);
    setMeta(resultData?.meta);
  };
  const globalSearch = async (params) => {
    setUserListData([]);
    setFilterObject({});
    setSort({});
    setSearchItem(params?.search);
    setFilterFields(["is_system"]);
    setFilterInputs(["0"]);
    setPage(1);
    setMeta();
    setUpdate((pre) => pre + 1);
    const paramData = {
      limit: params?.limit,
      page: params?.page,
      ...(params?.search && {
        search: params?.search,
      }),
      filter_fields: filterFields,
      filter_inputs: filterInputs,
    };
    const resultData = await userListApi(paramData);
    setUserListData(resultData);
    setMeta(resultData?.meta);
  };
  // --------------------------------------------------------------
  const userListApi = async (params) => {
    try {
      setIsLoading(true);
      const response = await getAllUser(params);
      if (response?.status === 200) {
        setCompanyEmployeesFilter(false);
      }
      return response?.data?.data;
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const filter = async (filterFields, filterInputs, page) => {
    setFilterInputs(filterInputs);
    setFilterFields(filterFields);
    const resultData = await userListApi({
      limit,
      ...(page == 1 && { page }),
      ...(searchItem && { search: searchItem }),
      filter_fields: filterFields,
      filter_inputs: filterInputs,
    });

    setUserListData(resultData);
    setMeta(resultData?.meta);
  };
  const handleSort = async (sort) => {
    setUserListData([]);
    setSort(sort);
    const resultData = await userListApi({
      ...(sort?.order && sort?.direction &&  { order: sort.order, direction: sort.direction }),
      ...(page && page),
      limit,
      ...(searchItem && {
        search: searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterField,
        filter_inputs: filterInput,
      }),
    });
    setUserListData(resultData);
    setMeta(resultData?.meta);
  };
  const handleFilter = (searchFile, page) => {
    setSort({});
    if (searchFile?.role) {
      filterFields.push("role");
      filterInputs.push(searchFile.role);
    }
    if (searchFile?.is_system) {
      filterFields.push("is_system");
      filterInputs.push(searchFile.is_system);
    }
    if (searchFile?.name) {
      filterFields.push("name");
      filterInputs.push(searchFile.name);
    }
    if (searchFile?.contact_number) {
      filterFields.push("contact_number");
      filterInputs.push(searchFile.contact_number);
    }
    if (searchFile?.status) {
      filterFields.push("status");
      filterInputs.push(searchFile.status);
    }
    // setFilterObject({});
    searchFile = {};
    filter(filterFields, filterInputs, page);
  };

  const editOwnerAction = async (data) => {
    setAddCompanyEmployees(!isAddCompanyEmployees);
    setIsEditMode(true);
    setUserId(data?.id);
    setIsDetail(false);
  };
  const onToggleCloseDelete = () => {
    setDeleteOpen(false);
  };
  const paginationModelMethod = async (data) => {
    if (data?.page) {
      setPage(data.page);
      // params.page = data.page
      fetchCompanyEmployees(searchItem, { page: data.page, limit: limit });
    } else {
      setUpdate((prev) => prev + 1);
      setPage(1);
      setLimit(data?.limit);
      // params.limit = data.limit
      fetchCompanyEmployees(searchItem, {
        page: data.page,
        limit: data?.limit,
      });
    }
  };

  const createEmployeeFunction = async (payload) => {
    setIsLoading(true);
    try {
      const response = await employeeUserCreate(payload);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        fetchCompanyEmployees();
        toggleAddCompanyEmployees();
        setIsLoading(false);
        return response;
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    } finally {
      setIsLoading(false);
    }
  };
  const EditEmployeeFunction = async (payload) => {
    const id = Number(userId);
    setIsLoading(true);
    try {
      const response = await updateUser(payload, id);
      if (response?.status === 200) {
        toast.success(response.data.message);
        fetchCompanyEmployees();
        setUserId("");
        toggleAddCompanyEmployees();
        setIsEditMode(false);
        return response;
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    } finally {
      setIsLoading(false);
    }
  };
  const deleteToggleFunction = (data) => {
    setDeleteOpen(true);
    setUserId(data?.id);
    setIsDetail(false);
    setIsEditMode(false);
  };
  const deleteFunction = async () => {
    const id = Number(userId);
    setIsLoading(true);
    try {
      const response = await deleteUser(id);
      if (response?.status === 200) {
        toast.success(response.data.message);
        fetchCompanyEmployees();
        setUserId("");
        setDeleteOpen(false);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    } finally {
      setIsLoading(false);
    }
  };
  const Listbox = styled("ul")(
    ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 150px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${theme.palette.mode === "dark"
        ? "rgba(0,0,0, 0.50)"
        : "rgba(0,0,0, 0.05)"
      };
      z-index: 99;
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `
  );

  const ListboxItem = styled("li")(`padding: '10px'`);

  const StyledMenuItem = styled(MenuItem)`
    && {
      padding: 10px;
      /* Add more styles as needed */
    }
  `;

  useEffect(() => {
    fetchCompanyEmployees();
  }, []);
  
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="d-flex flex-wrap justify-content-start"></Row>
          <Row>
            <Col className="col-12">
              <div className="flexbox_holder mb-2">
                <div className="app-search">
                  <Search listCall={globalSearch} params={{ limit, page: 1 }} />
                </div>
    
                <div>
                  <Button
                    className="yellow_gradient_btn me-2"
                    onClick={toggleCompanyEmployeesFilter}
                  >
                    <i className="mdi mdi-filter-variant" />
                    Filter
                  </Button>
                  {usePermissionGiven(
                    ALL_MODULES.COMPANY_EMPLOYEES,
                    OPERATION_TYPE.CREATE
                  ) ? (
                    <Button
                      color="primary"
                      onClick={() => {
                        toggleAddCompanyEmployees();
                        setIsEditMode(false);
                        setUserId("");
                        setIsDetail(false);
                      }}
                    >
                      <i className="mdi mdi-plus" />
                      Employees
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Card className="table_card_holder">
                <CardBody>
                  {userListData?.data?.length > 0 ? (
                    <>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-bordered"
                          >
                            <TableHeaders
                              headers={Columns}
                              sortBy={sort}
                              emitSortedColumn={handleSort}
                              module={ALL_MODULES.COMPANY_EMPLOYEES}
                            />
                            <Tbody>
                              {userListData?.data &&
                                userListData?.data?.map((item, index) => (
                                  <Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{item?.code || "N/A"}</Td>
                                    <Td
                                      className="cursor_pointer"
                                      onClick={() =>
                                        toggleCompanyEmployeesDetails(item)
                                      }
                                    >
                                      {item.name}
                                    </Td>
                                    <Td>{item?.role?.role || "N/A"}</Td>
                                    <Td>{item?.employee?.status}</Td>
                                    <Td>{item?.contact_number || "N/A"}</Td>
                                    <Td>{item?.email || "N/A"}</Td>
                                    {(hasEditPermission || hasDeletePermission) && (
                                    <Td className="threeDotsSpace">
                                      <Dropdown>
                                        <MenuButton>
                                          <i className="mdi mdi-dots-vertical font-size-15"></i>
                                        </MenuButton>
                                        <Menu slots={{ listbox: Listbox }}>
                                          {hasEditPermission && (
                                              <StyledMenuItem
                                                className="cursor-pointer"
                                                slots={{
                                                  ListboxItem: ListboxItem,
                                                }}
                                                onClick={() =>
                                                  editOwnerAction(item)
                                                }
                                              >
                                                <i className="mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1" />
                                                Edit
                                              </StyledMenuItem>
                                            )}
                                          {hasEditPermission && 
                                          !item?.is_password_reset &&
                                            (<StyledMenuItem
                                                className="cursor-pointer"
                                                slots={{ListboxItem: ListboxItem}}
                                                onClick={() => setIsSendConfirm({status: true, id: item?.id})}>
                                                <i className="mdi mdi-message-draw font-size-18 align-middle text-muted me-1" />
                                                Send Invite
                                              </StyledMenuItem>
                                            )}
                                          {hasDeletePermission && (
                                              <StyledMenuItem
                                                className="cursor-pointer"
                                                slots={{
                                                  ListboxItem: ListboxItem,
                                                }}
                                                onClick={() =>
                                                  deleteToggleFunction(item)
                                                }
                                              >
                                                <i className="mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted" />
                                                Delete
                                              </StyledMenuItem>
                                            )}
                                        </Menu>
                                      </Dropdown>
                                    </Td>
                                    )}
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                      {userListData?.data?.length > 0 && (
                        <div className="numbers_pagination_holder">
                          <PaginationComponent
                            paginationMeta={meta}
                            handleChangePagination={paginationModelMethod}
                            update={update}
                          ></PaginationComponent>
                        </div>
                      )}
                    </>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                        <NodataFound text="No employee to show" icon={NoDataIcon} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {isAddCompanyEmployees && (
        <AddCompanyEmployeesDrawer
          setIsLoading={setIsLoading}
          isOpen={isAddCompanyEmployees}
          toggleDrawer={toggleAddCompanyEmployees}
          createEmployeeFunction={createEmployeeFunction}
          isLoading={isLoading}
          isEdit={isEditMode}
          setIsEditMode={setIsEditMode}
          userId={userId}
          EditEmployeeFunction={EditEmployeeFunction}
        />
      )}

      {isCompanyEmployeesFilter && (
        <CompanyEmployeesFilterDrawer
          setFilterObject={setFilterObject}
          handleFilter={handleFilter}
          isOpen={isCompanyEmployeesFilter}
          toggleDrawer={toggleCompanyEmployeesFilter}
          filterObject={filterObject}
          isLoading={isLoading}
          setPage={setPage}
          setUpdate={setUpdate}
        />
      )}
      {deleteOpen && (
        <DeleteModal
          isLoading={isLoading}
          show={deleteOpen}
          onCloseClick={onToggleCloseDelete}
          onDeleteClick={deleteFunction}
        />
      )}
      {isDetail && (
        <CompanyEmployeesDetailDrawer
          isOpen={isCompanyEmployeesDetails}
          toggleDrawer={toggleCompanyEmployeesDetails}
          userId={userId}
        />
      )}
      {isSendConfirm.status &&
        <ConfirmationModal
        text={'send'}
        show={isSendConfirm.status}
        onCloseClick={() => setIsSendConfirm({ status: false, id: null })}
        onAcceptClick={async () => {
          const isSend = await handlePasswordResetLink(isSendConfirm.id);
          if (isSend) {
            setIsLoading(false)
          }
          setIsSendConfirm({ status: false, id: null });
        }}
        isDisabled={isLoading}
      />}
    </>
  );
}
const mapStateToProps = (state) => ({
  user: state.AuthLogin.auth.user,
});

export default connect(mapStateToProps, null)(CompanyEmployees);
