import React, { useEffect, useState } from "react";
import FloorBedMatrix from "./floorBedMatrix";

function BedMatrixBhk({ propertyDetails }) {
    const [floorRowData, setFloorRowData] = useState([]);
    const [flatMax, setFlatMax] = useState(0);

    useEffect(() => {
        if (propertyDetails) {
            let flatMaxValue = 0
            let flatArray = []
            const floorData = propertyDetails?.floors?.map((floor) => {
                let singleRow = []
                floor?.flats.forEach((flat) => {
                    singleRow.push({
                        name: flat?.name,
                        rooms: flat.rooms,
                        ...(flat?.user !== null ? {
                            user: flat.user
                        } : {
                            user: propertyDetails.user 
                        })
                    })
                })

                let sortedFlat = []
                if (singleRow?.length > 0) {
                    sortedFlat = singleRow.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
                }


                if (sortedFlat?.length > 0) {
                    if (flatMaxValue < sortedFlat?.length) {
                        flatMaxValue = sortedFlat?.length
                        setFlatMax(sortedFlat)
                        flatArray.push(...sortedFlat)
                    }
                    return {
                        name: floor.name,
                        flats: sortedFlat,
                        ...(floor?.user && {
                            user: floor.user
                        })
                    }
                }
            })

            let sortedFloor = []
            if (floorData?.length > 0) {
                sortedFloor = floorData.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
            }

            setFloorRowData(sortedFloor)
        }
    }, [propertyDetails])

    return (
        <div className="bedMatrixPg">
            <div className="activeProperties activePropertyBedmatrix activePropertyBedmatrixBhk">
                <div className="bm_wrapper">
                    <div className="bm_main_headcols d-flex">
                        <div className="bm_col_sn">Floor</div>
                        {flatMax?.length > 0 && flatMax.map((it, index) => (
                            <div className="bm_col_flat" key={index}>Flat {index + 1}</div>
                        ))}
                    </div>
                    <div className="bm_body_cols_outer">
                        <div className="bm_body_cols d-flex flex-column">
                            {floorRowData?.length > 0 && floorRowData.map((item, index) => (
                                <div className="bm_single_row d-flex" key={index}>
                                    <div className="bm__sn">{item.name === '0th' ? 'Ground' : item.name}</div>
                                    {item.flats.length > 0 && item.flats.map((fl, ind) => (
                                        <div className="bm__flat" key={ind}>
                                            <FloorBedMatrix rooms={fl} propertyStatus={propertyDetails.status} />
                                        </div>
                                    ))}
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BedMatrixBhk;
