import React, { useState, useEffect } from 'react';
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete, TextField, Box } from '@mui/material';
import {
  ACTIONS,
  DOCUMENT_HASH_BASE_KEYS,
  KNOWN_ROLES,
  USER_STATUS,
} from '../../common/constant';
import { PropTypes } from 'prop-types';
import {
  createPropertyOwnerHandler,
  updatePropertyOwnerHandler,
} from '../../api/PropertyOwner/api';
import uuid from 'react-native-uuid';
import ConfirmationModal from '../Common/ConfirmationModal';
import {
  formatBytes,
  getS3BaseUrl,
  handleKeyDown,
} from '../../helpers/string_helper';
import { uploadDocuments } from '../../api/Tenants/api';
import FileList from '../Common/FileList';
import FileUpload from '../../assets/images/icons/image-add-line.svg';
import { getOwnerDetails } from '../../api/owner';
import { toast } from 'react-toastify';
import { fileUploads } from '../../api/compay-employee';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function AddPropertyOwnerDrawer({
  isOpen,
  toggleDrawer,
  refresh,
  ownerId,
  action,
}) {
  const [isAddPropertyOwner] = useState(false);
  const [ownerData, setOwnerData] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateConfirm, setCreateConfirm] = useState({
    status: false,
    data: null,
  });
  const [isEditConfirm, setEditConfirm] = useState({
    status: false,
    id: null,
    data: null,
  });
  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });
  const [profilePhoto, setProfilePhoto] = useState(null);
  const s3BaseUrl = getS3BaseUrl();

  const propertyOwnerSchema = {
    occupation: ownerData?.occupation ?? '',
    other_documents: ownerData?.other_documents ?? [],
    total_documents_size: ownerData?.total_documents_size,
    user: {
      name: ownerData?.user?.name ?? '',
      email: ownerData?.user?.email ?? '',
      status: ownerData?.user?.status ?? '',
      contact_number: ownerData?.user?.contact_number ?? '',
      address: ownerData?.user?.address ?? '',
      blood_group: ownerData?.user?.blood_group ?? '',
      profile_photo: ownerData?.user?.profile_photo,
      profile_photo_size: ownerData?.user?.profile_photo_size,
    },
    // bank_detail: {
    //   account_holder_name: ownerData?.bank_detail?.account_holder_name ?? '',
    //   account_number: ownerData?.bank_detail?.account_number ?? '',
    //   bank_name: ownerData?.bank_detail?.bank_name ?? '',
    //   ifsc: ownerData?.bank_detail?.ifsc ?? '',
    // },
  };

  const fetchOwnerData = async () => {
    try {
      const response = await getOwnerDetails(ownerId);
      if (response?.status === 200) {
        setOwnerData(response?.data?.data);
        if (response?.data?.data?.other_documents?.length > 0) {
          const newDocuments = response?.data?.data?.other_documents?.map(
            (doc) => ({
              ...doc,
              uniqueId: uuid.v4(),
              formattedSize: formatBytes(doc.size),
            }),
          );
          setOwnerData({
            ...response?.data?.data,
            ...(newDocuments?.length > 0 && {
              other_documents: [...newDocuments],
            }),
            ...(newDocuments?.length > 0 && {
              total_documents_size: [...newDocuments].reduce(
                (p, c) => p + c.size,
                0,
              ),
            }),
          });
          setselectedFiles([...newDocuments]);
        }
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };

  const propertyOwnerValidation = Yup.object().shape({
    occupation: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .optional(),
    other_documents: Yup.array()
      .max(10, 'You are only allowed to have 10 documents here.')
      .optional(),
    total_documents_size: Yup.number()
      .positive()
      .when('other_documents', {
        is: (docs) => docs?.length > 0,
        then: (schema) =>
          schema
            .min(1, 'The documents size must be greater than 1 Byte.')
            .max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.')
            .required(),
        otherwise: (schema) => schema.optional(),
      }),
    user: Yup.object().shape({
      name: Yup.string()
        .strict(true)
        .trim('Must not include leading and trailing spaces')
        .max(100, 'Too Long!')
        .required('Please Enter name'),
      contact_number: Yup.string()
        .strict(true)
        .trim('Must not include leading and trailing spaces')
        .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
        .required('Please enter contact number'),
      email: Yup.string()
        .strict(true)
        .trim('Must not include leading and trailing spaces')
        .email()
        .typeError('Please Enter Valid Email')
        .optional(),
      status: Yup.string().required("Please select property owner's status"),
      ...(action === ACTIONS.EDIT &&
      {
        contact_number: Yup.string()
          .strict(true)
          .trim('Must not include leading and trailing spaces')
          .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
          .required('Please Enter Contact Number')
      }),
      address: Yup.string().optional(),
      profile_photo: Yup.string().nullable(),
      profile_photo_size: Yup.number()
        .positive()
        .max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.'),
    }),
    // bank_detail: Yup.object().shape({
    //   account_holder_name: Yup.string()
    //     .strict(true)
    //     .trim('Must not include leading and trailing spaces')
    //     .max(100, 'Too Long!'),
    //   account_number: Yup.string()
    //   .matches(/^\d{9,18}$/, 'Invalid account number'),
    //   bank_name: Yup.string()
    //     .strict(true)
    //     .trim('Must not include leading and trailing spaces')
    //     .max(100, 'Too Long!'),
    //   ifsc: Yup.string()
    //     .strict(true)
    //     .trim('Must not include leading and trailing spaces')
    //     .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code'),
    // }),
  });

  const addProfilePic = (file) => {
    let imageFile = file.target.files[0];
    Object.assign(imageFile, {
      uniqueId: uuid.v4(),
      location: URL.createObjectURL(imageFile),
      formattedSize: formatBytes(imageFile.size),
    });
    setProfilePhoto(imageFile);
    formik.setValues({
      user: {
        profile_photo: imageFile?.location,
        profile_photo_size: imageFile?.size,
      },
      ...formik.values,
    });
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    formik
      .setValues(
        {
          ...formik.values,
          other_documents: [...selectedFiles, ...files],
          total_documents_size: [...selectedFiles, ...files].reduce(
            (p, c) => p + c.size,
            0,
          ),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) => {
          return [...prev, ...files];
        });
      });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: propertyOwnerSchema,
    validationSchema: propertyOwnerValidation,
    validateOnMount: true,
    onSubmit: async (ownerData) => {
      action === ACTIONS.EDIT
        ? setEditConfirm({
          status: true,
          id: +ownerId,
          data: ownerData,
        })
        : setCreateConfirm({ status: true, data: ownerData });
    },
  });
  let isSuccess = null;
  const submitHandler = async (ownerData, id) => {
    setIsLoading(true);
    let documents;
    const filesUnchanged =
      formik.values?.other_documents?.filter((file) => file?.id) ?? [];
    if (filesUnchanged.length > 0) {
      documents = [...filesUnchanged.map((sd) => sd.id)];
    }
    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
    if (filesToBeUploaded.length > 0) {
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append('files', f);
      });
      formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.OWNER);
      if (action === ACTIONS.CREATE) {
        formData.append('max_items', 10);
      }

      const result = await uploadDocuments(formData);
      if (result.success) {
        const { response } = result;
        if (documents !== undefined) {
          documents = [
            ...documents,
            ...response.data.data.documents.map((doc) => doc.id),
          ];
        } else {
          documents = [...response.data.data.documents.map((doc) => doc.id)];
        }
      } else {
        return false;
      }
    }

    let imageURL = ownerData?.profile_photo;
    if (profilePhoto) {
      const profileImage = new FormData();
      profileImage.append('files', profilePhoto);
      profileImage.append('base_key', DOCUMENT_HASH_BASE_KEYS.TENANT);
      const result = await fileUploads(profileImage);
      if (result?.success) {
        imageURL = result?.response?.data?.data?.responses[0]?.actual_location;
      } else {
        return false;
      }
    }

    ownerData = {
      occupation: ownerData.occupation,
      user: {
        name: ownerData.user.name,
        profile_photo: imageURL,
        contact_number: ownerData.user.contact_number,
        ...(ownerData.user.status && {
          status: ownerData.user.status,
        }),
        ...(ownerData.user.email && {
          email: ownerData.user.email,
        }),
        ...(ownerData.user.address && {
          address: ownerData.user.address,
        }),
        ...(ownerData.user.blood_group && {
          blood_group: ownerData.user.blood_group,
        }),
        ...(ownerData.user.aadhaar && {
          aadhaar: ownerData.user.aadhaar,
        }),
      },
      ...(ownerData.property_status && {
        property_status: ownerData.property_status,
      }),
      // ...(ownerData.bank_detail && {
      //   bank_detail: {
      //     ...(ownerData.bank_detail.account_holder_name && {
      //       account_holder_name: ownerData.bank_detail.account_holder_name,
      //     }),
      //     ...(ownerData.bank_detail.account_number && {
      //       account_number: ownerData.bank_detail.account_number,
      //     }),
      //     ...(ownerData.bank_detail.ifsc && {
      //       ifsc: ownerData.bank_detail.ifsc,
      //     }),
      //     ...(ownerData.bank_detail.bank_name && {
      //       bank_name: ownerData.bank_detail.bank_name,
      //     }),
      //   },
      // }),
      ...(action === ACTIONS.EDIT
        ? documents === undefined
          ? { other_documents: [] }
          : { other_documents: documents }
        : documents === undefined
          ? {}
          : { other_documents: documents }),
    };
    isSuccess =
      action === ACTIONS.EDIT
        ? await updatePropertyOwnerHandler(id, ownerData)
        : await createPropertyOwnerHandler(ownerData);

    setIsLoading(false);
    return isSuccess;
  };

  const handleClose = () => {
    toggleDrawer(!isOpen);
    formik.resetForm();
  };

  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = formik.values.other_documents.filter(
      (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId),
    );
    const tempValues = {
      ...formik.values,
    };

    if (newDocuments?.length === 0) {
      delete tempValues.other_documents;
      delete tempValues.total_documents_size;
    }
    formik
      .setValues(
        {
          ...tempValues,
          ...(newDocuments?.length > 0 && {
            other_documents: newDocuments,
          }),
          ...(newDocuments?.length > 0 && {
            total_documents_size: selectedFiles
              .filter(
                (doc, i) =>
                  !(i === fileIndex && doc.uniqueId === file.uniqueId),
              )
              .reduce((p, c) => p + c.size, 0),
          }),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) =>
          prev.filter(
            (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId),
          ),
        );
      });

    return true;
  }

  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex },
    });
  }

  useEffect(() => {
    document.body.className = 'add-tenant-bg';
    if (action === ACTIONS.EDIT) fetchOwnerData();

    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  }, []);

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });

  return (
    <Offcanvas isOpen={isOpen} direction='end' toggle={handleClose}>
      <OffcanvasHeader
        toggle={() => {
          formik.resetForm();
          toggleDrawer(!isAddPropertyOwner);
        }}>
        {action === ACTIONS.EDIT
          ? `Edit ${ownerData?.user?.role?.role === KNOWN_ROLES.PROSPECTING_OWNER ? ownerData?.user?.role?.role : 'Property Owner'}`
          : `Add New Prospecting Owner`}
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}>
          <Row>
            {/* Profile Picture */}
            <div className='col-sm-12 mb-3'>
              <div className='custom-image-upload' style={{ alignItems: 'center' }}>
                <div className='image-placeholder-employee image-placeholder'>
                  <img
                    src={
                      profilePhoto
                        ? profilePhoto.location
                        : formik?.values?.user?.profile_photo
                          ? `${s3BaseUrl}${formik?.values?.user?.profile_photo}`
                          : FileUpload
                    }
                    alt='Not Found'
                  />
                </div>
                <div className='upload_picture'>
                  <Input
                    className='form-control pos-rel'
                    accept='image/*'
                    type='file'
                    id='user.profile_photo'
                    name='user.profile_photo'
                    style={{ display: 'none' }}
                    onClick={formik.handleBlur}
                    onChange={addProfilePic}
                    invalid={
                      !!(
                        formik.touched.user?.profile_photo &&
                        formik.errors.user?.profile_photo
                      )
                    }
                  />
                  <label
                    style={{
                      alignSelf: 'center',
                    }}
                    htmlFor='user.profile_photo'
                    className='custom-file-label mb-0 mx-2'>
                    Upload Your Picture
                  </label>
                </div>
              </div>
              {formik.touched.user?.profile_photo &&
                formik.errors.user?.profile_photo ? (
                <span className='text-danger-formik'>
                  {' '}
                  {formik.errors.user?.profile_photo}
                  {formik.errors.user?.profile_photo_size}
                </span>
              ) : (
                ''
              )}
            </div>
            <br />

            {/* Name */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Name'
                  name='user.name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.user?.name || ''}
                  error={
                    !!(formik?.touched?.user?.name && formik?.errors?.user?.name)
                  }
                  helperText={
                    formik?.touched?.user?.name && formik?.errors?.user?.name
                      ? formik?.errors?.user?.name
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Contact Number */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Contact Number'
                  name='user.contact_number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.user?.contact_number || ''}
                  error={
                    !!(
                      formik.touched?.user?.contact_number &&
                      formik.errors?.user?.contact_number
                    )
                  }
                  helperText={
                    formik.touched?.user?.contact_number &&
                      formik.errors?.user?.contact_number
                      ? formik.errors?.user?.contact_number
                      : ''
                  }
                  size='small'
                  onWheel={(e) => e.target.blur()}
                  disabled={isLoading || action === ACTIONS.EDIT}
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  required
                />
              </ThemeProvider>
            </div>

            {/* Tenant Status */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='user.status'
                value={formik.values?.user?.status}
                onChange={(event, selecteValue) => {
                  formik.setFieldValue('user.status', selecteValue ?? '');
                }}
                onBlur={() => {
                  if (!formik.touched?.user?.status) {
                    formik.setFieldTouched('user.status', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(USER_STATUS)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        error={
                          !!(
                            formik?.touched?.user?.status &&
                            formik?.errors?.user?.status
                          )
                        }
                        helperText={
                          formik?.touched?.user?.status &&
                            formik?.errors?.user?.status
                            ? formik?.errors?.user?.status
                            : ''
                        }
                        label='Select Owner Status'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
                size='small'
              />
            </div>

            {/* Property Status
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                size='small'
                value={formik.values.property_status}
                name='property_status'
                getOptionLabel={(option) => (option ? option : '')}
                onChange={(event, newValue) => {
                  formik.setFieldValue('property_status', newValue);
                }}
                onBlur={() => {
                  if (!formik.touched?.property_status) {
                    formik.setFieldTouched('property_status', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(PROPERTY_STATUS)}
                disabled={isLoading}
                required
                renderInput={(params) => (
                  <ThemeProvider theme={theme}>
                  <TextField
                    {...params}
                    label='Select Property Status'
                    onBlur={formik.handleBlur}
                    error={
                      !!(
                        formik?.touched?.property_status &&
                        formik?.errors?.property_status
                      )
                    }
                    helperText={
                      formik?.touched?.property_status &&
                      formik?.errors?.property_status
                        ? formik?.errors?.property_status
                        : ''
                    }
                    size='small'
                    required
                  />
                  </ThemeProvider>
                )}
              />
            </div> */}

            {/* Occupation */}
            <div className='col-sm-6 mb-3'>
              <TextField
                size='small'
                className='form-control'
                type='text'
                name='occupation'
                label='Occupation'
                value={formik.values?.occupation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  !!(formik.touched?.occupation && formik.errors?.occupation)
                }
                helperText={
                  formik.touched?.occupation && formik.errors?.occupation
                    ? formik.errors?.occupation
                    : ''
                }
                disabled={isLoading}
              />
            </div>

            {/* Email */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Email'
                name='user.email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.user?.email || ''}
                error={
                  !!(formik.touched?.user?.email && formik.errors?.user?.email)
                }
                helperText={
                  formik.touched?.user?.email && formik.errors?.user?.email
                    ? formik.errors?.user?.email
                    : ''
                }
                size='small'
              />
            </div>

            {/* Address */}
            <div className='col-sm-6 mb-3'>
              <TextField
                // fullWidth
                id='outlined-multiline-static reason'
                label='Address'
                name='user.address'
                // multiline
                // rows={2}
                className='form-control'
                value={formik.values?.user?.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  !!(
                    formik.touched?.user?.address &&
                    formik.errors?.user?.address
                  )
                }
                helperText={
                  formik.touched?.user?.address && formik.errors?.user?.address
                    ? formik.errors?.user?.address
                    : ''
                }
                disabled={isLoading}
                size='small'
              />
            </div>

            {/* Bank Detials */}
            {/* <Row>
              <div className='col-12 mb-3'>
                <div className='bankAccountDet'>
                  <h5 className='mb-3'>Bank Account Details</h5>
                </div>
              </div>
              <div className='col-sm-6 mb-3'>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  name='bank_detail.account_holder_name'
                  label='Account Holder Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.bank_detail?.account_holder_name || ''}
                  error={
                    !!(
                      formik.touched?.bank_detail?.account_holder_name &&
                      formik.errors?.bank_detail?.account_holder_name
                    )
                  }
                  helperText={
                    formik.touched?.bank_detail?.account_holder_name &&
                    formik.errors?.bank_detail?.account_holder_name
                      ? formik.errors?.bank_detail?.account_holder_name
                      : ''
                  }
                />
              </div>
              <div className='col-sm-6 mb-3'>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  name='bank_detail.account_number'
                  label='Account Number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.bank_detail?.account_number || ''}
                  error={
                    !!(
                      formik.touched?.bank_detail?.account_number &&
                      formik.errors?.bank_detail?.account_number
                    )
                  }
                  helperText={
                    formik.touched?.bank_detail?.account_number &&
                    formik.errors?.bank_detail?.account_number
                      ? formik.errors?.bank_detail?.account_number
                      : ''
                  }
                  onWheel={(e) => e.target.blur()}
                  disabled={isLoading}
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                />
              </div>
              <div className='col-sm-6 mb-4'>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  name='bank_detail.ifsc'
                  label='IFSC'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.bank_detail?.ifsc || ''}
                  error={
                    !!(
                      formik.touched?.bank_detail?.ifsc &&
                      formik.errors?.bank_detail?.ifsc
                    )
                  }
                  helperText={
                    formik.touched?.bank_detail?.ifsc &&
                    formik.errors?.bank_detail?.ifsc
                      ? formik.errors?.bank_detail?.ifsc
                      : ''
                  }
                />
              </div>
              <div className='col-sm-6 mb-4'>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  name='bank_detail.bank_name'
                  label='Bank Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.bank_detail?.bank_name || ''}
                  error={
                    !!(
                      formik.touched?.bank_detail?.bank_name &&
                      formik.errors?.bank_detail?.bank_name
                    )
                  }
                  helperText={
                    formik.touched?.bank_detail?.bank_name &&
                    formik.errors?.bank_detail?.bank_name
                      ? formik.errors?.bank_detail?.bank_name
                      : ''
                  }
                />
              </div>
            </Row> */}

            {/* Other Docs */}
            <div className='col-12 mb-3'>
              <label>Upload Documents</label>
              <div className='col-12 mb-3'>
                {(formik.errors.other_documents ||
                  formik.errors.total_documents_size) && (
                    <ul>
                      {formik.errors.other_documents && (
                        <li className='text-danger'>
                          {formik.errors.other_documents}
                        </li>
                      )}
                      {formik.errors.total_documents_size && (
                        <li className='text-danger'>
                          {formik.errors.total_documents_size}
                        </li>
                      )}
                    </ul>
                  )}
              </div>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone'>
                    <div className='dz-message needsclick' {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 text-muted uil uil-cloud-upload' />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>

              {selectedFiles?.length > 0 && (
                <FileList
                  files={selectedFiles}
                  confirmFileRemoval={confirmFileRemoval}
                />
              )}
            </div>
          </Row>

          <div className='formActions btn_right'>
            <Button
              className='yellow_gradient_btn'
              type='submit'
              disabled={!formik.isValid || isLoading}>
              Save
            </Button>
          </div>
        </Form>

        {/* Create confirmation modal */}
        {isCreateConfirm?.status &&
          <ConfirmationModal
            action={ACTIONS.CREATE}
            show={isCreateConfirm?.status}
            onCloseClick={() => setCreateConfirm({ status: false, data: null })}
            onAcceptClick={async () => {
              const isCreated = await submitHandler(isCreateConfirm?.data);
              if (isCreated && isSuccess) {
                await refresh();
                handleClose();
              }
              setCreateConfirm({ status: false, id: null });
            }}
            isDisabled={isLoading}
          />}

        {/* Edit confirmation modal */}
        {isEditConfirm?.status && action === ACTIONS.EDIT &&
          <ConfirmationModal
            action={ACTIONS.EDIT}
            show={isEditConfirm?.status}
            onCloseClick={() =>
              setEditConfirm({ status: false, id: null, data: null })
            }
            onAcceptClick={async () => {
              delete isEditConfirm?.data?.user?.contact_number;
              const isUpdated = await submitHandler(
                isEditConfirm?.data,
                isEditConfirm?.id,
              );
              if (isUpdated && isSuccess) {
                await refresh();
                handleClose();
              }
              setEditConfirm({ status: false, id: null });
            }}
            isDisabled={isLoading}
          />}

        {/* Document Delete confirmation modal */}
        {isDocumentDeleteConfirm?.status &&
          <ConfirmationModal
            action={ACTIONS.DELETE}
            show={isDocumentDeleteConfirm?.status}
            onCloseClick={() =>
              setIsDocumentDeleteConfirm({ status: false, data: null })
            }
            onAcceptClick={async () => {
              const { e, file, fileIndex } = isDocumentDeleteConfirm.data;
              const isDeleted = handleFileRemove(e, file, fileIndex);
              if (isDeleted) {
                setIsDocumentDeleteConfirm({ status: false, data: null });
              }
            }}
            isDisabled={isLoading}
          />}
      </OffcanvasBody>
    </Offcanvas>
  );
}

AddPropertyOwnerDrawer.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  refresh: PropTypes.func,
  ownerId: PropTypes.number,
  action: PropTypes.string,
};
export default AddPropertyOwnerDrawer;
