import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// Import Images
import errorImg from '../../assets/images/404-error.png';

function Pages404() {
  document.title = ' Error 404 | Minible - Responsive Bootstrap 5 Admin Dashboard';

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <div className="my-5 pt-sm-5">
      <Container>
        <Row>
          <Col md={12}>
            <div className="text-center">
              <div>
                <Row className="row justify-content-center">
                  <Col sm={4}>
                    <div className="error-img">
                      <img src={errorImg} alt="" className="img-fluid mx-auto d-block" />
                    </div>
                  </Col>
                </Row>
              </div>
              <h4 className="text-uppercase mt-4">Sorry, page not found</h4>
              <p className="text-muted">It will be as simple as Occidental in fact, it will be Occidental</p>
              <div className="mt-5">
                <Link className="btn btn-primary waves-effect waves-light" to="/">Back to Dashboard</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Pages404;
