import React, { useEffect, useState } from 'react';
import FilePreview from './FilePreview';
import { PropTypes } from 'prop-types';

export default function FileList(props) {
  
  return (
    <div className='dropzone-previews' id='file-previews'>
        {
            props?.files.map((file, fileIndex) => {
                return (
                    <FilePreview shouldEyeVisible={props?.shouldEyeVisible} key={file.uniqueId} file={file} fileIndex={fileIndex} confirmFileRemoval={props?.confirmFileRemoval} />
                )
            })
        }
    </div>
  );
}

FileList.propTypes = {
    files: PropTypes.array,
    confirmFileRemoval: PropTypes.func
  }