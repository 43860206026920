export const ROLES = {
    TENANT: 'Tenant',
    EMPLOYEE: '',
    SUPER_ADMIN: 'Super Admin',
    OWNER: 'Owner',
    ADMIN: 'Admin',
    PROPERTY_MANAGER: 'Property Manager',
    PROSPECTING_OWNER: 'Prospecting Owner',
}

export const ACTIVE_STATUS={
    ACTIVE:'Active',
    IN_ACTIVE:'Inactive'
}

export const NOTIFICATION_EVENTS = {
    PROSPECTING_OWNER_CREATE: 'prospecting_owner_create', // TESTED // LINKED
    PROSPECTING_OWNER_CONVERT: 'prospecting_owner_convert', // TESTED // LINKED
    OWNER_DELETE: 'owner_delete', // TESTED // LINKED
    TENANT_ASSIGNED_TO_BED_BY_ADMIN: 'tenant_assigned_to_bed_by_admin', // TESTED // LINKED
    TENANT_BOOKED_BED_BY_SELF: 'tenant_booked_bed_by_self', // not implemented
    PG_LEAVING_REQUEST_BY_TENANT: 'pg_leaving_request_by_tenant', // not implemented
    TENANT_EVICTED_BY_ADMIN: 'tenant_evicted_by_admin', // TESTED // LINKED
    NEW_PROPERTY_REQUEST_CREATE: 'new_property_request_create', // TESTED // LINKED
    NEW_PROPERTY_REQUEST_DELETE: 'new_property_request_delete', // TESTED // not implemented
    PROPERTY_ACTIVATE: 'property_create',// TESTED // LINKED
    PROPERTY_STATUS_UPDATE: 'property_status_update',// TESTED // LINKED
    NEW_VISIT_REQUEST_CREATE: 'new_visit_request_create', // TESTED // LINKED
    NEW_COMPLAIN_TICKET_CREATE: 'new_complain_ticket_create', // TESTED // LINKED 
    COMPLAIN_STATUS_CHANGE: 'complain_status_change', // TESTED // LINKED
    MESSAGE_RECEIVED: 'message_received', // TESTED // LINKED
    RENT_SLIP_PUBLISHED: 'rent_slip_published', // TESTED // LINKED
    RENT_PAYMENT_BY_TENANT: 'rent_payment_by_tenant', // TESTED // From where can i get rentslip id in rent history. 
    PAYOUT_RECEIVED: 'payout_received', // not implemented
    TASK_ASSIGNED: 'task_assigned', // TESTED 
    TASK_STATUS_UPDATE: 'task_status_update', //TESTED  // LINKED
    PROPERTY_REVIEW_CREATE: 'property_review_create',
    OWNER_REPLY_TO_REVIEW_ADMIN_PORTAL: 'owner_reply_to_review_admin_portal',
    CANCEL_PG_BOOKING: 'cancel_pg_booking',
}