import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Button } from 'reactstrap';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { SelectDropDownListPaginationComponent } from "../Common/SelectDropDownPaginationList";
import { getAllLivePropertiesBasedOnOrder, getAllProperties, updateLivePropertyCustomOrder } from "../../api/property";
import { PROPERTY_STATUS } from "../../common/constant";
import { toast } from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 660,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export default function PropertyPrefModal({ isPropertyPrefOpen, isPropertyPreftoggle }) {
    const [selectedProperty, setSelectedProperty] = useState('');
    const [propertyList, setPropertyList] = useState([]);
    const [initialProperty, setInitialProperty] = useState([]);
    const [initialOrder, setInitialOrder] = useState()
    const [selectedOrder, setSelectedOrder] = useState();
    const [update, setUpdate] = useState(0)

    const handleCloseModal = () => {
        isPropertyPreftoggle(); // Toggles the modal visibility
    };

    useEffect(() => {
        fetchActiveAndLiveProperties()
    }, [])


    const fetchActiveAndLiveProperties = async () => {
        try {
            const response = (await getAllLivePropertiesBasedOnOrder()).data
            const resultData = response.data.map((res) => {
                return {
                    id: res.id,
                    order: res.order,
                    name: res.name,
                };
            });
            setPropertyList(resultData)
            setInitialProperty(resultData)
        } catch (error) {
            console.log(error?.response?.data?.message)
        }
    }

    useEffect(() => {
        if (selectedProperty === undefined) {
            setPropertyList(initialProperty)
        } else {
            const filterPrperty = propertyList?.filter((item) => item.id === selectedProperty.id)
            setPropertyList(filterPrperty)
        }

    }, [selectedProperty])

    const handleShowOrder = () => {
        const filterProperty = []
        const filter = propertyList?.filter((item) => {
            if (item?.id === initialOrder?.id && item?.order === parseInt(initialOrder?.order)) {
                return item
            }
        })
        if (filter?.length > 0) {
            return toast.error('Property is already this position, please select other position')
        }
        const findPropertyData = propertyList.find((item) => item?.id === initialOrder?.id)
        initialProperty.forEach((item) => {
            let isCheck = false
            if (findPropertyData?.order > parseInt(selectedOrder)) {
                if (item.order < parseInt(selectedOrder)) {
                    isCheck = true
                    filterProperty.push({
                        ...item
                    })
                }
                if (item.order === parseInt(selectedOrder)) {
                    isCheck = true
                    filterProperty.push({
                        ...findPropertyData,
                        order: parseInt(selectedOrder)
                    })
                    filterProperty.push({
                        ...item,
                        order: parseInt(selectedOrder) + 1
                    })
                } if (item.order > parseInt(selectedOrder) && findPropertyData.order !== item.order && findPropertyData.order > item.order) {
                    isCheck = true
                    filterProperty.push({
                        ...item,
                        order: item.order + 1
                    })
                } else if (!isCheck && findPropertyData.order !== item.order) {
                    filterProperty.push(item)
                }
            } else if (findPropertyData.order < parseInt(selectedOrder)) {
                if (item.order < parseInt(selectedOrder) && item.order !== findPropertyData.order) {
                    filterProperty.push({
                        ...item,
                        order: item.order < findPropertyData.order ? item.order : (item.order - 1)
                    })
                }
                if (item.order === parseInt(selectedOrder) && item.order !== findPropertyData.order) {
                    filterProperty.push({
                        ...item,
                        order: (item.order - 1)
                    })
                    filterProperty.push({
                        ...findPropertyData,
                        order: parseInt(selectedOrder)
                    })
                }
                if (item.order > parseInt(selectedOrder) && item.order !== findPropertyData.order) {
                    filterProperty.push({
                        ...item
                    })
                }
            }
        })
        setPropertyList(filterProperty)
        setInitialProperty(filterProperty)
    }

    const handleSubmit = async () => {
        try {
            const response = await updateLivePropertyCustomOrder({ property_details: initialProperty })
            toast.success(response.data.message)
            handleCloseModal()
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    return (
        <div key={update}>
            <Modal
                open={isPropertyPrefOpen}
                onClose={handleCloseModal} 
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="amenityCustomBox">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Property Re-ordering</h5>
                        <CancelOutlinedIcon onClick={handleCloseModal} className="cursor-pointer" />
                    </div>
                    <div className="search-field-wrapper d-flex align-items-center mb-2">
                        <SelectDropDownListPaginationComponent
                            label="Select Property"
                            searchEnabled={false}
                            defaultValue={selectedProperty?.id ? selectedProperty : null}
                            listCall={getAllProperties}
                            emitItem={(newValue) => {
                                setSelectedProperty(newValue)
                                setInitialOrder({
                                    ...initialOrder,
                                    name: newValue?.label,
                                    id: newValue?.id
                                })
                            }}
                            sx={{ width: 280, marginRight: '16px' }}
                            size="small"
                            apiParams={{
                                filter_fields: ['status'],
                                filter_inputs: [PROPERTY_STATUS.LIVE],
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Enter Order Number"
                            variant="outlined"
                            value={selectedOrder}
                            sx={{ width: 155, marginRight: '16px' }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            size="small"
                            onChange={(event) => {
                                setSelectedOrder(event.target.value)
                                setInitialOrder({
                                    ...initialOrder,
                                    order: event.target.value
                                })
                            }}
                        />
                        <Button onClick={() => handleShowOrder()} disabled={!selectedProperty || !selectedOrder} className="red_gradient_btn" style={{ width: '100px', height: '40px', fontSize: '13px', marginRight: '16px' }}>
                            Show Order
                        </Button>
                        <ReplayOutlinedIcon disabled={!selectedProperty || !selectedOrder} onClick={() => {
                            setSelectedOrder()
                            setSelectedProperty()
                            setUpdate(update + 1)
                            fetchActiveAndLiveProperties()
                        }} style={(!selectedProperty || !selectedOrder) ? { color: '#8A8E9A', } : { color: '#0e0e0f', cursor: 'pointer' }} />
                    </div>

                    {propertyList?.length > 0 && (
                    <ul className="property_reorder ps-0 mt-4" style={{ listStyle: 'none' }}>
                        {propertyList?.map((item, index) => (
                            <li
                                className="single_property_item"
                                key={index}
                                style={{ border: item?.name === selectedProperty?.name ? '1px solid #ED3542' : 'none' }}
                            >
                                <span className="serialNo">{item?.order}.</span>
                                <span className="home_icon"><MapsHomeWorkOutlinedIcon /></span>
                                <span>{item?.name}</span>
                            </li>
                        ))}
                    </ul>
                    )}

                    <div className="modal_footer property_reorder_actions">
                        <Button className="grey_outline_btn me-2" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button disabled={!selectedOrder} onClick={() => {
                            handleSubmit()
                        }} className="yellow_gradient_btn">
                            Submit
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
