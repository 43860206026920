import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import Dropzone from 'react-dropzone';
import { TextField, Autocomplete, Box } from '@mui/material';
import * as Yup from 'yup';
import {
  ACTIONS,
  COMPANY_ASSOCIATE_TYPES,
  DOCUMENT_HASH_BASE_KEYS,
  USER_STATUS,
} from '../../common/constant';
import { useFormik } from 'formik';
import ConfirmationModal from '../Common/ConfirmationModal';
import {
  createCompanyAssociatesHandler,
  updateCompanyAssociatesHandler,
  uploadDocuments,
} from '../../api/CompanyAssociates/api';
import FileList from '../Common/FileList';
import { formatBytes, handleKeyDown } from '../../helpers/string_helper';
import uuid from 'react-native-uuid';
import { toast } from 'react-toastify';
import { getCompanyAssociatesDetails } from '../../api/company-associates';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function AddCompanyAssociatesDrawer({
  isOpen,
  toggleDrawer,
  refresh,
  associateId,
  action,
}) {
  const [isAddCompanyAssociates] = useState(false);
  const [associateData, setAssociateData] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateConfirm, setCreateConfirm] = useState({
    status: false,
    data: null,
  });
  const [isEditConfirm, setEditConfirm] = useState({
    status: false,
    id: null,
    data: null,
  });

  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });

  const associatesSchema = {
    name: associateData?.name ?? '',
    type: associateData?.type ?? '',
    status: associateData?.status ?? '',
    contact_number: associateData?.contact_number ?? '',
    designation: associateData?.designation ?? '',
    email: associateData?.email ?? '',
    address: associateData?.address ?? '',
    contact_person_contact_number:
      associateData?.contact_person_contact_number ?? '',
    contact_person_name: associateData?.contact_person_name ?? '',
    purpose: associateData?.purpose ?? '',
    notes: associateData?.notes ?? '',
    documents: associateData?.documents ?? [],
    total_documents_size: associateData?.total_documents_size,
  };

  const associatesValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Associate's Name"),
    type: Yup.string().required('Please Select a Associates type'),
    status: Yup.string().required('Please Select Associates Status'),
    email: Yup.string().email('Email must be valid'),
    designation: Yup.string().optional(),
    contact_number: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
      .required('Please Enter Contact Number'),
    address: Yup.string().optional(),
    contact_person_name: Yup.string().optional(),
    contact_person_contact_number: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
      .optional(),
    purpose: Yup.string().optional(),
    notes: Yup.string().optional(),
    documents: Yup.array()
      .max(4, 'You are only allowed to have 4 documents here.')
      .optional(),
    total_documents_size: Yup.number()
      .positive()
      .when('documents', {
        is: (docs) => docs?.length > 0,
        then: (schema) =>
          schema
            .min(1, 'The documents size must be greater than 1 Byte.')
            .max(10 * 1024 * 1024, 'The documents size must not exceed 10 MB.')
            .required(),
        otherwise: (schema) => schema.optional(),
      }),
  });

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: associatesSchema,
    validationSchema: associatesValidationSchema,
    validateOnMount: true,
    onSubmit: async (associatesData) => {
      action === ACTIONS.EDIT
        ? setEditConfirm({
            status: true,
            id: +associateId,
            data: associatesData,
          })
        : setCreateConfirm({ status: true, data: associatesData });
    },
  });

  let isSuccess = null
  const submitHandler = async (associatesData, id) => {
    setIsLoading(true);
    let documents;
    const filesUnchanged =
      formik.values?.documents?.filter((file) => file?.id) ?? [];
    if (filesUnchanged.length > 0) {
      documents = [...filesUnchanged.map((sd) => sd.id)];
    }

    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
    if (filesToBeUploaded.length > 0) {
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append('files', f);
      });
      formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.COMPANY_ASSOCIATES);
      if (action === ACTIONS.CREATE) {
        formData.append('max_items', 10);
      }

      const result = await uploadDocuments(formData);

      if (result.success) {
        const { response } = result;

        if (documents !== undefined) {
          documents = [
            ...documents,
            ...response.data.data.documents.map((doc) => doc.id),
          ];
        } else {
          documents = [...response.data.data.documents.map((doc) => doc.id)];
        }
      } else {
        setIsLoading(false);
        return false;
      }
    }
    // removing empty fields
    associatesData = {
      name: associatesData.name,
      type: associatesData.type,
      status: associatesData.status,
      contact_number: associatesData.contact_number,
      ...(associatesData.designation && {
        designation: associatesData.designation,
      }),
      ...(associatesData.email && {
        email: associatesData.email,
      }),
      ...(associatesData.address && {
        address: associatesData.address,
      }),
      ...(associatesData.contact_person_name && {
        contact_person_name: associatesData.contact_person_name,
      }),
      ...(associatesData.contact_person_contact_number && {
        contact_person_contact_number:
          associatesData.contact_person_contact_number,
      }),
      ...(associatesData.purpose && {
        purpose: associatesData.purpose,
      }),
      ...(associatesData.notes && {
        notes: associatesData.notes,
      }),
      ...(action === ACTIONS.EDIT
        ? documents === undefined
          ? { documents: [] }
          : { documents }
        : documents === undefined
        ? {}
        : { documents }),
    };
    isSuccess =
      action === ACTIONS.EDIT
        ? await updateCompanyAssociatesHandler(id, associatesData)
        : await createCompanyAssociatesHandler(associatesData);
    setIsLoading(false);
    return isSuccess;
  };
  const handleClose = () => {
    toggleDrawer(!isOpen);
    formik.resetForm();
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    formik
      .setValues(
        {
          ...formik.values,
          documents: [...selectedFiles, ...files],
          total_documents_size: [...selectedFiles, ...files].reduce(
            (p, c) => p + c.size,
            0,
          ),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) => {
          return [...prev, ...files];
        });
      });
  }

  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = formik.values.documents.filter(
      (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId),
    );
    const tempValues = {
      ...formik.values,
    };

    if (newDocuments.length === 0) {
      delete tempValues.documents;
      delete tempValues.total_documents_size;
    }
    formik
      .setValues(
        {
          ...tempValues,
          ...(newDocuments.length > 0 && {
            documents: newDocuments,
          }),
          ...(newDocuments.length > 0 && {
            total_documents_size: selectedFiles
              .filter(
                (doc, i) =>
                  !(i === fileIndex && doc.uniqueId === file.uniqueId),
              )
              .reduce((p, c) => p + c.size, 0),
          }),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) =>
          prev.filter(
            (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId),
          ),
        );
      });

    return true;
  }

  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex },
    });
  }

  const fetchAssociateData = async () => {
    try {
      const response = await getCompanyAssociatesDetails(associateId);
      if (response?.status === 200) {
        setAssociateData(response?.data?.data);
        if (response?.data?.data?.documents?.length > 0) {
          const newDocuments = response?.data?.data?.documents?.map((doc) => ({
            ...doc,
            uniqueId: uuid.v4(),
            formattedSize: formatBytes(doc.size),
          }));
          setAssociateData({
            ...response?.data?.data,
            ...(newDocuments.length > 0 && {
              documents: [...newDocuments],
            }),
            ...(newDocuments.length > 0 && {
              total_documents_size: [...newDocuments].reduce(
                (p, c) => p + c.size,
                0,
              ),
            }),
          });
          setselectedFiles([...newDocuments]);
        }
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };

  useEffect(() => {
    document.body.className = 'add-associates-bg';
    if (action === ACTIONS.EDIT) fetchAssociateData();

    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  }, []);

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });

  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => {
        formik.resetForm();
        toggleDrawer(!isAddCompanyAssociates);
      }}>
      <OffcanvasHeader toggle={toggleDrawer}>
        {action === ACTIONS.EDIT
          ? 'Edit Company Associates'
          : 'Add Company Associate'}
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}>
          <Row>
            {/* Name */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Name'
                  size='small'
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.name || ''}
                  error={!!(formik?.touched?.name && formik?.errors?.name)}
                  helperText={
                    formik?.touched?.name && formik?.errors?.name
                      ? formik?.errors?.name
                      : ''
                  }
                  required
                />
              </ThemeProvider>
            </div>

            {/* Associates Type */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='type'
                size='small'
                value={formik.values?.type}
                onChange={(event, newValue) => {
                  formik.setFieldValue('type', newValue ? newValue : '');
                }}
                onBlur={() => {
                  if (!formik.touched?.type) {
                    formik.setFieldTouched('type', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                isOptionEqualToValue={(option, value) => option === value}
                options={Object.values(COMPANY_ASSOCIATE_TYPES)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        error={!!(formik?.touched?.type && formik?.errors?.type)}
                        helperText={
                          formik?.touched?.type && formik?.errors?.type
                            ? formik?.errors?.type
                            : ''
                        }
                        label='Select Associates Type'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
              />
            </div>

            {/* Associates Status */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='status'
                size='small'
                value={formik.values?.status}
                onChange={(event, newValue) => {
                  formik.setFieldValue('status', newValue ? newValue : '');
                }}
                onBlur={() => {
                  if (!formik.touched?.status) {
                    formik.setFieldTouched('status', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                isOptionEqualToValue={(option, value) => option === value}
                options={Object.values(USER_STATUS)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        error={!!(formik?.touched?.status && formik?.errors?.status)}
                        helperText={
                          formik?.touched?.status && formik?.errors?.status
                            ? formik?.errors?.status
                            : ''
                        }
                        label='Select Associates Status'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
              />
            </div>

            {/* Contact No */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  size='small'
                  type='text'
                  label='Contact No'
                  name='contact_number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.contact_number || ''}
                  error={
                    !!(
                      formik?.touched?.contact_number &&
                      formik?.errors?.contact_number
                    )
                  }
                  helperText={
                    formik?.touched?.contact_number &&
                      formik?.errors?.contact_number
                      ? formik?.errors?.contact_number
                      : ''
                  }
                  onWheel={(e) => e.target.blur()}
                  disabled={isLoading}
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  required
                />
              </ThemeProvider>
            </div>

            {/* Email */}
            <div className='col-sm-6 mb-3'>
              <TextField
                size='small'
                className='form-control'
                type='text'
                label='Email'
                name='email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.email}
                error={!!(formik?.touched?.email && formik?.errors?.email)}
                helperText={
                  formik?.touched?.email && formik?.errors?.email
                    ? formik?.errors?.email
                    : ''
                }
              />
            </div>

            {/* Representative Name */}
            <div className='col-sm-6 mb-3'>
              <TextField
                size='small'
                className='form-control'
                type='text'
                label='Representative Name'
                name='contact_person_name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.contact_person_name}
                error={
                  !!(
                    formik?.touched?.contact_person_name &&
                    formik?.errors?.contact_person_name
                  )
                }
                helperText={
                  formik?.touched?.contact_person_name &&
                  formik?.errors?.contact_person_name
                    ? formik?.errors?.contact_person_name
                    : ''
                }
              />
            </div>

            {/* Representative Designation */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                size='small'
                type='text'
                label='Representative Designation'
                name='designation'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.designation}
                error={
                  !!(
                    formik?.touched?.designation && formik?.errors?.designation
                  )
                }
                helperText={
                  formik?.touched?.designation && formik?.errors?.designation
                    ? formik?.errors?.designation
                    : ''
                }
              />
            </div>

            {/* Representative Contact No. */}
            <div className='col-sm-6 mb-3'>
              <TextField
                size='small'
                className='form-control'
                type='text'
                label='Representative Contact No.'
                name='contact_person_contact_number'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.contact_person_contact_number}
                error={
                  !!(
                    formik?.touched?.contact_person_contact_number &&
                    formik?.errors?.contact_person_contact_number
                  )
                }
                helperText={
                  formik?.touched?.contact_person_contact_number &&
                  formik?.errors?.contact_person_contact_number
                    ? formik?.errors?.contact_person_contact_number
                    : ''
                }
                onWheel={(e) => e.target.blur()}
                disabled={isLoading}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              />
            </div>

            {/* Purpose */}
            <div className='col-sm-6 mb-3'>
              <TextField
                size='small'
                className='form-control'
                type='text'
                label='Purpose'
                name='purpose'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.purpose}
                error={!!(formik?.touched?.purpose && formik?.errors?.purpose)}
                helperText={
                  formik?.touched?.purpose && formik?.errors?.purpose
                    ? formik?.errors?.purpose
                    : ''
                }
              />
            </div>

            {/* Address */}
            <div className='col-sm-6 mb-3'>
              <TextField
                size='small'
                className='form-control'
                type='text'
                label='Address'
                name='address'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.address}
                error={!!(formik?.touched?.address && formik?.errors?.address)}
                helperText={
                  formik?.touched?.address && formik?.errors?.address
                    ? formik?.errors?.address
                    : ''
                }
              />
            </div>

            {/* Notes */}
            <div className='col-12 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Note'
                name='notes'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.notes}
                error={!!(formik?.touched?.notes && formik?.errors?.notes)}
                helperText={
                  formik?.touched?.notes && formik?.errors?.notes
                    ? formik?.errors?.notes
                    : ''
                }
                size='small'
              />
            </div>

            {/* Documents */}
            <div className='col-12 mb-3'>
              <label>Upload Documents</label>
              <div className='col-12 mb-3'>
                {(formik.errors.documents ||
                  formik.errors.total_documents_size) && (
                  <ul>
                    {formik.errors.documents && (
                      <li className='text-danger'>{formik.errors.documents}</li>
                    )}
                    {formik.errors.total_documents_size && (
                      <li className='text-danger'>
                        {formik.errors.total_documents_size}
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone'>
                    <div className='dz-message needsclick' {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 text-muted uil uil-cloud-upload' />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              {selectedFiles.length > 0 && (
                <FileList
                  files={selectedFiles}
                  confirmFileRemoval={confirmFileRemoval}
                />
              )}
            </div>
          </Row>
          <div className='formActions btn_right'>
            <Button
              className='yellow_gradient_btn'
              type='submit'
              disabled={!formik.isValid}>
              {action === ACTIONS.EDIT ? 'Save' : 'Create'}
            </Button>
          </div>
        </Form>
        {/* Create confirmation modal */}
        {isCreateConfirm?.status &&
          <ConfirmationModal
          action={ACTIONS.CREATE}
          show={isCreateConfirm?.status}
          onCloseClick={() => setCreateConfirm({ status: false, data: null })}
          onAcceptClick={async () => {
            const isCreated = await submitHandler(isCreateConfirm?.data);
            if (isCreated && isSuccess) {
                await refresh();
                handleClose();
            }
            setCreateConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}
        {/* Edit confirmation modal */}
        {isEditConfirm?.status &&
          <ConfirmationModal
          action={ACTIONS.EDIT}
          show={isEditConfirm?.status}
          onCloseClick={() =>
            setEditConfirm({ status: false, id: null, data: null })
          }
          onAcceptClick={async () => {
            const isUpdated = await submitHandler(
              isEditConfirm?.data,
              isEditConfirm?.id,
            );
            if (isUpdated && isSuccess) {
                await refresh();
                handleClose();
            }
            setEditConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}

        {/* Document Delete confirmation modal */}
        {isDocumentDeleteConfirm?.status &&
          <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDocumentDeleteConfirm?.status}
          onCloseClick={() =>
            setIsDocumentDeleteConfirm({ status: false, data: null })
          }
          onAcceptClick={async () => {
            const { e, file, fileIndex } = isDocumentDeleteConfirm.data;
            const isDeleted = handleFileRemove(e, file, fileIndex);
            if (isDeleted) {
              setIsDocumentDeleteConfirm({ status: false, data: null });
            }
          }}
          isDisabled={isLoading}
        />}
      </OffcanvasBody>
    </Offcanvas>
  );
}

AddCompanyAssociatesDrawer.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  refresh: PropTypes.func,
  associateId: PropTypes.number,
  action: PropTypes.string,
};
export default AddCompanyAssociatesDrawer;
