export const Columns = [
  {
    column_name: "Month & Year",
    key_name: "start_date",
    sort_enabled: true,
  },
  {
    column_name: "Property Name",
    key_name: "property_name",
    sort_enabled: true,
  },
  {
    column_name: "Rent Document",
    key_name: "attachments",
    sort_enabled: false,
  },

  {
    column_name: "Status",
    key_name: "status",
    sort_enabled: true,
  },
  {
    column_name: 'Total Due Amount',
    key_name: 'due_amount',
    sort_enabled: false,
  },
  {
    column_name: "Publish Date",
    key_name: "published_at",
    sort_enabled: true,
  },
];
