import React, { useEffect, useState } from "react";
import { Button, InputGroup } from "reactstrap";
import GlobalInnerHeader from "./globalInnerHeader";
import { viewByIdApi, editApi } from "../../api/GlobalSettings/api";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import { GLOBAL_SETTINGS_KEY } from "../../common/constant";
import { useProfile } from '../../components/Hooks/UserHooks';
import { ROLES } from "../../constants/variables";
function NoticePeriod({ tabDetails }) {
  const { id, payload } = tabDetails;
  const [noticePeriodPayload, setNoticePeriodPayload] = useState(payload);
  const [unit, setUnit] = useState(noticePeriodPayload?.unit);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isDataModified, setIsDataModified] = useState(false);
  const [savedValue, setSavedValue] = useState(payload.value);
  const isSuperAdmin = useProfile().user.role.role === ROLES.SUPER_ADMIN;

  async function validateInput(value) {
    try {
      await Yup.number()
        .min(0)
        .required(`${tabDetails.display_name} is required`)
        .typeError(`${tabDetails.display_name} must be a number`)
        .validate(value);
      setError("");
      setIsDataModified(true);
    } catch (err) {
      setError(err.message);
    }
  }

  async function counterIncrease() {
    if (parseInt(noticePeriodPayload?.value) >= 0) {
      setNoticePeriodPayload((prev) => ({
        ...prev,
        value: parseInt(prev.value) + 1,
      }));

      const value = parseInt(noticePeriodPayload?.value) + 1;
      await validateInput(value);
      if (unit === 'days' || unit === 'day') {
        if (value > 1) {
          setUnit("days");
        } else setUnit("day");
      }

      await validateInput(value);
    }
  }

  async function counterDecrease() {
    if (parseInt(noticePeriodPayload?.value) > 0) {
      const value = parseInt(noticePeriodPayload?.value) - 1;
      setNoticePeriodPayload((prev) => ({
        ...prev,
        value: parseInt(prev.value) - 1,
      }));
      await validateInput(value);
      if (unit === 'days' || unit === 'day') {
        if (value > 1) {
          setUnit("days");
        } else setUnit("day");
      }

      await validateInput(value);
    }
  }

  async function onDataAttributeChange(e) {
    let value = e.target.value;
    if (isNaN(value)) value = "";

    setNoticePeriodPayload((prev) => ({
      ...prev,
      value,
    }));
    if (unit === 'days' || unit === 'day') {
      if (value > 1) {
        setUnit("days");
      } else setUnit("day");
    }
    await validateInput(value);
  }

  useEffect(() => {
    getNoticePeriodDetails();
  }, []);

  async function getNoticePeriodDetails() {
    try {
      const response = await viewByIdApi(id);
      const { payload } = response;
      setNoticePeriodPayload(payload);
      setSavedValue(payload.value);
      if (unit === 'days' || unit === 'day') {
        if (payload.value > 1) {
          setUnit("days");
        } else {
          setUnit("day");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function saveData() {
    try {
      setIsLoading(true);
      const payload = {
        id,
        payload: { payload: noticePeriodPayload },
      };
      await editApi(payload);
      setSavedValue(noticePeriodPayload.value);
      setIsDataModified(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const matchGlobalKey = (name, position) => {
    switch (name) {
      case GLOBAL_SETTINGS_KEY.NOTICE_PERIOD:
        if (position === 'start') {
          return 'Tenant should inform'
        } else {
          return unit + ' before leaving the PG.'
        }
      case GLOBAL_SETTINGS_KEY.PLATFORM_FEE:
        if (position === 'start') {
          return 'Platform fee must be taken Rs.'
        } else {
          return ''
        }
         case GLOBAL_SETTINGS_KEY.CANCELLATION_CHARGE:
        if (position === 'start') {
          return 'Cancellation Charge must be taken Rs.'
        } else {
          return ''
        }
      case GLOBAL_SETTINGS_KEY.PAYOUT_PERCENTAGE:
        if (position === 'start') {
          return 'Owners will be paid'
        } else {
          return unit + ' of the rent amount.'
        }
      case GLOBAL_SETTINGS_KEY.PRE_BOOKING_DAYS:
        if (position === 'start') {
          return 'Tenant can book for maximum'
        } else {
          return unit + ' ago before joining'
        }
    }
  }

  return (
    <div className="tabInnerContent">
      <GlobalInnerHeader title={tabDetails.display_name} />
      <div className="settingBody notice_period_holder mt-4">
        <span> {matchGlobalKey(tabDetails.name, 'start')} </span>
        <InputGroup>
          <div
            className="input-group-append"
          >
            <Button type="button" color="primary" disabled={(isLoading || !isSuperAdmin)} onClick={(e) => {
              counterDecrease(e);
            }}>
              <i className="mdi mdi-minus" />
            </Button>
          </div>

          <input
            pattern="[0-9]"
            type="text"
            className="form-control"
            value={noticePeriodPayload?.value}
            placeholder="Enter notice"
            onChange={(e) => onDataAttributeChange(e)}
            disabled={isLoading}
          />
          <div
            className="input-group-append"
          >
            <Button type="button" color="primary" disabled={(isLoading || !isSuperAdmin)} onClick={(e) => {
              counterIncrease(e);
            }}>
              <i className="mdi mdi-plus" />
            </Button>
          </div>
        </InputGroup>
        <span>{matchGlobalKey(tabDetails.name, 'end')} </span>
        {error && <div className="text-danger mt-2">{error}</div>}
        <div className="text-end mt-4">
          {(tabDetails.isEditable || isSuperAdmin) &&
            <Button
              disabled={
                !isDataModified ||
                isLoading ||
                noticePeriodPayload.value == savedValue ||
                error
              }
              color="primary"
              onClick={saveData}
            >
              {isLoading && <Spinner size="sm" color="light" />} Save
            </Button>}
        </div>
      </div>
    </div>
  );
}

NoticePeriod.propTypes = {
  tabDetails: PropTypes.any,
};

export default NoticePeriod;
