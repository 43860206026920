import React, { useState } from "react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Modal, TextField, Box } from '@mui/material';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ACTIONS } from "../../common/constant";
import ConfirmationModal from "../Common/ConfirmationModal";
import { AsterikLabel } from "../Common/AsterikLabel";
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: 370,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export function InitiateRefundAmount({ isOpen, depositDetails, handleClose, handleSubmit }) {
    const [isConfirm, setIsConfirm] = useState(false);
    const [modal, setModal] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseForInfo = () => {
        setAnchorEl(null);
    };
    const showInfo = Boolean(anchorEl);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            refund_amount: '',
            note: ""
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            refund_amount: Yup.number().typeError('Please enter the number')
                .max(depositDetails?.deposit_amount, `Deposit refund amount can be less or equal to the deposit amount ${depositDetails?.deposit_amount}`)
                .min(0)
                .required('Please enter refund amount'),
            note: Yup.string().required("Please enter note")
        }),
    });

    return (
        <div>
            {modal && (
                <Modal
                    open={isOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableScrollLock={true}
                >
                    <Box sx={style} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Initiate Refund Amount</h5>
                            <CloseOutlinedIcon onClick={handleClose} className="cursor-pointer" />
                        </div>
                        <div className="mb-3">
                            <b>Deposit Paid During Booking: {depositDetails?.deposit_amount}</b>
                            {depositDetails?.payment && (
                                <>
                                    <InfoIcon onClick={handleClick} className='ms-2' />
                                    <Popover
                                        className='popup_block_refund'
                                        id={depositDetails?.id}
                                        open={showInfo}
                                        anchorEl={anchorEl}
                                        onClose={handleCloseForInfo}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Typography sx={{ p: 2 }} className='popContent'>
                                            <p>Payment Date: {depositDetails?.payment ? dayjs(depositDetails.payment?.payment_date).format('DD-MM-YYYY') : 'N/A'}</p>
                                            <p>Transaction ID: {depositDetails?.payment ? depositDetails.payment?.transaction_id : 'N/A'}</p>
                                        </Typography>
                                    </Popover>
                                </>
                            )}
                        </div>
                        <div className="tenantBody">
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label={<div>Refund Amount <AsterikLabel /></div>}
                                    name="refund_amount"
                                    type="text"
                                    value={formik.values.refund_amount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="mb-1"
                                />
                                {formik.touched.refund_amount && formik.errors.refund_amount && (
                                    <span className="text-danger">{formik.errors.refund_amount}</span>
                                )}
                            </div>
                            <div className={((formik.touched.refund_amount && formik.errors.refund_amount) || (formik.touched.note && formik.errors.note)) ? "mb-3" : "mb-4"}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label={<div>Note <AsterikLabel /></div>}
                                    name="note"
                                    value={formik.values.note}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    multiline
                                    rows={3}
                                    className="tenantNoteTextarea mb-1"
                                />
                                {formik.touched.note && formik.errors.note && (
                                    <span className="text-danger">{formik.errors.note}</span>
                                )}
                            </div>
                        </div>

                        <div className="modal_footer text-center">
                            <Button className="yellow_gradient_btn"
                                disabled={!formik.isValid}
                                onClick={() => {
                                    setIsConfirm(true)
                                    setModal(false)
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Box>
                </Modal>
            )}
            {isConfirm && (
                <ConfirmationModal
                    action={ACTIONS.EDIT}
                    show={isConfirm}
                    onCloseClick={() => {
                        setIsConfirm(false)
                        handleClose()
                    }}
                    onAcceptClick={() => handleSubmit(formik.values)}
                />
            )}
        </div>
    )
}