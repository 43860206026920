import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import logo from '../../assets/images/logo1.png';
import { Link } from 'react-router-dom';
import { handleVerifyOpt } from '../../api/User/reset';
import { toast } from "react-toastify";
import SetPassword from './setPassword';

function VerifyToken({ generateDetails, setIsVerify }) {
  const [isSetPassword, setIsSetPassword] = useState(false);
  document.title = ' Verify OTP| DStayZ';

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      otp: '',
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      otp: Yup.string().matches(/^[0-9]{6}$/, 'OTP must be 6 digits').required()
    }),
    onSubmit: async (values) => {
      try {
        // for testing purpose this is using untill email part is not done
        if (values.otp === '123456' && generateDetails.resetType === 'email') {
          toast.success('User verified successfully')
        } else {
          await handleVerifyOpt({
            ...(generateDetails.resetType === 'email' && {
              email: generateDetails.email,
              isEmail: true
            }),
            otp: values.otp,
            ...(generateDetails.resetType === 'contactNo' && {
              contact_number: generateDetails.contact_number,
              isEmail: false
            })
          })
        }
        setIsSetPassword(true)
      } catch (error) {
        console.log(error)
      }
    }
  });

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      {isSetPassword ? (
        <SetPassword generateDetails={generateDetails} setIsSetPassword={setIsSetPassword} />
      ) : (
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div>
                <div className="card">
                  <Link to="/" className="mt-2 d-block auth-logo">
                    <img src={logo} alt="" height="40" className="logo logo-dark" />
                  </Link>
                  <div className="card-body">

                    <div className="text-center mt-2">
                      <h5 className="text-primary">Verify Token</h5>
                      <p className="text-muted">Verify Token with DStayZ.</p>
                    </div>

                    <div className="p-2 mt-2">
                      <div className="alert alert-success text-center mb-4" role="alert">
                        Enter your OTP!
                      </div>
                      <Form className="form-horizontal" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="useremail">OTP</Label>
                          <Input
                            name="otp"
                            className="form-control"
                            id="otp"
                            placeholder="Enter otp"
                            onChange={validation.handleChange}
                            type="text"
                            onBlur={validation.handleBlur}
                            value={validation.values.otp || ''}
                            invalid={
                              !!(validation.touched.otp && validation.errors.otp)
                            }
                          />
                          {validation.touched.otp && validation.errors.otp ? (
                            <FormFeedback type="invalid">{validation.errors.otp}</FormFeedback>
                          ) : null}
                        </FormGroup>

                        <div className="mt-3 text-end">
                          <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            type="submit"
                            disabled={!validation.isValid}
                          >
                            Verify
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <p className="mb-0" onClick={() => setIsVerify(false)}>
                            Resend OTP ?
                            <Link to="/recover-password" className="fw-medium text-primary"> Resend </Link>
                          </p>
                        </div>
                      </Form>
                    </div>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default VerifyToken;
