/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal } from 'reactstrap';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import ComplainTenantFilterDrawer from './complainTenantFilterDrawer';
import ComplainNewTenantDrawer from './complainAddTenant';
import {
  allComplain,
  updateComplainHandler,
} from '../../api/ComplainTicket/api';
import moment from 'moment';
import PaginationComponent from '../Common/PaginationComponent';
import {
  ACTIONS,
  ALL_MODULES,
  DEFAULT_DATE_FORMAT,
  DEFAULT_PER_PAGE_VALUE,
  KNOWN_ROLES,
  OPERATION_TYPE,
} from '../../common/constant';
import Search from '../Common/GlobalSearch';
import StatusSelectBox from './statusSelectBox';
import TableHeaders from '../Common/TableHeaders';
import { SearchDropDownListPaginationComponent } from '../Common/SearchDropDownPaginationList';
import { removeComplain, searchTenants } from '../../api/complainTicket';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteModel from './noteModel';
import PrioritySelectBox from './PrioritySelectBox';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import { usePermissionGiven } from '../Hooks/UserPermission';
import { connect } from 'react-redux';
import FileList from '../Common/FileList';
import { formatBytes } from '../../helpers/string_helper';
import uuid from 'react-native-uuid';
import { toast } from 'react-toastify';
import NodataFound from '../Common/NoDataFound';
import CircularProgress from '@mui/material/CircularProgress';
import NoDataIcon from "../../assets/images/no-data.png";
import ConfirmationModal from '../Common/ConfirmationModal';

function TenantTab(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddEditDrawerOpen, setAddEditDrawerOpen] = useState(false);
  const [isExistingTenantFilter, setExistingTenantFilter] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [complains, setComplains] = useState([]);
  const [complainDetails, setComplainDetails] = useState('');
  const [noteDeatails, setNoteDeatails] = useState('');
  // peginations
  const [paginationMeta, setPaginationMeta] = useState({});
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(0);
  const [searchItem, setSearchItem] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const hasDeletePermission = usePermissionGiven(
    ALL_MODULES.COMPLAIN_TICKETS,
    OPERATION_TYPE.DELETE,
  );
  const hasEditPermission = usePermissionGiven(ALL_MODULES.COMPLAIN_TICKETS, OPERATION_TYPE.UPDATE);
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    fetchAllPropertyOwnerList();
  }, []);

  async function tog_standard(cellvalue) {
    setmodal_standard(!modal_standard);
    removeBodyCss();
    try {
      setComplainDetails(cellvalue);
    } catch (error) {
      console.log(error);
    }
  }

  async function tog_notes_standard(cellValue) {
    setNotesModal(!notesModal);
    removeBodyCss();
    try {
      setNoteDeatails(cellValue);
    } catch (error) {
      console.log(error);
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  const toggleExistingTenantFilter = () => {
    setExistingTenantFilter(!isExistingTenantFilter);
  };

  const toggleAddEditDrawerOpen = (data) => {
    if (data?.id) setEditData(data);
    else setEditData(null)
    setAddEditDrawerOpen(!isAddEditDrawerOpen);
  };

  const ownerListApi = async (params) => {
    try {
      setIsLoading(true);
      const response = (await allComplain(params)).data.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          ...res,
          indexNo: ++level,
        };
      });
      return resultData;
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // 2nd part
  const [filterFields, setFilterFields] = useState(['role']);
  const [filterInputs, setFilterInputs] = useState(['TENANT']);

  const fetchAllPropertyOwnerList = async (
    searchItem = '',
    paginatedData = { page, limit: rowsPerPage },
  ) => {
    const params = {
      ...paginatedData,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(sort?.order &&
        sort?.direction && {
        order: sort?.order,
        direction: sort?.direction,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
    };
    const resultData = await ownerListApi(params);
    setComplains(resultData);
  };

  // 3rd
  const globalSearch = async (params) => {
    setComplains([]);
    setSearchItem(params);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    const resultData = await ownerListApi({
      limit: params.limit,
      page: params.page,
      ...(params.search && {
        search: params.search,
      }),
      filter_fields: filterFields,
      filter_inputs: filterInputs,
    });

    setComplains(resultData);
  };

  // 4th
  const paginationModelMethod = async (data) => {
    let param = {
      ...(searchItem?.search && {
        ...searchItem,
      }),
    };

    if (data?.page) {
      setPage(data.page);
      param.page = data.page;
      fetchAllPropertyOwnerList(param, { page: data.page, limit: rowsPerPage });
    } else {
      setUpdate((prev) => prev + 1);
      setPage(1);
      setRowsPerPage(data?.limit);
      param.limit = data.limit;
      fetchAllPropertyOwnerList(param, { page: data.page, limit: data?.limit });
    }
  };

  // 5th
  const filter = async (filterField, filterInput) => {
    setComplains([]);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    setFilterInputs(filterInput);
    setFilterFields(filterField);
    const resultData = await ownerListApi({
      limit: rowsPerPage,
      ...(searchItem && {
        ...searchItem,
      }),
      filter_fields: ['role', ...filterField],
      filter_inputs: [KNOWN_ROLES.TENANT, ...filterInput],
    });
    setComplains(resultData);
  };

  // 6th
  const refresh = async () => {
    setComplains([]);
    setPage(1);
    const resultData = await ownerListApi({
      limit: rowsPerPage,
      page: 1,
      ...(sort?.order &&
        sort?.direction && {
        order: sort?.order,
        direction: sort?.direction,
      }),
      filter_fields: ['role'],
      filter_inputs: [KNOWN_ROLES.TENANT],
    });
    setComplains(resultData);
    props.fetchCounterData();
  };

  // sortings starts
  const [sort, setSort] = useState({});

  const handleSort = async (sort) => {
    setComplains([]);
    setSort(sort);
    const resultData = await ownerListApi({
      // Sort params
      ...(sort?.order &&
        sort?.direction && { order: sort.order, direction: sort.direction }),

      // Remaining sustainable params
      ...(page && page),
      ...(rowsPerPage && { limit: rowsPerPage }),
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
    });
    setComplains(resultData);
  };

  const columns = [
    // Ticket Id
    {
      column_name: 'Ticket Id',
      key_name: 'Id',
      sort_enabled: false,
    },
    // Property Name
    {
      column_name: 'Property Name',
      key_name: 'Property Name',
      sort_enabled: false,
    },
    // Complain Lodged by
    {
      column_name: 'Complain Lodged by',
      key_name: 'Complain Lodged by',
      sort_enabled: false,
    },
    // Contact No
    {
      column_name: 'Contact No',
      key_name: 'Contact No',
      sort_enabled: false,
    },
    // Complain Date
    {
      column_name: 'Complain Date',
      key_name: 'created_at',
      sort_enabled: true,
    },
    // Complain Details
    {
      column_name: 'Complain Details',
      key_name: 'complain_details',
      sort_enabled: false,
    },
    // Status
    {
      column_name: 'Status',
      key_name: 'status',
      sort_enabled: true,
    },
    // Priority
    {
      column_name: 'Priority',
      key_name: 'priority',
      sort_enabled: true,
    },
    // handle By
    {
      column_name: 'Handled By',
      key_name: 'resolved_by',
      sort_enabled: false,
      style: { minWidth: '300px' },
    },

    //Notes from Admin / note
    {
      column_name: 'Notes from Admin',
      key_name: 'description',
      sort_enabled: false,
    },
    // Resolved Date
    {
      column_name: 'Resolved Date/Declined Date',
      key_name: 'resolved_at',
      sort_enabled: false,
    },
  ];

  // handled By
  const [inputdata, setInputData] = useState({
    user_id: '',
  });

  // note
  const [noteModal, setNoteModal] = useState(false);

  const [noteModalActionAndPayload, setNoteModalActionAndPayload] = useState({
    action: null,
    item: null,
  });

  const handleNoteModalToggle = (action, item, modalState) => {
    setNoteModalActionAndPayload({
      action,
      item,
    });
    setNoteModal((prev) => {
      if (modalState) {
        return modalState;
      }
      return !prev;
    });
  };

  const handleHandlerUpdate = async (id, value) => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: value.id,
      };
      setInputData({
        ...inputdata,
        ...payload,
      });
      await updateComplainHandler(id, payload);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeComplainHandler = async (id) => {
    try {
      const response = await removeComplain(id);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        return true;
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    }
    return false;
  }

  const Listbox = styled('ul')(
    ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 150px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]
      };
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark'
        ? 'rgba(0,0,0, 0.50)'
        : 'rgba(0,0,0, 0.05)'
      };
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `,
  );

  const ListboxItem = styled('li')(`padding: '10px'`);

  const StyledMenuItem = styled(MenuItem)`
    && {
      padding: 10px;
      /* Add more styles as needed */
    }
  `;
  return (
    <div className='tenantTab'>
      <Row>
        <Col className='col-12'>
          <div className='flexbox_holder mb-3'>
            <div className='app-search'>
              <Search
                listCall={globalSearch}
                params={{
                  limit: rowsPerPage,
                  page: 1,
                  ...(sort?.order &&
                    sort?.direction && {
                    order: sort?.order,
                    direction: sort?.direction,
                  }),
                  ...(filterFields &&
                    filterInputs && {
                    filter_fields: filterFields,
                    filter_inputs: filterInputs,
                  }),
                }}
              />
            </div>
            <div>
              <Button
                className='yellow_gradient_btn me-2'
                onClick={toggleExistingTenantFilter}>
                <i className='mdi mdi-filter-variant' />
                Filter
              </Button>
              {usePermissionGiven(
                ALL_MODULES.COMPLAIN_TICKETS,
                OPERATION_TYPE.CREATE,
              ) && (
                  <Button color='primary' onClick={toggleAddEditDrawerOpen}>
                    <i className='mdi mdi-plus' />
                    Ticket
                  </Button>
                )}
            </div>
          </div>

          <div className='tabsBody'>
            <div className='table-rep-plugin complainTenantTable table_card_holder'>
              <div
                className='table-responsive mb-0'
                data-pattern='priority-columns'>
                {complains?.length === 0 && isLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )}

                {complains?.length === 0 && !isLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <NodataFound text={`No tenant's complain to show`} icon={NoDataIcon} />
                  </div>
                )}

                {complains?.length > 0 && (
                  <Table id='tech-companies-1' className='table table-bordered'>
                    <TableHeaders
                      headers={columns}
                      sortBy={sort}
                      emitSortedColumn={handleSort}
                      module={ALL_MODULES.COMPLAIN_TICKETS}
                    />
                    <Tbody>
                      {complains?.map((value) => {
                        return (
                          <Tr key={value.id}>
                            <Td>{value.indexNo}</Td>
                            <Td>{value.id}</Td>
                            <Td>{value?.property?.name}</Td>
                            <Td>
                              {value.complained_by?.name} <br />(
                              {value.complained_by?.code})
                            </Td>
                            <Td>{value.contact_no}</Td>
                            <Td>
                              {moment(value.created_at).format('DD-MM-YYYY')}
                            </Td>
                            <Td>
                              <div
                                className='clickableText'
                                onClick={() => {
                                  tog_standard(value);
                                }}
                                data-toggle='modal'
                                data-target='#myModal'>
                                Check Details
                              </div>
                            </Td>
                            <Td>
                              <StatusSelectBox
                                id={value.id}
                                name='status'
                                currentStatus={value.status}
                                refresh={refresh}
                                disableUpdate={
                                  !hasEditPermission
                                }
                              />
                            </Td>
                            <Td>
                              <PrioritySelectBox
                                id={value.id}
                                name='priority'
                                currentPriority={value.priority}
                                refresh={refresh}
                                disableUpdate={
                                  !hasEditPermission
                                }
                              />
                            </Td>
                            <Td>
                              <SearchDropDownListPaginationComponent
                                label='Select User'
                                searchEnabled={true}
                                listCall={searchTenants}
                                defaultValue={value.resolved_by}
                                disabled={
                                  !hasEditPermission
                                }
                                emitItem={(newValue) => {
                                  if (newValue)
                                    handleHandlerUpdate(value.id, newValue);
                                }}
                                apiParams={{
                                  filter_fields: ['is_system'],
                                  filter_inputs: [0],
                                }}
                              />
                            </Td>

                            <Td className='d-flex justify-content-center'>
                              {value?.note ? (
                                <DescriptionIcon
                                  titleAccess='Show Note'
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '28px',
                                    cursor: 'pointer',
                                  }}
                                  fontSize={'inherit'}
                                  color='info'
                                  onClick={() => {
                                    handleNoteModalToggle(
                                      ACTIONS.VIEW,
                                      value,
                                    );
                                  }}
                                />
                              ) : (
                                <NoteAddIcon
                                  titleAccess='Add Note'
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '28px',
                                    cursor: 'pointer',
                                  }}
                                  fontSize={'inherit'}
                                  color='error'
                                  onClick={() => {
                                    handleNoteModalToggle(
                                      ACTIONS.CREATE,
                                      value,
                                    );
                                  }}
                                />
                              )}
                            </Td>

                            <Td>
                              {value.resolved_at
                                ? moment(value.resolved_at).format(
                                  DEFAULT_DATE_FORMAT,
                                )
                                : value.declined_at
                                  ? moment(value.declined_at).format(
                                    DEFAULT_DATE_FORMAT,
                                  )
                                  : 'N/A'}
                            </Td>
                            {(hasEditPermission || hasDeletePermission) ?
                              (<Td className='threeDotsSpace'>
                                <Dropdown>
                                  <MenuButton>
                                    <i className='mdi mdi-dots-vertical font-size-15'></i>
                                  </MenuButton>
                                  <Menu slots={{ listbox: Listbox }}>
                                    {hasEditPermission && (
                                      <StyledMenuItem
                                        className='cursor-pointer'
                                        slots={{ ListboxItem: ListboxItem }}
                                        onClick={() => toggleAddEditDrawerOpen(value)}
                                      >
                                        <i className='mdi mdi-pencil-outline font-size-18 align-middle me-1 text-muted' />
                                        Edit
                                      </StyledMenuItem>
                                    )}
                                    {hasDeletePermission && (
                                      <StyledMenuItem
                                        className='cursor-pointer'
                                        slots={{ ListboxItem: ListboxItem }}
                                        onClick={() => setDeleteConfirm({ status: true, id: value?.id })}
                                      >
                                        <i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                                        Delete
                                      </StyledMenuItem>
                                    )}
                                  </Menu>
                                </Dropdown>
                              </Td>) : null}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                )}
              </div>
            </div>
            <div className='numbers_pagination_holder'>
              <div className='number_holder'></div>
              {/* Pagination starts */}
              {complains?.length > 0 && (
                <div className='numbers_pagination_holder'>
                  <PaginationComponent
                    paginationMeta={paginationMeta}
                    handleChangePagination={paginationModelMethod}
                    update={update}></PaginationComponent>
                </div>
              )}
              {/* Pagination ends here  */}
            </div>
          </div>
        </Col>
      </Row>

      {/* ========= Modal of complain details ===========  */}
      {modal_standard && (
        <Modal
          isOpen={modal_standard}
          toggle={tog_standard}
          size='lg'
          centered={true}>
          <div className='modal-header'>
            <h5 className='modal-title mt-0' id='myModalLabel'>
              Complain Details
            </h5>
            <button
              type='button'
              onClick={() => {
                setmodal_standard(false);
              }}
              className='close'
              data-dismiss='modal'
              aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <p>{complainDetails?.complain_details}</p>
            <ul className='downloadableFiles list-inline'>
              {complainDetails?.documents?.length > 0 && (
                <FileList
                  files={complainDetails?.documents.map((doc) => ({
                    ...doc,
                    uniqueId: uuid.v4(),
                    formattedSize: formatBytes(doc.size),
                  }))}
                />
              )}
            </ul>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              onClick={tog_standard}
              className='btn btn-primary waves-effect waves-light'
              data-dismiss='modal'>
              Close
            </button>
          </div>
        </Modal>
      )}

      {/* ========= Modal of Notes details ===========  */}
      {notesModal && (
        <Modal
          isOpen={notesModal}
          toggle={tog_notes_standard}
          size='lg'
          centered={true}>
          <div className='modal-header'>
            <h5 className='modal-title mt-0' id='myModalLabel'>
              Notes
            </h5>
            <button
              type='button'
              onClick={() => {
                setNotesModal(false);
              }}
              className='close'
              data-dismiss='modal'
              aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <p>{noteDeatails?.description}</p>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              onClick={tog_notes_standard}
              className='btn btn-primary waves-effect waves-light'
              data-dismiss='modal'>
              Close
            </button>
          </div>
        </Modal>
      )}

      {/* Delete confirmation modal */}
      {isDeleteConfirm.status &&
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await removeComplainHandler(isDeleteConfirm.id);
            if (isDeleted) {
              refresh();
            }
            setDeleteConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}

      {/* // note test  */}
      {noteModal && (
        <NoteModel
          refreshPropertyScheduleVisit={refresh}
          handleNoteModalToggle={handleNoteModalToggle}
          noteModalActionAndPayload={noteModalActionAndPayload}
        />
      )}

      {isExistingTenantFilter && (
        <ComplainTenantFilterDrawer
          isOpen={isExistingTenantFilter}
          toggleDrawer={toggleExistingTenantFilter}
          emitFilter={filter}
          selectedFields={filterFields}
          selectedInputs={filterInputs}
        />
      )}

      {isAddEditDrawerOpen && (
        <ComplainNewTenantDrawer
          action={editData?.id ? ACTIONS.EDIT : ACTIONS.CREATE}
          isOpen={isAddEditDrawerOpen}
          toggleDrawer={toggleAddEditDrawerOpen}
          refresh={refresh}
          data={editData}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.AuthLogin.auth.user,
});

export default connect(mapStateToProps, null)(TenantTab);
