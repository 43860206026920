import React, { useEffect, useState } from 'react';

import {
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
import logo from '../../assets/images/logo1.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { passwordResetWithURL, verifyPasswordResetLink } from '../../api/common';
import Pages404 from '../Utility/pages-404';

function VerifyPasswordReset() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isVerified, setIsVerified] = useState(false);
  document.title = 'Set Password| DStayZ';

  const passwordPattern = /^(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,16}$/;
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      password: Yup.string().required('Please Enter Your Password').matches(passwordPattern, "Password should be 8 character long with maximum 16 character of one capital one small one digit and one special case without whitespace"),
      confirmPassword: Yup.string().required('Please Enter Your Password').oneOf([Yup.ref('password')], 'Both the passwords should match.')
    }),
    onSubmit: async (payload) => {
      try {
        const response = await passwordResetWithURL(token, payload);
        if(response?.data?.data?.success === true) {
          toast.success(response.data.message);
          navigate("/login")
        }
        if(response?.data?.data?.success === false) toast.warning(response.data.message);
      } catch (e) {
        if(e.response?.data?.message) toast.error(e.response?.data?.message)
        console.log(e)
      }
    }
  });
  const validateLink = async () => {
    try {
      const response = await verifyPasswordResetLink(token);
      if(response?.data?.data?.success === true) {
        setIsVerified(true);
        toast.success(response?.data?.message);
      }
      if(response?.data?.data?.success === false) {
        setIsVerified(false);
        toast.warning(response?.data?.message);
      }
      
    } catch(e) {
      if(e?.response?.data?.message) toast.error(e.response.data.message);
      else toast.error(e?.message);
      console.log(e);
    }
  }
  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    validateLink();
    return function cleanup() {
      document.body.className = '';
    };
  }, []);

  return (
    <div className="account-pages my-5 pt-sm-5">
      {isVerified ? (
        <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <div>
              <div className="card">
                <Link to="/" className="mt-2 d-block auth-logo">
                  <img src={logo} alt="" height="40" className="logo logo-dark" />
                </Link>
                <div className="card-body">

                  <div className="text-center mt-2">
                    <h5 className="text-primary">Set Password</h5>
                    <p className="text-muted">Set Password with DStayZ.</p>
                  </div>

                  <div className="p-2 mt-2">
                    <Form className="form-horizontal" onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <div className="mb-3">
                        <Label className="form-label">Enter Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            !!(validation.touched.password && validation.errors.password)
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Enter Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder="Enter Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword}
                          invalid={
                            !!(validation.touched.confirmPassword && validation.errors.confirmPassword)
                          }
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                          disabled={!validation.isValid}
                        > Submit </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      )
      : (<Pages404/>) }
    </div>
  );
}

export default VerifyPasswordReset;
