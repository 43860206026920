import React, { useEffect, useMemo, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
  Row,
} from "reactstrap";
import uuid from 'react-native-uuid';
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BasicTabs from "../SimpleTab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { CKEditorComp } from "../CKEditor";
import {
  addTaskComment,
  deleteTaskComment,
  fetchCommentData,
  getParticularTask,
  updateTaskComment,
} from "../../api/kanban";
import { toast } from "react-toastify";
import ReactHtmlParser from "react-html-parser";
import { useProfile } from "../Hooks/UserHooks";
import { CircularProgress, Paper } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import DeleteModal from "../Common/DeleteModal";
import NodataFound from "../Common/NoDataFound";
import FileList from "../Common/FileList";
import { formatBytes } from "../../helpers/string_helper";
import { defaultStatus, getInitials } from "../../constants/kanban_helper";
import moment from "moment/moment";
import { ACTIONS, DEFAULT_DATETIME_FORMAT, DOCUMENT_HASH_BASE_KEYS } from "../../common/constant";
import Dropzone from "react-dropzone";
import ConfirmationModal from "../Common/ConfirmationModal";
import { uploadDocuments } from "../../api/Tenants/api";
import FileShowModal from "./FileShowModal";
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

const LIMIT = 10;
const useStyles = makeStyles((theme) => ({
  scrollableList: {
    maxHeight: 500,
    overflowY: "auto",
    width: "100%",
    maxWidth: "100%",
    bgcolor: "background.paper",
    position: "relative",
    overflow: "auto",
    marginTop: "10px",
    "& ul": { padding: 0 },
  },
}));
function KanbanDetailDrawer({
  isOpen,
  toggleDrawer,
  selectedId,
  setDedicatedTabValue,
  dedicatedTabValue,
  actionAfterEdit,
}) {
  const classes = useStyles();
  const [isAddExistingTenant] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});
  const myId = useProfile()?.user?.id;
  const [status, setStatus] = useState(defaultStatus);
  function handleStatusChange(e) {
    setStatus(e.target.value);
  }
  useEffect(() => {
    getParticularTask(selectedId, setTaskDetails);
  }, []);

  const DetailsTaskDetails = () => {
    return (
      <div className="view-details">
        <div className="task-header d-flex justify-content-between align-items-center">
          <div className="ticketNo cursor-pointer">{taskDetails?.name}</div>
          <div className="assignPro d-flex align-items-center">
            <div className="assignTitle m-0">Reported By:</div>
            <div className="assigneeName mx-2">
              <Tooltip title={taskDetails?.reporter?.name || "N/A"} arrow>
                {getInitials(
                  taskDetails?.reporter?.name
                    ? taskDetails?.reporter?.name
                    : "N/A"
                )}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="task-desc">{taskDetails.description}</div>

        <div className="task-info d-flex justify-content-between">
          <div className="d-flex">
            <div className="assignPro d-flex align-items-center">
              <div className="assignTitle m-0">Assigned To:</div>
              {!taskDetails.assignees?.length
                ? " N/A"
                : taskDetails?.assignees?.length > 1
                  ? taskDetails?.assignees?.slice(0, 1)?.map((ele) => (
                    <>
                      <div
                        className="assigneeName mx-2"
                        style={{
                          padding: "-5px",
                        }}
                      >
                        {ele?.assignee?.profile_photo ? (
                          <img
                            src={ele?.assignee?.profile_photo}
                            alt="img"
                            className="smallProfile"
                          />
                        ) : (
                          <Tooltip title={ele?.assignee?.name} arrow>
                            {getInitials(ele?.assignee?.name)}
                          </Tooltip>
                        )}
                      </div>

                      <Tooltip
                        title={taskDetails?.assignees?.map(
                          (e, indx) =>
                            indx > 0 && <li key={indx}>{e?.assignee?.name}</li>
                        )}
                        arrow
                      >
                        <span className="d-flex">
                          <small
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            &nbsp;
                            <i>+{taskDetails?.assignees?.length - 1} more</i>
                          </small>
                        </span>
                      </Tooltip>

                      {/* <span>{taskDetails?.assignee_id?.length-1} more</span> */}
                    </>
                  ))
                  : taskDetails?.assignees?.map((assign, idx) => (
                    <div key={idx} className="assigneeName mx-2">
                      {assign?.assignee?.profile_photo ? (
                        <img
                          src={assign?.assignee?.profile_photo}
                          alt="img"
                          className="smallProfile"
                        />
                      ) : (
                        <Tooltip title={assign?.assignee?.name} arrow>
                          {getInitials(assign?.assignee?.name)}
                        </Tooltip>
                      )}
                    </div>
                  ))}
            </div>
            <div className="assignPro d-flex align-items-center mx-5">
              <div className="assignTitle mx-2">Priority:</div>
              <div>
                <Badge
                  badgeContent={taskDetails.priority}
                  color="primary"
                  className="mx-4"
                />
              </div>
            </div>
          </div>
          <div className="assignPro d-flex align-items-center">
            {/* <div className="assignTitle m-0">Status:</div>
                        <div className="mx-2">To do</div> */}
            <div className="assignTitle m-0">Status:</div>
            <div className="statusDropdown mx-2">
              <Select value={status} disabled onChange={handleStatusChange}>
                <MenuItem value="To do">
                  {(taskDetails?.status)}
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className="attachmentBox">
          <div className="col-12 my-3">
            <label>
              <b>Notes: </b>
            </label>
            <textarea
              id="txtAddress"
              name="notes"
              rows="2"
              disabled
              className="form-control"
              placeholder="Note"
              value={taskDetails?.note?.description}
            />
          </div>
          <div>
            <b>Due Date : </b>
            {taskDetails.due_date ? taskDetails.due_date?.split("T")[0] : "N/A"}
          </div>
          <Row className="mb-3">
            <div className="col-sm-12 custom_border_bottom pb-3">
              <div className="tenantDetailTitle tenantDetFullTitle assignTitle m-0">
                Attachments :
              </div>
              {taskDetails?.attachments?.length > 0 && (
                <FileList
                  files={taskDetails?.attachments?.map((doc) => ({
                    ...doc,
                    uniqueId: uuid.v4(),
                    formattedSize: formatBytes(doc.size),
                  }))}
                />
              )}
            </div>
          </Row>
        </div>
      </div>
    );
  };

  const CommentsDetails = () => {
    const [commentMeta, setCommentMeta] = useState({});
    const [commentsData, setCommentsData] = useState([]);
    const [comment, setComment] = useState("");
    const [page, setPage] = useState(1);
    const [Id, setId] = useState("");
    const [mode, setMode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState({});
    const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
      status: false,
      data: null,
    });
    const [open, setOpen] = useState(false);

    const addValues = (e) => {
      setComment(e);
    };

    const fetchComments = async (page, limit, oldData) => {
      const params = {
        page: page,
        limit: limit,
      };
      try {
        const result = await fetchCommentData(params, selectedId);
        setCommentsData([...oldData, ...(result?.data?.data?.data || [])]);
        setCommentMeta(result?.data?.data?.meta);
      } catch (e) {
        toast.error(e.message);
      }
    };

    const createComment = async () => {
      try {
        setIsLoading(true);

        // Handle documents.
        let documents;
        const filesUnchanged =
          files?.documents?.filter((file) => file?.id) ?? [];
        if (filesUnchanged.length > 0) {
          documents = [...filesUnchanged.map((sd) => sd.id)];
        }
        const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
        if (filesToBeUploaded.length > 0) {
          // Docs upload
          const formData = new FormData();
          filesToBeUploaded.forEach((f) => {
            formData.append('files', f);
          });
          formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.TASK_COMMENT);
          formData.append('max_items', 10);

          const result = await uploadDocuments(formData);
          if (result.success) {
            const { response } = result;
            if (documents !== undefined) {
              documents = [
                ...documents,
                ...response.data.data.documents.map((doc) => doc.id),
              ];
            } else {
              documents = [...response.data.data.documents.map((doc) => doc.id)];
            }
          } else {
            return false;
          }
        }

        // Comment upload
        const payload = {
          description: comment,
          commented_by: +myId,
          task_id: selectedId,
          ...(documents === undefined
            ? {}
            : { documents: documents }),
        };

        const result =
          mode === "Edit"
            ? await updateTaskComment(
              {
                description: comment,
                ...(documents === undefined
                  ? { documents: [] }
                  : { documents: documents }),
              },
              Id
            )
            : await addTaskComment(payload);

        if (result?.status === 200) {
          toast.success(result?.data?.message);
          refresh();
          setComment("");
          setFiles({});
          setselectedFiles([]);
          mode !== "Edit" && actionAfterEdit();
        } else {
          toast.error(result.data.message);
        }
      } catch (e) {
        toast.error(e?.response?.data?.message || e?.message);
      } finally {
        setIsLoading(false);
      }
    };

    const deleteComment = async () => {
      try {
        setIsLoading(true);
        const result = await deleteTaskComment(Id);
        if (result?.status === 200) {
          toast.success(result?.data?.message);
          refresh();
          setId("");
          actionAfterEdit();
          setIsDeleteOpen(false);
        }
      } catch (e) {
        toast.error(e?.response?.data?.message || e?.message);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchParticularComment = async (data) => {
      setMode("Edit");
      setId(data?.id);
      setComment(data?.description);
      setFiles({
        documents: data?.documents,
        total_documents_size: data?.documents?.reduce(
          (p, c) => p + c.size,
          0,
        ),
      });
      setselectedFiles(data?.documents);
      const elements = document.getElementsByClassName("offcanvas-body");
      elements[0].scrollTop = 0;
    };

    const scrollHandle = (e) => {
      const listboxNode = e.currentTarget;

      const x = listboxNode.scrollTop + listboxNode.clientHeight;
      if (
        listboxNode.scrollHeight - x <= 1 &&
        commentMeta?.current_page < commentMeta?.last_page
      ) {
        fetchComments(page + 1, LIMIT, commentsData);
        setPage((old) => old + 1);
      }
    };
    const refresh = () => {
      fetchComments(1, LIMIT, []);
      setPage(1);
      setMode("");
    };
    const onReady = (editor) => {
      editor.focus();
    };

    useEffect(() => {
      fetchComments(page, LIMIT, []);
      return () => {
        setId("");
        setDedicatedTabValue(0);
      };
    }, []);

    function handleAcceptedFiles(targetFiles) {
      targetFiles.map((file) =>
        Object.assign(file, {
          uniqueId: uuid.v4(),
          location: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }),
      );
      setFiles({
        documents: [...selectedFiles, ...targetFiles],
        total_documents_size: [...selectedFiles, ...targetFiles].reduce(
          (p, c) => p + c.size,
          0,
        ),
      })
      setselectedFiles((prev) => {
        return [...prev, ...targetFiles];
      });
    }

    function confirmFileRemoval(e, file, fileIndex) {
      setIsDocumentDeleteConfirm({
        status: true,
        data: { e, file, fileIndex },
      });
    }

    function handleFileRemove(e, file, fileIndex) {
      const newDocuments = files.documents.filter(
        (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId),
      );
      const tempValues = {
        ...files,
      };

      if (newDocuments.length === 0) {
        delete tempValues.documents;
        delete tempValues.total_documents_size;
      }
      setFiles({
        ...tempValues,
        ...(newDocuments.length > 0 && {
          documents: newDocuments,
        }),
        ...(newDocuments.length > 0 && {
          total_documents_size: selectedFiles
            .filter(
              (doc, i) =>
                !(i === fileIndex && doc.uniqueId === file.uniqueId),
            )
            .reduce((p, c) => p + c.size, 0),
        }),
      })
      setselectedFiles((prev) =>
        prev.filter(
          (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId),
        ),
      );
      return true;
    }

    const handleClickOpen = (data) => {
      setOpen(data);
    };

    const handleClose = () => {
      setOpen(null);
    };

    return (
      <div>
        <div
          style={{
            width: "100%",
            border: "1px solid grey",
          }}
        >
          <CKEditorComp
            onChange={addValues}
            onReady={onReady}
            data={comment}
            isDisabled={isLoading}
          />
        </div>
        {/* Files */}
        <div className='col-12 mb-3 mt-2'>
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles);
            }}>
            {({ getRootProps, getInputProps }) => (
              <div
                className='dropzone'
                style={{ height: '91px', 'min-height': 'auto' }}
              >
                <div
                  {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className='mb-3 d-flex align-items-center flex-column'>
                    <i className='display-6 text-muted uil uil-cloud-upload' />
                    <h5>Drop files here or click to upload.</h5>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
            {selectedFiles.length > 0 && (
              <FileList
                files={selectedFiles}
                confirmFileRemoval={confirmFileRemoval}
              />
            )}
        </div >
        <Button
          variant="contained"
          color="success"
          disabled={!comment || isLoading}

          onClick={createComment}
        >
          <CircularProgress
            size={12}
            thickness={7}
            color="inherit"
            style={{
              color: "#00000",
              textAlign: "center",
              display: !isLoading ? "none" : "inline-block",
            }}
          />
          &nbsp; {mode === "Edit" ? "Edit Comment" : "Post Comment"}
        </Button>

        {
          commentsData?.length > 0 ? (
            <Paper className={`${classes.scrollableList} detail_comment_bullets`} onScroll={scrollHandle}>
              <List sx={{}}>
                {commentsData?.map((com) => (
                  <>
                    <ListItem alignItems="flex-start" key={com?.id}>
                      <ListItemAvatar>
                        <Avatar
                          alt="Avatar"
                          src={com?.commenter?.profile_photo}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<>{ReactHtmlParser(com?.description)}</>}
                        secondary={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              {com?.commenter?.name}, {""}
                              {moment(com?.updated_at)?.format(DEFAULT_DATETIME_FORMAT) || ""}
                            </div>

                            {myId === com?.commenter?.id && (
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    setId(com?.id);
                                    setIsDeleteOpen(true);
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon
                                    fontSize="small"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>

                                <div
                                  className='ps-3'
                                  onClick={() => fetchParticularComment(com)}>
                                  <EditRoundedIcon
                                    fontSize="small"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                {com?.documents?.length > 0 && (
                                  <div
                                    className='ps-3'
                                    onClick={() => handleClickOpen(com)}
                                  >
                                    <>
                                      {com.documents.length > 0 ? (
                                        <span className="badge bg-warning rounded-pill">
                                          <AttachmentOutlinedIcon
                                            fontSize="small"
                                            style={{
                                              marginRight: "2px",
                                              cursor: "pointer",
                                            }}
                                          />
                                          {com.documents.length}</span>) : null}
                                    </>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        }
                      />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                  </>
                ))}
              </List>
            </Paper>
          ) : (
            <NodataFound text={"No Comments Found"} />
          )
        }

        {/* View Attachments */}
        {
          open && (
            <FileShowModal
              data={open}
              onClose={handleClose}
            />
          )
        }

        {
          isDeleteOpen && (
            <DeleteModal
              show={isDeleteOpen}
              onCloseClick={() => setIsDeleteOpen(false)}
              onDeleteClick={deleteComment}
              isLoading={isLoading}
            />
          )
        }

        {/* Document Delete confirmation modal */}
        {
          isDocumentDeleteConfirm?.status &&
          <ConfirmationModal
            action={ACTIONS.DELETE}
            show={isDocumentDeleteConfirm?.status}
            onCloseClick={() =>
              setIsDocumentDeleteConfirm({ status: false, data: null })
            }
            onAcceptClick={async () => {
              const { e, file, fileIndex } = isDocumentDeleteConfirm.data;
              const isDeleted = handleFileRemove(e, file, fileIndex);
              if (isDeleted) {
                setIsDocumentDeleteConfirm({ status: false, data: null });
              }
            }}
            isDisabled={isLoading}
          />
        }
      </div >
    );
  };

  const tabValues = useMemo(() => {
    return {
      Details: <DetailsTaskDetails />,
      Comments: <CommentsDetails />,
    };
  }, [taskDetails]);

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isAddExistingTenant)}
    >
      <OffcanvasHeader toggle={toggleDrawer}>Task Details</OffcanvasHeader>
      <OffcanvasBody>
        <BasicTabs
          tabValues={tabValues}
          dedicatedTabValueIndex={dedicatedTabValue}
        />
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default KanbanDetailDrawer;
