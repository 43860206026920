export const PER_PAGE = [5, 10, 20, 50, 100];

export const DEFAULT_PER_PAGE_VALUE = 5;

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'

export const DEFAULT_DATETIME_FORMAT = 'DD-MM-YYYY hh:mm A'

export const ACTIONS = {
  CREATE: 'Create',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
};

export const PAYMENT_TYPES = {
  RENT: 'Rent',
  PAYOUT: 'Payout',
  DEPOSIT_RECEIVE: 'Deposit Receive',
  DEPOSIT_REFUND: 'Deposit Refund',
};

export const PROPERTY_STATUS = {
  ACTIVE: 'Active', // DEFAULT
  LIVE: 'Live',
  SUSPENDED: 'Suspended',
  CLOSED: 'Closed',
};

export const BOOKING_STATUS = {
  COMPLETE_WITHOUT_PROPERTY: 'Complete And Not Associated To Any Property', // DEFAULT
  COMPLETE_WITH_PROPERTY: 'Complete And Associated With Property',
  INCOMPLETE: 'Incomplete',
};

export const VERIFICATION_STATUS = {
  INCOMPLETE: 'Incomplete',
  COMPLETE: 'Complete',
};

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const TENANT_TYPES = {
  OTHERS: 'Others',
  STUDENT: 'Student',
  WORKING_PROFESSIONAL: 'Working Professional',
}

export const PROPERTY_REQUEST_STATUS = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
};

export const MODULE = {
  PROPERTY_REQUEST: 'Property Request',
  COMPLAIN_TICKET: 'Complain Tickets'
}

export const COMPANY_ASSOCIATE_TYPES = {
  VENDOR: 'Vendor',
  PARTNER: 'Partner',
  OTHERS: 'Others',
};

export const PORTAL = {
  TENANT_PORTAL: 'Tenant Portal',
  BACKOFFICE_PORTAL: 'Backoffice Portal',
  OWNER_PORTAL: 'Owner Portal',
};

export const KNOWN_ROLES = {
  SUPER_ADMIN: 'Super Admin',
  OWNER: 'Owner',
  TENANT: 'Tenant',
  PROPERTY_MANAGER: 'Property Manager',
  PROSPECTING_OWNER: 'Prospecting Owner',
}

export const COMPLAIN_TICKET_STATUS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  RESOLVED: 'Resolved',
  DECLINED: 'Declined',
};

export const COMPLAIN_TICKET_PRIORITY = {
  MEDIUM: 'Medium',
  HIGH: 'High',
  LOW: 'Low',
}

export const ROOM_TYPES = {
  AC: 'AC',
  NON_AC: 'Non-AC',
}

export const TASK_PRIORITY = {
  MEDIUM: 'Medium',
  HIGH: 'High',
  LOW: 'Low',
}
export const TASK_STATUS_OBJECT = {
  TO_DO: 'To Do',
  IN_PROGRESS: 'In Progress',
  DONE: 'Done',
  BLOCKER: 'Blocker',
};

export const SCHEDULE_PROPERTY_VISIT_STATUS = {
  VISITED: 'Visited',
  NOT_VISITED: 'Not Visited'
};

export const DOCUMENT_HASH_BASE_KEYS = {
  COMPANY_ASSOCIATES: 'company-associates',
  TENANT: 'tenant',
  OWNER: 'owner',
  PROPERTY: 'property',
  ROOM: 'room',
  COMPANY_EMPLOYEES: 'company-employees',
  TASK: 'task',
  PROPERTY_REQUEST: 'property-request',
  COMPLAIN_TICKET: 'complain-ticket',
  MESSAGE: 'message',
  PROPERTY_FOOD_MENU: 'property food menu',
  TASK_COMMENT: 'task-comment',
};

export const ALL_MODULES = {
  TENANTS: 'Tenants',
  OWNERS: 'Owners',
  PROPERTIES: 'Properties',
  FINANCE: 'Finance',
  NON_ACTIVE_PROPERTIES: 'Non Active Properties',
  COMPLAIN_TICKETS: 'Complain Tickets',
  ADD_PROPERTY_REQUESTS: 'New Property Requests',
  VISIT_REQUESTS: 'Visit Requests',
  COMPANY_EMPLOYEES: 'Company Employees',
  PG_VACANT_REQUESTS: 'PG Vacant Requests',
  COMPANY_ASSOCIATES: 'Company Associates',
  TASKS: 'Tasks',
  ROLE_PERMISSIONS: 'Role Permissions',
  MESSAGE: 'Message',
  TENANT_RENT_SLIPS: 'Tenant Rent Slips',
  PAYOUT_SLIP: 'Payout Slip',
  NOTIFICATION: 'Notification',
}

export const OPERATION_TYPE = {
  CREATE: 'Create',
  UPDATE: 'Edit',
  DELETE: 'Delete',
  READ: 'Read',
  ADD_TENANT: 'Add Tenant',
  REMOVE_TENANT: 'Remove Tenant',
  PAYOUT_PAYMENT: 'Payout Payment',
  DEPOSIT_PAYMENT: 'Deposit Payment',
}

export const ROUTE_VALIDITY = {
  PERMITTED: "Permitted",
  NOT_PERMITTED: "notPermitted",
}

export const BED_TYPES = {
  SINGLE: 'Single Sharing',
  DOUBLE: 'Double Sharing',
  TRIPLE: 'Triple Sharing',
};

export const BILL_PAID_STATUS = {
  FAILED: 'Failed',
  PENDING: 'Pending',
  PAID: 'Paid',
  PROCESSING: 'Processing',
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
};

export const VACANT_REQUEST_ACKNOWLEDGE_STATUS = {
  PENDING: 'Pending', // default
  ACKNOWLEDGED: 'Acknowledged',
  // NOT_ACKNOWLEDGED: 'Not Acknowledged',
  CANCELLED: 'Cancelled',
  REFUNDED: 'Refunded',
};

export const TIME_INTERVAL = {
  THIS_MONTH: 'This Month',
  PREVIOUS_MONTH: 'Previous Month',
  LAST_3_MONTH: 'Last 3 Month', 
  LAST_6_MONTH: 'Last 6 Month',
  CUSTOM: 'Custom Date Range'
};

export const GLOBAL_SETTINGS_KEY = {
  NOTICE_PERIOD: 'notice_period',
  PRE_BOOKING_DAYS: 'pre_booking_days',
  PLATFORM_FEE: 'platform_fee',
  PAYOUT_PERCENTAGE: 'payout_percentage',
  CANCELLATION_CHARGE: 'cancellation_charge'
}

export const PROPERTY_TYPES = {
  APARTMENT: 'Apartment or Flat Style',
  PERSONAL_HOME: 'Non-Apartment Style',
};

export const GENDER_PREFERENCE = {
  GIRLS_PG: `Girls' PG`,
  BOYS_PG: `Boys' PG`,
  COLIVE_PG: 'Colive PG'
}

export const PAYMENT_MODE = {
  UPI: 'upi',
  BANK: 'bank',
};

export const TENANT_LIVING_STATUS = {
  UPCOMING_JOINER: 'Upcoming Joiner',
  CURRENTLY_LIVING: 'Currently Living',
  PAST_TENANT: 'Past Tenant',
  CANCELLED_BOOKING: 'Cancelled Booking',
};