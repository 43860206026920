export const Columns = [
  {
    column_name: 'Employee Id',
    key_name: 'code',
    sort_enabled: true,
  },
  // Name
  {
    column_name: 'Name',
    key_name: 'name',
    sort_enabled: true,
  },
  {
    column_name: 'Designation/ Role',
    key_name: 'role',
    sort_enabled: true,
  },
  {
    column_name: 'Status',
    key_name: 'status',
    sort_enabled: false,
  },
  {
    column_name: 'Contact Number',
    key_name: 'contact_number',
    sort_enabled: false,
  },
  {
    column_name: 'Email',
    key_name: 'email',
    sort_enabled: true,
  },
];