import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import * as url from '../url_helper';
import accessToken from '../jwt-token-access/accessToken';
import {
  calenderDefaultCategories,
  chats,
  contacts,
  comments,
  events,
  groups,
  messages,
  orderData,
  productsData,
  customerData,
  shops,
  invoiceList,
  cartData,
  users as members,
  userProfile,
} from '../../common/data';

const users = [
  {
    uid: 1,
    username: 'Admin',
    role: 'Admin',
    password: '123456',
    email: 'admin@themesbrand.com',
  },
];

const fakeBackend = () => {
  // This sets the mock adapter on the default instance
  const mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });

  mock.onPost(url.POST_FAKE_REGISTER).reply((config) => {
    const user = JSON.parse(config.data);
    users.push(user);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([200, user]);
      });
    });
  });

  mock.onPost('/post-fake-login').reply((config) => {
    const user = JSON.parse(config.data);
    const validUser = users.filter(
      (usr) => usr.email === user.email && usr.password === user.password,
    );

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (validUser.length === 1) {
          resolve([200, validUser[0]]);
        } else {
          reject([
            'Username and password are invalid. Please enter correct username and password',
          ]);
        }
      });
    });
  });

  mock.onPost('/fake-forget-pwd').reply((config) =>
  // User needs to check that user is eXist or not and send mail for Reset New password

    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([200, 'Check you mail and reset your password.']);
      });
    }));

  mock.onPost('/post-jwt-register').reply((config) => {
    const user = JSON.parse(config.data);
    users.push(user);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([200, user]);
      });
    });
  });

  mock.onPost('/post-jwt-login').reply((config) => {
    const user = JSON.parse(config.data);
    const validUser = users.filter(
      (usr) => usr.email === user.email && usr.password === user.password,
    );

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (validUser.length === 1) {
          // You have to generate AccessToken by jwt. but this is fakeBackend so, right now its dummy
          const token = accessToken;

          // JWT AccessToken
          const tokenObj = { accessToken: token }; // Token Obj
          const validUserObj = { ...validUser[0], ...tokenObj }; // validUser Obj

          resolve([200, validUserObj]);
        } else {
          reject([
            400,
            'Username and password are invalid. Please enter correct username and password',
          ]);
        }
      });
    });
  });

  mock.onPost('/post-jwt-profile').reply((config) => {
    const user = JSON.parse(config.data);

    const one = config.headers;

    const finalToken = one.Authorization;

    const validUser = users.filter((usr) => usr.uid === user.idx);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Verify Jwt token from header.Authorization
        if (finalToken === accessToken) {
          if (validUser.length === 1) {
            let objIndex;

            // Find index of specific object using findIndex method.
            objIndex = users.findIndex((obj) => obj.uid === user.idx);

            // Update object's name property.
            users[objIndex].username = user.username;

            // Assign a value to locastorage
            localStorage.removeItem('authUser');
            localStorage.setItem('authUser', JSON.stringify(users[objIndex]));

            resolve([200, 'Profile Updated Successfully']);
          } else {
            reject([400, 'Something wrong for edit profile']);
          }
        } else {
          reject([400, 'Invalid Token !!']);
        }
      });
    });
  });

  mock.onPost('/post-fake-profile').reply((config) => {
    const user = JSON.parse(config.data);

    const validUser = users.filter((usr) => usr.uid === user.idx);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (validUser.length === 1) {
          let objIndex;

          // Find index of specific object using findIndex method.
          objIndex = users.findIndex((obj) => obj.uid === user.idx);

          // Update object's name property.
          users[objIndex].username = user.username;

          // Assign a value to locastorage
          localStorage.removeItem('authUser');
          localStorage.setItem('authUser', JSON.stringify(users[objIndex]));

          resolve([200, 'Profile Updated Successfully']);
        } else {
          reject([400, 'Something wrong for edit profile']);
        }
      });
    });
  });

  mock.onPost('/jwt-forget-pwd').reply((config) =>
  // User needs to check that user is eXist or not and send mail for Reset New password

    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([200, 'Check you mail and reset your password.']);
      });
    }));

  mock.onPost('/social-login').reply((config) => {
    const user = JSON.parse(config.data);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (user && user.token) {
          // You have to generate AccessToken by jwt. but this is fakeBackend so, right now its dummy
          const token = accessToken;

          // JWT AccessToken
          const tokenObj = { accessToken: token }; // Token Obj
          const validUserObj = { ...user, ...tokenObj }; // validUser Obj

          resolve([200, validUserObj]);
        } else {
          reject([
            400,
            'Username and password are invalid. Please enter correct username and password',
          ]);
        }
      });
    });
  });

  mock.onGet(url.GET_PRODUCTS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (productsData) {
        // Passing fake JSON data as response
        resolve([200, productsData]);
      } else {
        reject([400, 'Cannot get products']);
      }
    });
  }));

  mock.onGet(new RegExp(`${url.GET_PRODUCTS_DETAIL}/*`)).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (productsData) {
        // Passing fake JSON data as response
        const { params } = config;
        const product = productsData.find(
          (product) => product.id.toString() === params.id.toString(),
        );
        resolve([200, { ...product, comments }]);
      } else {
        reject([400, 'Cannot get product detail']);
      }
    });
  }));

  mock.onGet(url.GET_EVENTS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (events) {
        // Passing fake JSON data as response
        resolve([200, events]);
      } else {
        reject([400, 'Cannot get events']);
      }
    });
  }));

  mock.onPost(url.ADD_NEW_USER).reply((user) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (user && user.data) {
        // Passing fake JSON data as response
        resolve([200, user.data]);
      } else {
        reject([400, 'Cannot add user']);
      }
    });
  }));

  mock.onPut(url.UPDATE_USER).reply((user) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (user && user.data) {
        // Passing fake JSON data as response
        resolve([200, user.data]);
      } else {
        reject([400, 'Cannot update user']);
      }
    });
  }));

  mock.onDelete(url.DELETE_USER).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.user]);
      } else {
        reject([400, 'Cannot delete user']);
      }
    });
  }));

  mock.onPost(url.ADD_NEW_PROJECT).reply((project) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        // Passing fake JSON data as response
        resolve([200, project.data]);
      } else {
        reject([400, 'Cannot add project']);
      }
    });
  }));

  mock.onPut(url.UPDATE_PROJECT).reply((project) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        // Passing fake JSON data as response
        resolve([200, project.data]);
      } else {
        reject([400, 'Cannot update project']);
      }
    });
  }));

  mock.onDelete(url.DELETE_PROJECT).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.project]);
      } else {
        reject([400, 'Cannot delete project']);
      }
    });
  }));

  mock.onPost(url.ADD_NEW_EVENT).reply((event) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (event && event.data) {
        // Passing fake JSON data as response
        resolve([200, event.data]);
      } else {
        reject([400, 'Cannot add event']);
      }
    });
  }));

  mock.onPut(url.UPDATE_EVENT).reply((event) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (event && event.data) {
        // Passing fake JSON data as response
        resolve([200, event.data]);
      } else {
        reject([400, 'Cannot update event']);
      }
    });
  }));

  mock.onDelete(url.DELETE_EVENT).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.event]);
      } else {
        reject([400, 'Cannot delete event']);
      }
    });
  }));

  mock.onGet(url.GET_CATEGORIES).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (calenderDefaultCategories) {
        // Passing fake JSON data as response
        resolve([200, calenderDefaultCategories]);
      } else {
        reject([400, 'Cannot get categories']);
      }
    });
  }));

  mock.onGet(url.GET_CHATS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (chats) {
        // Passing fake JSON data as response
        resolve([200, chats]);
      } else {
        reject([400, 'Cannot get chats']);
      }
    });
  }));

  mock.onGet(url.GET_GROUPS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (groups) {
        // Passing fake JSON data as response
        resolve([200, groups]);
      } else {
        reject([400, 'Cannot get groups']);
      }
    });
  }));

  mock.onGet(url.GET_CONTACTS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (contacts) {
        // Passing fake JSON data as response
        resolve([200, contacts]);
      } else {
        reject([400, 'Cannot get contacts']);
      }
    });
  }));

  mock.onGet(new RegExp(`${url.GET_MESSAGES}/*`)).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (messages) {
        // Passing fake JSON data as response
        const { params } = config;
        const filteredMessages = messages.filter(
          (msg) => msg.roomId === params.roomId,
        );
        resolve([200, filteredMessages]);
      } else {
        reject([400, 'Cannot get messages']);
      }
    });
  }));

  mock.onPost(url.ADD_MESSAGE).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config.data) {
        // Passing fake JSON data as response
        resolve([200, config.data]);
      } else {
        reject([400, 'Cannot add message']);
      }
    });
  }));

  mock.onGet(url.GET_ORDERS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (orderData) {
        // Passing fake JSON data as response
        resolve([200, orderData]);
      } else {
        reject([400, 'Cannot get orderData']);
      }
    });
  }));

  mock.onPost(url.ADD_NEW_ORDER).reply((order) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (order && order.data) {
        // Passing fake JSON data as response
        resolve([200, order.data]);
      } else {
        reject([400, 'Cannot add order']);
      }
    });
  }));

  mock.onPut(url.UPDATE_ORDER).reply((order) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (order && order.data) {
        // Passing fake JSON data as response
        resolve([200, order.data]);
      } else {
        reject([400, 'Cannot update order']);
      }
    });
  }));

  mock.onDelete(url.DELETE_ORDER).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.order]);
      } else {
        reject([400, 'Cannot delete order']);
      }
    });
  }));

  mock.onGet(url.GET_CART_DATA).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (cartData) {
        // Passing fake JSON data as response
        resolve([200, cartData]);
      } else {
        reject([400, 'Cannot get cart data']);
      }
    });
  }));

  mock.onGet(url.GET_CUSTOMERS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (customerData) {
        // Passing fake JSON data as response
        resolve([200, customerData]);
      } else {
        reject([400, 'Cannot get customers data']);
      }
    });
  }));

  mock.onPost(url.ADD_NEW_CUSTOMER).reply((customer) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (customer && customer.data) {
        // Passing fake JSON data as response
        resolve([200, customer.data]);
      } else {
        reject([400, 'Cannot add customer']);
      }
    });
  }));

  mock.onPut(url.UPDATE_CUSTOMER).reply((customer) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (customer && customer.data) {
        // Passing fake JSON data as response
        resolve([200, customer.data]);
      } else {
        reject([400, 'Cannot update customer']);
      }
    });
  }));

  mock.onDelete(url.DELETE_CUSTOMER).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.customer]);
      } else {
        reject([400, 'Cannot delete customer']);
      }
    });
  }));

  mock.onGet(url.GET_SHOPS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (shops) {
        // Passing fake JSON data as response
        resolve([200, shops]);
      } else {
        reject([400, 'Cannot get shops data']);
      }
    });
  }));

  mock.onGet(url.GET_INVOICES).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (invoiceList) {
        // Passing fake JSON data as response
        resolve([200, invoiceList]);
      } else {
        reject([400, 'Cannot get invoices']);
      }
    });
  }));

  mock.onPost(url.ADD_NEW_INVOICE).reply((invoice) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (invoice && invoice.data) {
        // passing fake JSON data as response
        resolve([200, invoice.data]);
      } else {
        reject([400, 'Cannot add invoice']);
      }
    });
  }));

  mock.onPut(url.UPDATE_INVOICE).reply((invoice) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (invoice && invoice.data) {
        // passing fake data JSON as response
        resolve([200, invoice.data]);
      } else {
        reject([400, 'Cannot update invoice']);
      }
    });
  }));
  mock.onDelete(url.DELETE_INVOICE).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.invoice]);
      } else {
        reject([400, 'Cannot delete invoice']);
      }
    });
  }));

  mock.onGet(new RegExp(`${url.GET_INVOICE_DETAIL}/*`)).reply((config) => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (invoiceList) {
        // Passing fake JSON data as response
        const { params } = config;
        const invoice = invoiceList.find(
          (invoice) => invoice.id.toString() === params.id.toString(),
        );
        resolve([200, invoice]);
      } else {
        reject([400, 'Cannot get invoice']);
      }
    });
  }));

  // mock.onGet(url.GET_PROJECTS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (projects) {
  //         // Passing fake JSON data as response
  //         resolve([200, projects]);
  //       } else {
  //         reject([400, "Cannot get projects"]);
  //       }
  //     });
  //   });
  // });

  // mock.onGet(new RegExp(`${url.GET_PROJECT_DETAIL}/*`)).reply((config) => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (projects) {
  //         // Passing fake JSON data as response
  //         const { params } = config;
  //         const project = projects.find(
  //           (project) => project.id.toString() === params.id.toString()
  //         );
  //         resolve([200, project]);
  //       } else {
  //         reject([400, "Cannot get project detail"]);
  //       }
  //     });
  //   });
  // });

  // mock.onGet(url.GET_TASKS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (tasks) {
  //         // Passing fake JSON data as response
  //         resolve([200, tasks]);
  //       } else {
  //         reject([400, "Cannot get tasks"]);
  //       }
  //     });
  //   });
  // });

  mock.onGet(url.GET_USERS).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (members) {
        // Passing fake JSON data as response
        resolve([200, members]);
      } else {
        reject([400, 'Cannot get users']);
      }
    });
  }));

  mock.onGet(url.GET_USER_PROFILE).reply(() => new Promise((resolve, reject) => {
    setTimeout(() => {
      if (userProfile) {
        // Passing fake JSON data as response
        resolve([200, userProfile]);
      } else {
        reject([400, 'Cannot get user profile']);
      }
    });
  }));
};

export default fakeBackend;
