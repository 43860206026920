import React, { useEffect, useState } from 'react';
import {
  Row, Form, Button, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { TextField } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DEFAULT_DATE_FORMAT } from '../../common/constant';
import dayjs from 'dayjs';
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";


export function PayoutFilterDrawer({ isOpen, toggleDrawer, emitFilterItem, selectedFields, selectedInputs }) {
  const [filterObject, setFilterObject] = useState({
    property_name: '',
    owner_name: '',
    date_range: '',
    payment_date: '',
  });
  const [selectedDate, setSelectedDate] = useState();
  const [selectedPaymentDate, setSelectedPaymentDate] = useState();
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });

  const [pickerOpen, setPickerOpen] = useState(false); // Manage picker visibility
  const [paymentDatePickerOpen, setPaymentDatePickerOpen] = useState(false)

  const handleApplyFilter = () => {
    const filterFields = [];
    const filterInputs = [];

    if (filterObject.property_name) {
      filterFields.push('property_name');
      filterInputs.push(filterObject.property_name);
    }

    if (filterObject.owner_name) {
      filterFields.push('owner_name');
      filterInputs.push(filterObject.owner_name);
    }

    if (filterObject.date_range) {
      filterFields.push('date_range');
      filterInputs.push(filterObject.date_range);
    }

    if (filterObject.payment_date) {
      filterFields.push('payment_date');
      filterInputs.push(filterObject.payment_date);
    }

    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
  };

  const handleDateRangeChange = (item) => {
    setSelectionRange(item.selection);
    const formattedStartDate = dayjs(item.selection.startDate).format(DEFAULT_DATE_FORMAT);
    const formattedEndDate = dayjs(item.selection.endDate).format(DEFAULT_DATE_FORMAT);
    setFilterObject({
      ...filterObject,
      date_range: `${dayjs(item.selection.startDate).format('YYYY-MM-DD')}/${dayjs(item.selection.endDate).format('YYYY-MM-DD')}`
    });
    setSelectedDate(`${formattedStartDate} - ${formattedEndDate}`)
    setPickerOpen(false);
  };

  const handlePaymentDateRangeChange = (item) => {
    setSelectionRange(item.selection);
    const formattedStartDate = dayjs(item.selection.startDate).format(DEFAULT_DATE_FORMAT);
    const formattedEndDate = dayjs(item.selection.endDate).format(DEFAULT_DATE_FORMAT);
    setFilterObject({
      ...filterObject,
      payment_date: `${dayjs(item.selection.startDate).format('YYYY-MM-DD')}/${dayjs(item.selection.endDate).format('YYYY-MM-DD')}`
    });
    setSelectedPaymentDate(`${formattedStartDate} - ${formattedEndDate}`)
    setPaymentDatePickerOpen(false);
  };

  const handleResetFilter = () => {
    setFilterObject({
      property_name: '',
      owner_name: '',
      date_range: '',
      payment_date: ''
    });
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    });
    setPickerOpen(false); // Ensure picker is closed
    setPaymentDatePickerOpen(false);
    emitFilterItem([], []);
    toggleDrawer();
  };

  useEffect(() => {
    selectedFields.forEach((field, index) => {
      setFilterObject((prev) => ({ ...prev, [field]: selectedInputs[index] }));
      if (field === 'date_range') {
        const dateRange = selectedInputs[index].split('/')
        const formattedStartDate = dayjs(dateRange[0]).format(DEFAULT_DATE_FORMAT);
        const formattedEndDate = dayjs(dateRange[1]).format(DEFAULT_DATE_FORMAT);
        setSelectedDate(`${formattedStartDate} - ${formattedEndDate}`)
      } else if (field === 'payment_date') {
        const dateRange = selectedInputs[index].split('/')
        const formattedStartDate = dayjs(dateRange[0]).format(DEFAULT_DATE_FORMAT);
        const formattedEndDate = dayjs(dateRange[1]).format(DEFAULT_DATE_FORMAT);
        setSelectedPaymentDate(`${formattedStartDate} - ${formattedEndDate}`)
      }
    });
  }, [selectedFields, selectedInputs]);

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => {
        toggleDrawer(isOpen);
        setFilterObject({});
        setSelectionRange({
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        });
        setPickerOpen(false); // Ensure picker is closed
      }}
    >
      <OffcanvasHeader toggle={() => {
        toggleDrawer(isOpen);
        setFilterObject({});
        setSelectionRange({
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        });
        setPickerOpen(false); // Ensure picker is closed
      }}>
        Payout Slip Filter
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="tenantDetailsDrawer">
            <Row>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='property_name'
                  value={filterObject.property_name}
                  label="Property Name"
                  onChange={(e) => {
                    setFilterObject({
                      ...filterObject,
                      property_name: e.target.value
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='owner_name'
                  value={filterObject.owner_name}
                  label="Owner Name"
                  onChange={(e) => {
                    setFilterObject({
                      ...filterObject,
                      owner_name: e.target.value
                    });
                  }}
                />
              </div>
              <div className="col-md-12 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='date_range'
                  value={selectedDate || ''}
                  label="Date Range"
                  InputProps={{
                    readOnly: true, endAdornment: selectedDate ? (
                      <IconButton size="small" onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setSelectedDate()
                        setFilterObject({
                          ...filterObject,
                          date_range: ''
                        });
                      }}>
                        <ClearIcon />
                      </IconButton>
                    ) : undefined
                  }}
                  onClick={() => setPickerOpen(!pickerOpen)}
                />
                {pickerOpen && (
                  <DateRangePicker
                    ranges={[selectionRange]}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection={true}
                    onChange={handleDateRangeChange}
                  />
                )}
              </div>

              <div className="col-md-12 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='payment_date'
                  value={selectedPaymentDate || ''}
                  label="Payment Date Range"
                  InputProps={{
                    readOnly: true, endAdornment: selectedPaymentDate ? (
                      <IconButton size="small" onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setSelectedPaymentDate()
                        setFilterObject({
                          ...filterObject,
                          payment_date: ''
                        });
                      }}>
                        <ClearIcon />
                      </IconButton>
                    ) : undefined
                  }}
                  onClick={() => setPaymentDatePickerOpen(!paymentDatePickerOpen)}
                />
                {paymentDatePickerOpen && (
                  <DateRangePicker
                    ranges={[selectionRange]}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection={true}
                    onChange={handlePaymentDateRangeChange}
                  />
                )}
              </div>
            </Row>
            <div className="formActions btn_right">
              <Button className="yellow_gradient_btn mx-2" onClick={handleApplyFilter}>
                Apply Filter
              </Button>
              <Button className="red_gradient_btn" onClick={handleResetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}
