import React, {
  useLayoutEffect,
  useMemo,
  useRef,
  useState, useEffect
} from "react";
import { Card, Button } from "reactstrap";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Table, Tbody, Tr, Td } from "react-super-responsive-table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import { Autocomplete, Badge, TextField, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import uuid from "react-native-uuid";
import AddNotes from "../../RentSlip/addNote";
import {
  createElectricityBill,
  fetchRentSlipData,
  fetchTenantOfaPropertyForMonth,
  updateRentBill,
  updateTenantRentSlipComment,
} from "../../../api/rent-bill";
import moment from "moment";
import NodataFound from "../../Common/NoDataFound";
import PaginationComponent from "../../Common/PaginationComponent";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  ACTIONS,
  ALL_MODULES,
  BILL_PAID_STATUS,
  DEFAULT_DATE_FORMAT,
  DOCUMENT_HASH_BASE_KEYS,
} from "../../../common/constant";
import { toast } from "react-toastify";
import { formatBytes, handleKeyDown } from "../../../helpers/string_helper";
import TableHeaders from "../../Common/TableHeaders";
import { ColumnRentSlip } from "./ColumnRentSlip";
import { uploadDocuments } from "../../../api/compay-employee";
import AlertDialog from "./ToggleImage";
import ConfirmationModal from "../../Common/ConfirmationModal";
import dayjs from "dayjs";
import { DetailsHeaderBlock } from "../../Common/DetailsHeaderBlock";
import { UploadFileButton } from "../../Common/UploadFileButton";
import NoDataIcon from "../../../assets/images/no-data.png";
import { getAllProperties } from "../../../api/property";
import { SelectDropDownListPaginationComponent } from "../../Common/SelectDropDownPaginationList"
import RentSlipPdf from "../../RentSlip/RentSlipPdf";
import { BlobProvider } from "@react-pdf/renderer";
import { getAllGlobalSettings } from "../../../api/globalSettings";

const LIMIT_SLIP = 5;
var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function RentSlipIndex() {
  document.title = " Rent Slip | Dstayz";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [limit, setLimit] = useState(LIMIT_SLIP);
  const [confirmation, setConfirmation] = useState(false);
  const [userData, setUserData] = useState();
  const pData = useRef({
    pId: null,
    month: null,
    year: null,
  });
  const [toggleOn, setToggleOn] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [update, setUpdate] = useState(0);
  const [params, setParams] = useState({
    limit: LIMIT_SLIP,
    month: "",
    year: "",
  });
  const paramId = useParams();
  const { pathname } = useLocation();
  const [rent_slips_id, setRentSlipID] = useState("");
  const [action, setAction] = useState(ACTIONS.CREATE);
  const nav = useNavigate();
  const [notes, setNotes] = useState({});
  const [sort, setSort] = useState({});
  const statRef = useRef(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [meta, setMeta] = useState({});
  const [selectedImage, setSelectedImage] = useState([]);
  const MAX_SIZE_IMAGE = 1000;
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [page, setPage] = useState(1);
  const [ObjName, setObjName] = useState("");
  const [ids, setId] = useState("");
  const [tenantRentSlipId, setTenantRentSlipId] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [platformFee, setPlatformFee] = useState(null);

  const fetchGlobalSettings = async () => {
    try {
      const response = (await getAllGlobalSettings({ search: 'platform_fee' })).data.data
      setPlatformFee(parseInt(response.data[0].payload?.value));
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchGlobalSettings();
  }, [])

  const handleClick = (event, item) => {
    setTenantRentSlipId(item.id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTenantRentSlipId()
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const selectMonth = useMemo(() => {
    const this_month = new Date().getMonth();
    const monthArray =
      this_month === 11
        ? [months.slice().pop(), months.slice().shift()]
        : [...months.slice(this_month, this_month + 2)];
    return monthArray;
  }, [selectedPropertyId]);

  const selectYear = useMemo(() => {
    const currentYear = new Date().getFullYear();
    if (new Date().getMonth() + 1 === 12) {
      return [currentYear, currentYear + 1];
    }
    return [currentYear];
  }, [selectedPropertyId]);

  const [isOpenAddNoteModal, setisOpenAddNoteModal] = useState(false);

  const toggleAddNote = (ele) => {
    setTenantRentSlipId(ele?.id)
    setId(ele?.tenant_id);
    setisOpenAddNoteModal(!isOpenAddNoteModal);
  };
  const calculateRent = (userVacantReq, joining_date, rent) => {
    function findDateDiff(sDay, eDay) {
      return moment(eDay).diff(moment(sDay), "days");
    }
    const joining_date_tenant = new Date(joining_date).getMonth();
    const date = `${selectedYear}-${joining_date_tenant + 1}-0${1}`;

    const endOfMonth = moment(date).daysInMonth();

    if (userVacantReq?.length) {
      const vacant_month = new Date(userVacantReq[0]?.leaving_date).getMonth();

      if (selectedMonth == months[vacant_month]) {
        const date = `${selectedYear}-${vacant_month + 1}-0${1}`;
        const endOfMonth = moment(date).daysInMonth();
        const days = findDateDiff(
          `${selectedYear}-${vacant_month + 1}-0${1}`,
          userVacantReq[0]?.leaving_date
        );
        return Math.floor((rent / endOfMonth) * (days + 1));
      }

      if (selectedMonth == months[joining_date_tenant]) {
        const days = findDateDiff(
          joining_date,
          `${selectedYear}-${joining_date_tenant + 1}-${endOfMonth}`
        );

        return Math.floor((rent / endOfMonth) * (days + 1));
      }

      return +rent;
    } else {
      if (selectedMonth == months[joining_date_tenant]) {
        const days = findDateDiff(
          joining_date,
          `${selectedYear}-${joining_date_tenant + 1}-${endOfMonth}`
        );

        return Math.floor((rent / endOfMonth) * (days + 1));
      }

      return +rent;
    }
  };
  const fetchAllTenant = async () => {
    const paramData = {
      limit: params?.limit,
      page: page,
      month: params?.month,
      year: params?.year,
    };
    setSelectedImage([]);
    try {
      const response = await fetchTenantOfaPropertyForMonth(
        paramData,
        selectedPropertyId
      );

      if (response?.data?.data?.length === 0) {
        setUserData([]);
        setMeta({});
        return;
      }

      const data = response?.data?.data?.data[0]?.tenant?.map((ele) => ({
        floor_no:
          ele?.user?.bed?.room?.floor?.name ||
          ele?.user?.bed?.room?.flat?.floor?.name,
        floor_no_id:
          ele?.user?.bed?.room?.floor?.id ||
          ele?.user?.bed?.room?.flat?.floor?.id,
        flat_no: ele?.user?.bed?.room?.flat?.name,
        flat_no_id: ele?.user?.bed?.room?.flat?.id,
        property_id: response?.data?.data?.data[0]?.id,
        property_name: response?.data?.data?.data[0]?.name,
        owner_id:
          response?.data?.data?.data[0]?.user_id ??
          ele?.user?.bed?.room?.flat?.user_id ??
          ele?.user?.bed?.room?.flat?.floor?.user_id ??
          ele?.user?.bed?.room?.floor?.user_id,
        tenant_id: ele?.id,
        name: ele?.user?.name,
        start_date: popOver(
          {
            joining_date: ele?.joining_date,
            rent:
              calculateRent(
                ele?.vacant_requests,
                ele?.joining_date,
                ele?.user?.bed?.price
              ) ?? 0,
            bed_price: ele?.user?.bed?.price,
            vacant_request: ele?.vacant_requests[0]?.leaving_date,
          },
          "YYYY-MM-DD"
        ),
        total_amount: 0,
        electricityAmount: 0,
        otherAmount: 0,
        joining_date: ele?.joining_date,
        room_no: ele?.user?.bed?.room?.name,
        room_no_id: ele?.user?.bed?.room?.id,
        bed_id: ele?.user?.bed?.id,
        status: BILL_PAID_STATUS.PENDING,
        bed_price: ele?.user?.bed?.price,
        rent:
          calculateRent(
            ele?.vacant_requests,
            ele?.joining_date,
            ele?.user?.bed?.price
          ) ?? 0,
        vacant_request: ele?.vacant_requests[0]?.leaving_date,
      }));
      setUserData(data);
      setMeta(response?.data?.data?.meta);
    } catch (e) {
      console.log(e?.message);
    }
  };

  const fetchDataFromRentSlip = async (param, id) => {
    const paramData = {
      ...param,
    };
    try {
      const response = await fetchRentSlipData(paramData, id);
      statRef.current = response?.data?.data?.data[0]?.status;
      const month = new Date(
        response?.data?.data?.data[0]?.start_date
      ).getMonth();
      setSelectedMonth(months[month]);
      setSelectedYear(
        new Date(response?.data?.data?.data[0]?.start_date).getFullYear()
      );

      setSelectedImage([...(response?.data?.data?.data[0]?.attachments || [])]);
      setselectedFiles([...(response?.data?.data?.data[0]?.attachments || [])]);
      setObjName(
        response?.data?.data?.data[0]?.rent_slips[0]?.property?.name || ""
      );

      setRentSlipID(response?.data?.data?.data[0]?.id);
      const data = response?.data?.data?.data[0]?.rent_slips?.map((ele) => ({
        id: ele?.id,
        floor_no: ele?.floor?.name,
        flat_no: ele?.flat?.name,
        tenant_id: ele?.tenant_id,
        total_amount: ele?.total_amount,
        electricityAmount: ele?.electricity_amount,
        otherAmount: ele?.other_amount,
        start_date: ele?.start_date,
        end_date: ele?.end_date,
        room_no: ele?.room?.name,
        room_id: ele?.room?.id,
        bed_id: ele?.bed?.id,
        status: ele?.status,
        name: ele?.tenant?.user?.name,
        note: ele?.note?.description,
        joining_date: ele?.joining_date,
        vacant_request: ele?.vacant_request,
        rent: ele?.rent,
        property_name: ele?.property?.name,
        property_address: ele?.property?.address,
        payment_history: ele?.tenant?.payment_history,
      }));

      response?.data?.data?.data[0]?.rent_slips?.forEach((ele) => {
        setNotes((old) => ({
          ...old,
          [ele?.tenant_id]: ele?.note?.description,
        }));
      });
      setUserData(data);
      setMeta(response?.data?.data?.meta);
    } catch (e) {
      nav(-1);
      toast.error(e?.response?.data?.message || e.message);
    }
  };

  useLayoutEffect(() => {
    if (paramId?.bill_id) {
      if (pathname.includes("view")) {
        setAction(ACTIONS.VIEW);
      } else {
        setAction(ACTIONS.EDIT);
      }
      fetchDataFromRentSlip(
        {
          page: page,
          limit: limit,
          ...(sort?.order && { order: sort.order }),
          ...(sort?.direction && { direction: sort.direction }),
        },
        paramId?.bill_id
      );
    }
  }, []);

  const handleSort = async (sort) => {
    if (action === ACTIONS.CREATE) return;
    setUserData([]);
    setSort(sort);
    await fetchDataFromRentSlip(
      {
        page: page,
        limit: limit,
        ...(sort?.order &&
          sort?.direction && { order: sort.order, direction: sort.direction }),
      },
      paramId?.bill_id
    );
  };

  const paginationModelMethod = async (data) => {
    if (data?.page) {
      setPage(data.page);
      setLimit(params?.limit);
      const paramData = {
        limit: params?.limit,
        page: data?.page,
      };
      fetchDataFromRentSlip({ ...paramData }, paramId?.bill_id);
    } else {
      setUpdate((prev) => prev + 1);
      // setPage(1);
      setLimit(data?.limit);
      setParams((old) => ({ ...old, ["limit"]: data?.limit }));
      fetchDataFromRentSlip(
        {
          limit: data?.limit,
          page: page,
        },
        paramId?.bill_id
      );
    }
  };
  const fileUploadFunction = async (filesToBeUploaded) => {
    try {
      let doc = [];
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append("files", f);
      });
      formData.append("base_key", DOCUMENT_HASH_BASE_KEYS.TASK);

      formData.append("max_items", 10);

      const result = await uploadDocuments(formData);
      if (result.success) {
        const { response } = result;

        doc = [...response.data.data.documents.map((doc) => doc.id)];

        return doc;
      } else {
        setIsLoading(false);
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getNoteUserWise = (id) => {
    for (let key in notes) {
      if (key == id) {
        return notes[key];
      }
    }
  };
  const submitValue = async (note_change = false) => {
    const date = new Date(`${params?.year}-${params?.month}-${2}`);
    const endOfMonth = `${moment(date)
      .endOf("month")
      .format("YYYY-MM-DDT23:59:59.000")}Z`;

    const start = `${moment(`${params?.year}-${params?.month}-0${2}`)
      .startOf("month")
      .format("YYYY-MM-DDT00:00:00.000")}Z`;

    let documents = [];
    const filesUnchanged = selectedImage?.filter((file) => file?.id) ?? [];
    if (filesUnchanged.length > 0) {
      documents = [...filesUnchanged.map((sd) => sd.id)];
    }
    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
    let uploadedValues = [];
    if (filesToBeUploaded.length > 0) {
      uploadedValues = await fileUploadFunction(filesToBeUploaded, documents);
    }
    documents = [...documents, ...uploadedValues];
    setIsLoading(true);
    let payLoadData = {};
    if (note_change) {
      payLoadData = {
        attachments: [...documents],
        rentSlip: userData?.map((ele) => ({
          id: ele?.id,
          note: getNoteUserWise(ele?.tenant_id),
          tenant_id: +ele?.tenant_id,
        })),
      };
      try {
        const response = await updateRentBill(rent_slips_id, payLoadData);
        if (response?.status === 200) {
          toast.success("Note updated successfully");
          setIsLoading(false);
        }
      } catch (e) {
        toast.error(e?.response?.data?.message || e.message);
        setIsLoading(false);
      }
      return;
    }
    if (action === ACTIONS.CREATE) {
      payLoadData = {
        start_date: start,
        end_date: endOfMonth,
        property_id: selectedPropertyId,
        attachments: [...documents],
        status: BILL_PAID_STATUS.DRAFT,
        rentSlip: userData?.map((ele) => ({
          bed_id: ele?.bed_id,
          ...(ele?.flat_no_id && { flat_id: ele.flat_no_id }),
          floor_id: ele.floor_no_id,
          room_id: ele?.room_no_id,
          rent: +ele?.rent || 0,
          start_date: ele?.start_date?.s?.includes("Start")
            ? start
            : `${moment
              .utc(ele?.start_date?.s)
              .local()
              .format("YYYY-MM-DDT00:00:00.000")}Z`,
          joining_date: ele?.joining_date,
          vacant_request: ele?.vacant_request,
          end_date: ele?.start_date?.e?.includes("end")
            ? endOfMonth
            : `${moment
              .utc(ele?.start_date?.e)
              .local()
              .format("YYYY-MM-DDT23:59:59.000")}Z`,
          electricity_amount: +ele?.electricityAmount || 0,
          other_amount: +ele?.otherAmount || 0,
          note: getNoteUserWise(ele?.tenant_id),
          total_amount:
            Number(ele?.otherAmount) +
            Number(ele?.electricityAmount) +
            Number(ele?.rent) || 0,
          status: BILL_PAID_STATUS.PENDING,
          property_id: +ele?.property_id,
          tenant_id: +ele?.tenant_id,
          owner_id: ele?.owner_id,
        })),
      };
    }
    if (action === ACTIONS.EDIT) {
      payLoadData = {
        attachments: [...documents],
        rentSlip: userData?.map((ele) => ({
          id: ele?.id,
          electricity_amount: +ele?.electricityAmount || 0,
          other_amount: +ele?.otherAmount || 0,
          note: getNoteUserWise(ele?.tenant_id),
          total_amount:
            Number(ele?.otherAmount) +
            Number(ele?.electricityAmount) +
            Number(ele?.rent) || 0,
          status: BILL_PAID_STATUS.PENDING,
          tenant_id: +ele?.tenant_id,
        })),
      };
    }

    try {
      let response = "";
      if (action === ACTIONS.CREATE) {
        response = await createElectricityBill(
          selectedPropertyId,
          Number(params?.month),
          Number(params?.year),
          payLoadData
        );
      }
      if (action === ACTIONS.EDIT) {
        response = await updateRentBill(rent_slips_id, payLoadData);
      }
      if (response?.status === 200) {
        toast.success(response.data.message);
        nav("/rent-slips");
        setIsLoading(false);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setConfirmation(false);
    }
  };

  const addDocs = async (e) => {
    e?.stopPropagation();
    let files = [];
    if (e?.target?.files?.length) {
      for (let i = 0; i < e?.target?.files?.length; i++) {
        files.push(e?.target?.files[i]);
      }
      files.map((file) =>
        Object.assign(file, {
          uniqueId: uuid.v4(),
          location: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      const size = [...files].reduce((p, c) => p + c.size, 0);
      if (Math.floor(size / 1028) > MAX_SIZE_IMAGE) {
        toast.error("File size exceeded allowed size");
        return;
      }
      setSelectedImage([...selectedFiles, ...files]);
      setselectedFiles((old) => {
        return [...old, ...files];
      });
    }
    if (action === ACTIONS.EDIT) {
      let documents = [];
      const filesUnchanged =
        [...selectedFiles, ...files]?.filter((file) => file?.id) ?? [];
      if (filesUnchanged.length > 0) {
        documents = [...filesUnchanged.map((sd) => sd.id)];
      }
      const filesToBeUploaded = [...selectedFiles, ...files].filter(
        (file) => !file?.id
      );

      const docArray = await fileUploadFunction(filesToBeUploaded);
      const payLoadData = {
        attachments: [...documents, ...docArray],
        rentSlip: userData?.map((ele) => ({
          id: ele?.id,
          tenant_id: +ele?.tenant_id,
        })),
      };
      try {
        const response = await updateRentBill(rent_slips_id, payLoadData);
        if (response?.status === 200) {
          const paramData = {
            limit: params?.limit,
            page: page,
          };
          const response_fetch = await fetchRentSlipData({ ...paramData }, paramId?.bill_id);
          setSelectedImage([
            ...(response_fetch?.data?.data?.data[0]?.attachments || []),
          ]);
          setselectedFiles([
            ...(response_fetch?.data?.data?.data[0]?.attachments || []),
          ]);
          toast.success("Documents updated successfully");
          setIsLoading(false);
        }
      } catch (e) {
        toast.error(e?.response?.data?.message || e.message);
        setIsLoading(false);
      }
    }
  };

  const popOver = (ele, format) => {
    const date = `${selectedYear}-${months.indexOf(selectedMonth) + 1}-0${1}`;
    const endOfMonth = moment(date).daysInMonth();
    const finalDate = `${endOfMonth}-${selectedMonth}-${selectedYear}`;

    if (ele?.bed_price == ele?.rent) {
      return {
        s: "Start",
        e: "end",
      };
    }
    if (ele?.vacant_request) {
      if (months[new Date(ele?.vacant_request).getMonth()] == selectedMonth) {
        const date = `${selectedYear}-${new Date(ele?.vacant_request).getMonth() + 1
          }-01`;
        return {
          s: moment(date).format(format),
          e: moment(ele?.vacant_request).format(format),
        };
      }
      return {
        s: dayjs(ele?.joining_date).format(format),
        e: moment(finalDate).format(format),
      };
    }
    return {
      s: dayjs(ele?.joining_date).format(format),
      e: moment(finalDate).format(format),
    };
  };
  const popOverView = (ele) => {
    return ` From ${dayjs(ele?.start_date?.split("T")[0]).format(
      "DD-MM-YYYY"
    )} to ${dayjs(ele?.end_date?.split("T")[0]).format("DD-MM-YYYY")}`;
  };
  const visibleIcon = () => (
    <div
      onClick={() => setToggleOn(true)}
      style={{
        marginTop: "0.5em",
        ...([ACTIONS.VIEW, ACTIONS.EDIT].includes(action)),
      }}
    >
      <Badge
        badgeContent={selectedImage?.length}
        color="primary"
        style={{
          marginRight: "14px",
          ...([ACTIONS.VIEW, ACTIONS.EDIT].includes(action) && {
            marginTop: "8px",
          }),
        }}
      >
        <Tooltip title="Click to see documents">
          <VisibilityIcon
            style={{
              cursor: "pointer",
              marginRight: "0.3em",
            }}
          />
        </Tooltip>
      </Badge>
    </div>
  );

  const handleSubmit = async (note) => {
    try {
      const response = await updateTenantRentSlipComment(tenantRentSlipId, { comment: note })
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

      setisOpenAddNoteModal(false)
    } catch (error) {
      toast.error(error.response.data.message);
      setisOpenAddNoteModal(false)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {/* <div
        className="page-content"
        style={{
          paddingTop: "inherit",
        }}
      > */}
      <div>
        <div className="flexbox_holder d-flex align-items-center justify-content-between mb-3">
          <div className="page-title-box pb-0 d-flex"></div>
          <div className="d-flex">
            {selectedImage?.length && action === ACTIONS.CREATE ? (
              <>{visibleIcon()}</>
            ) : (
              ""
            )}

            {!(
              userData?.length === 0 ||
              action === ACTIONS.VIEW ||
              statRef.current === BILL_PAID_STATUS.PUBLISHED
            ) && (
                <div
                  className="upload_picture"
                  style={{
                    marginTop: "5px",
                    visibility:
                      action === ACTIONS.CREATE || action === ACTIONS.EDIT
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <input
                    className="form-control pos-rel"
                    accept=".pdf,docx,image/*"
                    type="file"
                    id="profile_photo"
                    style={{ display: "none" }}
                    onChange={addDocs}
                    multiple
                  />
                  <label
                    style={{
                      alignSelf: "center",
                    }}
                    htmlFor="profile_photo"
                    className="custom-file-label mb-0 mx-2"
                  >
                    <Tooltip title="Upload File">
                      <UploadFileButton />
                    </Tooltip>
                  </label>
                </div>
              )}

            {/* photo */}
            {!(
              userData?.length === 0 ||
              action === ACTIONS.VIEW ||
              statRef.current === BILL_PAID_STATUS.PUBLISHED
            ) && (
                <Button
                  onClick={() => setConfirmation(true)}
                  disabled={
                    !selectedYear ||
                    !selectedMonth ||
                    !ObjName ||
                    !userData?.length ||
                    action === ACTIONS.VIEW
                  }
                  className="yellow_gradient_btn me-2 d-flex align-items-center"
                  style={{
                    marginBottom: "9px",
                  }}
                >
                  {action === ACTIONS.EDIT ? 'Save' : 'Submit'}
                </Button>
              )}
          </div>
        </div>
        {action === ACTIONS.CREATE ? (
          <div
            className="page_filters mb-3"
            style={{
              marginTop: "-12px",
            }}
          >
            <div className="row">
              <div className="col-sm-3" style={{ width: "270px" }}>
                <SelectDropDownListPaginationComponent
                  label="Select Property"
                  searchEnabled={true}
                  listCall={getAllProperties}
                  emitItem={(newValue) => {
                    setSelectedPropertyId(newValue?.id);
                    setObjName(newValue?.name);
                    pData.current.pId = newValue?.id;
                  }}
                />
              </div>
              <div
                className="col-sm-2 month-scroll"
                style={{ width: "200px" }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  sx={{
                    zIndex: 9999,
                  }}
                  size="small"
                  value={selectedMonth}
                  disabled={
                    action === ACTIONS.EDIT || action === ACTIONS.VIEW
                  }
                  options={selectMonth}
                  onChange={(e) => {
                    const indexValue =
                      +months.indexOf(e?.target?.textContent) + 1;
                    setParams((old) => ({ ...old, ["month"]: indexValue }));
                    pData.current.month = indexValue;
                    setSelectedMonth(months[indexValue - 1]);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Month" />
                  )}
                />
              </div>
              <div className="col-sm-2" style={{ width: "200px" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  disabled={
                    action === ACTIONS.EDIT || action === ACTIONS.VIEW
                  }
                  value={selectedYear}
                  options={selectYear}
                  onChange={(e) => {
                    pData.current.year = e?.target?.textContent;
                    setParams((old) => ({
                      ...old,
                      ["year"]: e?.target?.textContent,
                    }));
                    setSelectedYear(e?.target?.textContent);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Year" />
                  )}
                />
              </div>
              <div className="col-sm-2">
                <Button
                  className="yellow_gradient_btn"
                  onClick={fetchAllTenant}
                  disabled={
                    !params?.year ||
                    !params?.month ||
                    !selectedPropertyId ||
                    action === ACTIONS.EDIT ||
                    action === ACTIONS.VIEW
                  }
                >
                  Show Rent Slip
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              marginTop: `${-3}vh`,
              marginBottom: `${2}vh`,
            }}
          >
            <DetailsHeaderBlock
              fields={[
                { filedName: "Property Name", value: ObjName },
                { filedName: "Month", value: selectedMonth },
                { filedName: "Year", value: selectedYear },
                selectedImage?.length && {
                  filedName: "Files",
                  value: visibleIcon(),
                },
              ]}
            />
          </div>
        )}

        {userData?.length === 0 && (
          <div style={{ textAlign: "center", height: "100%" }}>
            <NodataFound text="No data to show" icon={NoDataIcon} />
          </div>
        )}
        {userData?.length > 0 && (
          <Card className="table_card_holder">
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-bordered rentSlipTable"
                >
                  <TableHeaders
                    headers={ColumnRentSlip(action !== ACTIONS.CREATE)}
                    sortBy={sort}
                    emitSortedColumn={handleSort}
                    module={ALL_MODULES.FINANCE}
                    shouldACtionDisplay={false}
                  />
                  <Tbody>
                    {userData?.map((ele, ind) => (
                      <Tr key={ele?.tenant_id}>
                        <Td>{ind + 1}</Td>
                        <Td>{ele?.floor_no || "N/A"}</Td>
                        <Td>{ele?.flat_no || "N/A"}</Td>
                        <Td>{ele?.room_no || "N/A"}</Td>
                        <Td>{ele?.name || "N/A"}</Td>
                        <Td>
                          <TextField
                            size="small"
                            className="form-control unit_top_margin tb_inpt_box"
                            type="text"
                            label="Enter Amount"
                            disabled={isLoading || action === ACTIONS.VIEW}
                            value={userData[ind]?.electricityAmount}
                            defaultValue={userData[ind]?.electricityAmount}
                            onChange={(e) => {
                              const arrayVal = userData?.slice();
                              arrayVal[ind].electricityAmount =
                                e?.target?.value;
                              setUserData(arrayVal);
                            }}
                            onKeyDown={(e) => {
                              handleKeyDown(e);
                            }}
                          />
                        </Td>
                        <Td>
                          <TextField
                            size="small"
                            className="form-control unit_top_margin tb_inpt_box"
                            type="text"
                            disabled={isLoading || action === ACTIONS.VIEW}
                            value={userData[ind]?.otherAmount}
                            defaultValue={userData[ind]?.otherAmount}
                            label="Enter Amount"
                            onChange={(e) => {
                              const arrayVal = userData?.slice();
                              arrayVal[ind].otherAmount = e?.target?.value;
                              setUserData(arrayVal);
                            }}
                            onKeyDown={(e) => {
                              handleKeyDown(e);
                            }}
                          />
                        </Td>

                        <Td>
                          <span>Rs.{ele?.rent || "N/A"}</span>
                          {
                            <Tooltip
                              title={
                                action === ACTIONS.CREATE
                                  ? `From ${popOver(ele, "DD-MM-YYYY").s
                                  } to ${popOver(ele, "DD-MM-YYYY").e}`
                                  : popOverView(ele)
                              }
                            >
                              <InfoIcon
                                fontSize="very-small"
                                style={{ cursor: "pointer" }}
                                className="ms-2"
                              />
                            </Tooltip>
                          }
                        </Td>
                        <Td>
                          Rs.
                          {Number(ele?.electricityAmount) +
                            Number(ele?.otherAmount) +
                            Number(ele?.rent) + Number(platformFee)}
                        </Td>
                        <Td className="d-flex text-center">
                          <span className={ele?.status === BILL_PAID_STATUS.PAID ? 'rentStatus paidStatus' : 'rentStatus unpaidStatus'}>
                            {ele?.status}
                          </span>
                          {ele?.status === BILL_PAID_STATUS.PAID &&
                            <><span className="ms-2">
                              <InfoIcon onClick={(e) => handleClick(e, ele)} />
                              <BlobProvider
                              document={<RentSlipPdf
                                item={ele} platformFee={platformFee} />}
                              >
                                {({ url }) => (
                                  <a
                                    href={url}
                                    target='_blank'
                                    rel='noreferrer'>
                                    <FileDownloadIcon
                                      titleAccess='Download Rent Slip'
                                      fontSize={'inherit'}
                                      color='error'
                                      style={{
                                        textAlign: 'center',
                                        fontSize: '28px',
                                        cursor: 'pointer',
                                    }} />
                                  </a>
                                )}
                              </BlobProvider>
                          </span><Popover
                            className="popup_block"
                            id={id}
                              open={open && ele?.id === tenantRentSlipId}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }} className="popContent">
                                <p>Payment Date: {moment(ele?.payment_history[0]?.payment_date).format(DEFAULT_DATE_FORMAT)}</p>
                                <p>Transaction ID: {ele?.payment_history[0]?.transaction_id}</p>
                              </Typography>
                              </Popover></>
                          }
                        </Td>
                        <Td>
                          {statRef.current === BILL_PAID_STATUS.PUBLISHED ? (
                            <div
                              onClick={() => {
                                setAction(ACTIONS.VIEW)
                                toggleAddNote(ele);
                              }}
                            >
                              <RemoveRedEyeIcon />
                            </div>
                          ) : (
                            <svg
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setAction(ele?.id ? ACTIONS.EDIT : ACTIONS.CREATE)
                                toggleAddNote(ele);
                              }}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V8.7C17.6833 8.55 17.3583 8.42083 17.025 8.3125C16.6917 8.20417 16.35 8.125 16 8.075V2H2V16H8.05C8.1 16.3667 8.17917 16.7167 8.2875 17.05C8.39583 17.3833 8.525 17.7 8.675 18H2ZM2 16V2V8.075V8V16ZM4 14H8.075C8.125 13.65 8.20417 13.3083 8.3125 12.975C8.42083 12.6417 8.54167 12.3167 8.675 12H4V14ZM4 10H10.1C10.6333 9.5 11.2292 9.08333 11.8875 8.75C12.5458 8.41667 13.25 8.19167 14 8.075V8H4V10ZM4 6H14V4H4V6ZM15 20C13.6167 20 12.4375 19.5125 11.4625 18.5375C10.4875 17.5625 10 16.3833 10 15C10 13.6167 10.4875 12.4375 11.4625 11.4625C12.4375 10.4875 13.6167 10 15 10C16.3833 10 17.5625 10.4875 18.5375 11.4625C19.5125 12.4375 20 13.6167 20 15C20 16.3833 19.5125 17.5625 18.5375 18.5375C17.5625 19.5125 16.3833 20 15 20ZM14.5 18H15.5V15.5H18V14.5H15.5V12H14.5V14.5H12V15.5H14.5V18Z"
                                fill="#565656"
                              />
                            </svg>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </div>

            {userData?.length > 0 && (
              <div
                className="numbers_pagination_holder mb-2 padding_setting_stick"
                style={{
                  paddingTop: "0.5em",
                }}
              >
                <PaginationComponent
                  paginationMeta={meta}
                  handleChangePagination={paginationModelMethod}
                  update={update}
                ></PaginationComponent>
              </div>
            )}
          </Card>
        )}
      </div>
      {/* </div> */}
      {isOpenAddNoteModal && (
        <AddNotes
          isAddNoteOpen={isOpenAddNoteModal}
          isAddNotetoggle={toggleAddNote}
          value={notes}
          isDisable={action === ACTIONS.VIEW}
          ids={ids}
          handleSubmit={handleSubmit}
          add_title={
            statRef.current === BILL_PAID_STATUS.PUBLISHED
              ? "Notes"
              : action === ACTIONS.EDIT
                ? "Edit Note"
                : action === ACTIONS.VIEW
                  ? "Notes"
                  : ""
          }
          setNotes={setNotes}
          addNote={submitValue}
          action={action}
        />
      )}
      {toggleOn && (
        <AlertDialog
          visible={toggleOn}
          visibleOff={() => setToggleOn(false)}
          selectedImage={selectedFiles}
          setSelectedImage={setSelectedImage}
          setSelectedFile={setselectedFiles}
        />
      )}
      {confirmation && (
        <ConfirmationModal
          action={action == ACTIONS.EDIT ? ACTIONS.EDIT : ACTIONS.CREATE}
          show={confirmation}
          onAcceptClick={() => {
            submitValue();
            return;
          }}
          onCloseClick={() => setConfirmation(false)}
          isDisabled={isLoading}
        />
      )}
    </>
  );
}

export default RentSlipIndex;
