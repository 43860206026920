import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
} from "@material-ui/core";
import * as Yup from 'yup';
import { Checkbox, FormControl, TextField } from "@mui/material";
import { useFormik } from "formik";
import { Button, Spinner } from "reactstrap";
import GlobalInnerHeader from "./globalInnerHeader";
import { editApi, viewByIdApi } from "../../api/GlobalSettings/api";

export default function PromoCode({ tabDetails }) {
  const { id } = tabDetails;
  const [isLoading, setIsLoading] = useState(false);
  const [savedValue, setSavedValue] = useState({
    referral: false,
    discount: false,
    discount_code: '',
    discount_value: 0,
  });
  const promoSchema = savedValue;
  const promoValidationSchema = Yup.object().shape({
    referral: Yup.boolean().required(),
    discount: Yup.boolean().required(),
    discount_code: Yup.string().when('discount', {
      is: true,
      then: (schema) => schema.required("Please Enter Unique Code").min(6, 'Discount code must be atleast 6 characters'),
      otherwise: (schema) => schema.optional(),
    }),
    discount_value: Yup.number().when('discount', {
      is: true,
      then: (schema) => schema.positive('Discount amount should be positive').required('Discount amount is required'),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: promoSchema,
    validationSchema: promoValidationSchema,
    validateOnMount: true,
    onSubmit: async (payload) => {
      console.log('submit handler', { payload });
      try {
        setIsLoading(true)
        const apiPayload = {
          id,
          payload: { payload }
        }
        await editApi(apiPayload);
        setSavedValue(payload);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false)
      }
      console.log('onSubmit', { payload });
    },
  });

  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, !formik.values[event.target.name]);
  };

  async function getPromoDetails() {
    try {
      const response = await viewByIdApi(id);
      const { payload } = response;
      setSavedValue(payload);

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPromoDetails();
  }, [])

  return Object.keys(savedValue).length && (
    <div className="tabInnerContent">
      <GlobalInnerHeader title="Promo Code" />
      <div className="settingBody settings_holder mt-4">
        <FormControl>
          <FormControlLabel control={<Checkbox name="referral"
            checked={formik.values.referral}
            onClick={handleChange} />} label="Referral Code" />
          <FormControlLabel control={<Checkbox name="discount"
            checked={formik.values.discount}
            onClick={handleChange} />} label="Discount Code" />
          {formik?.values?.discount && (<div className="input-group-append">
            <TextField
              className="mb-2"
              name="discount_code"
              label='Discount Code'
              value={formik?.values?.discount_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              inputProps={{ style: { textTransform: "uppercase" } }}
              error={!!(formik?.touched?.discount_code && formik?.errors?.discount_code)}
              helperText={
                formik?.touched?.discount_code && formik?.errors?.discount_code
                  ? formik?.errors?.discount_code
                  : ''
              }
            />

            <div className="d-flex align-items-center ">
              <TextField
                className="mb-2"
                type="number"
                name='discount_value'
                value={formik?.values?.discount_value}
                label='Amount'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size="small"
                error={!!(formik?.touched?.discount_value && formik?.errors?.discount_value)}
                helperText={
                  formik?.touched?.discount_value && formik?.errors?.discount_value
                    ? formik?.errors?.discount_value
                    : ''
                }
              />
              <span className="mx-2">INR. </span>
            </div>
          </div>)}
        </FormControl>
        <div className="text-end mt-4">
          {tabDetails.isEditable &&
            <Button
              disabled={!formik.isValid || isLoading || (formik.values === savedValue)}
              color="primary"
              onClick={formik.handleSubmit}>
              {isLoading && <Spinner size="sm" color="light" />}
              Save
            </Button>}
        </div>
      </div>
    </div>
  );
}

PromoCode.propTypes = {
  tabDetails: PropTypes.any,
};
