import React, { useState } from 'react';
import Select from 'react-select';
import { updateComplainStatusById } from '../../api/ComplainTicket/api'; // Import your API function here
import { COMPLAIN_TICKET_STATUS } from '../../common/constant';
import { createTheme } from '@material-ui/core';

function StatusSelectBox({
  id,
  currentStatus,
  refresh,
  disableUpdate = false,
}) {
  const colors = createTheme()?.palette;
  const options = [
    { value: COMPLAIN_TICKET_STATUS.PENDING, label: COMPLAIN_TICKET_STATUS.PENDING },
    { value: COMPLAIN_TICKET_STATUS.IN_PROGRESS, label: COMPLAIN_TICKET_STATUS.IN_PROGRESS },
    { value: COMPLAIN_TICKET_STATUS.RESOLVED, label: COMPLAIN_TICKET_STATUS.RESOLVED },
    { value: COMPLAIN_TICKET_STATUS.DECLINED, label: COMPLAIN_TICKET_STATUS.DECLINED },
  ];
  const customStyles = {
    control: (provided, state) => {
      let backgroundColor = 'transparent';

      if (state.selectProps.value) {
        const selectedOption = state.selectProps.options.find(
          (option) => option.value === state.selectProps.value.value,
        );

        backgroundColor = selectedOption.value === COMPLAIN_TICKET_STATUS.RESOLVED
          ? colors.success.main
          : selectedOption.value === COMPLAIN_TICKET_STATUS.PENDING
            ? colors.warning.main
            : selectedOption.value === COMPLAIN_TICKET_STATUS.IN_PROGRESS
              ? colors.primary.main
              : selectedOption.value === COMPLAIN_TICKET_STATUS.DECLINED
                ? colors.error.main : 'transparent';
      }

      return {
        ...provided,
        backgroundColor,
      };
    },
    menu: (provided) => ({
      ...provided,
      borderRadius: '20px',
      overflow: 'hidden',
      backgroundColor: '#fff',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.value === COMPLAIN_TICKET_STATUS.PENDING ? colors.warning.contrastText : // Yellow for Pending
        state.data.value === COMPLAIN_TICKET_STATUS.IN_PROGRESS ? colors.primary.contrastText : // Blue for In-Progress
          state.data.value === COMPLAIN_TICKET_STATUS.RESOLVED ? colors.success.contrastText : // Green for Resolved
            state.data.value === COMPLAIN_TICKET_STATUS.DECLINED ? colors.error.contrastText : // Red for Declined
              '#000000', // Default color
    }),
  };


  const [status, setStatus] = useState(currentStatus);

  const handleChange = async (val) => {
    setStatus(val.value);
    await updateComplainStatusById(id, val.value); // Pass the selected value to your API function
    refresh();
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      defaultValue={options.find((option) => option.value === currentStatus)}
      value={options.find((option) => option.value === status)}
      onChange={(val) => handleChange(val)}
      className='customSelectfilter'
      menuPortalTarget={document.body}
      isDisabled={disableUpdate}
    />
  );
}

export default StatusSelectBox;
