import React, { useState, useEffect } from "react";
import {
    Button,
} from "reactstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ActivePropertiesBasic from "../../components/ActiveProperties/activePropertyBasic";
import ActivePropertiesBedMetrix from "../../components/ActiveProperties/activePropertyBedmetrix";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS, PROPERTY_TYPES } from "../../common/constant";
import { toast } from 'react-toastify';
import { createProperty, getPropertyDetails, updateProperty } from "../../api/property";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { uploadDocuments, uploadDocumentsForModuleWise } from "../../api/CompanyAssociates/api";
import { useParams } from 'react-router-dom';
import { getS3BaseUrl } from "../../helpers/string_helper";
import { selectAmenitiesIcon } from "../../common/amenities_icon";

export default function AddActiveProperty(props) {
    const [activeStep, setActiveStep] = useState(0); // State to track active step
    const [basicPropertyData, setBasicPropertyData] = useState();
    const [isDisabled, setIsDisbaled] = useState(true);
    const [update, setUpdate] = useState(0);
    const [isBack, setIsBack] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [pictureUploadIds, setPictureUploadIds] = useState([]);
    const [documentUploadIds, setDocumentUploadIds] = useState([]);
    const [defaultImageId, setDefaultImageId] = useState()
    const { propertyId } = useParams();
    const [propertyData, setPropertyData] = useState();
    const [isSingleOwner, setIsSingleOwner] = useState();
    const [prepareData, setPrepareData] = useState([]);

    useEffect(() => {
        if (props.action === ACTIONS.EDIT) {
            fetchPropertyDetails()
        }
    }, [props?.action])

    const fetchPropertyDetails = async () => {
        try {
            const res = (await getPropertyDetails(parseInt(propertyId))).data.data
            const s3BaseUrl = getS3BaseUrl()
            const result = res?.floors?.map((item) => {
                let flatFoRoom = []
                let flats = []
                let rooms = []
                if (item?.flats?.length > 0) {
                    const flatMap = item?.flats.map((fl) => {
                        return {
                            id: fl?.id ?? null, name: fl.name, ...(fl?.user && {
                                user_id: { name: fl.user?.name, id: fl.user?.id, code: fl.user?.code },
                            })
                        }
                    })
                    flats.push({
                        floorName: item.name,
                        flats: flatMap
                    })
                    item?.flats?.forEach((fl, index) => {
                        flatFoRoom.push({
                            ...flatMap[index],
                            rooms: fl.rooms.map((rm) => {
                                return {
                                    id: rm?.id ?? null,
                                    name: rm?.name,
                                    room_type_id: rm.room_type?.id ? { label: rm?.room_type.name, id: rm?.room_type.id, key: `${rm?.room_type.id}|${rm?.room_type.name}` } : '',
                                    documents: rm.documents?.length > 0 ? rm.documents?.map((item) => {
                                        return { ...item, location: `${s3BaseUrl}${item?.location}` }
                                    }) : [],
                                    amenities: rm?.amenities?.length > 0 ? rm?.amenities?.map((am) => {
                                        return { name: am, icon: selectAmenitiesIcon(am?.toLowerCase()) }
                                    }) : [],
                                    beds: rm.beds.map((bd) => {
                                        return { id: bd?.id ?? null, name: bd?.name, price: bd?.price, user_id: bd?.user?.id }
                                    })
                                }
                            })
                        })
                    })

                    rooms.push({
                        floorData: {
                            name: item.name,
                            flats: flatFoRoom
                        }
                    })
                } else if (item?.rooms?.length > 0) {
                    item.rooms.forEach((rm) => {
                        rooms.push({
                            id: rm?.id ?? null,
                            name: rm?.name,
                            room_type_id: rm.room_type?.id ? { label: rm?.room_type.name, id: rm?.room_type.id, key: `${rm?.room_type.id}|${rm?.room_type.name}` } : '',
                            documents: rm.documents?.length > 0 ? rm.documents?.map((item) => {
                                return { ...item, location: `${s3BaseUrl}${item?.location}` }
                            }) : [],
                            amenities: rm?.amenities?.length > 0 ? rm?.amenities?.map((am) => {
                                return { name: am, icon: selectAmenitiesIcon(am?.toLowerCase()) }
                            }) : [],
                            beds: rm.beds.map((bd) => {
                                return { id: bd?.id ?? null, name: bd?.name, price: bd?.price, user_id: bd?.user?.id }
                            })
                        })
                    })
                }

                return {
                    name: item.name, id: item.id, ...(item?.user && {
                        user_id: { name: item.user?.name, id: item.user?.id, code: item.user?.code },
                    }),
                    ...(res.type === PROPERTY_TYPES.APARTMENT ? {
                        flats: flatFoRoom,
                    } : {
                        rooms: rooms
                    })
                }

            })
            const pictures = res.pictures?.map((item) => {
                return { ...item, location: `${s3BaseUrl}${item?.location}` }
            })
            const documents = res.documents?.map((item) => {
                return { ...item, location: `${s3BaseUrl}${item?.location}` }
            })
            setPropertyData({ ...res, floors: result, pictures: pictures, documents: documents })
        } catch (err) {
            console.log(err?.response?.data?.message)
            toast.error(err?.response?.data?.message)
        }
    }

    const handleSelectedOption = (data) => {
        setBasicPropertyData({
            ...basicPropertyData,
            type: data === 'bhk' ? PROPERTY_TYPES.APARTMENT : PROPERTY_TYPES.PERSONAL_HOME
        })
        setPrepareData()
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setIsBack(true)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setPrepareData()
    };

    const navigate = useNavigate();

    const handleBackPage = () => {
        navigate("/active-properties/active-properties-live")
    };

    const steps = ["Basic Details", "Bed Matrix"];

    useEffect(() => {
        if (basicPropertyData && basicPropertyData.name && basicPropertyData.addressLine1 && basicPropertyData.city && basicPropertyData.state && basicPropertyData.country && basicPropertyData.type && basicPropertyData.pinCode && basicPropertyData?.lat && basicPropertyData?.long && basicPropertyData.defaultImage?.name && (isSingleOwner ? basicPropertyData?.ownerId : true) && basicPropertyData?.gender_preference) {
            setIsDisbaled(false)
        }
    }, [update])

    const handleCreate = async () => {
        try {
            setIsDisbaled(true)
            setIsLoading(true);
            let isAttchament = true;
            let payload = {
                name: basicPropertyData?.name,
                type: basicPropertyData?.type,
                ...(basicPropertyData.amenities?.length > 0 && {
                    amenities: basicPropertyData.amenities.map((am) => am.name)
                }),
                ...(basicPropertyData.rules?.length > 0 && {
                    rules: basicPropertyData.rules
                }),
                property_manager_id: basicPropertyData?.careTaker?.assignee?.id,
                ...(basicPropertyData.ownerId && {
                    user_id: basicPropertyData.ownerId?.id
                }),
                ...(basicPropertyData?.careTaker?.name && {
                    care_taker_name: basicPropertyData?.careTaker?.name
                }),
                ...(basicPropertyData?.careTaker?.contactNo && {
                    care_taker_contact_no: basicPropertyData?.careTaker?.contactNo
                }),
                ...(basicPropertyData?.nearTo?.length > 0 && {
                    near_to: basicPropertyData?.nearTo.toString()
                }),
                latitude: basicPropertyData?.lat?.toString(),
                longitude: basicPropertyData?.long?.toString(),
                gender_preference: basicPropertyData?.gender_preference,
                address: {
                    address_line_1: basicPropertyData.addressLine1,
                    city: basicPropertyData.city,
                    state: basicPropertyData.state,
                    ...(basicPropertyData?.district && {
                        district: basicPropertyData?.district,
                    }),
                    country: basicPropertyData.country,
                    zip_code: basicPropertyData.pinCode,
                    ...(basicPropertyData.addressLine2 && {
                        address_line_2: basicPropertyData.addressLine2,
                    })
                }
            }

            if (prepareData?.length > 0) {
                let documentUpload
                let pictureUpload
                let default_image_id
                if (basicPropertyData.pictures?.length > 0 && pictureUploadIds.length !== basicPropertyData.pictures?.length) {
                    const formData = new FormData();
                    basicPropertyData.pictures.forEach((f) => {
                        formData.append('files', f)
                    })
                    formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY)
                    formData.append('max_items', 10)
                    const result = await uploadDocuments(formData)
                    if (result.success) {
                        const { response } = result
                        pictureUpload = [...response.data.data.documents.map((doc) => {
                            if (doc.name === basicPropertyData.defaultImage?.name) {
                                default_image_id = doc.id
                                setDefaultImageId(doc.id)
                            }

                            return doc.id
                        })]
                        setPictureUploadIds(pictureUpload);
                    } else {
                        setIsLoading(false);
                        return false;
                    }
                }
                if (basicPropertyData.documents?.length > 0 && documentUploadIds.length !== basicPropertyData.documents?.length) {
                    const formData = new FormData();
                    basicPropertyData.documents.forEach((f) => {
                        formData.append('files', f)
                    })
                    formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY)
                    formData.append('max_items', 10)
                    const result = await uploadDocuments(formData)
                    if (result.success) {
                        const { response } = result
                        documentUpload = [...response.data.data.documents.map((doc) => doc.id)]
                        setDocumentUploadIds(documentUpload)
                    } else {
                        setIsLoading(false);
                        return false;
                    }
                }

                payload.floors = prepareData
                const response = await createProperty({
                    ...payload,
                    default_image_id: default_image_id ? default_image_id : defaultImageId,
                    ...((documentUploadIds.length > 0 || documentUpload) && {
                        documents: documentUploadIds.length > 0 ? documentUploadIds : documentUpload
                    }),
                    ...((pictureUploadIds.length > 0 || pictureUpload) && {
                        pictures: pictureUploadIds.length > 0 ? pictureUploadIds : pictureUpload
                    })
                });

                if (response?.data?.data) {
                    const res = (await getPropertyDetails(parseInt(response?.data?.data?.id))).data.data
                    let isChecked = false
                    await res?.floors?.forEach((floor, index) => {
                        if (floor.name === prepareData[index].name) {
                            if (floor?.flats?.length > 0) {
                                floor?.flats.forEach((flat, flatIndex) => {
                                    if (flat?.name === prepareData[index]?.flats[flatIndex]?.name) {
                                        flat.rooms?.forEach(async (room, roomIndex) => {
                                            if (room.name === prepareData[index]?.flats[flatIndex]?.rooms[roomIndex]?.name) {
                                                try {
                                                    isChecked = true
                                                    const formData = new FormData();
                                                    let isFile = false
                                                    prepareData[index]?.flats[flatIndex]?.rooms[roomIndex]?.documents?.forEach((f) => {
                                                        formData.append('files', f)
                                                        isFile = true
                                                    })
                                                    if (isFile) {
                                                        formData.append('max_items', 10)
                                                        formData.append('moduleId', room.id)
                                                        formData.append('module', DOCUMENT_HASH_BASE_KEYS.ROOM)
                                                        await uploadDocumentsForModuleWise(formData)
                                                        isAttchament = false
                                                    }
                                                } catch (error) {
                                                    console.error(error)
                                                }
                                            }
                                        })
                                    }
                                })
                            } else if (floor?.rooms?.length > 0) {
                                floor.rooms?.forEach(async (room, roomIndex) => {
                                    isChecked = true
                                    if (room.name === prepareData[index]?.rooms[roomIndex].name && prepareData[index]?.rooms[roomIndex].documents?.length > 0) {
                                        try {
                                            const formData = new FormData();
                                            let isFile = false;
                                            prepareData[index]?.rooms[roomIndex].documents?.forEach((f) => {
                                                formData.append('files', f)
                                                isFile = true
                                            })

                                            if (isFile) {
                                                formData.append('max_items', 10)
                                                formData.append('moduleId', room.id)
                                                formData.append('module', DOCUMENT_HASH_BASE_KEYS.ROOM)
                                                await uploadDocumentsForModuleWise(formData)
                                                isAttchament = false
                                            }
                                        } catch (error) {
                                            console.error(error)
                                        }
                                    }
                                })
                            }
                        }

                        if (index === (prepareData.length - 1) && !isAttchament && isChecked) {
                            toast.success(response.data.message);
                            navigate("/active-properties/active-properties-live")
                            setIsLoading(false)
                            setIsConfirm(false)
                            setPrepareData()
                        }
                    })

                    if (isAttchament && isChecked) {
                        toast.success(response.data.message);
                        navigate("/active-properties/active-properties-live")
                        setIsLoading(false)
                        setIsConfirm(false)
                        setPrepareData()
                    }
                }
            } else {
                toast.warning('Please enter all required field')
                setIsConfirm(false)
            }

        } catch (error) {
            toast.error(error.response.data.message);
            setIsLoading(false);
            setIsConfirm(false);
            setIsDisbaled(false);
        }
    };

    const handleUpdate = async () => {
        try {
            setIsDisbaled(true)
            setIsLoading(true);
            let isAttchament = true;
            let payload = {
                name: basicPropertyData?.name,
                type: basicPropertyData?.type,
                status: propertyData?.status,
                ...(basicPropertyData.amenities?.length >= 0 && {
                    amenities: basicPropertyData.amenities.map((am) => am.name)
                }),
                ...(basicPropertyData.rules?.length >= 0 && {
                    rules: basicPropertyData.rules
                }),
                property_manager_id: basicPropertyData?.careTaker?.assignee?.id ?? null,
                ...((propertyData?.user?.id || basicPropertyData.ownerId) && {
                    user_id: isSingleOwner ? basicPropertyData.ownerId?.id : null
                }),
                ...(basicPropertyData?.careTaker?.name && {
                    care_taker_name: basicPropertyData?.careTaker?.name
                }),
                ...(basicPropertyData?.careTaker?.contactNo && {
                    care_taker_contact_no: basicPropertyData?.careTaker?.contactNo
                }),
                ...((basicPropertyData?.nearTo?.length > 0 || propertyData?.near_to?.length > 0) && {
                    near_to: basicPropertyData?.nearTo?.length > 0 ? basicPropertyData?.nearTo.toString() : null
                }),
                latitude: basicPropertyData?.lat?.toString(),
                longitude: basicPropertyData?.long?.toString(),
                gender_preference: basicPropertyData?.gender_preference,
                address: {
                    address_line_1: basicPropertyData.addressLine1,
                    city: basicPropertyData.city,
                    state: basicPropertyData.state,
                    ...(basicPropertyData?.district && {
                        district: basicPropertyData.district,
                    }),
                    country: basicPropertyData.country,
                    zip_code: basicPropertyData.pinCode,
                    ...(basicPropertyData.addressLine2 && {
                        address_line_2: basicPropertyData.addressLine2,
                    })
                }
            }

            if (prepareData?.length > 0) {
                let documentUpload
                let pictureUpload
                let documentExistIds = []
                let pictureExistIds = []
                let default_image_id
                if (basicPropertyData.pictures?.length > 0 && pictureUploadIds?.length !== basicPropertyData.pictures?.length) {
                    const formData = new FormData();
                    let isFile = false
                    await basicPropertyData.pictures.forEach((f) => {
                        if (f?.id) {
                            pictureExistIds.push(f.id)
                            if (f.name === basicPropertyData.defaultImage?.name) {
                                default_image_id = f.id
                                setDefaultImageId(f.id)
                            }
                        } else {
                            isFile = true
                            formData.append('files', f)
                        }
                    })
                    if (isFile) {
                        formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY)
                        formData.append('max_items', 10)
                        const result = await uploadDocuments(formData)
                        if (result.success) {
                            const { response } = result
                            const results = [...response.data.data.documents.map((doc) => {
                                if (doc.name === basicPropertyData.defaultImage?.name) {
                                    default_image_id = doc.id
                                    setDefaultImageId(doc.id)
                                }

                                return doc.id
                            })]
                            setPictureUploadIds(pictureExistIds?.length > 0 ? [...pictureExistIds, ...results] : results);
                            pictureUpload = pictureExistIds?.length > 0 ? [...pictureExistIds, ...results] : results
                        } else {
                            setIsLoading(false);
                            return false;
                        }
                    } else if (pictureExistIds?.length > 0) {
                        pictureUpload = pictureExistIds
                    }
                }
                if (basicPropertyData.documents?.length > 0 && documentUploadIds.length !== basicPropertyData.documents?.length) {
                    const formData = new FormData();
                    let isFile = false
                    await basicPropertyData.documents.forEach((f) => {
                        if (!f?.id) {
                            formData.append('files', f)
                            isFile = true
                        } else {
                            documentExistIds.push(f.id)
                        }
                    })

                    if (isFile) {
                        formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY)
                        formData.append('max_items', 10)
                        const result = await uploadDocuments(formData)
                        if (result.success) {
                            const { response } = result
                            const results = [...response.data.data.documents.map((doc) => doc.id)]
                            setDocumentUploadIds(documentExistIds?.length > 0 ? [...documentExistIds, ...results] : results)
                            documentUpload = documentExistIds?.length > 0 ? [...documentExistIds, ...results] : results
                        } else {
                            setIsLoading(false);
                            return false;
                        }
                    } else if (documentExistIds?.length > 0) {
                        documentUpload = documentExistIds
                    }
                }

                payload.floors = prepareData
                const response = await updateProperty(propertyId, {
                    ...payload,
                    default_image_id: default_image_id ? default_image_id : defaultImageId,
                    ...((documentUploadIds.length > 0 || documentUpload) && {
                        documents: documentUploadIds.length > 0 ? documentUploadIds : documentUpload
                    }),
                    ...((pictureUploadIds.length > 0 || pictureUpload) && {
                        pictures: pictureUploadIds.length > 0 ? pictureUploadIds : pictureUpload
                    })
                });

                if (response?.data?.data) {
                    const res = (await getPropertyDetails(parseInt(response?.data?.data?.id))).data.data
                    let isChecked = false
                    await res?.floors?.forEach((floor, index) => {
                        if (floor.name === prepareData[index].name) {
                            if (floor?.flats?.length > 0) {
                                floor?.flats.forEach((flat, flatIndex) => {
                                    if (flat?.name === prepareData[index]?.flats[flatIndex]?.name) {
                                        flat.rooms?.forEach(async (room, roomIndex) => {
                                            if (room.name === prepareData[index]?.flats[flatIndex]?.rooms[roomIndex]?.name) {
                                                try {
                                                    isChecked = true
                                                    const formData = new FormData();
                                                    let isFile = false
                                                    prepareData[index]?.flats[flatIndex]?.rooms[roomIndex]?.documents?.forEach((f, ind) => {
                                                        if (room.documents[ind]?.name !== f?.name) {
                                                            formData.append('files', f)
                                                            isFile = true
                                                        }
                                                    })
                                                    if (isFile) {
                                                        formData.append('max_items', 10)
                                                        formData.append('moduleId', room.id)
                                                        formData.append('module', DOCUMENT_HASH_BASE_KEYS.ROOM)
                                                        await uploadDocumentsForModuleWise(formData)
                                                        isAttchament = false
                                                    }
                                                } catch (error) {
                                                    console.error(error)
                                                }
                                            }
                                        })
                                    }
                                })
                            } else if (floor?.rooms?.length > 0) {
                                floor.rooms?.forEach(async (room, roomIndex) => {
                                    isChecked = true
                                    if (room.name === prepareData[index]?.rooms[roomIndex].name && prepareData[index]?.rooms[roomIndex].documents?.length > 0) {
                                        try {
                                            const formData = new FormData();
                                            let isFile = false;
                                            prepareData[index]?.rooms[roomIndex].documents?.forEach((f, ind) => {
                                                if (room.documents[ind]?.name !== f?.name) {
                                                    formData.append('files', f)
                                                    isFile = true
                                                }
                                            })

                                            if (isFile) {
                                                formData.append('max_items', 10)
                                                formData.append('moduleId', room.id)
                                                formData.append('module', DOCUMENT_HASH_BASE_KEYS.ROOM)
                                                await uploadDocumentsForModuleWise(formData)
                                                isAttchament = false
                                            }
                                        } catch (error) {
                                            console.error(error)
                                        }
                                    }
                                })
                            }
                        }

                        if (index === (prepareData.length - 1) && !isAttchament && isChecked) {
                            toast.success(response.data.message);
                            setIsLoading(false)
                            setIsConfirm(false)
                            setPrepareData()
                            navigate("/active-properties/active-properties-live")
                        }
                    })

                    if (isAttchament && isChecked) {
                        toast.success(response.data.message);
                        setIsLoading(false)
                        setIsConfirm(false)
                        setPrepareData()
                        navigate("/active-properties/active-properties-live")
                    }
                }
            } else {
                toast.warning('Please enter all required field')
                setIsConfirm(false)
            }

        } catch (error) {
            if (error.response?.data?.message) toast.error(error.response?.data?.message);
            else toast.error(error.message);
        } finally {
            setIsLoading(false);
            setIsConfirm(false);
            setIsDisbaled(false);
        }
    };

    const refactorData = (data) => {
        const floors = []
        data?.floors?.forEach((floor) => {
            if (basicPropertyData?.type === PROPERTY_TYPES.PERSONAL_HOME) {
                if (floor?.name && (basicPropertyData?.ownerId || floor?.user_id)) {
                    const rooms = []
                    floor?.rooms?.forEach((room) => {
                        if (room?.name && room?.room_type_id) {
                            const beds = []
                            room?.beds?.forEach((bed) => {
                                if (bed?.name && bed?.price) {
                                    beds.push({
                                        ...(propertyData?.id ? {
                                            id: bed.id ?? null
                                        } : bed.id && {
                                            id: bed.id
                                        }),
                                        ...(bed?.user_id && {
                                            user_id: bed.user_id
                                        }),
                                        name: bed?.name,
                                        price: bed?.price
                                    })
                                }
                            })
                            if (beds?.length > 0) {
                                rooms.push({
                                    ...(propertyData?.id ? {
                                        id: room.id ?? null
                                    } : room.id && {
                                        id: room.id
                                    }),
                                    ...(room.amenities?.length >= 0 && {
                                        amenities: room.amenities?.length === 0 ? [] : room.amenities.map((am) => am.name)
                                    }),
                                    ...(room.documents && {
                                        documents: room.documents
                                    }),
                                    name: room?.name,
                                    room_type_id: room?.room_type_id?.id,
                                    beds: beds
                                })
                            }
                        }
                    })
                    if (rooms?.length > 0) {
                        floors.push({
                            ...(propertyData?.id ? {
                                id: floor.id ?? null
                            } : floor.id && {
                                id: floor.id
                            }),
                            name: floor?.name,
                            ...(propertyData?.id ? {
                                user_id: basicPropertyData?.ownerId ? null : floor?.user_id?.id
                            } : !basicPropertyData?.ownerId && {
                                user_id: floor?.user_id?.id,
                            }),
                            rooms: rooms
                        })
                        setIsDisbaled(true)
                    }
                }
            } else if (floor?.name && floor?.flats?.length > 0) {
                const flats = []
                floor?.flats?.forEach((flat) => {
                    if (flat?.name && ((basicPropertyData?.ownerId || flat?.user_id))) {
                        const rooms = []
                        flat?.rooms?.forEach((room) => {
                            if (room?.name && room?.room_type_id) {
                                const beds = []
                                room?.beds?.forEach((bed) => {
                                    if (bed?.name && bed?.price) {
                                        beds.push({
                                            ...(propertyData?.id ? {
                                                id: bed.id ?? null
                                            } : bed.id && {
                                                id: bed.id
                                            }),
                                            name: bed?.name,
                                            price: bed?.price,
                                            ...(bed?.user_id && {
                                                user_id: bed.user_id
                                            })
                                        })
                                    }
                                })
                                if (beds?.length > 0) {
                                    rooms.push({
                                        ...(propertyData?.id ? {
                                            id: room.id ?? null
                                        } : room.id && {
                                            id: room.id
                                        }),
                                        ...(room.amenities?.length >= 0 && {
                                            amenities: room.amenities?.length === 0 ? [] : room.amenities.map((am) => am.name)
                                        }),
                                        name: room?.name,
                                        room_type_id: room?.room_type_id?.id,
                                        ...(room.documents && {
                                            documents: room.documents
                                        }),
                                        beds: beds
                                    })
                                }
                            }
                        })
                        if (rooms?.length > 0) {
                            flats.push({
                                ...(propertyData?.id ? {
                                    id: flat.id ?? null
                                } : flat.id && {
                                    id: flat.id
                                }),
                                name: flat?.name,
                                ...(propertyData?.id ? {
                                    user_id: basicPropertyData?.ownerId ? null : flat?.user_id?.id
                                } : !basicPropertyData?.ownerId && {
                                    user_id: flat?.user_id?.id,
                                }),
                                rooms: rooms
                            })
                        }
                    }
                })
                if (flats?.length > 0) {
                    floors.push({
                        ...(propertyData?.id ? {
                            id: floor.id ?? null
                        } : floor.id && {
                            id: floor.id
                        }),
                        name: floor?.name,
                        flats: flats
                    })
                    setIsDisbaled(true)
                }

            }
        })

        if (floors?.length > 0) {
            setPrepareData(floors)
        } else {
            setPrepareData([])
        }
    }

    return (
        <div className="page-content addActiveProperty">
            <div className="container-fluid">
                <div className="HrAfterRow d-flex align-items-center">
                    <div className="w-50">
                        <div className="page-title-box d-flex align-items-center pb-0">
                            <ArrowBackOutlinedIcon style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handleBackPage} />
                            <h4 className="mb-0"> {props.action === ACTIONS.EDIT ? 'Edit Active Property' : 'Add Active Property'} </h4>
                        </div>
                    </div>
                    <div className="w-50" style={{ textAlign: "right" }}>
                        {activeStep === 1 ? (
                            <div>
                                <Button className="me-2 addPropertyNextBtn" onClick={handleBack} >
                                    Back
                                </Button>
                                <Button className="yellow_gradient_btn addPropertyNextBtn" onClick={() => setIsConfirm(true)} disabled={isLoading || !isDisabled || prepareData?.length === 0}>
                                    Save
                                </Button>
                            </div>
                        ) : (
                                <Button className="yellow_gradient_btn me-2 addPropertyNextBtn" disabled={isDisabled} key={update} onClick={handleNext}>
                                Next
                            </Button>
                        )}
                    </div>
                </div>
                <Box className="ActivePropertyStepper" sx={{ width: "500px", margin: '20px auto 0px' }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel >
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                {/* <StepContent> */}
                <div className="step-content">
                    {activeStep === 0 && (
                        <ActivePropertiesBasic emitBasicActiveData={(data, valid, selectedOption) => {
                            setIsSingleOwner(selectedOption === 'single')
                            setBasicPropertyData(data)
                            setIsDisbaled(!valid)
                            setIsBack(false)
                            setUpdate(update + 1)
                        }} backData={isBack ? basicPropertyData : null} propertyData={propertyData} />
                    )}
                    {activeStep === 1 && (
                        <ActivePropertiesBedMetrix
                            key={update}
                            basicPropertyData={basicPropertyData}
                            emitPropertyData={(data) => refactorData(data)}
                            changeBasicData={handleSelectedOption}
                            propertyData={propertyData} />
                    )}
                </div>
                {/* </StepContent> */}
            </div>

            {isConfirm && (
                <ConfirmationModal
                    show={isConfirm}
                    action={props.action === ACTIONS.EDIT ? props.action : ACTIONS.CREATE}
                    onAcceptClick={() => {
                        props.action === ACTIONS.EDIT ? handleUpdate() : handleCreate()
                        return false;
                    }}
                    onCloseClick={() => setIsConfirm(false)}
                    disabled={isLoading}
                />
            )}
        </div>
    );
}
