import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Common/Loader";
import NodataFound from '../Common/NoDataFound';
import { ACTIONS, ALL_MODULES, DEFAULT_DATETIME_FORMAT, PER_PAGE, TIME_INTERVAL } from '../../common/constant';
import NoDAtaIcon from '../../assets/images/no-data.png';
import { fetchAllActivityForDashboard } from '../../api/activity';
import { getInitials } from '../../constants/kanban_helper';
import { removeSpecial } from '../../helpers/string_helper';
import dayjs from 'dayjs';

function TopUser() {
  const [personName, setPersonName] = useState(["This Month"]);
  const [filterDate, setFilterDate] = useState(TIME_INTERVAL.THIS_MONTH)
  const [isLoading, setIsLoading] = useState(false);
  const [allActivities, setAllActivities] = useState([])
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
    setFilterDate(value)
    setPage(1);
    setHasMore(true)
    setAllActivities([])
  };

  useEffect(() => {
    fetchAllActivity();
  }, [filterDate]);

  const fetchAllActivity = async () => {
    try {
      setIsLoading(true);
      let params = {
        page: page,
        limit: PER_PAGE[0],
        date_range: filterDate,
      };

      const res = (await fetchAllActivityForDashboard(params)).data.data;
      setHasMore(res.meta.current_page !== res.meta.last_page)
      setAllActivities((prevState) => {
        return [...prevState, ...res.data];
      });
      setPage((prevState) => prevState + 1);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getDate = (date) => {
    const dateArray = dayjs(date).format(DEFAULT_DATETIME_FORMAT).split(' ')
    return `${dateArray[0]} at ${dateArray[1]} ${dateArray[2]}`
  }

  function getList(item) {
    switch (item.event) {
      case ACTIONS.CREATE:
        return (
          <>
            <div className="named_avatar rounded-circle d-inline-block text-center">
              <span className="text-uppercase"> {getInitials(item?.user?.name)}</span>
            </div>

            <div className="activity_status">
              <h6 className="font-size-14 mb-1 fw-semibold fullname_color">{item.user?.name} ({item?.user?.code})</h6>
              <p className='update font-size-12 mb-0'>
                has created a{" "}
                <span className='highlighted_text'>
                  {removeSpecial(item?.payload?.actual_updated_property)}
                </span>
              </p>
              {item?.payload?.name ? (
                <>
                  <span className='font-size-12'>&nbsp;for</span>
                  <span style={{ fontWeight: "bolder", }} className='font-size-12'>
                    &nbsp;{item?.payload?.name}
                  </span>
                </>
              ) : item?.payload?.created_property_name ? (
                <>
                  <span className='font-size-12'>&nbsp;with name</span>
                  <span style={{ fontWeight: "bolder", }} className='font-size-12'>
                    &nbsp;{item?.payload?.created_property_name}
                  </span>
                </>
              ) : (
                " "
              )}
              <span className="font-size-10 text-muted fw-semibold text-end">
                &nbsp;on&nbsp;<span>{getDate(item?.created_at)}</span>
              </span>
            </div>
          </>
        );
      case ACTIONS.EDIT:
        if (item.module === ALL_MODULES.PG_VACANT_REQUESTS) {
          return (
            <><div className="named_avatar rounded-circle d-inline-block text-center">
              <span className="text-uppercase"> {getInitials(item?.user?.name)}</span>
            </div><div style={{ width: "90%" }} className="activity_status">
                <h6 className="font-size-14 mb-1 fw-semibold fullname_color">{item.user?.name} ({item?.user?.code})</h6>
                <span className='update font-size-12 mb-0'>
                  has updated the <b>{item.payload?.field_name ? removeSpecial(item.payload?.field_name) : ''}</b>
                  <span>
                    {" "}
                    {item?.payload?.old_value?.length > 0 && (
                      <span> from &nbsp;
                    <span className='highlighted_text font-size-12'>
                      <b>{item?.payload?.old_value}</b>
                        </span>{" "}
                      </span>
                    )}
                    <>
                      {" "}to{" "}
                      <span className='highlighted_text font-size-12'>
                        <b>{item?.payload?.new_value}</b>{" "}
                      </span>
                    </>
                  </span>
                  of PG Leaving Request of &nbsp;
                  <b>
                    {item.payload?.actual_updated_property} &nbsp;
                  </b>
                  stays in <b>{item.payload.name} </b>
                </span>
                <span className="font-size-10 text-muted fw-semibold text-end">
                  &nbsp;on&nbsp;<span>{getDate(item?.created_at)}</span>
                </span>
              </div>
            </>
          )
        } else {
          return (
            <>
              {item?.payload?.actual_updated_property ? (
                <>
                  <div className="named_avatar rounded-circle d-inline-block text-center">
                    <span className="text-uppercase"> {getInitials(item?.user?.name)}</span>
                  </div>

                  <div style={{ width: "90%" }} className="activity_status">
                    <h6 className="font-size-14 mb-1 fw-semibold fullname_color">{item.user?.name} ({item?.user?.code})</h6>
                    {item.payload.module === 'Bed' ? (
                      <span className='update font-size-12 mb-0'>
                        <span dangerouslySetInnerHTML={{ __html: item?.payload?.actual_updated_property }}>
                        </span>
                      </span>
                    ) : (((item?.payload?.old_value && !!item?.payload?.new_value) || (!!item?.payload?.old_value && !item?.payload?.new_value) || (!item?.payload?.old_value && !!item?.payload?.new_value)) ? (
                      <span>
                        {!!item?.payload?.old_value && !!item?.payload?.new_value && (
                          <span className='update font-size-12 mb-0'>
                            has updated &nbsp;
                            <span className='highlighted_text'>
                              {item?.payload?.actual_updated_property
                                ? removeSpecial(item?.payload?.actual_updated_property)
                                : ""}{" "}
                            </span>

                            <span>
                              {" "}
                              from{" "}
                              <span className='font-size-12'>
                                <b>{item?.payload?.old_value}</b>
                              </span>{" "}
                              <>
                                to{" "}
                                <span className='font-size-12'>
                                  <b>{item?.payload?.new_value}</b>{" "}
                                </span>
                              </>
                            </span>
                          </span>
                        )}
                        {!!item?.payload?.new_value && !item?.payload?.old_value && (
                          <span className='update font-size-12 mb-0'>
                            has added &nbsp;
                            <span className='highlighted_text'>
                              {item?.payload?.actual_updated_property
                                ? removeSpecial(item?.payload?.actual_updated_property)
                                : ""}{" "}
                            </span>
                            <span className='font-size-12'>
                              as <b>{item?.payload?.new_value} </b>
                            </span>
                          </span>
                        )}
                        {!item?.payload?.new_value && !!item?.payload?.old_value && (
                          <p className='update font-size-12 mb-0'>
                            has reset &nbsp;
                            <span className='highlighted_text'>
                              {item?.payload?.actual_updated_property
                                ? removeSpecial(item?.payload?.actual_updated_property)
                                : ""}{" "}
                            </span>
                            <span className='font-size-12'>
                                as <b>{item.payload.actual_updated_property === 'custom_payout_percentage' ? 'default' : item?.payload?.new_value} </b>
                            </span>
                          </p>
                        )}
                      </span>
                    ) : (
                      <span className='update font-size-12 mb-0'>
                        has updated &nbsp;
                        <span className='highlighted_text'>
                          {item?.payload?.actual_updated_property
                            ? removeSpecial(item?.payload?.actual_updated_property)
                            : ""}{" "}
                        </span>
                      </span>
                    )
                    )}

                    {item?.payload?.name && (
                      <>
                        <span>for </span>
                        <span
                          style={{
                            fontWeight: "bolder",
                          }}
                          className='font-size-12'
                        >
                          {item?.payload?.name}{" "}
                        </span>
                      </>
                    )}
                    {item?.payload?.created_property_name && (
                      <>
                        <span className='font-size-12'>with name </span>
                        <span
                          style={{
                            fontWeight: "bolder",
                          }}
                          className='font-size-12'
                        >
                          {item?.payload?.created_property_name}{" "}
                        </span>
                      </>
                    )}
                    <span className="font-size-10 text-muted fw-semibold text-end">
                      &nbsp;on&nbsp;<span>{getDate(item.created_at)}</span>
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          );
        }

      default:
    }
  }

  return (
    <div className='shadow_card'>
      <Card className='latest_activities'>
        <CardBody>
          <div className="float-end">
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={personName}
              onChange={handleChange}
              MenuProps={MenuProps}
              style={{ width: 111, height: 31, fontSize: '12px', backgroundColor: '#fff' }}
            >
              {Object.values(TIME_INTERVAL).map((name) => (
                name !== TIME_INTERVAL.CUSTOM &&
              (<MenuItem key={name} value={name}>
                {name}
              </MenuItem>)
              ))}
            </Select>
          </div>
          <h4 className="card-title mb-4" style={{ color: '#565656' }}>Latest Activities</h4>
          <div
            style={{
              height: "240px",
              overflow: allActivities.length ? "auto" : "hidden",
            }}
          >
            <InfiniteScroll
              dataLength={allActivities.length}
              next={fetchAllActivity}
              hasMore={hasMore}
              height={allActivities?.length ? "240px" : "auto"}
              loader={isLoading && <Loader />}
            >
              <div className="all_activities">
                {allActivities.map((item) => (
                  <div className='single_activity d-flex' key={item.id}>
                    {getList(item)}
                  </div>
                ))}
              </div>
            </InfiniteScroll>

            {!allActivities.length && !isLoading && (
              <NodataFound text="No activities to show" style={{ width: '150px', height: '150px' }} icon={NoDAtaIcon} />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default TopUser;
