import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import Dropzone from 'react-dropzone';
import { TextField, Autocomplete, Box } from '@mui/material';
import * as Yup from 'yup';
import {
  ACTIONS,
  DOCUMENT_HASH_BASE_KEYS,
  KNOWN_ROLES,
  PROPERTY_TYPES,
  USER_STATUS,
} from '../../common/constant';
import { useFormik } from 'formik';
import ConfirmationModal from '../Common/ConfirmationModal';
import {
  createPropertyRequestHandler,
  fetchAllNearTo,
  updatePropertyRequestHandler,
  uploadDocuments,
} from '../../api/NewPropertyRequest/api';
import FileList from '../Common/FileList';
import { formatBytes } from '../../helpers/string_helper';
import uuid from 'react-native-uuid';
import AddAmenitiesModal from '../ActiveProperties/addAmenitiesModal';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { SearchDropDownListPaginationComponent } from '../Common/SearchDropDownPaginationList';
import { getAllUser } from '../../api/user';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { selectAmenitiesIcon } from '../../common/amenities_icon';


function AddNewPropertyRequestDrawer({ isOpen, toggleDrawer, refresh, selectedPropertyRequestData, action }) {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateConfirm, setCreateConfirm] = useState({
    status: false,
    data: null,
  });
  const [isEditConfirm, setEditConfirm] = useState({
    status: false,
    id: null,
    data: null,
  });

  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });
  const [isAmenitiesModalOpen, setIsAmenitiesModalOpen] = useState(false);
  const [nearToOptions, setNearToOptions] = useState([]);
  let count = 0

  const propertyRequestSchema = {
    name: selectedPropertyRequestData?.name ?? '',
    owner_user_id: selectedPropertyRequestData?.owner_user_id ? { name: selectedPropertyRequestData.owner_name, id: selectedPropertyRequestData.owner_user_id, code: selectedPropertyRequestData?.code } : '',
    type: selectedPropertyRequestData?.type ?? '',
    amenities: selectedPropertyRequestData ? selectedPropertyRequestData.amenities?.map((am) => {
      return { name: am, icon: selectAmenitiesIcon(am.toLowerCase()) }
    }) : '',
    near_to: selectedPropertyRequestData?.near_to ?? '',
    room_count: selectedPropertyRequestData?.room_count ?? '',
    address: {
      address_line_1: selectedPropertyRequestData?.address_line_1 ?? '',
      address_line_2: selectedPropertyRequestData?.address_line_2 ?? '',
      city: selectedPropertyRequestData?.city ?? '',
      district: selectedPropertyRequestData?.district ?? '',
      state: selectedPropertyRequestData?.state ?? '',
      country: selectedPropertyRequestData?.country ?? '',
      zip_code: selectedPropertyRequestData?.zip_code ?? '',
    },
  };

  useEffect(() => {
    // fetching all near to location
    fetchAllNearTo(setNearToOptions)

    if (selectedPropertyRequestData?.documents?.length > 0) {
      const newDocuments = selectedPropertyRequestData?.documents?.map((doc) => ({...doc, uniqueId: uuid.v4(), formattedSize: formatBytes(doc.size)}))
      formik.setValues({
        ...formik.values,
        ...(
          newDocuments.length > 0 && 
            {
              documents: [...newDocuments]
            }
        ),
        ...(
          newDocuments.length > 0 && 
            {
              total_documents_size: [...newDocuments].reduce((p, c) => p + c.size, 0)
            }
        )
      }, true).then(() => {
        setselectedFiles([...newDocuments])
      })
    }
  }, [])

  const propertyRequestValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Property Name"),
    owner_user_id: Yup.object().required("Please Selcet a Property Owner"),
    type: Yup.string().required('Please select a Property type'),
    near_to: Yup.string().optional('Please Enter Near To Location'),
    room_count: Yup.number().required('Please Enter Room Count'),
    address: Yup.object().shape({
      address_line_1: Yup.string().required('Please Enter Address Line 1'),
      address_line_2: Yup.string().optional('Please Enter Address Line 2'),
      city: Yup.string().required('Please Enter City'),
      district: Yup.string().required('Please Enter District'),
      state: Yup.string().required('Please Enter State'),
      country: Yup.string().required('Please Enter Country'),
      zip_code: Yup.number().required('Please Enter Zip Code'),
    })
  });

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: propertyRequestSchema,
    validationSchema: propertyRequestValidationSchema,
    validateOnMount: true,
    onSubmit: async (propertyRequestData) => {
      action === ACTIONS.EDIT
        ? setEditConfirm({
          status: true,
          id: selectedPropertyRequestData?.id,
          data: propertyRequestData,
        })
        : setCreateConfirm({ status: true, data: propertyRequestData });
    },
  });

  let isSuccess = null;
  const submitHandler = async (propertyRequestData, id) => {
    setIsLoading(true);
    let documents
    const filesUnchanged = formik.values?.documents?.filter((file) => file?.id) ?? []
    if (filesUnchanged?.length > 0) {
      documents = [...filesUnchanged.map(sd => sd.id)]
    }

    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id)
    if (filesToBeUploaded?.length > 0) {
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append('files', f)
      })
      formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY_REQUEST)
      if (action === ACTIONS.CREATE) {
        formData.append('max_items', 10)
      }

      const result = await uploadDocuments(formData)
      if (result.success) {
        const { response } = result

        if (documents !== undefined) {
          documents = [...documents, ...response.data.data.documents.map((doc) => doc.id)]
        } else {
          documents = [...response.data.data.documents.map((doc) => doc.id)]
        }
        
      } else {
        setIsLoading(false);
        return false;        
      }
    }
    // removing empty fields
    propertyRequestData = {
      ...(propertyRequestData.name && {
        name: propertyRequestData.name,
      }),
      ...(propertyRequestData.owner_user_id?.id && {
        owner_user_id: propertyRequestData.owner_user_id?.id,
      }),
      ...(propertyRequestData.amenities && {
        amenities: propertyRequestData.amenities?.map((item) => item.name),
      }),
      ...(propertyRequestData.type && {
        type: propertyRequestData.type,
      }),
      ...(propertyRequestData.near_to && {
        near_to: propertyRequestData.near_to,
      }),
      ...(propertyRequestData.landmark && {
        landmark: propertyRequestData.landmark,
      }),
      ...(propertyRequestData.room_count && {
        room_count: propertyRequestData.room_count,
      }),
      ...(propertyRequestData.address && {
        address: {
          ...(propertyRequestData.address.address_line_1 && {
            address_line_1: propertyRequestData.address.address_line_1,
          }),
          ...(propertyRequestData.address.address_line_2 && {
            address_line_2: propertyRequestData.address.address_line_2,
          }),
          ...(propertyRequestData.address.city && {
            city: propertyRequestData.address.city,
          }),
          ...(propertyRequestData.address.district && {
            district: propertyRequestData.address.district,
          }),
          ...(propertyRequestData.address.state && {
            state: propertyRequestData.address.state,
          }),
          ...(propertyRequestData.address.country && {
            country: propertyRequestData.address.country,
          }),
          ...(propertyRequestData.address.zip_code && {
            zip_code: propertyRequestData.address.zip_code,
          }),
        },
      }),
      ...(action === ACTIONS.EDIT ? documents === undefined ? { documents: [] }: { documents } : documents === undefined ? { }: { documents })
    };
    isSuccess =
      action === ACTIONS.EDIT
        ? await updatePropertyRequestHandler(id, propertyRequestData)
        : await createPropertyRequestHandler(propertyRequestData);
    setIsLoading(false);
    return isSuccess;
  };
  const handleClose = () => {
    toggleDrawer(!isOpen);
    formik.resetForm();
  };

  const handleCloseModal = () => {
    setIsAmenitiesModalOpen(false);
};

const handleAddAmenities = (data) => {
    formik.setValues({
        ...formik.values,
        amenities: data
    })
}

const handleRemoveAmenities = (itemName) => {
    const updatedAmenities = formik.values.amenities?.filter((it) => it.name !== itemName.name)
    formik.setValues({
        ...formik.values,
        amenities: updatedAmenities
    })
}

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    formik.setValues({
      ...formik.values,
      documents: [...selectedFiles, ...files],
      total_documents_size: [...selectedFiles, ...files].reduce((p, c) => p + c.size, 0)
    }, true).then(() => {
        setselectedFiles((prev) => {
          return [...prev, ...files]
        })
    });
  }

  function handleFileRemove (e, file, fileIndex) {
    const newDocuments = formik.values.documents.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId))
    const tempValues = {
      ...formik.values
    }

    if (newDocuments.length === 0) {
      delete tempValues.documents
      delete tempValues.total_documents_size
    }
    formik.setValues({
      ...tempValues,
      ...(
        newDocuments.length > 0 && {
          documents: newDocuments,
        }
      ),
      ...(
        newDocuments.length > 0 && {
          total_documents_size: selectedFiles.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)).reduce((p, c) => p + c.size, 0)
        }
      ),
    }, true).then(() => {
      setselectedFiles(prev => prev.filter((_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId) ))
    });

    return true
  }

  function confirmFileRemoval (e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: {e, file, fileIndex}
    })
  }

  useEffect(() => {
    document.body.className = 'add-tenant-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });
  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => {
        formik.resetForm()
        toggleDrawer(!isOpen)
      }}>
      <OffcanvasHeader toggle={toggleDrawer}>
        {action === ACTIONS.EDIT
          ? 'Edit Property Request'
          : 'Add Property Request'}
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <Row>
            {/* Name */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Name'
                  size='small'
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.name || ''}
                  error={!!(formik?.touched?.name && formik?.errors?.name)}
                  helperText={formik?.touched?.name && formik?.errors?.name ? formik?.errors?.name : ''}
                  required
                />
              </ThemeProvider>
            </div>

            {/* Property owner */}
            <div className="col-md-6 mb-3">
              <ThemeProvider theme={theme}>
                <SearchDropDownListPaginationComponent
                  label="Select Owner"
                  // required
                  name='owner_user_id'
                  searchEnabled={true}
                  listCall={getAllUser}
                  defaultValue={formik.values?.owner_user_id ? formik.values?.owner_user_id : null}
                  emitItem={(newValue) => {
                    if (newValue === undefined) {
                      formik.setFieldValue("owner_user_id", '')
                    }
                    else {
                      formik.setFieldTouched('owner_user_id', true)
                      formik.setFieldValue('owner_user_id', {
                        id: newValue?.id,
                        name: newValue?.key?.split('|')[1],
                        code: newValue?.code
                      }, true);
                    }
                  }}
                  // onChange={formik.handleChange}
                  onBlur={() => { formik.setFieldTouched('owner_user_id', true) }}
                  // emitItem={(newValue) => {
                  //   console.log('newValue', newValue)
                  //   let id
                  //   if (newValue) {
                  //     id = parseInt(newValue?.id);
                  //   }
                  //   formik.setFieldTouched('owner_user_id', true)
                  //   formik.setFieldValue('owner_user_id', id, true); // Set the value in Formik
                  // }}
                  // error={formik.touched.owner_user_id && formik.errors.owner_user_id} // Pass the error state to the component
                  // helperText={formik.touched.owner_user_id && formik.errors.owner_user_id ? formik.errors.owner_user_id : ''}
                  apiParams={{
                    filter_fields: ['role','status'],
                    filter_inputs: [`${KNOWN_ROLES.PROSPECTING_OWNER},${KNOWN_ROLES.OWNER}`, USER_STATUS.ACTIVE],
                  }}
                  required={true}

                />
              </ThemeProvider>
              {
                formik.touched.owner_user_id && formik.errors.owner_user_id && <span className='text-danger'>{formik.errors.owner_user_id}</span>
              }
            </div>

            {/* Property Type */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='type'
                size='small'
                value={formik.values?.type}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'type',
                    newValue ? newValue : ''
                  );
                }}
                onBlur={() => {
                  if (!formik.touched?.type) {
                    formik.setFieldTouched('type', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                isOptionEqualToValue={(option, value) => option === value}
                options={Object.values(PROPERTY_TYPES)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        error={!!(formik?.touched?.type && formik?.errors?.type)}
                        helperText={(formik?.touched?.type && formik?.errors?.type) ? formik?.errors?.type : ''}
                        label='Select Property Request Type'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
              />
            </div>

            {/* Near To */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='near_to'
                size='small'
                value={formik.values?.near_to}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'near_to',
                    newValue ? newValue : ''
                  );
                }}
                onBlur={() => {
                  if (!formik.touched?.near_to) {
                    formik.setFieldTouched('near_to', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                isOptionEqualToValue={(option, value) => option === value}
                options={nearToOptions}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onBlur={formik.handleBlur}
                      error={!!(formik?.touched?.near_to && formik?.errors?.near_to)}
                      helperText={(formik?.touched?.near_to && formik?.errors?.near_to) ? formik?.errors?.near_to : ''}
                      label='Near To'
                      size='small'
                    />
                  );
                }}
              />
            </div>

            {/* Room Count */}
            <div className='col-sm-6 mb-3'>
            <ThemeProvider theme={theme}>
              <TextField
                size='small'
                className='form-control'
                type='text'
                label='Room Count'
                name='room_count'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.room_count}
                error={!!(formik?.touched?.room_count && formik?.errors?.room_count)}
                helperText={(formik?.touched?.room_count && formik?.errors?.room_count) ? formik?.errors?.room_count : ''}
                required
              />
              </ThemeProvider>
            </div>

            {/* Address Line 1 */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Address Line 1'
                  name='address.address_line_1'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.address_line_1 || ''}
                  error={
                    !!(formik?.touched?.address?.address_line_1 && formik?.errors?.address?.address_line_1)
                  }
                  helperText={
                    formik?.touched?.address?.address_line_1 && formik?.errors?.address?.address_line_1
                      ? formik?.errors?.address?.address_line_1
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Address Line 2 */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Address Line 2'
                name='address.address_line_2'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.address?.address_line_2 || ''}
                error={
                  !!(formik?.touched?.address?.address_line_2 && formik?.errors?.address?.address_line_2)
                }
                helperText={
                  formik?.touched?.address?.address_line_2 && formik?.errors?.address?.address_line_2
                    ? formik?.errors?.address?.address_line_2
                    : ''
                }
                size='small'
              />
            </div>

            {/* City */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='City'
                  name='address.city'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.city || ''}
                  error={
                    !!(formik?.touched?.address?.city && formik?.errors?.address?.city)
                  }
                  helperText={
                    formik?.touched?.address?.city && formik?.errors?.address?.city
                      ? formik?.errors?.address?.city
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* District */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='District'
                  name='address.district'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.district || ''}
                  error={
                    !!(formik?.touched?.address?.district && formik?.errors?.address?.district)
                  }
                  helperText={
                    formik?.touched?.address?.district && formik?.errors?.address?.district
                      ? formik?.errors?.address?.district
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* State */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='State'
                  name='address.state'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.state || ''}
                  error={
                    !!(formik?.touched?.address?.state && formik?.errors?.address?.state)
                  }
                  helperText={
                    formik?.touched?.address?.state && formik?.errors?.address?.state
                      ? formik?.errors?.address?.state
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Country */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Country'
                  name='address.country'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.country || ''}
                  error={
                    !!(formik?.touched?.address?.country && formik?.errors?.address?.country)
                  }
                  helperText={
                    formik?.touched?.address?.country && formik?.errors?.address?.country
                      ? formik?.errors?.address?.country
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Zip Code */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Zip Code'
                  name='address.zip_code'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.zip_code || ''}
                  error={
                    !!(formik?.touched?.address?.zip_code && formik?.errors?.address?.zip_code)
                  }
                  helperText={
                    formik?.touched?.address?.zip_code && formik?.errors?.address?.zip_code
                      ? formik?.errors?.address?.zip_code
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>
            
            {/* Landmark */}
            <div className='col-sm-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Landmark'
                  name='landmark'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.landmark || ''}
                  error={
                    !!(formik?.touched?.landmark && formik?.errors?.landmark)
                  }
                  helperText={
                    formik?.touched?.landmark && formik?.errors?.landmark
                      ? formik?.errors?.landmark
                      : ''
                  }
                  size='small'
                />
            </div>

            {/* Amenities */}
            <div className="single_input_block">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Link href="#" underline="none" onClick={() => {
                  setIsAmenitiesModalOpen(true)
                }
                } style={{ color: 'blue' }}>
                  <OtherHousesOutlinedIcon /> &nbsp; Add Amenities
                </Link>
              </div>
              <ul className="custom_amenties_options d-flex flex-wrap ps-0" style={{ listStyle: 'none' }}>
                {formik.values?.amenities?.length > 0 && formik.values?.amenities?.map((item, index) => (
                  <li className="position-relative d-flex mx-1" key={index + 1}>
                    {/* {item.icon}  */}
                    {item.icon}  <div className="amenity_item">{item.name}</div>
                    <CancelOutlinedIcon color="error" className="cancelIcon" onClick={() => handleRemoveAmenities(item)} />
                  </li>
                ))}
              </ul>
            </div>
            {isAmenitiesModalOpen && <AddAmenitiesModal open={isAmenitiesModalOpen} handleClose={handleCloseModal} handleAddAmenities={handleAddAmenities} selectedAmenities={formik.values?.amenities} />}

            {/* Documents */}
            <div className='col-12 mb-3'>
                {
                  (formik.errors.documents || formik.errors.total_documents_size) &&
                  <ul>
                    {
                      formik.errors.documents && <li className='text-danger'>{formik.errors.documents}</li>
                    }
                    {
                      formik.errors.total_documents_size && <li className='text-danger'>{formik.errors.total_documents_size}</li>
                    }
                  </ul>

                }
            </div>
            <div className='col-12 mb-3'>
              <label>Upload Documents</label>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone'>
                    <div className='dz-message needsclick' {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 text-muted uil uil-cloud-upload' />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              {
                selectedFiles.length > 0 && (
                  <FileList files={selectedFiles} confirmFileRemoval={confirmFileRemoval} />
                )
              }

            </div>

          </Row>
          <div className='formActions btn_right'>
            <Button className='yellow_gradient_btn' type='submit' disabled={!formik.isValid} onClick={formik.handleSubmit} >
              {action === ACTIONS.EDIT ? 'Save' : 'Create'}
            </Button>
          </div>
        </Form>
        {/* Create confirmation modal */}
        <ConfirmationModal
          action={ACTIONS.CREATE}
          show={isCreateConfirm?.status}
          onCloseClick={() => setCreateConfirm({ status: false, data: null })}
          onAcceptClick={async () => {
            const isCreated = await submitHandler(isCreateConfirm?.data);
            if (isCreated && isSuccess) {
                await refresh();
                handleClose();
            }
            setCreateConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />
        
        {/* Edit confirmation modal */}
        <ConfirmationModal
          action={ACTIONS.EDIT}
          show={isEditConfirm?.status}
          onCloseClick={() =>
            setEditConfirm({ status: false, id: null, data: null })
          }
          onAcceptClick={async () => {
            const isUpdated = await submitHandler(
              isEditConfirm?.data,
              isEditConfirm?.id,
            );
            if (isUpdated && isSuccess) {
                await refresh();
                handleClose();
            }
            setEditConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />

        {/* Document Delete confirmation modal */}
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDocumentDeleteConfirm?.status}
          onCloseClick={() =>
            setIsDocumentDeleteConfirm({ status: false, data: null })
          }
          onAcceptClick={async () => {
            const {e, file, fileIndex} = isDocumentDeleteConfirm.data
            const isDeleted = handleFileRemove(
              e, file, fileIndex
            );
            if (isDeleted) {
              setIsDocumentDeleteConfirm({ status: false, data: null });
            }
          }}
          isDisabled={isLoading}
        />
      </OffcanvasBody>
    </Offcanvas>      
  );
}

AddNewPropertyRequestDrawer.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  refresh: PropTypes.func,
  selectedPropertyRequestData: PropTypes.object,
  action: PropTypes.string,
}
export default AddNewPropertyRequestDrawer;
