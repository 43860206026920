import { documentUpload } from '../documents';
import {
  createTenant,
  updateTenant,
  deleteTenant,
  getDashboardData,
} from '../tenant';
import { toast } from 'react-toastify';

export const createTenantHandler = async (payload) => {
  try {
    const response = await createTenant(payload);
    if (response?.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};

export const uploadDocuments = async (formData) => {
  try {
    const response = await documentUpload(formData);
    if (response.status === 200) {
      return {response, success: true};
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
    return {error, success: false};
  }
};

export const updateTenantHandler = async (id, tenantData) => {
  try {
    const response = await updateTenant(id, tenantData);
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};

export const updateStatus = async (id, payload) => {
  try {
    const response = await updateTenant(id, payload);
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};

export const deleteTenantHandler = async (id) => {
  try {
    const response = await deleteTenant(id);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};

export const updateStats = async (setStats) => {
  try {
    const res = await getDashboardData();
    if (res.status === 200) {
    const {
      total_incomplete_agreement_tenants,
      total_incomplete_police_tenants,
      total_tenants,
      currently_living_tenant,
    } = res.data.data;
    setStats({
      totalTenants: total_tenants,
      totalIncompletePoliceTenants: total_incomplete_police_tenants,
      totalIncompleteAgreementTenants: total_incomplete_agreement_tenants,
      currentlyLivingTenant: currently_living_tenant,
    });
    } else {
      toast.error(res?.data?.message);
    }
  }
  catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
};
