import React, { useState, useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Card } from "reactstrap";
import { PROPERTY_TYPES } from "../../common/constant";
import UpdateActiveBhkRoom from "./updateActiveBhkRoom";

export default function ActivePropertiesBedMetrix({ basicPropertyData, emitPropertyData, changeBasicData, propertyData }) {
    const [selectedOption, setSelectedOption] = useState('bhk');

    useEffect(() => {
        if (basicPropertyData.type) {
            setSelectedOption(basicPropertyData.type === PROPERTY_TYPES.APARTMENT ? 'bhk' : 'room')
        }
    }, [basicPropertyData])

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        changeBasicData(event.target.value)
    };

    return (
        <div className="activePropertyBedMatrix">
            <Card className="bedMatrixOptions" style={{ width: '200px', margin: '15px auto 0', boxShadow: 'unset' }}>
                <FormControl>
                    <RadioGroup
                        row
                        // aria-labelledby="demo-row-radio-buttons-group-label"
                        // name="row-radio-buttons-group"
                        value={selectedOption}
                        onClick={handleChange}
                    // onChange={(e, v) => { console.log(e,v,'handle change') }}
                    >
                        <FormControlLabel value="bhk" disabled={!!propertyData} control={<Radio color="default" />} label="BHK" style={{ color: 'black' }} />
                        <FormControlLabel value="room" disabled={!!propertyData} control={<Radio color="default" />} label="Room" style={{ color: 'black' }} />
                    </RadioGroup>
                </FormControl>
            </Card>
            <div>
                {selectedOption === 'bhk' && (
                    <UpdateActiveBhkRoom heading="Bed Matrix" disableFlat={true} basicPropertyData={basicPropertyData} emitPropertyData={emitPropertyData} propertyData={propertyData} />
                )}
                {selectedOption === 'room' && (
                    <UpdateActiveBhkRoom heading="Room" disableFlat={false} basicPropertyData={basicPropertyData} emitPropertyData={emitPropertyData} propertyData={propertyData} />
                )}
            </div>


        </div>
    )
}
