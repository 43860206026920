import React, { useEffect, useState } from 'react';
import { Row, Form, Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { Autocomplete, TextField } from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { getAllProperties } from '../../api/property';
import { toast } from 'react-toastify';
import { createSchedulePropertyVisitRequestByAdmin, updateSchedulePropertyVisitRequestByAdmin } from '../../api/schedule-property-visit';
import { ACTIONS, DEFAULT_DATETIME_FORMAT } from '../../common/constant';

// import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { contactNoPattern, handleKeyDown } from '../../helpers/string_helper';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function AddOrEditVisitRequestsDrawer({ isOpen, toggleDrawer, action, scheduleDetails, refreshPropertyScheduleVisit }) {
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [allProperties, setAllProperties] = useState({
    items: [],
    loading: false
  });
  const [allPropertiesOptions, setAllPropertiesOptions] = useState([]);
  const [formRefresh, setFormRefresh] = useState(+new Date());
  const fetchAllProperties = async () => {
    try {
      setAllProperties({
        items: [],
        loading: true
      })
      const response = await getAllProperties(
        {
          filter_fields: ['status'],
          filter_inputs: ['Live'],

        }
      );
      setAllProperties({
        items: response.data.data.data,
        loading: false
      })
      setAllPropertiesOptions(response.data.data.data.map((_d) => ({ label: _d.name, value: _d.id })))
    } catch (e) {
      toast.error('Encountered problem with fetching property records.')
    }
  }

  const createPropertyScheduleRequest = async (payload) => {
    try {
      setScheduleLoading(true)
      payload = Object.fromEntries(Object.entries(payload).filter(([_k, v]) => {
        return !!v
      }))
      const response = await createSchedulePropertyVisitRequestByAdmin(payload)
      toast.success(response.data.message)
      await refreshPropertyScheduleVisit()
      toggleDrawer()
    } catch (e) {
      toast.error(e.response?.data?.message)
    } finally {
      setScheduleLoading(false)
    }
  }

  const updatePropertyScheduleRequest = async (payload) => {
    try {
      setScheduleLoading(true)
      payload = Object.fromEntries(Object.entries(payload).filter(([_k, v]) => {
        return !!v
      }))
      const response = await updateSchedulePropertyVisitRequestByAdmin(scheduleDetails?.id, payload)
      toast.success(response.data.message)
      await refreshPropertyScheduleVisit()
      toggleDrawer()
    } catch (e) {
      toast.error(e.response?.data?.message)
    } finally {
      setScheduleLoading(false)
    }
  }

  useEffect(() => {
    fetchAllProperties()
  }, [])
  useEffect(() => {
    if (action === ACTIONS.EDIT) {
      scheduleVisitFormik.setValues({
        fullname: scheduleDetails?.fullname,
        email: scheduleDetails?.email ?? '',
        phone_no: scheduleDetails?.phone_no,
        visit_datetime: scheduleDetails?.visit_datetime,
        property_id: scheduleDetails?.property?.id
      })
      setFormRefresh(+new Date())
    }
  }, [action, scheduleDetails])
  const initialScheduleDetails = {
    fullname: '',
    phone_no: '',
    email: '',
    property_id: '',
    visit_datetime: ''
  }
  const scheduleVisitFormik = useFormik({
    initialValues: { ...initialScheduleDetails },
    validationSchema: Yup.object({
      fullname: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').required('Please enter visitor full name.'),
      phone_no: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').matches(contactNoPattern, 'Invalid contact information.').required('Please enter visitor phone number.'),
      email: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').email('Invalid email format').optional(),
      property_id: Yup.number().typeError('Please select a property for visitation.').positive('Invalid property selected').required('Please select a property for visitation.'),
      visit_datetime: Yup.date().required('Please select a visitation date.'),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      if (action === ACTIONS.CREATE) {
        createPropertyScheduleRequest(values)
      } else {
        updatePropertyScheduleRequest(values)
      }
    }
  })
  // const [selectedFiles, setselectedFiles] = useState([]);


  // function handleAcceptedFiles(files) {
  //   files.map((file) => Object.assign(file, {
  //     preview: URL.createObjectURL(file),
  //     // formattedSize: formatBytes(file.size),
  //   }));
  //   setselectedFiles(files);
  // }

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });
  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer()}
    >
      <OffcanvasHeader toggle={() => { toggleDrawer() }}>
        {action === ACTIONS.CREATE ? 'Add New ' : 'Edit Existing '} Request
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form
          key={formRefresh}
          onSubmit={(e) => {
            e.preventDefault();
            scheduleVisitFormik.handleSubmit();
            return false;
          }}
        >
          <Row>
            <div className="col-sm-6 mb-3">
              {/* <Label className="form-label">Visitor's Name*</Label> */}
              <ThemeProvider theme={theme}>
                <TextField
                  size="small"
                  name="fullname"
                  className="form-control"
                  required
                  label="Visitor's Name"
                  disabled={scheduleLoading}
                  type="text"
                  onChange={scheduleVisitFormik.handleChange}
                  onBlur={scheduleVisitFormik.handleBlur}
                  value={scheduleVisitFormik.values.fullname}
                //   invalid={
                //   !!(validation.touched.email && validation.errors.email)
                // }
                />
              </ThemeProvider>
              {scheduleVisitFormik.touched.fullname && scheduleVisitFormik.errors.fullname ? (
                <span className='text-danger'>{scheduleVisitFormik.errors.fullname}</span>
              ) : null}
            </div>
            <div className="col-sm-6 mb-3">
              {/* <Label className="form-label">Visitor's Contact No*</Label> */}
              <ThemeProvider theme={theme}>
                <TextField
                  size="small"
                  name="phone_no"
                  className="form-control"
                  required
                  label="Visitor's Contact No"
                  type="text"
                  disabled={scheduleLoading}
                  onKeyDown={(e) => { handleKeyDown(e) }}
                  onChange={scheduleVisitFormik.handleChange}
                  onBlur={scheduleVisitFormik.handleBlur}
                  value={scheduleVisitFormik.values.phone_no}
                //   invalid={
                //   !!(validation.touched.email && validation.errors.email)
                // }
                />
              </ThemeProvider>
              {scheduleVisitFormik.touched.phone_no && scheduleVisitFormik.errors.phone_no ? (
                <span className='text-danger'>{scheduleVisitFormik.errors.phone_no}</span>
              ) : null}
            </div>
            <div className="col-sm-6 mb-2">
              {/* <Label className="form-label">Visitor's Email*</Label> */}
              <TextField
                size="small"
                name="email"
                className="form-control"
                label="Visitor's Email"
                type="email"
                disabled={scheduleLoading}
                onChange={scheduleVisitFormik.handleChange}
                onBlur={scheduleVisitFormik.handleBlur}
                value={scheduleVisitFormik.values.email}
              //   invalid={
              //   !!(validation.touched.email && validation.errors.email)
              // }
              />
              {scheduleVisitFormik.touched.email && scheduleVisitFormik.errors.email ? (
                <span className='text-danger'>{scheduleVisitFormik.errors.email}</span>
              ) : null}
            </div>
            <div className="col-sm-6 mb-2">
              {allProperties.items.length === 0 && allProperties.loading && (<div className='mt-1'><CircularProgress size={30} color='primary' style={{ textAlign: 'center' }} /></div>)}
              <Autocomplete
                disablePortal
                options={allPropertiesOptions}
                size='small'
                disabled={scheduleLoading || allProperties.items.length === 0}
                value={allProperties.items.length > 0 ? allPropertiesOptions.find((alo) => parseInt(alo.value) === parseInt(scheduleVisitFormik.values.property_id)) : 'No property to select.'}
                renderInput={(params) => <ThemeProvider theme={theme}><TextField {...params} label='Select a Property' required /> </ThemeProvider>}
                onChange={(e, newValue) => {
                  scheduleVisitFormik.setFieldTouched('property_id', true, true)
                  scheduleVisitFormik.setFieldValue('property_id', newValue ? newValue.value : '', true)
                }}
                onBlur={() => {
                  scheduleVisitFormik.setFieldTouched('property_id', true, true)
                }}
              />
              {scheduleVisitFormik.touched.property_id && scheduleVisitFormik.errors.property_id ? (
                <span className='text-danger'>{scheduleVisitFormik.errors.property_id}</span>
              ) : null}
            </div>
            <div className="col-sm-6 mb-3 plDatepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={theme}>
                  <DateTimePicker
                    label={'Schedule Visit Date & Time'}
                    format={DEFAULT_DATETIME_FORMAT}
                    slotProps={{ textField: { size: 'small', required: true } }}
                    value={scheduleVisitFormik.values.visit_datetime ? dayjs(scheduleVisitFormik.values.visit_datetime) : null}
                    onChange={(newValue) => {
                      scheduleVisitFormik.setFieldTouched('visit_datetime', true)
                      scheduleVisitFormik.setFieldValue('visit_datetime', newValue, true)
                    }}
                    referenceDate={dayjs(new Date())}
                    timeSteps={{ minutes: 1 }}
                  />
                </ThemeProvider>
                {scheduleVisitFormik.touched.visit_datetime && scheduleVisitFormik.errors.visit_datetime ? (
                  <span className='text-danger'>{scheduleVisitFormik.errors.visit_datetime}</span>
                ) : null}
              </LocalizationProvider>
              {/* <Label className="form-label" htmlFor="visitation-datetime-local-input">Visitation Time*</Label>
              <input
                className="form-control"
                type="datetime-local"
                name="visit_datetime"
                required
                disabled={scheduleLoading}
                id="visitation-datetime-local-input"
                value={scheduleVisitFormik.values?.visit_datetime ? moment(scheduleVisitFormik.values?.visit_datetime).format('YYYY-MM-DD[T]HH:mm') : ''}
                onChange={scheduleVisitFormik.handleChange}
                onBlur={scheduleVisitFormik.handleBlur}
              />
              defaultValue="2019-08-19T13:45:00"
              {scheduleVisitFormik.touched.visit_datetime && scheduleVisitFormik.errors.visit_datetime ? (
                <span className='text-danger'>{scheduleVisitFormik.errors.visit_datetime}</span>
              ) : null} */}
            </div>
            {/* <div className="col-sm-6 mb-3">
              <input
                className="form-control"
                type="text"
                placeholder="Contact Person's Contact No*"
                onChange={scheduleVisitFormik.handleChange}
                onBlur={scheduleVisitFormik.handleBlur}
              />
            </div> */}
            {/* <div className="col-12 mb-3">
              <label>Upload Slip</label>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted uil uil-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles.map((f, i) => (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={`${i}-file`}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <NavLink
                            href="#"
                            className="text-muted font-weight-bold"
                          >
                            {f.name}
                          </NavLink>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ))}
              </div>
            </div> */}
          </Row>
          <div className="formActions btn_right">
            <Button
              className='yellow_gradient_btn'
              disabled={scheduleLoading || !scheduleVisitFormik.isValid}
              style={{
                cursor: scheduleLoading || !scheduleVisitFormik.isValid ? 'not-allowed' : 'pointer'
              }}
            >
              <CircularProgress size={12} thickness={5} color='inherit' style={{ color: '#00000', textAlign: 'center', display: !scheduleLoading ? 'none' : 'inline-block' }} />
              <span className='mx-1'>Save</span>
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default AddOrEditVisitRequestsDrawer;
