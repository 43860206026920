import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col } from "reactstrap";
import { Table, Tbody, Tr, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useNavigate } from "react-router-dom";
import TableHeaders from "../../components/Common/TableHeaders";
import { Columns } from "../../components/Finance/RentSlip/Column";
import { ALL_MODULES, BILL_PAID_STATUS, DEFAULT_DATE_FORMAT, OPERATION_TYPE } from "../../common/constant";
import {
  deleteBill,
  fetchBillDataPropertyMonthWise,
  publishBill,
} from "../../api/rent-bill";
import EditIcon from "@mui/icons-material/Edit";
const LIMIT = 5;
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "flatpickr/dist/themes/material_blue.css";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import moment from "moment";
import PaginationComponent from "../../components/Common/PaginationComponent";
import NodataFound from "../../components/Common/NoDataFound";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import DeleteModal from "../../components/Common/DeleteModal";
import { toast } from "react-toastify";
import SimpleCarousel from "../../components/Common/SimpleCarousel";
import NoDataIcon from "../../assets/images/no-data.png";
import dayjs from "dayjs";
import { usePermissionGiven } from "../../components/Hooks/UserPermission";
import Search from "../../components/Common/GlobalSearch";
import { RentSlipFilterDrawer } from "../../components/Finance/RentSlip/RentSlipFilterDrawer";

function RentSlips() {
  document.title = " Finance | Dstayz";
  const [sort, setSort] = useState({});
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [update, setUpdate] = useState(0);
  const [page, setPage] = useState(1);
  const [ID, setId] = useState("");
  const [publish, setPublish] = useState(false);
  const [meta, setMeta] = useState({});
  const [toggleOn, setToggleOn] = useState(false);
  const [images, setImages] = useState([]);
  const [billData, setBillData] = useState([]);
  const [limit, setLimit] = useState(LIMIT);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [filterInputs, setFilterInputs] = useState([])
  const [filterFields, setFilterFields] = useState([])
  const [isRentSlipFilter, setIsRentSlipFilter] = useState(false);
  const [searchItem, setSearchItem] = useState()
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.DELETE)
  const hasCreatePermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.CREATE)
  const hasUpdatePermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.UPDATE)

  const handleSort = async (sort) => {
    setBillData([]);
    setSort(sort);
    const resultData = await fetchBillDataPropertyMonthWise({
      ...(sort?.order &&
        sort?.direction && { order: sort.order, direction: sort.direction }),
      ...(page && page),
      limit,
    });
    setBillData(resultData?.data?.data?.data);
    setMeta(resultData?.data?.data?.meta);
  };
  const navigateToGenerateTicket = (id) => {
    navigate(`/generate-rent-slip/view/${id}`);
  };
  const navigateToGenerateTicketEdit = () => {
    navigate(`/generate-rent-slip/${ID}`);
  };
  const fetchBillSlips = async (data) => {
    const params = {
      ...(sort?.order && { order: sort.order }),
      ...(sort?.direction && { direction: sort.direction }),
      ...data,
    };
    const response = await fetchBillDataPropertyMonthWise(params);
    setBillData(response?.data?.data?.data);
    setMeta(response?.data?.data?.meta);
  };

  useEffect(() => {
    fetchBillSlips({ page: page, limit: limit });
  }, []);

  function findMonthYear(ele) {
    const month = moment(ele?.start_date).format("MMMM");
    const year = new Date(ele?.start_date).getFullYear();
    return `${month} ${year}`;
  }

  const paginationModelMethod = (data) => {
    if (data?.page) {
      setPage(data.page);
      fetchBillSlips({ page: data.page, limit: limit });
    } else {
      setUpdate((prev) => prev + 1);
      setPage(1);
      setLimit(data?.limit);
      fetchBillSlips({
        page: data.page,
        limit: data?.limit,
      });
    }
  };
  const publishAction = async () => {
    try {
      setIsLoading(true);
      const res = await publishBill(ID);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
        setPage(1);
        setLimit(10);
        fetchBillSlips({ page: 1, limit: 10 });
        setPublish(false);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      toast.error(e?.response?.data?.message || e.message);
    } finally {
      setIsLoading(false);
      setPublish(false);
    }
  };
  const onToggleCloseDelete = () => setDeleteOpen(false);

  const deleteFunction = async () => {
    setIsLoading(true);
    try {
      const res = await deleteBill(ID);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
        setPage(1);
        setLimit(10);
        fetchBillSlips({ page: 1, limit: 10 });
        setDeleteOpen(false);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      toast.error(e?.response?.data?.message || e.message);
    } finally {
      setIsLoading(false);
      onToggleCloseDelete();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenIndex(null)
  };

  const calculatePendingRent = (items) => {
    let sum = 0
    items.forEach((el) => {
      if (el.status !== BILL_PAID_STATUS.PAID) {
        sum = sum + el.total_amount
      }
    })
    return sum
  }

  const globalSearch = async (params) => {
    setBillData([]);
    setSearchItem(params)
    setPage(1)
    setMeta()
    const resultData = await fetchBillDataPropertyMonthWise({
      ...params,
      limit: params.limit,
      page: params.page,
      ...(params.search && {
        search: params.search,
      }),
      ...(sort?.order && { order: sort.order }),
      ...(sort?.direction && { direction: sort.direction }),
    });
    setBillData(resultData?.data?.data?.data);
    setMeta(resultData?.data?.data?.meta);
  };

  const handleFilter = async (filterFields, filterInputs) => {
    setBillData([]);
    setPage(1)
    setMeta()
    setUpdate((pre) => pre + 1)
    setFilterInputs(filterInputs)
    setFilterFields(filterFields)
    const resultData = await fetchBillDataPropertyMonthWise({
      limit: limit,
      ...(searchItem && {
        ...searchItem,
      }),
      filter_fields: filterFields,
      filter_inputs: filterInputs,
      ...(sort?.order && { order: sort.order }),
      ...(sort?.direction && { direction: sort.direction }),
    });
    setBillData(resultData?.data?.data?.data);
    setMeta(resultData?.data?.data?.meta);
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Rent Slip</h4>
              </div>
            </div>
          </div>
          <Row>
            <Col className="col-12">
              <div className="flexbox_holder">
                <div className='app-search'>
                  <Search
                    listCall={globalSearch}
                    params={{
                      limit: limit,
                      page: 1,
                      order: sort?.order,
                      direction: sort?.direction,
                      filter_fields: filterFields,
                      filter_inputs: filterInputs,
                    }}
                  />
                </div>
                <div>
                  <Button className='yellow_gradient_btn me-2' onClick={() => setIsRentSlipFilter(true)}>
                    <i className="mdi mdi-filter-variant" />
                    Filter
                  </Button>
                  {hasCreatePermission && (
                    <Button color='primary' className="me-2" onClick={() => navigate(`/generate-rent-slip`)} >
                      <PostAddOutlinedIcon />
                      Generate Rent Slip
                    </Button>
                  )}
                </div>
              </div>
              <Card className="table_card_holder">
                {billData?.length > 0 ? (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-bordered rentSlipTable"
                      >
                        <TableHeaders
                          headers={Columns}
                          sortBy={sort}
                          emitSortedColumn={handleSort}
                          module={ALL_MODULES.FINANCE}
                          shouldACtionDisplay={hasDeletePermission || hasUpdatePermission}
                        />
                        <Tbody>
                          {billData?.map((ele, ind) => (
                            <Tr key={ind}>
                              <Td>{ind + 1}</Td>
                              <Td
                                onClick={() => navigateToGenerateTicket(ele?.id)}
                                style={{ cursor: "pointer" }}
                              >
                                {findMonthYear(ele)}
                              </Td>
                              <Td>{ele?.rent_slips[0]?.property?.name ?? "N/A"}</Td>
                              <Td className="cursor_pointer view_rent_slip">
                                {ele?.attachments?.length > 0 ? (
                                  <div
                                    onClick={() => {
                                      setToggleOn(true);
                                      setImages([...(ele.attachments || [])]);
                                    }}
                                  >
                                    <RemoveRedEyeOutlinedIcon
                                      style={{
                                        width: "18px",
                                        marginRight: "5px",
                                        color: "#0A50B9",
                                      }}
                                    />
                                    View File
                                  </div>
                                ) : (
                                  <>
                                    <ImageNotSupportedIcon
                                      style={{
                                        width: "18px",
                                        marginRight: "5px",
                                        color: "#0A50B9",
                                      }}
                                    />
                                    No File
                                  </>
                                )}
                              </Td>
                              <Td>
                                <span
                                  className={`status ${ele?.status === "Published"
                                    ? "publish_status"
                                    : "draft_status"
                                    }`}
                                >
                                  {ele?.status}
                                </span>
                              </Td>
                              <Td>{ele?.published_at ? calculatePendingRent(ele.rent_slips) : 'N/A'}</Td>
                              <Td>
                                {ele?.published_at
                                  ? dayjs(ele?.published_at).format(DEFAULT_DATE_FORMAT)
                                  : "N/A"}
                              </Td>
                              {(hasDeletePermission || (hasUpdatePermission && ele?.status !== BILL_PAID_STATUS.PUBLISHED)) && (
                                <Td className="threeDotsSpace">
                                  <Button
                                    id="basic-button"
                                    aria-controls={open ? "basic-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={(e) => {
                                      setOpenIndex(ind)
                                      handleClick(e);
                                      setId(ele?.id);
                                    }}
                                  >
                                    <i className="mdi mdi-dots-vertical font-size-15"></i>
                                  </Button>
                                  <Menu
                                    disableScrollLock={true}
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={ind === openIndex}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    {ele?.status !== BILL_PAID_STATUS.PUBLISHED && hasUpdatePermission && (
                                      <MenuItem
                                        onClick={() => {
                                          setPublish(true);
                                          handleClose();
                                        }}
                                      >
                                        <PublishedWithChangesOutlinedIcon className="font-size-18 align-middle me-1 text-muted" />
                                        Publish
                                      </MenuItem>
                                    )}
                                    {ele?.status !== BILL_PAID_STATUS.PUBLISHED && hasUpdatePermission && (
                                      <MenuItem
                                        onClick={() => {
                                          navigateToGenerateTicketEdit();
                                          handleClose();
                                        }}
                                      >
                                        <EditIcon
                                          fontSize="very-small"
                                          style={{
                                            marginRight: "5px",
                                          }}
                                        />
                                        Edit
                                      </MenuItem>
                                    )}
                                    {hasDeletePermission && (
                                      <MenuItem
                                        onClick={() => {
                                          setDeleteOpen(true);
                                          handleClose();
                                        }}
                                      >
                                        <i className="mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted" />
                                        Delete
                                      </MenuItem>
                                    )}
                                  </Menu>
                                </Td>
                              )}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <NodataFound text="No rent slip to show" icon={NoDataIcon} />
                  </div>
                )}

                {billData?.length > 0 && (
                  <div
                    className="numbers_pagination_holder padding_setting_stick"
                    style={{
                      marginBottom: "0.5em",
                    }}
                  >
                    <PaginationComponent
                      paginationMeta={meta}
                      handleChangePagination={paginationModelMethod}
                      update={update}
                    ></PaginationComponent>
                  </div>
                )}
              </Card>
            </Col>
          </Row>

        </div>
        {publish && (
          <ConfirmationModal
            action={""}
            show={publish}
            text={"publish it"}
            onCloseClick={() => setPublish(false)}
            onAcceptClick={publishAction}
            isDisabled={isLoading}
          />
        )}
        {deleteOpen && (
          <DeleteModal
            isLoading={isLoading}
            show={deleteOpen}
            onCloseClick={onToggleCloseDelete}
            onDeleteClick={deleteFunction}
          />
        )}
        {toggleOn && (
          <SimpleCarousel
            open={toggleOn}
            setOpen={setToggleOn}
            onClose={() => setToggleOn(false)}
            images={images}
          />
        )}

        {isRentSlipFilter && (
          <RentSlipFilterDrawer
            isOpen={isRentSlipFilter}
            toggleDrawer={() => setIsRentSlipFilter(!isRentSlipFilter)}
            emitFilterItem={handleFilter}
            selectedFields={filterFields}
            selectedInputs={filterInputs}
          />
        )}
      </div>
    </>
  );
}

export default RentSlips;
