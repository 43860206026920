import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  FormFeedback,
} from "reactstrap";
import Dropzone from "react-dropzone";
import FileUpload from "../../assets/images/icons/image-add-line.svg";
import { Autocomplete, Box, TextField } from "@mui/material";
// import dayjs from 'dayjs';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import uuid from "react-native-uuid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { roleListing } from "../../api/role";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { getSpecificUser } from "../../api/user";
import ConfirmationModal from "../Common/ConfirmationModal";
import { ACTIVE_STATUS, ROLES } from "../../constants/variables";
import {
  contactNoPattern,
  formatBytes,
  getS3BaseUrl,
  handleKeyDown,
} from "../../helpers/string_helper";
import { DatePicker } from "@mui/x-date-pickers";
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS } from "../../common/constant";
import FileList from "../Common/FileList";
import { fileUploads, uploadDocuments } from "../../api/compay-employee";
import { createTheme, ThemeProvider } from '@mui/material/styles';

function AddNewTenantDrawer({
  isOpen,
  toggleDrawer,
  createEmployeeFunction,
  EditEmployeeFunction,
  isLoading,
  setIsLoading,
  setIsEditMode,
  isEdit,
  userId,
}) {
  const [objectName, setObjectName] = useState("");
  const [isAddExistingTenant] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [limitRole] = useState(10);
  const [date, setDate] = useState(null);
  const [rolePage, setRolePage] = useState(1);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [roleMeta, setRoleMeta] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const imageInoutRef = useRef(null);
  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });
  const exclude_filter_fields = ["role"];
  const exclude_filter_inputs = [
    `${ROLES.OWNER},${ROLES.TENANT},${ROLES.SUPER_ADMIN},${ROLES.PROSPECTING_OWNER}`,
  ];
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    validationData
      .setValues(
        {
          ...validationData.values,
          attachments: [...selectedFiles, ...files],
          total_documents_size: [...selectedFiles, ...files].reduce(
            (p, c) => p + c.size,
            0
          ),
        },
        true
      )
      .then(() => {
        setselectedFiles((prev) => {
          return [...prev, ...files];
        });
      });
  }
  const findFirstLastName = (fullName) => {
    fullName = fullName.trim();
    const words = fullName.split(/\s+/);
    const lastName = words.pop();
    const firstName = words.join(" ");
    return {
      firstName: firstName,
      lastName: lastName,
    };
  };

  const validationData = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName:
        employeeData?.name && findFirstLastName(employeeData?.name)?.firstName,
      lastName:
        employeeData?.name && findFirstLastName(employeeData?.name).lastName,
      contact_number: employeeData?.contact_number || "",
      email: employeeData?.email || "",
      address: employeeData?.address || "",
      role_id: employeeData?.role_id || "",
      profile_photo: employeeData?.profile_photo,
      joining_date: employeeData?.employee?.joining_date || "",
      status: employeeData?.employee?.status|| ACTIVE_STATUS.ACTIVE,
      yearly_ctc: employeeData?.employee?.yearly_ctc || "",
      additional_information:
        employeeData?.employee?.additional_information || "",
      attachments: employeeData?.attachments || [],
      total_documents_size: employeeData?.total_documents_size,
      profile_photo_size: employeeData?.profile_photo_size,
    },

    //<------------------------------Validation---------------------->
    validationSchema: Yup.object({
      role_id: Yup.string().required("Please Enter Role"),
      status: Yup.string().required("Please Enter Active Status"),
      firstName: Yup.string()
        .max(20, "First name can't be more than 20 characters")
        .required("Please Enter First Name"),
      lastName: Yup.string()
        .max(20)
        .required("Please Enter Last Name")
        .test(
          "no-space-between-words",
          "Last name should not contain spaces",
          (value) => {
            return !/\s/.test(value); // Return true if there are no spaces
          }
        ),
      profile_photo: Yup.string().required("Please Provide Profile Picture"),
      contact_number: Yup.string()
        .strict(true)
        .max(10, "Contact number can't be more than 10")
        .trim("Leading and trailing spaces is not allowed.")
        .matches(contactNoPattern, "Invalid contact information.")
        .required("Please enter contact number."),
      email: Yup.string().email().optional(),
      address: Yup.string()
        .max(150)
        .optional("Please Enter  Residential Address"),
      additional_information: Yup.string().optional().max(150),
      attachments: Yup.array()
        .max(2, "You are only allowed to have 2 documents here.")
        .optional(),
      total_documents_size: Yup.number()
        .positive()
        .when("attachments", {
          is: (docs) => docs?.length > 0,
          then: (schema) =>
            schema
              .min(1, "The documents size must be greater than 1 Byte.")
              .max(
                50 * 1024 * 1024,
                "The documents size must not exceed 50 MiB."
              )
              .required(),
          otherwise: (schema) => schema.optional(),
        }),

      profile_photo_size: Yup.number()
        .positive()
        .max(50 * 1024 * 1024, "The documents size must not exceed 50 MiB.")
        .required(),
    }),

    // -------------------------submit-------------------------------->

    onSubmit: async (payload) => {
      setIsLoading(true);
      let payLoadData = structuredClone(payload);
      let documents = [];
      const filesUnchanged =
        validationData.values?.attachments?.filter((file) => file?.id) ?? [];
      if (filesUnchanged.length > 0) {
        documents = [...filesUnchanged.map((sd) => sd.id)];
      }
      const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
      if (filesToBeUploaded.length > 0) {
        const formData = new FormData();

        filesToBeUploaded.forEach((f) => {
          formData.append("files", f);
        });
        formData.append("base_key", DOCUMENT_HASH_BASE_KEYS.COMPANY_EMPLOYEES);
        if (!isEdit) {
          formData.append("max_items", 10);
        }
        const result = await uploadDocuments(formData);
        if (result.success) {
          const { response } = result;
          if (documents !== undefined) {
            documents = [
              ...documents,
              ...response.data.data.documents.map((doc) => doc.id),
            ];
          } else {
            documents = [...response.data.data.documents.map((doc) => doc.id)];
          }
        } else {
          setIsLoading(false);
          return false;
        }
      }
      let profile_photo_address = payLoadData?.profile_photo;

      if (profilePhoto) {
       
        const profileImage = new FormData();
        profileImage.append("files", profilePhoto);
        profileImage.append(
          "base_key",
          DOCUMENT_HASH_BASE_KEYS.COMPANY_EMPLOYEES
        );
        // if (!isEdit) {
        //   profileImage.append("max_items", 1);
        // }
        const result = await fileUploads(profileImage);
        if (result?.success) {
          profile_photo_address =
            result?.response?.data?.data?.responses[0]?.actual_location;
        } else {
          setIsLoading(false);
          return false;
        }
      }
      
      const data = {
        name: payLoadData?.firstName + " " + payLoadData?.lastName,
        profile_photo: profile_photo_address,
        contact_number: String(payLoadData?.contact_number),
        role_id: payLoadData?.role_id,
        ...(validationData.values.additional_information && {
          additional_information: validationData.values.additional_information,
        }),
        ...(validationData?.values.email && {
          email: validationData?.values.email,
        }),
        ...(validationData?.values.address && {
          address: validationData?.values.address,
        }),
        ...(validationData.values.yearly_ctc && {
          yearly_ctc: String(validationData.values.yearly_ctc),
        }),
        ...(validationData.values.joining_date && {
          joining_date: validationData.values.joining_date,
        }),
        employee_status: validationData?.values?.status,
        attachments: [...documents],
      };

      const result = isEdit
        ? await EditEmployeeFunction({
            ...data,
            yearly_ctc: String(payLoadData?.yearly_ctc),
            additional_information: payLoadData?.additional_information,
          })
        : await createEmployeeFunction(data);
      if (result) {
        reset();
      }
    },
  });
 
  const fetchEmployeeData = async () => {
    try {
      const response = await getSpecificUser(userId);
      if (response?.status === 200) {
        const date = dayjs(response?.data?.data?.employee?.joining_date);
        setEmployeeData(response?.data?.data);
        const newDocuments = response?.data?.data?.attachments?.map((doc) => ({
          ...doc,
          uniqueId: uuid.v4(),
          formattedSize: formatBytes(doc.size),
        }));

        setEmployeeData({
          ...response?.data?.data,
          profile_photo_size: 1,
          ...(newDocuments.length > 0 && {
            attachments: [...newDocuments],
          }),
          ...(newDocuments.length > 0 && {
            total_documents_size: [...newDocuments].reduce(
              (p, c) => p + c.size,
              0
            ),
          }),
        });
        setselectedFiles([...newDocuments]);
        if (date["$D"]) {
          setDate(date);
        } else setDate(null);
        setObjectName(response?.data?.data?.role?.role);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };

  const s3BaseUrl = getS3BaseUrl()
  
  const fetchRoles = async (
    rolePage,
    limitRole,
    exclude_filter_fields,
    exclude_filter_inputs
  ) => {
    try {
      const params = {
        page: rolePage,
        limit: limitRole,
        exclude_filter_fields,
        exclude_filter_inputs,
      };
      const response = (await roleListing(params)).data.data;
      const modifiedData = response?.data || [];
      setRoleList((old) => [...old, ...modifiedData]);
      setRoleMeta(response?.meta);
    } catch (error) {
      return toast.error(error.message);
    }
  };
  const reset = () => {
    setEmployeeData([]);
    setObjectName("");
    validationData?.resetForm({});
  };
  useEffect(() => {
    fetchRoles(
      rolePage,
      limitRole,
      exclude_filter_fields,
      exclude_filter_inputs
    );
  }, []);
  useEffect(() => {
    if (isEdit && userId) {
      fetchEmployeeData();
    }
  }, [isEdit, userId]);

  function changeDate(e) {
    validationData.setFieldValue("joining_date", e);
    setDate(e);
  }
  // const s3BaseUrl = getS3BaseUrl()
  const scrollHandle = (e) => {
    const listboxNode = e.currentTarget;
    const x = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - x <= 1 &&
      roleMeta?.current_page < roleMeta?.last_page
    ) {
      fetchRoles(
        rolePage + 1,
        limitRole,
        exclude_filter_fields,
        exclude_filter_inputs
      );
      setRolePage((old) => old + 1);
    }
  };
  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex },
    });
  }
  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = validationData.values.attachments.filter(
      (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)
    );
    const tempValues = {
      ...validationData.values,
    };

    if (newDocuments.length === 0) {
      tempValues.attachments = [];
      delete tempValues.total_documents_size;
    }
    validationData
      .setValues(
        {
          ...tempValues,
          ...(newDocuments.length > 0 && {
            attachments: newDocuments,
          }),
          ...(newDocuments.length > 0 && {
            total_documents_size: selectedFiles
              .filter(
                (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)
              )
              .reduce((p, c) => p + c.size, 0),
          }),
        },
        true
      )
      .then(() => {
        setselectedFiles((prev) =>
          prev.filter(
            (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)
          )
        );
      });

    return true;
  }

  const addProfilePic = (file) => {
    let imageFile = file.target.files[0];
    Object.assign(imageFile, {
      uniqueId: uuid.v4(),
      location: URL.createObjectURL(imageFile),
      formattedSize: formatBytes(imageFile.size),
    });
    setProfilePhoto(imageFile);
    validationData.setValues({
      ...validationData.values,
      profile_photo: imageFile?.location,
      profile_photo_size: imageFile?.size,
    });
  };

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });

  return (
    <Offcanvas isOpen={isOpen} direction="end">
      <OffcanvasHeader
        toggle={() => {
          reset();
          isEdit && setIsEditMode(false);
          toggleDrawer(!isAddExistingTenant);
        }}
      >
        {isEdit ? "Edit" : "Add"} Company Employee
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setConfirmation(true);
          }}
        >
          <Row>
            <div className="col-sm-12 mb-3">
              <div className="custom-image-upload" style={{
                  display:'flex',
                  alignItems:'center'
                }}>
                <div className="image-placeholder-employee">
                  <img
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={()=>{
                      if (imageInoutRef.current) {
                        imageInoutRef.current.click();
                      }
                    }}
                    src={
                      profilePhoto ? profilePhoto.location : (
                        validationData?.values?.profile_photo ?
                        `${s3BaseUrl}${validationData?.values?.profile_photo}` :
                          FileUpload
                      )
                    }
                    alt={FileUpload}
                  />
                </div>
                <div className="upload_picture" >
                  <Input
                    className="form-control pos-rel"
                    accept="image/*"
                    type="file"
                    innerRef={imageInoutRef}
                    id="profile_photo"
                    style={{ display: "none" }}
                    onClick={validationData.handleBlur}
                    onChange={addProfilePic}
                    invalid={
                      !!(
                        validationData.touched.profile_photo &&
                        validationData.errors.profile_photo
                      )
                    }
                  />
                  <label
                   style={{
                    alignSelf:'center'
                   }}
                    htmlFor="profile_photo"
                    className="custom-file-label mb-0 mx-2"
                  >
                    Upload Picture <span className="label_asterik">*</span>
                  </label>
                </div>
              </div>
              {validationData.touched.profile_photo &&
              validationData.errors.profile_photo ? (
                <span className="text-danger-formik">
                  {" "}
                  {validationData.errors.profile_photo}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="col-sm-6 mb-3">
              <ThemeProvider theme={theme}>
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  label="First Name"
                  name="firstName"
                  value={validationData?.values?.firstName || ""}
                  required
                  disabled={isLoading}
                  onChange={validationData.handleChange}
                  onBlur={validationData.handleBlur}
                  error={
                    !!(
                      validationData.touched?.firstName &&
                      validationData.errors?.firstName
                    )
                  }
                  helperText={
                    validationData.touched?.firstName &&
                      validationData.errors?.firstName
                      ? validationData.errors?.firstName
                      : ""
                  }
                />
              </ThemeProvider>
              {validationData.touched.firstName &&
              validationData.errors.firstName ? (
                <FormFeedback type="invalid">
                  {validationData.errors.firstName}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-sm-6 mb-3">
              <ThemeProvider theme={theme}>
                <TextField
                  className="form-control"
                  type="text"
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name *"
                  value={validationData?.values?.lastName || ""}
                  onChange={validationData.handleChange}
                  onBlur={validationData.handleBlur}
                  error={
                    !!(
                      validationData.touched?.lastName &&
                      validationData.errors?.lastName
                    )
                  }
                  helperText={
                    validationData.touched?.lastName &&
                      validationData.errors?.lastName
                      ? validationData.errors?.lastName
                      : ""
                  }
                  size="small"
                  required
                />
              </ThemeProvider>
              {validationData.touched.lastName &&
              validationData.errors.lastName ? (
                <FormFeedback type="invalid">
                  {validationData.errors.lastName}
                </FormFeedback>
              ) : null}
            </div>

            <div className="col-sm-6 mb-3">
              <Autocomplete
                value={objectName}
                disabled={isLoading}
                onChange={(event, newValue) => {
                  validationData.setFieldValue("role_id", newValue?.id);
                  setObjectName(newValue?.role);
                  if (!newValue?.role) setObjectName("");
                }}
                onBlur={() => {
                  if (!validationData.touched?.role_id) {
                    validationData.setFieldTouched("role_id", true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" key={option.id} {...props}>
                    {option.role}
                  </Box>
                )}
                options={roleList}
                renderInput={(params) => (
                  <ThemeProvider theme={theme}>
                    <TextField
                      {...params}
                      error={
                        !!(
                          validationData.touched.role_id &&
                          validationData.errors.role_id
                        )
                      }
                      label="Designation/ Role"
                      size="small"
                      required
                    />
                  </ThemeProvider>
                )}
                ListboxProps={{
                  onScroll: scrollHandle,
                }}
              />

              {validationData.touched.role_id &&
              validationData.errors.role_id ? (
                <span className="text-danger-formik">
                  {" "}
                  {validationData.errors.role_id}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="col-sm-6 mb-3">
              <ThemeProvider theme={theme}>
                <TextField
                  className="form-control"
                  type="text"
                  label="Contact Number"
                  name="contact_number"
                  value={validationData?.values?.contact_number}
                  onChange={validationData.handleChange}
                  onBlur={validationData.handleBlur}
                  error={
                    !!(
                      validationData.touched?.contact_number &&
                      validationData.errors?.contact_number
                    )
                  }
                  helperText={
                    validationData.touched?.contact_number &&
                      validationData.errors?.contact_number
                      ? validationData.errors?.contact_number
                      : ""
                  }
                  size="small"
                  onWheel={(e) => e.target.blur()}
                  disabled={isLoading}
                  placeholder="Contact number*"
                  required
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                />
              </ThemeProvider>
              {validationData.touched.contact_number &&
              validationData.errors.contact_number ? (
                <FormFeedback type="invalid">
                  {validationData.errors.contact_number}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-sm-6 mb-3">
              <TextField
                size="small"
                className="form-control"
                name="email"
                type="email"
                label="Email (optional)"
                onChange={validationData.handleChange}
                disabled={isLoading}
                value={validationData?.values?.email}
                placeholder="Email*"
              />
            </div>

            <div className="col-sm-6 mb-3">
              <TextField
                size="small"
                className="form-control"
                type="number"
                label="Yearly CTC"
                disabled={isLoading}
                value={validationData?.values?.yearly_ctc || ''}
                onChange={validationData.handleChange}
                name="yearly_ctc"
                placeholder="Yearly CTC"
              />
            </div>
            <div className="col-sm-6 mb-3">
              <Autocomplete
                value={validationData?.values?.status}
                disabled={isLoading}
                onChange={(event, newValue) => {
                  validationData.setFieldValue("status", newValue);
                  
                }}
                onBlur={() => {
                  if (!validationData.touched?.status) {
                    validationData.setFieldTouched("status", true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={[ACTIVE_STATUS.ACTIVE,ACTIVE_STATUS.IN_ACTIVE]}
                renderInput={(params) => (
                  <ThemeProvider theme={theme}>
                    <TextField
                      {...params}
                      error={
                        !!(
                          validationData.touched.status &&
                          validationData.errors.status
                        )
                      }
                      label="Active Status"
                      size="small"
                      required
                    />
                  </ThemeProvider>
                )}
                
              />

              {validationData.touched.status &&
              validationData.errors.status ? (
                <span className="text-danger-formik">
                  {" "}
                  {validationData.errors.status}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="col-sm-6 mb-3"
              style={{
                marginTop: "-8px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]} style={{ width: '100%'}}>
                  <DatePicker
                    id="example-datetime-local-input"
                    slotProps={{ textField: { size: "small" } }}
                    referenceDate={dayjs(new Date())}
                    ampm={false}
                    label="Select Joining Date"
                    timeSteps={{ minutes: 1 }}
                    value={date}
                    onChange={changeDate}
                    style={{ width: '100%'}}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="col-12 mb-3">
              <TextField
                fullWidth
                id="outlined-multiline-static txtAddress1"
                label="Address"
                name="address"
                multiline
                rows={2}
                className="tenantNoteTextarea mb-3"
                disabled={isLoading}
                value={validationData?.values?.address || ''}
                onChange={validationData.handleChange}
                onBlur={validationData.handleBlur}
                invalid={
                  !!(
                    validationData.touched.address &&
                    validationData.errors.address
                  )
                }
              />
              {validationData.touched.address &&
              validationData.errors.address ? (
                <FormFeedback type="invalid">
                  {validationData.errors.address}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12 mb-3">
              <TextField
                fullWidth
                id="outlined-multiline-static notes"
                label="Additional Information"
                name="additional_information"
                multiline
                rows={2}
                className="tenantNoteTextarea mb-3"
                disabled={isLoading}
                onChange={validationData.handleChange}
                value={validationData?.values?.additional_information || ''}
                minRows={2}
              />
            </div>
            <div className="col-12 mb-3">
              {(validationData.errors.attachments ||
                validationData.errors.total_documents_size) && (
                <ul>
                  {validationData.errors.attachments && (
                    <li className="text-danger">
                      {validationData.errors.attachments}
                    </li>
                  )}
                  {validationData.errors.total_documents_size && (
                    <li className="text-danger">
                      {validationData.errors.total_documents_size}
                    </li>
                  )}
                </ul>
              )}
            </div>
            <div className="col-12 mb-3">
              <label>Upload Documents</label>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted uil uil-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>

              {selectedFiles.length > 0 && (
                <FileList
                  files={selectedFiles}
                  confirmFileRemoval={confirmFileRemoval}
                />
              )}
            </div>
          </Row>
          <div className="formActions btn_right">
            <Button
              disabled={!validationData?.isValid || isLoading || !objectName}
              className={
                !isLoading ? "yellow_gradient_btn" : "fade_yellow_gradient_btn"
              }
              type="submit"
            >
              Save
            </Button>
          </div>
        </Form>
        {confirmation && (
          <ConfirmationModal
            action={isEdit ? ACTIONS.EDIT : ACTIONS.CREATE}
            show={confirmation}
            onAcceptClick={() => {
              validationData.handleSubmit();
              return;
            }}
            onCloseClick={() => setConfirmation(false)}
            isDisabled={isLoading}
          />
        )}
        {isDocumentDeleteConfirm.status && (
          <ConfirmationModal
            action={ACTIONS.DELETE}
            show={isDocumentDeleteConfirm?.status}
            onCloseClick={() =>
              setIsDocumentDeleteConfirm({ status: false, data: null })
            }
            onAcceptClick={async () => {
              const { e, file, fileIndex } = isDocumentDeleteConfirm.data;
              const isDeleted = handleFileRemove(e, file, fileIndex);
              if (isDeleted) {
                setIsDocumentDeleteConfirm({ status: false, data: null });
              }
            }}
            isDisabled={isLoading}
          />
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default AddNewTenantDrawer;
