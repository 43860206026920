import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "reactstrap";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CancelIcon from '@mui/icons-material/Cancel';
import Dropzone from 'react-dropzone';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { endsWithAny } from "../../helpers/string_helper";
import uuid from 'react-native-uuid';
import { formatBytes } from "../../helpers/string_helper";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PdfIcon from "../../assets/images/pdfIcon.png";
import Divider from '@mui/material/Divider';
import ConfirmationModal from "../Common/ConfirmationModal";
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS } from "../../common/constant";
import Tooltip from '@mui/material/Tooltip';
import { uploadDocumentsForModuleWise } from "../../api/CompanyAssociates/api";
import { toast } from "react-toastify";
import { documentRemove } from "../../api/documents";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 620,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
    overflow: 'scroll',
    height: 'auto',
    maxHeight: '80%',
    display: 'block'
};

export default function UploadDocumentModal({
    open,
    handleClose,
    documents,
    handleSubmit,
    handleDelete,
    propertyId,
    refresh
}) {
    const [selectedDocuments, setSelectedDocuments] = useState();
    const [isLoading, setIsLoading] = useState();
    const [isModal, setIsModal] = useState(true);
    const [action, setAction] = useState();
    const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
        status: false,
        data: null,
    });

    useEffect(() => {
        if (documents?.length > 0) {
            setSelectedDocuments(documents)
        }
    }, [documents]);

    function handleAcceptedFiles(files) {
        setIsLoading(true)
        files.map((file) =>
            Object.assign(file, {
                uniqueId: uuid.v4(),
                location: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            }),
        );
        setSelectedDocuments((prev) => {
            return prev ? [...prev, ...files] : files
        })
        setIsLoading(false)
    }

    async function handleFileRemove(file, fileIndex) {
        try {
            setIsLoading(true)
            if (file?.id) {
                const response = await documentRemove(file.id, { moduleId: propertyId, module: DOCUMENT_HASH_BASE_KEYS.PROPERTY_FOOD_MENU });
                toast.success(response.data.message);
                if (selectedDocuments?.length === 1) {
                    refresh()
                    handleClose()
                }
            }

            setSelectedDocuments(prev => prev.filter((_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)))
            setIsLoading(false);
            return true
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const uploadFiles = async () => {
        try {
            const formData = new FormData();
            let isAttchament = false
            selectedDocuments.forEach((f) => {
                if (!f?.id) {
                    formData.append('files', f)
                    isAttchament = true
                }
            })
            if (isAttchament) {
                formData.append('max_items', 10)
                formData.append('moduleId', propertyId)
                formData.append('module', DOCUMENT_HASH_BASE_KEYS.PROPERTY_FOOD_MENU)
                const { response } = await uploadDocumentsForModuleWise(formData);
                toast.success(response.data.message);
            }
            refresh()
            handleClose()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div>
            {isModal && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableScrollLock={true}
                >
                    <Box sx={style} className="dropZoneStyle">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5> {handleSubmit ? 'Upload Picture' : 'Upload Food Menu Card'}</h5>
                            <CancelOutlinedIcon
                                onClick={handleClose}
                                className="cursor-pointer"
                            />
                        </div>
                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                handleSubmit ? handleSubmit(acceptedFiles) : handleAcceptedFiles(acceptedFiles);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div style={{
                                    background: '#f0fcff',
                                    marginBottom: '10px',
                                    border: '2px dashed #69ceee',
                                    borderRadius: '15px'
                                }}>
                                    <div className="dz-message needsclick" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="mb-3 dragIco">
                                            <AddPhotoAlternateOutlinedIcon />
                                        </div>
                                        <h4>Browse or Drag and Drop Picture</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>

                        <ul
                            className="dropzoneImg d-flex flex-wrap ps-0 mb-0"
                            style={{ listStyle: "none", paddingTop: "5px" }}
                        >
                            {!isLoading &&
                                selectedDocuments?.length > 0 &&
                                selectedDocuments?.map(
                                    (item, index) => (
                                        <li className="position-relative d-flex" key={index}>
                                            {endsWithAny([".jpg", ".png", ".jpeg"], item?.name?.toLowerCase()) ? (
                                                <div className="single_priority_pic">
                                                    <label htmlFor={`item-${index}`}>
                                                        <img src={item.location} alt={item.name} style={{ width: '160px', height: '160px' }} />
                                                        <Tooltip title={item.name}><div className="text-truncate">{item.name}</div></Tooltip>

                                                        <CancelIcon className="position-absolute" style={{ color: '#fb003a' }} onClick={() => {
                                                            setIsModal(false)
                                                            setAction(ACTIONS.DELETE)
                                                            setIsDocumentDeleteConfirm({
                                                                status: true,
                                                                data: { file: item, fileIndex: index }
                                                            })
                                                        }} />
                                                    </label>
                                                </div>
                                            ) : item?.name?.toLowerCase().endsWith(".pdf") ? (
                                                <div className="single_priority_pic">
                                                    <label htmlFor={`item-${index}`}>
                                                        <img src={PdfIcon} alt={PdfIcon} style={{ width: '160px', height: '160px' }} />
                                                        <Tooltip title={item.name}><div className="text-truncate">{item.name}</div></Tooltip>

                                                        <CancelIcon className="position-absolute" style={{ color: '#fb003a' }} onClick={() => {
                                                                setIsModal(false)
                                                                setAction(ACTIONS.DELETE)
                                                            setIsDocumentDeleteConfirm({
                                                                status: true,
                                                                data: { file: item, fileIndex: index }
                                                            })
                                                        }} />
                                                    </label>
                                                </div>

                                            ) : (
                                                <div className="single_priority_pic">
                                                    <label htmlFor={`item-${index}`}>
                                                        <FileUploadOutlinedIcon className="me-1" style={{ width: '160px', height: '160px' }} />
                                                        <Tooltip title={item.name}><div className="text-truncate">{item.name}</div></Tooltip>

                                                        <CancelIcon className="position-absolute" style={{ color: '#fb003a' }} onClick={() => {
                                                                    setIsModal(false)
                                                                    setAction(ACTIONS.DELETE)
                                                            setIsDocumentDeleteConfirm({
                                                                status: true,
                                                                data: { file: item, fileIndex: index }
                                                            })
                                                        }} />
                                                    </label>
                                                </div>
                                            )}
                                        </li>
                                    )
                                )}
                        </ul>
                        <Divider
                            style={{ backgroundColor: "rgb(74 74 74)", margin: "15px 0" }}
                        />
                        <div className="modal_footer text-center">
                            <Button
                                className="yellow_gradient_btn"
                                disabled={!selectedDocuments?.length}
                                onClick={() => {
                                    setIsModal(false)

                                    if (propertyId) {
                                        setIsDocumentDeleteConfirm({ status: true, data: null })
                                    } else {
                                        handleClose()
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </Box>
                </Modal>
            )}

            {!isModal && isDocumentDeleteConfirm?.status && (
                <ConfirmationModal
                    action={action}
                    show={isDocumentDeleteConfirm?.status}
                    onCloseClick={() => {
                        setIsDocumentDeleteConfirm({ status: false, data: null })
                        setIsModal(true)
                    }}
                    onAcceptClick={async () => {
                        if (action === ACTIONS.DELETE) {
                            const { file, fileIndex } = isDocumentDeleteConfirm.data;
                            const isDeleted = !propertyId ? handleDelete(file, fileIndex) : handleFileRemove(file, fileIndex);
                            if (isDeleted) {
                                setIsDocumentDeleteConfirm({ status: false, data: null });
                                setIsModal(true);
                                setAction()
                            }
                        } else {
                            await uploadFiles()
                            setAction()
                        }

                    }}
                    isDisabled={isLoading}
                />
            )}
        </div>
    );
}
