import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import PaginationComponent from '../../components/Common/PaginationComponent';
import TableHeaders from '../../components/Common/TableHeaders';
import {
  ACTIONS,
  ALL_MODULES,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_PER_PAGE_VALUE,
  OPERATION_TYPE,
} from '../../common/constant';
import SingleMessageDetailDrawer from '../../components/Message/SingleMessageDetailDrawer';
import CreateNewMessageDrawer from '../../components/Message/CreateNewMessageDrawer';
import { getAllMessages } from '../../api/message';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import NodataFound from '../../components/Common/NoDataFound';
import moment from 'moment';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import { deleteMessageHandler } from '../../api/Message/api';
import { useLocation } from 'react-router-dom';
import NoDataIcon from "../../assets/images/no-data.png";
import { usePermissionGiven } from '../../components/Hooks/UserPermission';

function Message() {
  document.title = 'Company Employees | Dstayz';

  const [selectedRow, setSelectedRow] = useState(null);
  const [isAddMessage, setAddMessage] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [paginationMeta, setPaginationMeta] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [page, setPage] = useState(1);
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });

  const [detailShowId, setDetailShowId] = useState(useLocation()?.state?.id);
  const [update, setUpdate] = useState(0);
  const [onMounted, setOnMounted] = useState(false);
  const [sort, setSort] = useState({});
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.MESSAGE, OPERATION_TYPE.DELETE);

  const messageListApi = async (params) => {
    try {
      setIsLoading(true);
      const response = (await getAllMessages(params)).data.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          ...res,
          indexNo: ++level,
        };
      });
      return resultData;
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessageList = async (
    paginatedData = { page, limit: rowsPerPage },
  ) => {
    const params = {
      ...paginatedData,
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    };
    const resultData = await messageListApi(params);
    setMessageList(resultData);
    if (!onMounted) {
      setOnMounted(true);
    }
  };

  const paginationModelMethod = async (data) => {
    let params = {};
    if (data?.page) {
      setPage(data.page);
      params.page = data.page;
      fetchMessageList({ page: data.page, limit: rowsPerPage });
    } else {
      setUpdate((prev) => prev + 1);
      setPage(1);
      setRowsPerPage(data?.limit);
      params.limit = data.limit;
      fetchMessageList({ page: data.page, limit: data?.limit });
    }
  };

  const refresh = async () => {
    setMessageList([]);
    setPage(1);
    const resultData = await messageListApi({
      limit: rowsPerPage,
      page: 1,
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setMessageList(resultData);
  };

  const handleSort = async (sort) => {
    setSort(sort);
    const resultData = await messageListApi({
      ...(page && page),
      ...(rowsPerPage && { limit: rowsPerPage }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setMessageList(resultData);
  };

  const handleRowClick = (index) => {
    setSelectedRow(messageList[index]);
  };

  const handleCreateMessage = () => {
    setAddMessage(!isAddMessage);
  };

  const deleteHandler = async (id) => {
    setDeleteConfirm({ status: true, id });
  };

  const Columns = [
    {
      column_name: 'Date Time',
      key_name: 'date_time',
      sort_enabled: true,
      style: { minWidth: '158px' },
    },
    // Associate Type
    {
      column_name: 'Sent By',
      key_name: 'sent_by',
      sort_enabled: true,
      style: { minWidth: '141px' },
    },
    // Phone No.
    {
      column_name: 'Sent To',
      key_name: 'sent_to',
      sort_enabled: true,
      style: { minWidth: '115px' },
    },
    // Email ID
    {
      column_name: 'Message',
      key_name: 'subject',
      sort_enabled: false,
      style: { minWidth: '140px' },
    },
    // Address
    {
      column_name: 'Attached Files',
      key_name: 'attached_files',
      sort_enabled: true,
      style: { minWidth: '140px' },
    },
  ];
  useEffect(() => {
    fetchMessageList();
  }, []);

  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          <Row className='d-flex flex-wrap justify-content-start'></Row>
          <Row>
            <Col className='col-12'>
              <div
                className='flexbox_holder mb-2'
                style={{ justifyContent: 'end' }}>
                <div>
                  <Button
                    className='yellow_gradient_btn me-2'
                    onClick={() => handleCreateMessage()}>
                    <i className='mdi mdi-plus' />
                    Create Message
                  </Button>
                </div>
              </div>
              <Card className='table_card_holder'>
                <CardBody>
                  <div className='table-rep-plugin'>
                    <div
                      className='table-responsive mb-0'
                      data-pattern='priority-columns'>
                      {messageList?.length === 0 && isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}

                      {messageList?.length === 0 && !isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <NodataFound text='No message to show' icon={NoDataIcon} />
                        </div>
                      )}
                      {messageList?.length > 0 && (
                        <Table
                          id='tech-companies-1'
                          className='table table-bordered'>
                          <TableHeaders
                            headers={Columns}
                            sortBy={sort ?? ''}
                            emitSortedColumn={handleSort}
                            module={ALL_MODULES.MESSAGE}
                          />
                          <Tbody>
                            {messageList.map((item, index) => (
                              <Tr
                                key={index}
                                onClick={() => handleRowClick(index)}
                                className='cursor_pointer'>
                                <Td onClick={() => setDetailShowId(item?.id)}>
                                  {index + 1}
                                </Td>
                                <Td onClick={() => setDetailShowId(item?.id)}>
                                  {moment(item?.created_at).format(DEFAULT_DATETIME_FORMAT)}
                                </Td>
                                <Td onClick={() => setDetailShowId(item?.id)}>
                                  {item?.sender?.name}
                                </Td>
                                <Td onClick={() => setDetailShowId(item?.id)}>
                                  {item?.tenant_count + item?.owner_count}
                                </Td>
                                <Td onClick={() => setDetailShowId(item?.id)}>
                                  <div className='main_message'>
                                    {item?.subject}
                                  </div>
                                </Td>
                                <Td>
                                  {item?.documents?.length > 0
                                    ? `${item?.documents?.length} Files Attached`
                                    : 'No File Attached'}
                                </Td>
                                {hasDeletePermission ?
                                  (<Td
                                    className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted theme_color text-center delete_action_col'
                                    onClick={() => deleteHandler(item?.id)}></Td>) : null}

                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                    </div>
                  </div>

                  {messageList?.length > 0 && (
                    <div className='numbers_pagination_holder'>
                      <PaginationComponent
                        paginationMeta={paginationMeta}
                        handleChangePagination={paginationModelMethod}
                        update={update}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Message Detail Drawer */}
      {detailShowId && (
        <SingleMessageDetailDrawer
          messageId={detailShowId}
          isOpen={!!detailShowId}
          setIsOpen={setDetailShowId}
        />
      )}

      {/* Message Create Drawer */}
      {isAddMessage && (
        <CreateNewMessageDrawer
          toggleDrawer={handleCreateMessage}
          isOpen={isAddMessage}
          refresh={refresh}
        />
      )}

      {/* Delete confirmation modal */}
      {isDeleteConfirm.status && (
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await deleteMessageHandler(isDeleteConfirm.id);
            if (isDeleted) {
              refresh();
            }
            setDeleteConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />
      )}
    </>
  );
}

export default Message;
