import React, { useState, useEffect } from 'react';
import { Card } from "reactstrap";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Link from '@mui/material/Link';
import * as Yup from "yup";
import { useFormik } from "formik";
import { KNOWN_ROLES, PROPERTY_TYPES, USER_STATUS } from '../../common/constant';
import { getAllUser } from '../../api/user';
import { SearchDropDownListPaginationComponent } from '../Common/SearchDropDownPaginationList';
import uuid from 'react-native-uuid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SelectDropDownListPaginationComponent } from '../Common/SelectDropDownPaginationList';
import { AsterikLabel } from '../Common/AsterikLabel';
import { roomTypeListing } from '../../api/common';
import { AddPhotoAlternateOutlined } from '@material-ui/icons';
import { OtherHousesOutlined } from '@mui/icons-material';
import UploadDocumentModal from './uploadDocumentModal';
import AddAmenitiesModal from './addAmenitiesModal';
import { formatBytes } from '../../helpers/string_helper';
import { documentRemove } from '../../api/documents';
import { toast } from 'react-toastify';

export default function UpdateActiveBhkRoom({ heading, disableFlat, basicPropertyData, emitPropertyData, propertyData }) {
    const floor = ["0th", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21th", "22th", "23th", "24th", "25th"]
    const [floorOptions, setFloorOptions] = useState(floor);
    const [update, setUpdate] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState();
    const handleOpen = () => setOpen(true);
    const [open, setOpen] = useState(false);
    const [indexData, setIndexData] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const initialBed = {
        name: '',
        price: '',
    }

    const roomInitial = {
        name: '',
        amenities: '',
        room_type_id: '',
        documents: [],
        beds: [{
            name: '',
            price: '',
        }]
    }

    const flatInitial = {
        name: '',
        user_id: '',
        rooms: [roomInitial]
    }

    const floorInitials = {
        name: '',
        user_id: '',
        ...(disableFlat === true && basicPropertyData?.type === PROPERTY_TYPES.APARTMENT ? {
            flats: [flatInitial]
        } : {
            rooms: [roomInitial]
        })
    }

    const initalFloorData = {
        floors: [floorInitials],
    }

    const handleAddFloor = () => {
        const result = [...formik.values.floors, {
            ...floorInitials
        }]
        formik.setValues({
            floors: result
        })
    };

    const handleAddFlat = (index) => {
        const updatedFloor = formik.values.floors?.map((floor, flIndex) => {
            if (flIndex === index) {
                return {
                    ...floor,
                    flats: [...floor.flats, {
                        ...flatInitial,
                        name: floor?.flats[floor.flats?.length - 1]?.name ? `Flat ${floor.flats?.length + 1}` : '',
                        rooms: floor?.name && basicPropertyData?.ownerId ? [{ ...roomInitial, name: 'Room 1' }] : [roomInitial]
                    }]
                }
            } else {
                return floor
            }
        })
        formik.setValues({
            floors: updatedFloor
        })
    };

    const handleAddRoom = (index, flatIndex) => {
        const updatedFloor = formik.values.floors?.map((floor, floorIndex) => {
            if (floorIndex === index) {
                if (disableFlat) {
                    const result = floor?.flats?.map((flat, flIndex) => {
                        if (flIndex === flatIndex) {
                            return {
                                ...flat,
                                rooms: [...flat.rooms, {
                                    ...roomInitial,
                                    name: (flat?.name && basicPropertyData?.ownerId) || flat.rooms[flat.rooms?.length - 1]?.name ? `Room ${flat?.rooms?.length + 1}` : '',
                                }]
                            }
                        } else {
                            return flat
                        }
                    })
                    if (result?.length > 0) {
                        return {
                            ...floor,
                            flats: result
                        }
                    }
                } else {
                    return {
                        ...floor,
                        rooms: [...floor.rooms, {
                            ...roomInitial,
                            name: (floor?.name && basicPropertyData?.ownerId) || floor.rooms[floor.rooms?.length - 1]?.name ? `Room ${floor?.rooms?.length + 1}` : '',
                        }]
                    }
                }
            } else {
                return floor
            }
        })
        formik.setValues({
            floors: updatedFloor
        })
    };

    const handleAddBed = (index, flatIndex, roomIndex) => {
        const updatedFloor = formik.values.floors?.map((floor, floorIndex) => {
            if (floorIndex === index) {
                if (disableFlat) {
                    const result = floor?.flats?.map((flat, flIndex) => {
                        if (flIndex === flatIndex) {
                            const rooms = flat?.rooms?.map((rm, rmIndex) => {
                                if (rmIndex === roomIndex) {
                                    return {
                                        ...rm,
                                        beds: [...rm.beds, {
                                            ...initialBed,
                                            name: rm?.beds[rm?.beds?.length - 1]?.name ? `Bed ${rm?.beds?.length + 1}` : ''
                                        }]
                                    }
                                } else {
                                    return rm
                                }
                            })

                            if (rooms?.length > 0) {
                                return {
                                    ...flat,
                                    rooms: rooms
                                }
                            }
                        } else {
                            return flat
                        }
                    })
                    if (result?.length > 0) {
                        return {
                            ...floor,
                            flats: result
                        }
                    }
                } else {
                    const rooms = floor?.rooms?.map((rm, rmIndex) => {
                        if (rmIndex === roomIndex) {
                            return {
                                ...rm,
                                beds: [...rm.beds, {
                                    ...initialBed,
                                    name: rm?.beds[rm?.beds?.length - 1]?.name ? `Bed ${rm?.beds?.length + 1}` : ''
                                }]
                            }
                        } else {
                            return rm
                        }
                    })

                    if (rooms?.length > 0) {
                        return {
                            ...floor,
                            rooms: rooms
                        }
                    }
                }
            } else {
                return floor
            }
        })
        formik.setValues({
            floors: updatedFloor
        })
    };

    const handleFloorChange = (index, field, value) => {
        const updatedFloors = [...formik.values.floors];
        updatedFloors[index][field] = value;
        if (field === 'name' || field === 'user_id') {
            if (disableFlat) {
                updatedFloors[index]['flats'] = updatedFloors[index].flats?.map((item, index) => {
                    let roomArray
                    if (basicPropertyData?.ownerId) {
                        roomArray = item?.rooms.map((rm, rmIndex) => {
                            return {
                                ...rm,
                                name: `Room ${rmIndex + 1}`,
                            }
                        })

                        return {
                            ...item,
                            name: `Flat ${index + 1}`,
                            rooms: roomArray
                        }
                    } else {
                        return {
                            ...item,
                            name: `Flat ${index + 1}`,
                        }
                    }
                })
            } else {
                updatedFloors[index]['rooms'] = updatedFloors[index].rooms?.map((item, index) => {
                    if (basicPropertyData?.ownerId) {
                        return {
                            ...item,
                            name: `Room ${index + 1}`,
                        }
                    } else if (!basicPropertyData?.ownerId && field === 'user_id') {
                        return {
                            ...item,
                            name: `Room ${index + 1}`,
                        }
                    } else {
                        return item
                    }
                })
            }
            formik.setValues({
                floors: updatedFloors
            })
            let options = []
            formik.values.floors.forEach((item) => {
                options.push(item.name)
            })
            const diff = floor.filter((it) => {
                if (!options.includes(it)) {
                    return it
                }
            })
            setFloorOptions(diff)
            setUpdate(update + 1)
        }
    }

    const handleFlatChange = (index, flatIndex, field, value) => {
        if (field === 'user_id') {
            const updatedFloor = formik.values.floors?.map((floor, floorIndex) => {
                if (floorIndex === index) {
                    const flats = floor?.flats.map((flat, flIndex) => {
                        if (flIndex === flatIndex) {
                            return {
                                ...flat,
                                user_id: value,
                                rooms: flat.rooms?.map((rm, rmIndex) => {
                                    return {
                                        ...rm,
                                        name: `Room ${rmIndex + 1}`
                                    }
                                })
                            }
                        } else {
                            return flat
                        }
                    })

                    if (flats?.length > 0) {
                        return {
                            ...floor,
                            flats: flats
                        }
                    }
                } else {
                    return floor
                }
            })
            formik.setValues({
                floors: updatedFloor
            })
        }
    };

    const handleRoomChange = (index, flatIndex, roomIndex, field, value) => {
        if (field === 'room_type_id') {
            const updatedFloor = formik.values.floors?.map((floor, floorIndex) => {
                if (floorIndex === index) {
                    if (disableFlat) {
                        const flats = floor?.flats.map((flat, flIndex) => {
                            if (flIndex === flatIndex) {
                                const rooms = flat?.rooms?.map((room, rmIndex) => {
                                    if (rmIndex === roomIndex) {
                                        return {
                                            ...room,
                                            room_type_id: value,
                                            beds: room?.beds?.map((bed, bedIndex) => {
                                                if (value) {
                                                    return {
                                                        ...bed,
                                                        name: `Bed ${bedIndex + 1}`
                                                    }
                                                } else {
                                                    return {
                                                        ...bed,
                                                        name: '',
                                                        price: '',
                                                    }
                                                }
                                            })
                                        }
                                    } else {
                                        return room
                                    }
                                })

                                if (rooms?.length > 0) {
                                    return {
                                        ...flat,
                                        rooms: rooms
                                    }
                                }
                            } else {
                                return flat
                            }
                        })

                        if (flats?.length > 0) {
                            return {
                                ...floor,
                                flats: flats
                            }
                        }
                    } else {
                        const rooms = floor?.rooms?.map((room, rmIndex) => {
                            if (rmIndex === roomIndex) {
                                return {
                                    ...room,
                                    room_type_id: value,
                                    beds: room?.beds?.map((bed, bedIndex) => {
                                        if (value) {
                                            return {
                                                ...bed,
                                                name: `Bed ${bedIndex + 1}`
                                            }
                                        } else {
                                            return {
                                                ...bed,
                                                name: '',
                                                price: '',
                                            }
                                        }
                                    })
                                }
                            } else {
                                return room
                            }
                        })

                        if (rooms?.length > 0) {
                            return {
                                ...floor,
                                rooms: rooms
                            }
                        }
                    }
                } else {
                    return floor
                }
            })
            formik.setValues({
                floors: updatedFloor
            })
        }
    };

    const removeFloor = (index) => {
        if (propertyData?.id) {
            let isTenantExist = false
            if (disableFlat) {
                formik.values.floors[index]?.flats?.forEach((flat) => {
                    flat.rooms?.forEach((room) => {
                        const data = room?.beds?.find((item) => item?.user_id)
                        if (data) {
                            isTenantExist = true
                            return
                        }
                    })
                })
            } else {
                formik.values.floors[index]?.rooms?.forEach((room) => {
                    const data = room?.beds?.find((item) => item?.user_id)
                    if (data) {
                        isTenantExist = true
                        return
                    }
                })
            }

            if (isTenantExist) {
                return toast.error('To remove this floor first removed all attached tenants to this floor.')
            }
        }
        if (formik.values.floors?.length === 1) {
            return;
        }

        const updatedFloors = [...formik.values.floors];
        updatedFloors.splice(index, 1);
        formik.setValues({
            floors: [...updatedFloors]
        })
        let options = []
        updatedFloors.forEach((item) => {
            options.push(item.name)
        })
        const diff = floor.filter((it) => {
            if (!options.includes(it)) {
                return it
            }
        })
        setFloorOptions(diff)
        setUpdate(update + 1)
    };

    const removeFlat = (index, flatIndex) => {
        if (propertyData?.id) {
            let isTenantExist = false
            formik.values.floors[index]?.flats[flatIndex]?.rooms?.forEach((room) => {
                const data = room?.beds?.find((item) => item?.user_id)
                if (data) {
                    isTenantExist = true
                    return
                }
            })

            if (isTenantExist) {
                return toast.error('To remove this flat first removed all attached tenants to this flat.')
            }
        }
        if (formik.values.floors[index].flats?.length === 1) {
            return;
        }

        const floors = formik.values.floors.map((floor, floorIndex) => {
            if (floorIndex === index) {
                const flats = []
                floor.flats.forEach((flat, flIndex) => {
                    if (flIndex < flatIndex) {
                        flats.push(flat)
                    } else if (flIndex > flatIndex) {
                        flats.push({
                            ...flat,
                            name: flat?.name ? `Flat ${flIndex + 1 - 1}` : ''
                        })
                    }
                })

                if (flats?.length > 0) {
                    return {
                        ...floor,
                        flats: flats
                    }
                }
            } else {
                return floor
            }
        })

        formik.setValues({
            floors: floors
        })
        setUpdate(update + 1)
    };

    const removeRoom = (index, flatIndex, roomIndex) => {
        if (propertyData?.id) {
            const room = disableFlat ? formik.values.floors[index]?.flats[flatIndex].rooms[roomIndex] : formik.values.floors[index]?.rooms[roomIndex]
            const data = room?.beds?.find((item) => item?.user_id)
            if (data) {
                return toast.error('To remove this room first removed all attached tenants to this room.')
            }
        }
        if (disableFlat ? formik.values.floors[index].flats[flatIndex]?.rooms?.length === 1 : formik.values.floors[index]?.rooms?.length === 1) {
            return;
        }

        const floors = formik.values.floors.map((floor, floorIndex) => {
            if (floorIndex === index) {
                if (disableFlat) {
                    const flats = floor.flats.map((flat, flIndex) => {
                        if (flIndex === flatIndex) {
                            const rooms = []
                            flat?.rooms.forEach((room, rmIndex) => {
                                if (rmIndex < roomIndex) {
                                    rooms.push(room)
                                } else if (rmIndex > roomIndex) {
                                    rooms.push({
                                        ...room,
                                        name: room?.name ? `Room ${rmIndex + 1 - 1}` : ''
                                    })
                                }
                            })

                            if (rooms?.length > 0) {
                                return {
                                    ...flat,
                                    rooms: rooms
                                }
                            }
                        } else {
                            return flat
                        }
                    })

                    if (flats?.length > 0) {
                        return {
                            ...floor,
                            flats: flats
                        }
                    }
                } else {
                    const rooms = []
                    floor.rooms.forEach((room, rmIndex) => {
                        if (rmIndex < roomIndex) {
                            rooms.push(room)
                        } else if (rmIndex > roomIndex) {
                            rooms.push({
                                ...room,
                                name: room?.name ? `Room ${rmIndex + 1 - 1}` : ''
                            })
                        }
                    })

                    if (rooms?.length > 0) {
                        return {
                            ...floor,
                            rooms: rooms
                        }
                    }
                }

            } else {
                return floor
            }
        })

        formik.setValues({
            floors: floors
        })
        setUpdate(update + 1)
    };

    const formik = useFormik({
        initialValues: {
            ...initalFloorData
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            floorName: Yup.array().of(Yup.object().shape({
                name: Yup.string()
                    .required("Please enter property floor name")
            })).required().min(1),
            name: Yup.string().optional()
        })
    });

    useEffect(() => {
        if (propertyData) {
            const options = propertyData.floors?.map((item) => item?.name)
            formik.setValues({
                floors: propertyData.floors
            })
            const diff = floor.filter((it) => {
                if (!options.includes(it)) {
                    return it
                }
            })
            setFloorOptions(diff)
            setUpdate(update + 1)
        }

    }, [propertyData])

    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: {
                        color: 'red', // Change the color here
                    },
                },
            },
        },
    });

    const removeBed = (index, flatIndex, roomIndex, bedIndex) => {
        if (propertyData?.id) {
            const bed = disableFlat ? formik.values.floors[index]?.flats[flatIndex].rooms[roomIndex]?.beds[bedIndex] : formik.values.floors[index]?.rooms[roomIndex]?.beds[bedIndex]
            if (bed?.user_id) {
                return toast.error('To remove this bed first removed attached tenant to this bed.')
            }
        }
        if (disableFlat ? formik.values.floors[index].flats[flatIndex]?.rooms[roomIndex]?.beds?.length === 1 : formik.values.floors[index]?.rooms[roomIndex]?.beds?.length === 1) {
            return;
        }
        const floors = formik.values.floors.map((floor, floorIndex) => {
            if (floorIndex === index) {
                if (disableFlat) {
                    const flats = floor.flats.map((flat, flIndex) => {
                        if (flIndex === flatIndex) {
                            const rooms = flat?.rooms.map((room, rmIndex) => {
                                if (rmIndex === roomIndex) {
                                    const beds = []
                                    room?.beds?.forEach((bed, bdIndex) => {
                                        if (bdIndex < bedIndex) {
                                            beds.push(bed)
                                        } else if (bdIndex > bedIndex) {
                                            beds.push({
                                                ...bed,
                                                name: bed?.name ? `Bed ${bdIndex + 1 - 1}` : ''
                                            })
                                        }
                                    })

                                    if (beds?.length > 0) {
                                        return {
                                            ...room,
                                            beds: beds
                                        }
                                    }
                                } else {
                                    return room
                                }
                            })

                            if (rooms?.length > 0) {
                                return {
                                    ...flat,
                                    rooms: rooms
                                }
                            }
                        } else {
                            return flat
                        }
                    })

                    if (flats?.length > 0) {
                        return {
                            ...floor,
                            flats: flats
                        }
                    }
                } else {
                    const rooms = floor.rooms.map((room, rmIndex) => {
                        if (rmIndex === roomIndex) {
                            const beds = []
                            room?.beds?.forEach((bed, bdIndex) => {
                                if (bdIndex < bedIndex) {
                                    beds.push(bed)
                                } else if (bdIndex > bedIndex) {
                                    beds.push({
                                        ...bed,
                                        name: bed?.name ? `Bed ${bdIndex + 1 - 1}` : ''
                                    })
                                }
                            })

                            if (beds?.length > 0) {
                                return {
                                    ...room,
                                    beds: beds
                                }
                            }
                        } else {
                            return room
                        }
                    })

                    if (rooms?.length > 0) {
                        return {
                            ...floor,
                            rooms: rooms
                        }
                    }
                }

            } else {
                return floor
            }
        })

        formik.setValues({
            floors: floors
        })
    }

    function handleAcceptedFiles(files) {
        files.map((file) =>
            Object.assign(file, {
                uniqueId: uuid.v4(),
                location: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            }),
        );

        const fieldUpdate = disableFlat ? `floors[${indexData.floorIndex}].flats[${indexData.flatIndex}].rooms[${indexData.roomIndex}].documents` : `floors[${indexData.floorIndex}].rooms[${indexData.roomIndex}].documents`
        const documents = disableFlat ?
            (formik.values.floors[indexData.floorIndex]?.flats[indexData?.flatIndex]?.rooms[indexData.roomIndex]?.documents?.length > 0 ? [...formik.values.floors[indexData.floorIndex].flats[indexData.flatIndex].rooms[indexData.roomIndex].documents, ...files] : files)
            : (formik.values.floors[indexData.floorIndex]?.rooms[indexData.roomIndex]?.documents?.length > 0 ? [...formik.values.floors[indexData.floorIndex].rooms[indexData.roomIndex].documents, ...files] : files)

        setSelectedFiles((prev) => {
            return prev ? [...prev, ...files] : files
        })
        formik.setFieldValue(fieldUpdate, documents)
    }

    async function handleFileRemove(file, fileIndex) {
        let newDocuments
        const documents = disableFlat ? formik.values.floors[indexData.floorIndex]?.flats[indexData?.flatIndex]?.rooms[indexData.roomIndex]?.documents
            : formik.values.floors[indexData.floorIndex]?.rooms[indexData.roomIndex]?.documents
            newDocuments = documents?.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId))
            if (file?.id) {
                const response = await documentRemove(file.id, { moduleId: file.room_id, module: 'room' });
                toast.success(response.data.message);
            }


        const fieldUpdate = disableFlat ? `floors[${indexData.floorIndex}].flats[${indexData.flatIndex}].rooms[${indexData.roomIndex}].documents` : `floors[${indexData.floorIndex}].rooms[${indexData.roomIndex}].documents`
        setSelectedFiles(newDocuments)
        formik.setFieldValue(fieldUpdate, newDocuments)

        return true
    }

    useEffect(() => {
        emitPropertyData(formik.values)
    }, [formik.values?.floors])

    return (
        <div className="bed_matrix_section" key={update}>
            <h5>{heading}</h5>
            <Card className="p-3" style={{ overflowX: 'auto' }}>
                <div className="roomWrapper">
                    {formik.values.floors?.length > 0 && formik.values.floors.map((floor, index) => (
                        <div key={`floor-${index}`} className="roomWrap d-flex ">
                            <div className="floorWrapper">
                                <div className='d-flex align-items-center'>
                                    <Autocomplete
                                        disableportal
                                        name={`formik.values.floors[${index}].name`}
                                        value={floor?.name ?? ''}
                                        id="combo-box-demo"
                                        key={index}
                                        options={floorOptions}
                                        disableClearable
                                        onChange={(event, newValue) => {
                                            handleFloorChange(index, 'name', newValue)
                                        }}
                                        sx={{ minWidth: 150, marginRight: '10px' }}
                                        renderInput={(params) => <ThemeProvider theme={theme}><TextField {...params} label="Select Floor" size="small" required /> </ThemeProvider>}
                                    />
                                    {!basicPropertyData?.ownerId && !disableFlat && (
                                        <div style={{ minWidth: '200px', marginRight: '15px', maxWidth: '200px' }}>
                                            <ThemeProvider theme={theme}>
                                                <SearchDropDownListPaginationComponent
                                                    label="Select Floor Owner"
                                                    searchEnabled={false}
                                                    listCall={getAllUser}
                                                    disabled={!floor?.name}
                                                    disableClearable={propertyData?.id}
                                                    defaultValue={floor?.user_id?.id ? floor?.user_id : null}
                                                    emitItem={(newValue) => {
                                                        handleFloorChange(index, 'user_id', newValue ? newValue : "")
                                                    }}
                                                    apiParams={{
                                                        filter_fields: ['role', 'status'],
                                                        filter_inputs: [`${KNOWN_ROLES.PROSPECTING_OWNER},${KNOWN_ROLES.OWNER}`, USER_STATUS.ACTIVE],
                                                    }}
                                                    sx={{ minWidth: 150, marginRight: '15px' }}
                                                    size="small"
                                                    required
                                                />
                                            </ThemeProvider>
                                        </div>
                                    )}
                                    <CancelOutlinedIcon color="error" onClick={() => {
                                        removeFloor(index)
                                    }} className={`cancelIcon ${index === 0 ? 'fadedCross' : ''}`} />
                                </div>
                            </div>
                            {(disableFlat === true && basicPropertyData?.type === PROPERTY_TYPES.APARTMENT ?
                                <div className="roomWrapper">
                                    {floor?.flats?.map((flat, flIndex) => (
                                        <div key={`flat-${flIndex}`} className="roomWrap d-flex">
                                            <div className="flatOwner padding_left_common">
                                                <div className="flatOwnerWrapper d-flex align-items-center">
                                                    <TextField
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        readOnly
                                                        label="Selected flat"
                                                        value={flat?.name ?? ''}
                                                        sx={{ minWidth: 150, marginRight: '10px' }}
                                                        size="small"
                                                    />

                                                    {!basicPropertyData?.ownerId && (
                                                        <div style={{ minWidth: '250px', marginRight: '15px', maxWidth: '300px' }}>
                                                            <ThemeProvider theme={theme}>
                                                                <SearchDropDownListPaginationComponent
                                                                    label="Select Flat Owner"
                                                                    searchEnabled={false}
                                                                    disableClearable={propertyData?.id}
                                                                    disabled={!flat?.name}
                                                                    listCall={getAllUser}
                                                                    defaultValue={flat?.user_id?.name ? flat.user_id : null}
                                                                    emitItem={(newValue) => {
                                                                        handleFlatChange(index, flIndex, 'user_id', newValue ? { id: newValue.id, name: newValue.name, code: newValue.code } : "")
                                                                    }}
                                                                    apiParams={{
                                                                        filter_fields: ['role', 'status'],
                                                                        filter_inputs: [`${KNOWN_ROLES.PROSPECTING_OWNER},${KNOWN_ROLES.OWNER}`, USER_STATUS.ACTIVE],
                                                                    }}
                                                                    sx={{ minWidth: 150, marginRight: '15px' }}
                                                                    size="small"
                                                                    required={true}
                                                                />
                                                            </ThemeProvider>
                                                        </div>
                                                    )}
                                                    <CancelOutlinedIcon color="error" onClick={() => removeFlat(index, flIndex)}
                                                        className={`cancelIcon ${flIndex === 0 ? 'fadedCross' : ''}`} />
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column  padding_left_common'>
                                                <div className="roomWrapper">
                                                    {flat?.rooms?.map((room, roomIndex) => (
                                                        <div key={`room-${roomIndex}`} className="roomWrap d-flex">
                                                            <div
                                                                className="room_section d-flex flex-column"
                                                                style={{ padding: "5px 10px 5px 0" }}
                                                            >
                                                                <div className="singleRoomWrap d-flex align-items-center mt-1 pe-2">
                                                                    <TextField
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        readOnly
                                                                        label="Selected room"
                                                                        value={room?.name}
                                                                        sx={{ minWidth: 150, marginRight: '10px' }}
                                                                        size="small"
                                                                    />
                                                                    <SelectDropDownListPaginationComponent
                                                                        label={<div>Select Room Type <AsterikLabel /></div>}
                                                                        searchEnabled={false}
                                                                        disabled={!room?.name}
                                                                        disableClearable={propertyData?.id}
                                                                        listCall={roomTypeListing}
                                                                        defaultValue={
                                                                            room?.room_type_id?.id ? room?.room_type_id : null
                                                                        }
                                                                        emitItem={(newValue) => {
                                                                            handleRoomChange(
                                                                                index,
                                                                                flIndex,
                                                                                roomIndex,
                                                                                "room_type_id",
                                                                                newValue ? newValue : ""
                                                                            );
                                                                        }}
                                                                    />
                                                                    <CancelOutlinedIcon
                                                                        color="error"
                                                                        onClick={() => removeRoom(index, flIndex, roomIndex)}
                                                                        className={`cancelIcon ${roomIndex === 0 ? "fadedCross" : ""}`}
                                                                    />
                                                                </div>
                                                                <Link
                                                                    href="#"
                                                                    underline="none"
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        color: (basicPropertyData?.ownerId || flat?.user_id) && flat?.name ? "blue" : "grey",
                                                                        pointerEvents: !((basicPropertyData?.ownerId || flat?.user_id) && flat?.name) ? "none" : "",
                                                                    }}
                                                                    onClick={() => {
                                                                        setIndexData({
                                                                            roomIndex,
                                                                            flatIndex: flIndex,
                                                                            floorIndex: index
                                                                        })
                                                                        setSelectedFiles(room.documents);
                                                                        handleOpen();
                                                                    }}
                                                                >
                                                                    <AddPhotoAlternateOutlined /> &nbsp; Add Picture
                                                                </Link>
                                                                <Link
                                                                    href="#"
                                                                    underline="none"
                                                                    onClick={() => {
                                                                        setIndexData({
                                                                            roomIndex,
                                                                            flatIndex: flIndex,
                                                                            floorIndex: index
                                                                        })
                                                                        setIsModalOpen(true);
                                                                    }}
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        color: (basicPropertyData?.ownerId || flat?.user_id) && flat?.name ? "blue" : "grey",
                                                                        pointerEvents: !((basicPropertyData?.ownerId || flat?.user_id) && floor?.name) ? "none" : "",
                                                                    }}
                                                                >
                                                                    <OtherHousesOutlined /> &nbsp; Add Amenities
                                                                </Link>
                                                            </div>

                                                            <div className="bed_section d-flex flex-column  padding_left_common">
                                                                {room?.beds?.map((bed, bedIndex) => (
                                                                    <div key={`bed-${bedIndex}`}>
                                                                        <div className="d-flex align-items-center" style={{ padding: '5px 0' }}>
                                                                            <TextField
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                readOnly
                                                                                label="Selected bed"
                                                                                value={bed?.name}
                                                                                sx={{ minWidth: 180, marginRight: '15px' }}
                                                                                size="small"
                                                                            />
                                                                            <div style={{ width: '200px', marginRight: '15px' }}>
                                                                                <ThemeProvider theme={theme}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label={"Price"}
                                                                                        name={`floors.${index}.flats.${flIndex}.rooms.${roomIndex}.beds.${bedIndex}.price`}                                                    
                                                                                        type="number"
                                                                                        disabled={!bed?.name}
                                                                                        value={bed.price}
                                                                                        onChange={formik.handleChange}
                                                                                        required
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        className="w-100"
                                                                                    />
                                                                                </ThemeProvider>
                                                                            </div>
                                                                            <CancelOutlinedIcon color="error"
                                                                                onClick={() => removeBed(index, flIndex, roomIndex, bedIndex)}
                                                                                className={`cancelIcon ${bedIndex === 0 ? 'fadedCross' : ''}`} />
                                                                        </div>

                                                                    </div>
                                                                ))}
                                                                <div>
                                                                    <Link href="javascript:void(0)" underline="none" style={{ display: 'inline', marginBottom: '10px' }} onClick={() => handleAddBed(index, flIndex, roomIndex)}>
                                                                        <AddOutlinedIcon /> &nbsp; Add Bed
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <Link href="javascript:void(0)" underline="none" onClick={() => handleAddRoom(index, flIndex)}>
                                                        <AddOutlinedIcon /> &nbsp; Add Room
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <Link href="javascript:void(0)" underline="none" onClick={() => handleAddFlat(index)} style={{ marginLeft: '10px', padding: '0 0 10px 0' }}>
                                        <AddOutlinedIcon /> &nbsp; Add Flat
                                    </Link>
                                </div> : (<div style={{ marginLeft: '15px' }} >
                                    <div className="roomWrapper">
                                        {floor?.rooms?.map((room, roomIndex) => (
                                            <div key={`room-${roomIndex}`} className="roomWrap d-flex">
                                                <div
                                                    className="room_section d-flex flex-column"
                                                    style={{ padding: "5px 10px 5px 0" }}
                                                >
                                                    <div className="singleRoomWrap d-flex align-items-center">
                                                        <TextField
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            readOnly
                                                            label="Selected room"
                                                            value={room?.name}
                                                            sx={{ minWidth: 150, marginRight: '10px' }}
                                                            size="small"
                                                        />
                                                        <SelectDropDownListPaginationComponent
                                                            label={<div>Select Room Type <AsterikLabel /></div>}
                                                            searchEnabled={false}
                                                            disabled={!room?.name}
                                                            disableClearable={propertyData?.id}
                                                            listCall={roomTypeListing}
                                                            defaultValue={
                                                                room?.room_type_id?.id ? room?.room_type_id : null
                                                            }
                                                            emitItem={(newValue) => {
                                                                handleRoomChange(
                                                                    index,
                                                                    '',
                                                                    roomIndex,
                                                                    "room_type_id",
                                                                    newValue ? newValue : ""
                                                                );
                                                            }}
                                                        />
                                                        <CancelOutlinedIcon
                                                            color="error"
                                                            onClick={() => removeRoom(index, '', roomIndex)}
                                                            className={`cancelIcon ${roomIndex === 0 ? "fadedCross" : ""}`}
                                                        />
                                                    </div>
                                                    <Link
                                                        href="#"
                                                        underline="none"
                                                        style={{
                                                            marginTop: "10px",
                                                            color: (basicPropertyData?.ownerId || floor?.user_id) && floor?.name ? "blue" : "grey",
                                                            pointerEvents: !((basicPropertyData?.ownerId || floor?.user_id) && floor?.name) ? "none" : "",
                                                        }}
                                                        onClick={() => {
                                                            setIndexData({
                                                                roomIndex,
                                                                flatIndex: '',
                                                                floorIndex: index
                                                            })
                                                            setSelectedFiles(room.documents);
                                                            handleOpen();
                                                        }}
                                                    >
                                                        <AddPhotoAlternateOutlined /> &nbsp; Add Picture
                                                    </Link>
                                                    <Link
                                                        href="#"
                                                        underline="none"
                                                        onClick={() => {
                                                            setIndexData({
                                                                roomIndex,
                                                                flatIndex: '',
                                                                floorIndex: index
                                                            })
                                                            setIsModalOpen(true);
                                                        }}
                                                        style={{
                                                            marginTop: "10px",
                                                            color: (basicPropertyData?.ownerId || floor?.user_id) && floor?.name ? "blue" : "grey",
                                                            pointerEvents: !((basicPropertyData?.ownerId || floor?.user_id) && floor?.name) ? "none" : "",
                                                        }}
                                                    >
                                                        <OtherHousesOutlined /> &nbsp; Add Amenities
                                                    </Link>
                                                </div>

                                                <div className="bed_section d-flex flex-column  padding_left_common">
                                                    {room?.beds?.map((bed, bedIndex) => (
                                                        <div key={`bed-${bedIndex}`}>
                                                            <div className="d-flex align-items-center" style={{ padding: '5px 0' }}>
                                                                <TextField
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    readOnly
                                                                    label="Selected bed"
                                                                    value={bed?.name}
                                                                    sx={{ minWidth: 180, marginRight: '15px' }}
                                                                    size="small"
                                                                />
                                                                <div style={{ width: '200px', marginRight: '15px' }}>
                                                                    <ThemeProvider theme={theme}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label={"Price"}
                                                                            name={`floors.${index}.rooms.${roomIndex}.beds.${bedIndex}.price`}                                                    
                                                                            type="number"
                                                                            disabled={!bed?.name}
                                                                            value={bed.price}
                                                                            onChange={formik.handleChange}
                                                                            required
                                                                            variant="outlined"
                                                                            size="small"
                                                                            className="w-100"
                                                                        />
                                                                    </ThemeProvider>
                                                                </div>
                                                                <CancelOutlinedIcon color="error"
                                                                    onClick={() => removeBed(index, '', roomIndex, bedIndex)}
                                                                    className={`cancelIcon ${bedIndex === 0 ? 'fadedCross' : ''}`} />
                                                            </div>

                                                        </div>
                                                    ))}
                                                    <div>
                                                        <Link href="javascript:void(0)" underline="none" onClick={() => handleAddBed(index, '', roomIndex)} style={{ display: 'inline', marginBottom: '10px' }} >
                                                            <AddOutlinedIcon /> &nbsp; Add Bed
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <Link href="javascript:void(0)" underline="none" onClick={() => handleAddRoom(index, '')}>
                                            <AddOutlinedIcon /> &nbsp; Add Room
                                        </Link>
                                    </div>
                                </div>)
                            )}
                        </div>
                    ))}
                    <Link href="javascript:void(0)" underline="none" onClick={handleAddFloor} >
                        <AddOutlinedIcon /> &nbsp; Add Floor
                    </Link>
                </div>

                {open && (
                    <UploadDocumentModal
                        key={selectedFiles?.length}
                        open={open}
                        handleClose={() => setOpen(false)}
                        documents={selectedFiles}
                        handleSubmit={(files) => handleAcceptedFiles(files)}
                        handleDelete={handleFileRemove}
                    />
                )}

                {isModalOpen && (
                    <AddAmenitiesModal
                        open={isModalOpen}
                        handleClose={() => setIsModalOpen(false)}
                        handleAddAmenities={(data) => {
                            const fieldUpdate = disableFlat ? `floors[${indexData.floorIndex}].flats[${indexData.flatIndex}].rooms[${indexData.roomIndex}].amenities` : `floors[${indexData.floorIndex}].rooms[${indexData.roomIndex}].amenities`
                            formik.setFieldValue(fieldUpdate, data)
                        }}
                        selectedAmenities={disableFlat ? formik.values.floors[indexData.floorIndex]?.flats[indexData?.flatIndex]?.rooms[indexData.roomIndex].amenities : formik.values.floors[indexData.floorIndex]?.rooms[indexData.roomIndex].amenities}
                    />
                )} 
            </Card>
        </div>
    )
}