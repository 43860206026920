const invoiceList = [
  {
    id: 1,
    invoiceID: '#MN0131',
    date: '10 Jul, 2020',
    Amount: '141',
    color: 'success',
    status: 'Paid',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Connie Franco',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123456',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260.00',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250.00',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 2,
    invoiceID: '#MN0130',
    date: '09 Jul, 2020',
    Amount: '153',
    color: 'success',
    status: 'Paid',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Paul Reynolds',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123457',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 3,
    invoiceID: '#MN0129',
    date: '09 Jul, 2020',
    Amount: '220',
    color: 'warning',
    status: 'Pending',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Ronald Patterson',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123457',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 4,
    invoiceID: '#MN0128',
    date: ' 08 Jul, 2020',
    Amount: '175',
    color: 'success',
    status: 'Paid',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Adella Perez',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 5,
    invoiceID: '#MN0127',
    date: '07 Jul, 2020',
    Amount: '160',
    color: 'success',
    status: 'Paid',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Theresa Mayers',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 6,
    invoiceID: '#MN0126',
    date: '06 Jul, 2020',
    Amount: '150',
    color: 'success',
    status: 'Paid',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Michael Wallace',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 7,
    invoiceID: '#MN0125',
    date: '05 Jul, 2020',
    Amount: '165',
    color: 'warning',
    status: 'Pending',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Oliver Gonzales',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 8,
    invoiceID: '#MN0124',
    date: '05 Jul, 2020',
    Amount: '170',
    color: 'success',
    status: 'Paid',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'David Burke',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 9,
    invoiceID: '#MN0123',
    date: '04 Jul, 2020',
    Amount: '140',
    color: 'warning',
    status: 'Pending',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Willie Verner',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 10,
    invoiceID: '#MN0122',
    date: '03 Jul, 2020',
    Amount: '155',
    color: 'success',
    status: 'Paid',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Felix Perry',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 11,
    invoiceID: '#MN0121',
    date: '02 Jul, 2020',
    Amount: '165',
    color: 'success',
    status: 'Paid',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Virgil Kelley',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
  {
    id: 12,
    invoiceID: '#MN0120',
    date: '02 Jul, 2020',
    Amount: '170',
    color: 'success',
    status: 'Pending',
    shippingAddress: '641 Counts Lane Wilmore, KY 40390',
    shippingEmail: 'abc@123.com',
    shippingPhoneno: '012-345-6789',
    billingName: 'Matthew Lawler',
    billingAddress: '4450 Fancher Drive Dallas, TX 75247',
    billingEmail: 'PrestonMiller@armyspy.com',
    billingPhoneno: '001-234-5678',
    orderId: '1123458',
    orderSummary: {
      items: [
        {
          id: 1,
          item: 'Nike N012 Running Shoes',
          price: '$260',
          quantity: 1,
          color: 'Gray',
          size: '08',
        },
        {
          id: 2,
          item: 'Adidas Running Shoes',
          price: '$250',
          quantity: 1,
          color: 'Black',
          size: '09',
        },
      ],
      subTotal: '$510.00',
      discount: '$50.00',
      shipping: '$25.00',
      tax: '$13.00',
      total: '$498.00',
    },
  },
];

export { invoiceList };
