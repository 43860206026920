import React, { useEffect, useState } from 'react';
import {
  Row, Form, Button, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { Autocomplete, TextField } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';

let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let years = Array.from({ length: 5 }, (_, i) => moment().year() - i)

export function RentSlipFilterDrawer({ isOpen, toggleDrawer, emitFilterItem, selectedFields, selectedInputs }) {
  const [filterObject, setFilterObject] = useState({
    property_name: '',
    month: '',
    year: '',
  });
  const [monthName, setMonthName] = useState()
  const [update, setUpdate] = useState(0)

  const handleApplyFilter = () => {
    const filterFields = [];
    const filterInputs = [];

    if (filterObject.property_name) {
      filterFields.push('property_name');
      filterInputs.push(filterObject.property_name);
    }

    if (filterObject.month) {
      filterFields.push('month');
      filterInputs.push(`${filterObject.month}-${filterObject.year}`);
    }

    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
  };

  const handleResetFilter = () => {
    setFilterObject({
      property_name: '',
      month: '',
      year: '',
    });
    emitFilterItem([], []);
    toggleDrawer();
  };

  useEffect(() => {
    selectedFields.forEach((field, index) => {
      if (field === 'month') {
        const dateArray = selectedInputs[index].split('-')
        setMonthName(months[dateArray[0] - 1])
        setFilterObject((prev) => ({ ...prev, [field]: dateArray[0], year: dateArray[1] }));
        setUpdate(update + 1)
      } else {
        setFilterObject((prev) => ({ ...prev, [field]: selectedInputs[index] }));
      }

    });
  }, [selectedFields, selectedInputs]);

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => {
        toggleDrawer(isOpen);
        setFilterObject({});
      }}
    >
      <OffcanvasHeader toggle={() => {
        toggleDrawer(isOpen);
        setFilterObject({});
      }}>
        Rent Slip Filter
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="tenantDetailsDrawer">
            <Row>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='property_name'
                  value={filterObject.property_name}
                  label="Property Name"
                  onChange={(e) => {
                    setFilterObject({
                      ...filterObject,
                      property_name: e.target.value
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  sx={{
                    zIndex: 9999,
                    width: '100%'
                  }}
                  size="small"
                  name="month"
                  key={update}
                  value={monthName}
                  options={months}
                  onChange={(e) => {
                    const indexValue =
                      +months.indexOf(e?.target?.textContent) + 1;
                    setFilterObject({
                      ...filterObject,
                      month: indexValue
                    });
                    setMonthName(e?.target?.textContent)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Month" />
                  )}
                />
              </div>
              {monthName && (
                <div className="col-md-6 col-12 mb-3">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{
                      zIndex: 9999,
                      width: '100%'
                    }}
                    size="small"
                    name="year"
                    value={filterObject.year}
                    options={years}
                    onChange={(e) => {
                      setFilterObject({
                        ...filterObject,
                        year: e?.target?.textContent
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Year" />
                    )}
                  />
                </div>
              )}
            </Row>
            <div className="formActions btn_right">
              <Button className="yellow_gradient_btn mx-2" disabled={!(monthName ? filterObject.year : true)} onClick={handleApplyFilter}>
                Apply Filter
              </Button>
              <Button className="red_gradient_btn" onClick={handleResetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}
